import inCompPage from './focuswin/pages/publ/incomp/InCompPage';
import * as fowinMes from './focuswin/pages/mes/index';
import { fwUtil } from './focuswin/script/util';
import { withNavigationWatcher } from './contexts/navigation';

let mesRoutes = [];

async function fetchRoutes() {
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate();
        const response = await axiosInstance.post('/public/api/menu/routerList');
        return {
            userMenu: response.data.userMenu,
        };
    } catch (error) {
        console.error("appRoutes.setting.error : " + error);
    }
};

export async function generateDynamicRoutes() {
    const menuList = await fetchRoutes();
    const mesMenuList = menuList.userMenu;

    const mesMap = mesMenuList.map(menuitem => {
        let menuGubu = menuitem.menu_gubu.trim();
        let menuCode = menuitem.menu_code.trim();
        let menuHigh = menuitem.menu_high.trim();
        let menuName = menuitem.menu_name.trim();
        let menuPath = menuitem.menu_path.trim();
        let menuFile = menuitem.menu_file.trim();

        if (!fwUtil.conv.check.nue(menuGubu) && menuGubu === "mes" && !fwUtil.conv.check.nue(menuPath) && !fwUtil.conv.check.nue(menuFile)) {
            return {
                mkey: menuCode,
                skey: menuHigh,
                name: menuName,
                path: menuPath,
                element: fowinMes[menuFile] || inCompPage
            };
        } else {
            return null;
        }
    }).filter(route => route !== null);   
    
    const mesHomeRoute = {
        path: '/mes/home',
        element: fowinMes.mesHome
    };

    mesMap.push(mesHomeRoute);

    const userRoute = {
        path: '/profile',
        element: fowinMes.Profile
    };

    mesMap.push(userRoute);

    mesRoutes = mesMap.map(route => ({
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    }));

    return {
        mesRoutes
    }
};