import defaultUser from '../focuswin/assets/user-default.png'

var userInfo = {
  userIdno: '',
  userName: '',
  posiName: '',
  deptName: '',
  avatarUrl: defaultUser,
}

export default userInfo;
