import TreeList, { RowDragging } from 'devextreme-react/tree-list';
import { fwUtil } from '../../util';
import "./tree-body.scss";

export const ReOrdingTree = (props) => {
    return (
        <TreeList
            ref={props.treeRef}
            dataSource={props.dataSource}
            keyExpr={props.keyExpr}
            parentIdExpr={props.parentIdExpr}
            showRowLines={true}
            showBorders={true}
            autoExpandAll={false}
            height={props.height}
            columnAutoWidth={fwUtil.conv.tern(true, props.columnAutoWidth)}
            rootValue={''}
        >
            <RowDragging
                onDragChange={props.onDragChange}
                onReorder={props.onReorder}
                allowDropInsideItem={fwUtil.conv.tern(false, props.allowDropInsideItem)}
                allowReordering={true}
                showDragIcons={fwUtil.conv.tern(true, props.showDragIcons)}
            />                
            {props.children}
        </TreeList>
    )
};