import { fwUtil } from "../../../util";

const coluCommOpts = (props) => {
    const {
        key,
        visible, fixed,
        dataField, caption,
        check, header, before, after,
        allowFiltering, allowSorting, allowEditing, allowResizing,
    } = props;

    const fixedOpt = fwUtil.conv.tern(false, fixed);
    const visiblieOpt = fwUtil.conv.tern(true, visible);
    const allowFilterOpt = fwUtil.conv.tern(true, allowFiltering);
    const allowSortingOpt = fwUtil.conv.tern(true, allowSorting);
    const allowEditingOpt = fwUtil.conv.tern(true, allowEditing);
    const allowResizingOpt = fwUtil.conv.tern(true, allowResizing);

    return {
        key: key,
        dataField: dataField,
        caption: caption,
        fixed: fixedOpt,
        visible: visiblieOpt,
        allowFiltering: allowFilterOpt,
        allowSorting: allowSortingOpt,
        allowEditing: allowEditingOpt,
        allowResizing: allowResizingOpt,
        cssClass: fwUtil.conv.class({ 'fw-check-column': check, 'fw-header-column': header, 'fw-before-column': before, 'fw-after-column': after }),
    }
}

const CountColTemplate = (cellInfo) => {
    return (
        <span className='fw-grid-count'>{cellInfo.row.rowIndex}</span>
    )
}

export const coluUtil = {
    opts: {
        comm: coluCommOpts,
    },
    template: {
        count: CountColTemplate,
    }
}
