// ProductsStat - 작업일보 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ProductsStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole,
        subsTitle, subsRole,
    } = props;

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/header/search`; // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/search`; // 서브 조회 api  
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = ['work_numb', 'item_code'];
    const subsKey = ['work_numb', 'item_code', 'rout_numb', 'rout_seri'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);
    // 검색 조건
    const [workNumb, setWorkNumb] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { workNumb: workNumb, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [workNumb, itemCode, itemName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { workNumb: keyData.work_numb, itemCode: keyData.item_code };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '작업지시번호', value: workNumb, valueChange: workNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [workNumb, workNumbChange, itemCode, itemCodeChange, itemName, itemNameChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "orde_qtys", caption: "지시수량" })}
                {DateCol({ dataField: "star_date", caption: "시작일자" })}
                {DateCol({ dataField: "clos_date", caption: "완료일자" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "rout_seri", caption: "일보순번" })}
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "cycl_time", caption: "재공시간" })}
                {CommCol({ dataField: "work_gubu", caption: "작업구분" })}
                {CommCol({ dataField: "work_name", caption: "작업자" })}
                {DateCol({ dataField: "work_date", caption: "작업일자" })}
                {DeciCol({ dataField: "work_qtys", caption: "작업수량" })}
                {DeciCol({ dataField: "infe_qtys", caption: "불량수량" })}
                {DeciCol({ dataField: "prod_memo", caption: "비고" })}
            </SubsGrid>
        </div>
    );
};
