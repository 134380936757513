import { fwUtil } from "../../focuswin/script/util";
import { apsGubu } from "../set";

const getEquiList = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/com/equ/equi/register/equicode/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemGubu = async () => {
    try {
        const api = `privat${apsGubu}/com/item/register/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemWorkGubu = async () => {
    try {
        const api = `privat${apsGubu}/com/item/register/workgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemEquiList = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/com/equ/equi/register/equicode/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsMatlList = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/com/mat/bom/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsMatlGubu = async () => {
    try {
        const api = `privat${apsGubu}/set/com/mat/bom/register/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsItemList = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/com/mat/bom/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsItemGubu = async () => {
    try {
        const api = `privat${apsGubu}/set/com/mat/bom/register/bomgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};


export const com = {
    item: {
        gubu: getItemGubu,
        work: getItemWorkGubu,
        equi: getItemEquiList,
    },
    boms: {
        matl: {
            info: getBomsMatlList,
            gubu: getBomsMatlGubu,
        },
        item: {
            info: getBomsItemList,
            gubu: getBomsItemGubu,
        }
    },
    equi: {
        info: getEquiList
    }
}