// EquiInfoStat - 설비 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { EquiInfoStatInsert } from './InfoStat-insert';
import { EquiInfoStatUpdate } from './InfoStat-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function EquiInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/register/search`;  // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/register/target`;  // 타겟 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/register/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/register/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/register/delete`;  // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'main_numb';
    const deleKey = (data) => {
        return { mainNumb: data }
    }           
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [equiName, setEquiName] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 관리 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);       

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, equiName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '설비명', value: equiName, valueChange: equiNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [equiName, equiNameChange, handleSearch]);

    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_numb", caption: "등록순번" })}
                {CommCol({ dataField: "equi_numb", caption: "관리번호" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "menu_comp", caption: "제작회사" })}
                {CommCol({ dataField: "equi_type", caption: "규격/형식" })}
                {CommCol({ dataField: "equi_seno", caption: "기기번호" })}
                {CommCol({ dataField: "equi_spec", caption: "용량/공칭능력" })}
                {CommCol({ dataField: "equi_loca", caption: "설치장소" })}
                {DateCol({ dataField: "reci_date", caption: "설치일자" })}
                {CommCol({ dataField: "insp_cycl", caption: "점검주기" })}
                {CommCol({ dataField: "uses_dept", caption: "사용부서" })}
                {CommCol({ dataField: "main_name", caption: "담당자(정)" })}
                {CommCol({ dataField: "subs_name", caption: "담당자(부)" })}
                {DateCol({ dataField: "menu_date", caption: "제작일자" })}
                {CommCol({ dataField: "equi_memo", caption: "비고" })}
            </PagingGrid>
            <EquiInfoStatInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <EquiInfoStatUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />                  
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 설비를 삭제하시겠습니까?'}
            />                 
        </div>
    );
};