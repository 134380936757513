import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormNumb, FormGrid, FormText, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    work_numb: '',
    item_code: '',
    item_name: '',
    item_quli: '',
    plan_numb: '',
    orde_numb: '',
    item_memo: '',
    work_qtys: 0,
}

const setWork = set.mes.mfg.work;

export function WorkOrdeRegiSubsInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const planGridRef = useRef(null);
    const itemGridRef = useRef(null);
    const [planData, setplanData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const planGridUpdate = useCallback((e) => setplanData(e), []);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const planKeyExpr = 'plan_numb';
    const itemKeyExpr = 'item_code';
    // 그리드 조회 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const [duraYesn, setDuraYesn] = useState(true);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);    
    const [planNumb, setPlanNumb] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const planNumbChange = useCallback((e) => setPlanNumb(e), []);
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []);
    // 그리드 팝업
    const [planPopupVisible, setPlanPopupVisible] = useState(false);
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const planPopupHiding = useCallback(() => setPlanPopupVisible(false), []);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 팝업 조회
    const planHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, planNumb: planNumb, itemCode: '', itemName: '' };
        const res = await setWork.plan(sendData);
        planGridUpdate(res);
    }, [dateValue, duraYesn, planNumb, planGridUpdate]);

    const itemHandleSearch = useCallback(async () => {
        const sendData = { itemCode: itemCode, itemName: itemName };
        const res = await setWork.item(sendData);
        itemGridUpdate(res);
    }, [itemCode, itemName, itemGridUpdate]);

    // 그리드 추가 툴바 아이템
    const planToolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '생산계획번호', value: planNumb, valueChange: planNumbChange, search: planHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: planHandleSearch },
    ], [planNumb, planNumbChange, planHandleSearch]);

    const itemToolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: itemHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: itemHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: itemHandleSearch }
    ], [itemCode, itemName, itemCodeChange, itemNameChange, itemHandleSearch]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                work_numb: seleData.work_numb || defaultData.work_numb,
                item_code: seleData.item_code || defaultData.item_code,
                item_name: seleData.item_name || defaultData.item_name,
                item_quli: seleData.item_quli || defaultData.item_quli,
                plan_numb: seleData.plan_numb || defaultData.plan_numb,
                orde_numb: seleData.orde_numb || defaultData.orde_numb,
                item_memo: seleData.item_memo || defaultData.item_memo,      
                work_qtys: seleData.work_qtys || defaultData.work_qtys,          
            };
            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true)
        const postData = {
            workNumb: data.work_numb || defaultData.work_numb,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            itemQuli: data.item_quli || defaultData.item_quli,
            planNumb: data.plan_numb || defaultData.plan_numb,
            ordeNumb: data.orde_numb || defaultData.orde_numb,
            itemMemo: data.item_memo || defaultData.item_memo,
            workQtys: data.work_qtys || defaultData.work_qtys,       
        };
        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const planPopupOnShowing = useCallback(() => {
        planHandleSearch();
    }, [planHandleSearch]);

    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 팝업 더블 클릭
    const planOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, plan_numb: e.data.plan_numb, item_code: e.data.item_code, item_name: e.data.item_name, item_quli: e.data.item_quli, 
            work_qtys: e.data.plan_qtys, orde_numb: e.data.orde_numb });
        planPopupHiding();
    }, [data, dataUpdate, planPopupHiding]);

    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name, item_quli: e.data.item_quli, plan_numb: '', plan_seri: '' });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    const PlanGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'계획'}
                role={'목록'}
                gridDataSource={planData}
                gridRef={planGridRef}
                mainKey={planKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowDblClick={planOnRowDblClick}              
                toolbar={{ default: true, items: planToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "plan_numb", caption: "생산계획번호" })}
                {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_quli", caption: "재질" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}                
                {NumbCol({ dataField: "plan_qtys", caption: "계획수량" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {DateCol({ dataField: "work_date", caption: "생산예정일" })}
                {CommCol({ dataField: "plan_memo", caption: "비    고" })} 
            </ScrollGrid>
        )
    }, [dateValue, dateValueChange, duraYesn, duraYesnChange, planData, planGridRef, planOnRowDblClick, planToolbarItems, isXSmall, isSmall]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, items: itemToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_gubu", caption: "구분" })}
                {CommCol({ dataField: "item_quli", caption: "재질" })}
            </ScrollGrid>
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, itemToolbarItems, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'계획 선택'}
                visible={planPopupVisible}
                shown={planPopupOnShowing}
                hiding={planPopupHiding}
            >
                {PlanGrid}
            </PopupGrid>
            <PopupGrid
                title={'제품 선택'}
                visible={itemPopupVisible}
                shown={itemPopupOnShowing}
                hiding={itemPopupHiding}
            >
                {itemGrid}
            </PopupGrid>
            <PopupForm
                title={'작업지시 품목 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormGrid({ value: data.plan_numb, onClick: () => setPlanPopupVisible(true), label: '계획번호', })}
                    {FormText({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호', disabled: true })}
                    {FormGrid({ value: data.item_code, onClick: () => setItemPopupVisible(true), label: '제품번호', required: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', disabled: true })}
                    {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true })}
                    {FormNumb({ value: data.work_qtys, onValueChange: updateField('work_qtys'), label: '지시수량(공정일괄등록)', length: [12, 2] })}
                </GroupItem>
                {FormMemo({ value: data.item_memo, onValueChange: updateField('item_memo'), label: '비고' })} 
            </PopupForm>
        </>
    );
}