export function convertShipDate(data) {
    const result = {};
    if (!data) {
        return;
    }
    data.forEach(item => {
        const dateKey = item.ship_date;

        if (!result[dateKey]) {
            result[dateKey] = {
                ship_date: dateKey,
                ship_qtys: 0,
                ship_pric: 0,
                ship_kwon: 0
            };
        }

        result[dateKey].ship_qtys += parseFloat(item.ship_qtys) || 0;
        result[dateKey].ship_pric += parseFloat(item.ship_pric) || 0;
        result[dateKey].ship_kwon += parseFloat(item.ship_kwon) || 0;
    });

    return Object.values(result);
}