import { useNavigate } from 'react-router-dom';
import DefaultUserImag from '../../../assets/user-default.png';
import { fwUtil } from '../../../script/util';
import { Button } from 'devextreme-react';
import { useCallback, useState } from 'react';
import { PopupAler } from '../../../script/components/popup/popup';

export function HomeUserInfo(props) {
    const { user } = props;
    const navigate = useNavigate();
    const [profileEditPopupVisible, setProfileEditPopupVisible] = useState(false);
    const profileEditPopupHide = useCallback(() => {
        setProfileEditPopupVisible(false);
    }, []);
    
    const onPropleEditBtnClick = useCallback(() => {
        setProfileEditPopupVisible(true);
    }, [setProfileEditPopupVisible]);

    return (
        <div className="home-user-info-container fw-flex-box col a-center">
            <div className="home-box-title">사용자 프로필</div>
            <div className="user-imag-box">
                <img src={user.pict_imag ? user.pict_imag : DefaultUserImag} alt="사용자 이미지" />
            </div>
            <div className="user-name-box">
                <div className="user-name">{user.user_name}</div>
                <div className="greeting">반갑습니다 😊</div>
            </div>
            <div className="user-dept-posi-box">
                <div className="user-dept-posi">{user.dept_name} - {user.posi_name}</div>
            </div>
            <div className="user-idno-box">
                <div className="user-idno">ID : {user.user_idno}</div>
            </div>
            <div className='user-concat-box fw-flex-box col'>
                <div className='user-cell'>
                    <i className='dx-icon-dragvertical'></i>
                    <span>{fwUtil.conv.to.concatNum(user.cell_numb)}</span>
                </div>
                <div className='user-tele'>
                    <i className='dx-icon-tel'></i>
                    <span>{fwUtil.conv.to.concatNum(user.tele_numb)}</span>
                </div>
                <div className='user-faxs'>
                    <i className='dx-icon-print'></i>
                    <span>{fwUtil.conv.to.concatNum(user.faxs_numb)}</span>
                </div>
            </div>
            <Button
                icon='send'
                text='프로필 수정'
                onClick={onPropleEditBtnClick}
            />
            <PopupAler
                visible={profileEditPopupVisible}
                hiding={profileEditPopupHide}
                confirm={() => navigate('/profile')}
                message={'프로필 수정 화면으로 가시겠습니까?'}
            />
        </div>
    )
}