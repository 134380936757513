import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getRepoClosGubu = async () => {
    try {
        const api = `privat${mesGubu}/undefined`;
        //const data = await fwUtil.apis.search(api);
        const data = ["진행", "완료"];
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getProsQcisDamdList = async () => {
    try {
        const api = `privat${mesGubu}/undefined`;
        //const data = await fwUtil.apis.search(api);
        const data = ["김진구", "서향탁"];
        return data;
    }
    catch (error) {
        console.log('getProsQcisDamdList.error === ', error);
        return [];
    }
};

const getRoutQcisDamdList = async () => {
    try {
        const api = `privat${mesGubu}/undefined`;
        //const data = await fwUtil.apis.search(api);
        const data = ["김진구", "서향탁"];
        return data;
    }
    catch (error) {
        console.log('getProsQcisDamdList.error === ', error);
        return [];
    }
};

export const qci = {
    repo: {
        clos: getRepoClosGubu
    },
    ppro: {
        damd: {
            list: getProsQcisDamdList,
        }
    },
    rout: {
        damd: {
            list: getRoutQcisDamdList,
        }
    }
}