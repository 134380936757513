export async function numbBeforeChange(e, integerLength, decimalLength) {
    if (!integerLength || (!decimalLength && decimalLength !== 0)) {
        return e;
    }
    let newValue = e;

    if (isNaN(newValue)) {
        newValue = 0;
    }
    
    let newValueStr = String(newValue);
    let intValueStr = '0';

    const decimalIndex = newValueStr.indexOf('.');
    if (decimalIndex !== -1) {
        // 정수 부분 처리
        const integerPartStr = newValueStr.substring(0, decimalIndex);
        if (integerPartStr.length > integerLength) {
            intValueStr = integerPartStr.substring(0, integerLength);
        } else {
            intValueStr = integerPartStr;
        }
        // 소수점 뒤 부분 처리
        const decimalPartStr = newValueStr.substring(decimalIndex + 1, decimalIndex + 1 + decimalLength);
        newValueStr = intValueStr + '.' + decimalPartStr.slice(0, decimalLength);
    } else {
        // 소수점 없는 정수 처리
        if (newValueStr.length > integerLength) {
            newValueStr = newValueStr.substring(0, integerLength);
        }
    }

    return newValueStr;
};
