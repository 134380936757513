// PreMaintResult - 결과 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PreMaintResultInsert } from './ResuRegi-insert';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PremResuRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/search`;  // 서브 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/register/insert`;         // 서브 수정 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'main_numb';
    const subsKey = ['main_numb', 'chck_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 수정 팝업창
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { mainNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '설비명', value: equiName, valueChange: equiNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [equiName, equiNameChange, mainHandleSearch]);

    // 수정 버튼 클릭
    const handleUpdateClick = useCallback(() => {
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);
        if (!subsSelectedRowKey) {
            fwUtil.aler.toast.nsWarn();
            return;
        };

        setSubsUpdatePopupVisible(true);
    }, [subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}           
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_numb", caption: "관리번호" })}
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: handleUpdateClick, disabled: authList.auth_inse === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "chck_seri", caption: "순번" })}
                {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                {CommCol({ dataField: "chck_gubu", caption: "잠감구분" })}
                {CommCol({ dataField: "chck_term", caption: "점검주기" })}   
                {CommCol({ dataField: "chck_desc", caption: "점검항목" })}   
            </SubsGrid>         
            <PreMaintResultInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                insertApi={subsInsertApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />                 
        </div>
    );
};