// 사이드바 네비게이션 트리에 사용되는 라우터 경로
function menuLoop(pUserMenu, pMenuGubu, pMenuCode) {
  let result = pUserMenu.map(menuItem => {
    let menuGubu = menuItem.menu_gubu.trim();
    let menuCode = menuItem.menu_code.trim();
    let menuHigh = menuItem.menu_high.trim();
    let menuName = menuItem.menu_name.trim();
    let menuPath = menuItem.menu_path.trim();
    let menuIcon = menuItem.menu_icon.trim();
    if (menuGubu === pMenuGubu && menuHigh === pMenuCode) {
      if (menuPath === "") {
        return ({
          text: menuName,
          icon: menuIcon,
          items: menuLoop(pUserMenu, menuGubu, menuCode),
        });
      } else {
        return ({
          text: menuName,
          path: menuPath,
        });
      }
    } else {
      return null;
    }
  }).filter(item => item !== null);
  return result;
};

export function CreateTreeNavigation(userMenu, menu) {
  let menuData = [];

  let homeRoute = {};
  if (menu === 'mes') {
    homeRoute = {
      text: '메인화면',
      path: '/home',
      icon: 'home'
    };
  }

  menuData.push(homeRoute);

  if (menu === 'mes') {
    const mesRoute = userMenu
    .filter(menuItem => menuItem.menu_gubu.trim() === 'mes' && menuItem.menu_high.trim() === "")
    .map(menuItem => {
      let menuCode = menuItem.menu_code.trim();
      let menuName = menuItem.menu_name.trim();
      let menuIcon = menuItem.menu_icon.trim();

      return {
        text: menuName,
        icon: menuIcon,
        items: menuLoop(userMenu, 'mes', menuCode),
      };
    });
    menuData.push(...mesRoute);
  }

  const userRoute = {
    text: '내 정보',
    path: '/profile',
    icon: 'user'
  };
  menuData.push(userRoute);
  
  return menuData;
};

export function createMenuTreeList(userMenu, menu) {
  let menuData = [];
  const homeRoute = {
    menu_keys: 'home',
    menu_code: '1',
    menu_high: '',
    menu_gubu: 'root',
    menu_icon: 'home',
    menu_name: '메인화면',
    icon: 'home'
  };
  menuData.push(homeRoute);

  if (menu === 'mes') {
    const mesRoute = {
      menu_keys: 'mes',
      menu_code: '2',
      menu_high: '',
      menu_gubu: 'root',
      menu_icon: 'home',
      menu_name: 'MES',
      icon: 'home'
    };
    menuData.push(mesRoute);
  }

  const profileRoute = {
    menu_keys: 'profile',
    menu_code: '3',
    menu_high: '',
    menu_gubu: 'root',
    menu_icon: 'user',
    menu_name: '내 정보',
    icon: 'user'
  };
  menuData.push(profileRoute);

  const updateHighMenu = userMenu.map(item => {
    const menuHigh = item.menu_high.trim();
    const menuKey = `${item.menu_gubu}-${item.menu_code}`;
    const menuParn = `${item.menu_gubu}-${menuHigh}`;

    if (menuHigh === '') {
      return {
        menu_keys: menuKey,
        menu_parn: item.menu_gubu === 'mes',
        ...item,
      };
    } else {
      return {
        menu_keys: menuKey,
        menu_parn: menuParn,
        ...item
      };
    }
  });
  menuData.push(...updateHighMenu);

  return menuData;
};
