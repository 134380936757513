import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    mana_numb: '',
    comp_seri: '',
    clie_code: '',
    clie_name: '',
    item_code: '',
    item_name: '',
    rece_date: fwUtil.date.getsText.date(),
    insp_date: fwUtil.date.getsText.date(),
    insp_name: '',
    insp_kwon: 0.00,
    comp_memo: '',
}

const partDataSource = fwUtil.grid.get.dataSource();

export function AserCompInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        let updatedData = defaultData;
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            console.log(seleData)
            updatedData = {
                ...defaultData,
                mana_numb: seleData.mana_numb,
                clie_code: seleData.clie_code,
                clie_name: seleData.clie_name,
                item_code: seleData.item_code,
                item_name: seleData.item_name,
            };
        }

        partDataSource.store().insert({ init: true }).then(() => partDataSource.reload());
        dataUpdate(updatedData);
    }, [hiding, dataUpdate, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            manaNumb: data.mana_numb || '',
            compSeri: data.comp_seri || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            receDate: data.rece_date ? fwUtil.date.getsText.date(data.rece_date) : fwUtil.date.getsText.date(),
            inspDate: data.insp_date ? fwUtil.date.getsText.date(data.insp_date) : fwUtil.date.getsText.date(),
            inspName: data.insp_name || '',
            inspKwon: data.insp_kwon || 0.00,
            compMemo: data.comp_memo || '',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <>      
        <PopupForm
            title={'운영/점검 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall || isSmall ? "100%" : isLarge ? 1200 : 600 }
        >
            <GroupItem colCount={isXSmall ? 1 : isLarge ? 4 : 2 }>
                {FormText({ value: data.mana_numb, onValueChange: updateField('mana_numb'), label: '관리번호', required: true, disabled: true })}
                {FormText({ value: data.comp_seri, onValueChange: updateField('comp_seri'), label: '점검순번[자동입력]', disabled: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', required: true, disabled: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', required: true, disabled: true })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '점검일자', required: true })}
                {FormDate({ value: data.rece_date, onValueChange: updateField('rece_date'), label: '접수일자' })}
                {FormText({ value: data.insp_name, onValueChange: updateField('insp_name'), label: '점검자', required: true })}
                {FormNumb({ value: data.insp_kwon, onValueChange: updateField('insp_kwon'), label: '출장비', length: [15, 2] })}
            </GroupItem>
            {FormMemo({ value: data.comp_memo, onValueChange: updateField('comp_memo'), label: '비고' })}
        </PopupForm>
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            warning={"소모부품이 있을 경우 등록 후 수정버튼을 눌러 추가해주세요."}
            message={'등록 하시겠습니까?'}
        />
        </>
    );
}