import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormExce, FormGrid, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    clie_code: '',
    clie_name: '',
    xlsx_save: null,    
}

const setOrde = set.mes.sal.orde;

export function OrdeInfoUploUpload(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieName: "" };
        const res = await setOrde.clie(sendData);
        clieGridUpdate(res);
    }, [clieGridUpdate]);

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = new FormData();

        postData.append('clieCode', data.clie_code || '');
        postData.append('xlsxSave', data.xlsx_save || null);

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        onHiding();
    }, [data, insertApi, refresh, onHiding]);

    return (
        <>
        <PopupGrid
            title={'고객 선택'}
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "업체구분" })}
                {CommCol({ dataField: "clie_name", caption: "업체명(한글)" })}
                {CommCol({ dataField: "clie_enam", caption: "업체명(영문)" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupForm
            title={'수주업로드 항목 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: "고객코드", required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "고객사명", readOnly: true })}
                {FormExce({ value: data.xlsx_save, onValueChange: updateField('xlsx_save'), label: '파일' })}
            </GroupItem>
        </PopupForm>        
        </>
    );
}