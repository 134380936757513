export function convertEquiSensDate(data) {
    const result = {};
    
    if (!data) {
        return [];
    }

    data.forEach(item => {
        const date = new Date(item.sens_date);
        const minutes = Math.floor(date.getMinutes() / 10) * 10;
        const dateKey = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`; // 'YYYY-MM-DD HH:mm'
        
        const equiKey = `${item.equi_numb}_${item.equi_name}`;

        if (!result[dateKey]) {
            result[dateKey] = {};
        }

        if (!result[dateKey][equiKey]) {
            result[dateKey][equiKey] = {
                count: 0,
                totalSensValue: 0
            };
        }

        result[dateKey][equiKey].count += 1;
        result[dateKey][equiKey].totalSensValue += item.sens_valu;
    });

    return Object.entries(result).map(([time, equiData]) => {
        const entry = { time };
        let index = 1;

        Object.entries(equiData).forEach(([key, { count, totalSensValue }]) => {
            entry[`equ${index}_sens`] = (totalSensValue / count).toFixed(2);
            entry[`equ${index}_numb`] = key.split('_')[0];
            entry[`equ${index}_name`] = key.split('_')[1];
            index++;
        });

        return entry;
    });
}
