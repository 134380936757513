// PursInfoStat - 요청 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PursInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['purs_numb', 'matl_code'];     
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [matlName, setMatlName] = useState("");
    const [pursNumb, setPursNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const pursNumbChange = useCallback((e) => { setPursNumb(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, matlName: matlName, pursNumb: pursNumb };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, matlName, pursNumb, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '구매요청번호', value: pursNumb, valueChange: pursNumbChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [matlName, matlNameChange, pursNumb, pursNumbChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "purs_numb", caption: "구매요청번호" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고량" })}
                {DeciCol({ dataField: "requ_qtys", caption: "요청수량" })}
                {CommCol({ dataField: "pros_unit", caption: "발주단위" })}
                {DeciCol({ dataField: "pros_pric", caption: "발주단가" })}
                {DeciCol({ dataField: "pros_cwon", caption: "발주요청금액" })}
                {CommCol({ dataField: "rece_plac", caption: "입고처" })}
                {DateCol({ dataField: "deli_date", caption: "납품요청일" })}
                {CommCol({ dataField: "purs_stat", caption: "구매상태" })}
                {CommCol({ dataField: "purs_memo", caption: "구매메모" })}
                {CommCol({ dataField: "user_name", caption: "최초등록자" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_name", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}     
            </PagingGrid>        
        </div>
    );
};