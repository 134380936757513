import BluePrinRegi from "./blueprint/InfoRegi";
import DesiInfoRegi from "./design/InfoRegi";

const catbBlue = ({ acce, gubu, cata }) => {
    const catb = "blue";
    const comp = {
        infoRegi: () => (<BluePrinRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="B1" mainTitle="도면" mainRole="관리" />),
    }

    return comp;
};

const catbDesi = ({ acce, gubu, cata }) => {
    const catb = "desi";
    const comp = {
        infoRegi: () => (<DesiInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="B2" mainTitle="설계" mainRole="관리" />),
    }

    return comp;
};

export const cataTec = ({ acce, gubu }) => {
    const cata = "tec"
    const comp = {
        catbBlue: catbBlue({ acce: acce, gubu: gubu, cata: cata }),
        catbDesi: catbDesi({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}