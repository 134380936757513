import { useScreenSize } from "../../../../utils/media-query";
import { suppComp } from "../../comp-data";
import "./footer.scss";

export default function Footer() {
  const { isXSmall, isSmall } = useScreenSize();

  if (isXSmall || isSmall) {
    return (
      <div className="content-footer fw-flex-box col">
        <div className="info">
          <div className="company-name fw-flex-box row a-center">
            {suppComp.knam}&nbsp; ({suppComp.enam})&nbsp;&nbsp;전화: {suppComp.tele}&nbsp;|&nbsp;
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content-footer fw-flex-box col">
      <div className="footer-info fw-flex-box row a-center info">
        <div className="company-name fw-flex-box row a-center">
          {suppComp.knam}&nbsp; ({suppComp.enam})&nbsp;&nbsp;
        </div>
        <div className="telephone">전화: {suppComp.tele}&nbsp;|&nbsp;</div>
      </div>
    </div>
  );
}
