// WorkOrdeRegi - 작업지시 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { WorkOrdeRegiMainInsert } from './InfoRegi-main-insert';
import { WorkOrdeRegiMainUpdate } from './InfoRegi-main-update';
import { WorkOrdeRegiSubsInsert } from './InfoRegi-subs-insert';
import { WorkOrdeRegiSubsUpdate } from './InfoRegi-subs-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function WorkOrdeRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/order/register/header/search`; // 메인 조회 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/order/register/header/insert`; // 메인 등록 api
    const mainUpdateApi = `${acce}/${gubu}/${cata}/${catb}/order/register/header/update`; // 메인 수정 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/order/register/header/delete`; // 메인 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/order/register/detail/search`; // 서브 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/order/register/detail/insert`; // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/order/register/detail/update`; // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/order/register/detail/delete`; // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'work_numb';               
    const subsKey = ['work_numb', 'item_code'];
    const mainDeleKey = (data) => { return { workNumb: data } };
    const subsDeleKey = (data) => { return { workNumb: data.work_numb, itemCode: data.item_code } };
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), [setSubsGridData]);    
    // 검색 조건
    const [WorkNumb, setWorkNumb] = useState("");       
    const WorkNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { workNumb: WorkNumb };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [WorkNumb, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { workNumb: key };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        } else {
            const keyData = fwUtil.grid.get.sKey(mainGridRef);
            const sendData = { workNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        key && subsHandleSearch(key);
    }, [subsHandleSearch]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
        if (type === 'main') {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(mainDeleteApi, mainGridRef , mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
        } else {
            const gridInstance = subsGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(subsDeleteApi, subsGridRef, subsDeleKey, subsHandleSearch);
            }
            subsDeleteAlertHide();
        }

    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, subsDeleteAlertHide, mainDeleteApi, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '작업지시번호', value: WorkNumb, valueChange: WorkNumbChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [WorkNumb, WorkNumbChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('작업지시 품목을 선택 후 시도해주세요.');
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdatePopupVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('작업지시를 선택 후 시도해주세요.');
            return;
        }

        if (type === 'main') {
            handleMainAction();
        } else if (type === 'subs') {
            handleSubsAction();
        }

    }, [mainGridRef, subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DateCol({ dataField: "work_date", caption: "작업지시일" })}
                {DateCol({ dataField: "star_date", caption: "생산시작일" })}
            </MainGrid>
            <WorkOrdeRegiMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <WorkOrdeRegiMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 작업지시를 삭제하시겠습니까?'}
            />
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "plan_numb", caption: "계획번호" })}
                {DeciCol({ dataField: "plan_qtys", caption: "계획수량" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {DeciCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {CommCol({ dataField: "item_memo", caption: "비고" })}          
            </SubsGrid>
            <WorkOrdeRegiSubsInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <WorkOrdeRegiSubsUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                updateApi={subsUpdateApi}
                visible={subsUpdatePopupVisible}
                hiding={subsUpdatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={() => onDeleteClick('subs')}
                message={'해당 작업지시 품목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};