export function convertOrderDate(data) {
    const result = {};
    if (!data) {
        return;
    }
    data.forEach(item => {
        const dateKey = item.orde_date;

        if (!result[dateKey]) {
            result[dateKey] = {
                orde_date: dateKey,
                orde_qtys: 0,
                orde_pric: 0,
                orde_kwon: 0
            };
        }

        result[dateKey].orde_qtys += parseFloat(item.orde_qtys) || 0;
        result[dateKey].orde_pric += parseFloat(item.orde_pric) || 0;
        result[dateKey].orde_kwon += parseFloat(item.orde_kwon) || 0;
    });

    return Object.values(result);
}
