import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    work_numb: '',
    item_code: '',
    rout_numb: '',
    rout_seri: '',
    item_name: '',
    rout_name: '',
    last_rout: false,
    stoc_loca: '',
    equi_numb: '',
    equi_name: '',
    cycl_time: '',
    work_date: fwUtil.date.getsText.date(),
    work_qtys: 0,
    infe_qtys: 0,
    work_gubu: '',
    work_name: '',
    prod_memo: '',
    orde_qtys: 0,
};

const setProd = set.mes.mfg.prod;

export function ProductsRegiUpdate(props) {
    const {
        refresh,
        mainGrid, subsGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const equiGridRef = useRef(null);
    const [equiData, setEquiData] = useState([]);
    const equiGridUpdate = useCallback((e) => setEquiData(e), []);
    const equiKeyExpr = 'equi_numb';
    // 그리드 팝업
    const [equiPopupVisible, setEquiPopupVisible] = useState(false);
    const equiPopupHiding = useCallback(() => setEquiPopupVisible(false), []);
    // 팝업 조회
    const equiHandleSearch = useCallback(async () => {
        const sendData = { equiNumb: '' }
        const res = await setProd.equi(sendData);
        equiGridUpdate(res);
    }, [equiGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        const selectedMainRowData = fwUtil.grid.get.sData(mainGrid);
        const selectedSubsRowData = fwUtil.grid.get.sData(subsGrid);
        if (!selectedMainRowData || !selectedSubsRowData) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                work_numb: selectedSubsRowData.work_numb || '',
                item_code: selectedSubsRowData.item_code || '',
                rout_numb: selectedSubsRowData.rout_numb || '',
                rout_seri: selectedSubsRowData.rout_seri || '',
                item_name: selectedSubsRowData.item_name || '',
                rout_name: selectedSubsRowData.rout_name || '',
                last_rout: selectedSubsRowData.last_rout || false,
                stoc_loca: selectedSubsRowData.stoc_loca || '',
                equi_numb: selectedSubsRowData.equi_numb || '',
                equi_name: selectedSubsRowData.equi_name || '',
                cycl_time: selectedSubsRowData.cycl_time || '',
                work_date: selectedSubsRowData.work_date || fwUtil.date.getsText.date(),
                work_qtys: parseFloat(selectedSubsRowData.work_qtys) || 0,
                infe_qtys: parseFloat(selectedSubsRowData.infe_qtys) || 0,
                work_gubu: selectedSubsRowData.work_gubu || '',
                work_name: selectedSubsRowData.work_name || '',
                prod_memo: selectedSubsRowData.prod_memo || '',
                orde_qtys: parseFloat(selectedSubsRowData.orde_qtys) || 0,
            };
            console.log('updatedData:', updatedData);
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, subsGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || '',
            itemCode: data.item_code || '',
            routNumb: data.rout_numb || '',
            routSeri: data.rout_seri || '',
            itemName: data.item_name || '',
            routName: data.rout_name || '',
            lastRout: data.last_rout || '',
            equiNumb: data.equi_numb || '',
            equiName: data.equi_name || '',
            cyclTime: data.cycl_time || '',
            workDate: data.work_date ? fwUtil.date.convTime.fmDate(data.work_date) : fwUtil.date.getsText.date(),
            workQtys: data.work_qtys || 0.00,
            infeQtys: data.infe_qtys || 0.00,
            workGubu: data.work_gubu || '',
            stocLoca: data.stoc_loca || '',
            workName: data.work_name || '',
            prodMemo: data.prod_memo || '',    
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    // 팝업 열릴 때
    const equiPopupOnShowing = useCallback(() => {
        equiHandleSearch();
    }, [equiHandleSearch]);

    // 팝업 더블 클릭
    const equiOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, equi_numb: e.data.equi_numb, equi_name: e.data.equi_name });
        equiPopupHiding();
    }, [data, dataUpdate, equiPopupHiding]);

    const equiGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'설비'}
                role={'목록'}
                gridDataSource={equiData}
                gridRef={equiGridRef}
                mainKey={equiKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={equiOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </ScrollGrid>
        )
    }, [equiData, equiGridRef, equiKeyExpr, equiOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'설비 선택'}
                visible={equiPopupVisible}
                shown={equiPopupOnShowing}
                hiding={equiPopupHiding}
            >
                {equiGrid}
            </PopupGrid>
            <PopupForm
                title={'작업일보 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 1200}
            >
                <GroupItem colCount={isXSmall ? 1 : isLarge ? 4 : 2 }>
                    <GroupItem colCount={isXSmall ? 1 : isLarge ? 4 : 2 } colSpan={isXSmall ? 1 : isLarge ? 4 : 2 }>
                        {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true, required: true })}
                        {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', disabled: true, required: true })}
                        {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제 품 명', disabled: true, required: true })}
                        {FormText({ value: data.rout_seri, onValueChange: updateField('rout_seri'), label: '일보순번', disabled: true })}
                    </GroupItem>
                    {FormText({ value: data.rout_numb, onValueChange: updateField('rout_numb'), label: '공정번호', disabled: true })}
                    {FormText({ value: data.rout_name, onValueChange: updateField('rout_name'), label: '공정명', disabled: true })}
                    {FormText({ value: data.work_gubu, onValueChange: updateField('work_gubu'), label: '작업구분', disabled: true })}                    
                    {FormBool({ value: data.last_rout, onValueChange: updateField('last_rout'), label: '완료공정', disabled: true })}                    
                    {FormGrid({ value: data.equi_numb, onValueChange: updateField('equi_numb'), onClick: () => setEquiPopupVisible(true), label: '설비번호' })}
                    {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', disabled: true })}
                    {FormNumb({ value: data.cycl_time, onValueChange: updateField('cycl_time'), label: '설비재공시간', length: [2, 0] })}
                    {FormText({ value: data.work_name, onValueChange: updateField('work_name'), label: '작업자', maxLength: 20 })}
                    {FormNumb({ value: data.orde_qtys, onValueChange: updateField('orde_qtys'), label: '지시수량', disabled: true })}
                    {FormNumb({ value: data.work_qtys, onValueChange: updateField('work_qtys'), label: '작업수량', length: [10, 2] })}
                    {FormNumb({ value: data.infe_qtys, onValueChange: updateField('infe_qtys'), label: '불량수량', length: [10, 2] })}
                    {FormDate({ value: data.work_date, onValueChange: updateField('work_date'), label: '작업일자' })}                         
                </GroupItem>
                {FormMemo({ value: data.prod_memo, onValueChange: updateField('prod_memo'), label: '비고' })}
            </PopupForm>
        </>
    );
}