import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';


const oldsDataSource = fwUtil.grid.get.dataSource();
const newsDataSource = fwUtil.grid.get.dataSource();

export function PremListRegiInsert(props) {
    const {
        mainGrid,
        refresh,
        targetApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 발주 그리드
    const oldsRef = useRef(null);
    const newsRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.sKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            oldsDataSource.store().insert({ init: true }).then(() => oldsDataSource.reload());
            newsDataSource.store().insert({ init: true }).then(() => newsDataSource.reload());
            const sendData= { mainNumb: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData , null);
            if (res && res.length > 0) {
                for (const item of res) {
                    const newData = {
                        chck_seri: item.chck_seri || '',
                        chck_part: item.chck_part || '',
                        chck_gubu: item.chck_gubu || '',
                        chck_term: item.chck_term || '',
                        chck_desc: item.chck_desc || '',
                        chck_memo: item.chck_memo || '',
                    };
                    oldsDataSource.store().insert(newData);
                }
            }
            oldsDataSource.reload();
        }
    }, [hiding, mainGrid, targetApi]);

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.grid.get.sKey(mainGrid);
        const newsData = newsRef?.current?.instance?.getDataSource()?.items();
        const postPremData = newsData.map((item) => ({
            chck_seri: item.chck_seri || '',
            chck_part: item.chck_part || '',
            chck_gubu: item.chck_gubu || '',
            chck_term: item.chck_term || '',
            chck_desc: item.chck_desc || '',
            chck_memo: item.chck_memo || '',
        }));        
        const postData = {
            mainNumb: mainKey || '',
            setaMaps: postPremData || [],
        };

        const allHiding = () => {
            alertHide();
            hiding();
        }
        await fwUtil.apis.action(insertApi, postData, refresh, allHiding);
        setLoading(false);
    }, [insertApi, refresh, hiding, alertHide, newsRef, mainGrid]);

    return (
        <>
        <PopupForm
            title={'예방정비 항목 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'기존 항목'}
                        gridRef={oldsRef} 
                        gridDataSource={oldsDataSource}
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번" })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분" })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기" })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목" })}
                        {CommCol({ dataField: "chck_memo", caption: "비고" })}  
                    </AddingGrid>
                    <AddingGrid
                        title={'신규 항목'}
                        gridRef={newsRef} 
                        gridDataSource={newsDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART", check: true })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분", check: true })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기", check: true })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목", check: true })}
                        {CommCol({ dataField: "chck_memo", caption: "비고", check: true })}
                    </AddingGrid>                    
                </div>
            }         
        />
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 등록 하시겠습니까?'}
        />  
        </>
    );
}