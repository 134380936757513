import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { SBarSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

export function DashShipCharCard(props) {
    const { loading, data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}원`,
        };
    };

    return (
        <DashCard
            title={"총 납품 현황"}
            addClass={'ship-char'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {SBarSeri({ valueField: "ship_kwon", caption: "금액", args: "ship_date" })}
            </CommChart>
        </DashCard>
    )
}