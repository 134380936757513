// LogsInfoStat - 로그 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DtmeCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { LogsInfoStatInsert } from './InfoStat-insert';
import { LogsInfoStatUpdate } from './InfoStat-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function LogsInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/target`;  // 타겟 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/delete`;  // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['user_idno', 'logs_date'];
    const deleKey = (data) => {
        return {
            userIdno: data.user_idno,
            logsDate: data.logs_date
        }
    }    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []); 
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const [screName, setScreName] = useState("");
    const [userName, setUserName] = useState("");
    const screNameChange = useCallback((e) => { setScreName(e) }, []);
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, screName: screName, userName: userName  };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, screName, userName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: "화면명", value: screName, valueChange: screNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: "사용자명", value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [screName, screNameChange, userName, userNameChange, handleSearch]);
    
    const handleActionClick = useCallback((mode) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);
        if (selectedRowKey) {
            if (mode === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (mode === 'del') {
                setDeleteAlertVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        }
    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}                
            >
                {CommCol({ dataField: "user_idno", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "사용자명" })}
                {DtmeCol({ dataField: "logs_date", caption: "로그일시" })}
                {CommCol({ dataField: "logs_gubu", caption: "사용구분" })}
                {CommCol({ dataField: "scre_name", caption: "화면명" })}
                {CommCol({ dataField: "logs_ipno", caption: "접속 IP" })}
                {NumbCol({ dataField: "data_size", caption: "데이터크기", suffix: 'KByte' })}
                {CommCol({ dataField: "send_remk", caption: "비고" })}
                {CommCol({ dataField: "send_erro", caption: "오류내용" })}
            </PagingGrid>
            <LogsInfoStatInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <LogsInfoStatUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 로그를 삭제하시겠습니까?'}
            />                
        </div>
    );
};