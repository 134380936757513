// WorkOrdeRegi - 작업지시 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteHeaderAlert, PopupAler as DeleteDetailAlert, PopupAler as InserRevHeaderAlert } from '../../../../script/components/popup/popup';
import { QuotRegiHeaderInsert } from './InfoRegi-header-insert';
import { QuotRegiHeaderUpdate } from './InfoRegi-header-update';
import { QuotRegiDetailInsert } from './InfoRegi-detail-insert';
import { QuotRegiDetailUpdate } from './InfoRegi-detail-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function QuotInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props;

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/header/search`;  // 조회 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/header/insert`;  // 등록 api
    const mainUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/header/update`;  // 수정 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/header/delete`;  // 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/search`;  // 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/insert`;  // 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/update`;  // 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/delete`;  // 삭제 api

    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = ['docu_numb', 'docu_revi'];              
    const subsKey = ['docu_numb', 'docu_seri'];  //subsKey.docu_numb = concat(docu_numb, docu_revi);
    const mainDeleKey = (data) => { return { docuNumb: data.docu_numb, docuRevi: data.docu_revi } };    
    const subsDeleKey = (data) => { return { docuNumb: data.docu_numb, docuSeri: data.docu_seri } };   

    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);

    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const [duraYesn, setDuraYesn] = useState(true);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const [serchData, setSerchData] = useState({docuNumb: "",custName: "",prjtName: ""});
    const serchDataChange = useCallback((name, value) => {
        setSerchData((prevData) => ({...prevData,[name]: value}));
    }, []);

    // Rev등록 팝업창
    const [mainInsRevPopupVisible, setMainInsRevPopupVisible] = useState(false);
    const mainInsRevPopupHide = useCallback(() => { setMainInsRevPopupVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);
    const [subsUpdatePopupVisible, setSubsUpdatePopupVisible] = useState(false);
    const subsUpdatePopupHide = useCallback(() => { setSubsUpdatePopupVisible(false); }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { 
            start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn,
            docuNumb: serchData.docuNumb, custName: serchData.custName, prjtName: serchData.prjtName, 
        };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, null);
        const mainRowKey = fwUtil.grid.get.sKey(mainGridRef);
        if (mainRowKey) {
            const sendData = { docuNumb: (mainRowKey.docu_numb + mainRowKey.docu_revi) };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [dateValue, duraYesn, serchData, mainSearchApi, mainGridRef, mainGridDataUpdate, subsSearchApi, subsGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const mainRowKey = fwUtil.grid.get.sKey(mainGridRef);
        if (mainRowKey) {
            const sendData = { docuNumb: (mainRowKey.docu_numb + mainRowKey.docu_revi) };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsSearchApi, subsGridRef, subsGridDataUpdate]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) { subsHandleSearch(); }
    }, [subsHandleSearch]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    // 삭제 경고 확인 클릭
    const mainDeleteConfirm = useCallback(async () => {
        await fwUtil.apis.delete(mainDeleteApi, mainGridRef, mainDeleKey, mainHandleSearch);
        mainDeleteAlertHide();
    }, [mainDeleteApi, mainGridRef, mainHandleSearch, mainDeleteAlertHide]);

    const subsDeleteConfirm = useCallback(async () => {
        await fwUtil.apis.delete(subsDeleteApi, subsGridRef, subsDeleKey, subsHandleSearch);
        subsDeleteAlertHide();
    }, [subsDeleteApi, subsGridRef, subsHandleSearch, subsDeleteAlertHide]);

    const mainInsRevConfirm = useCallback(async () => {
        const mainRowData = fwUtil.grid.get.sData(mainGridRef);
        const postData = {
            docuNumb: mainRowData.docu_numb || '',
            docuRevi: mainRowData.docu_revi || '',
            custCode: mainRowData.cust_code || '',
            custName: mainRowData.cust_name || '',
            custDamd: mainRowData.cust_damd || '',
            custDept: mainRowData.cust_dept || '',
            custPosi: mainRowData.cust_posi || '',
            custTels: mainRowData.cust_tels || '',
            custFaxs: mainRowData.cust_faxs || '',
            custMail: mainRowData.cust_mail || '',
            prjtName: mainRowData.prjt_name || '',
            quotGubu: mainRowData.quot_gubu || '작성',
            issuDate: mainRowData.issu_date || fwUtil.date.getsText.date(),
            corpProf: mainRowData.corp_prof || '0',
            quotCond: mainRowData.quot_cond || '',
            deliDays: mainRowData.deli_days || '',
            paysCond: mainRowData.pays_cond || '',
            deliCond: mainRowData.deli_cond || '',
            deliPlac: mainRowData.deli_plac || '',
            inspCond: mainRowData.insp_cond || '',
            guarCond: mainRowData.guar_cond || '',
            quotRemk: mainRowData.quot_remk || '',
        };
        await fwUtil.apis.action(mainInsertApi, postData, mainHandleSearch, mainInsRevPopupHide);
    }, [mainInsertApi, mainGridRef, mainHandleSearch, mainInsRevPopupHide]);

    // 등록/수정/삭제 버튼 클릭
    const mainHandleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        if (selectedRowKey) {
            if (act === 'revnub') { setMainInsRevPopupVisible(true); return; }  
            if (act === 'update') { setMainUpdatePopupVisible(true); return; }
            if (act === 'delete') { setMainDeleteAlertVisible(true); return; }  
        } 
        if (act === 'insert') { setMainInsertPopupVisible(true); return; }  
        fwUtil.aler.toast.nsWarn();
    }, [mainGridRef]);

    const subsHandleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);
        if (!mainSelectedRowKey) { fwUtil.aler.toast.warn('견적정보를 선택 후 시도해주세요.'); return; }
        if (act === 'insert') { setSubsInsertPopupVisible(true); return; }
        if (!subsSelectedRowKey) { fwUtil.aler.toast.warn('견적상세정보를 선택 후 시도해주세요.'); return; }
        if (act === 'update') { setSubsUpdatePopupVisible(true); return; }
        if (act === 'delete') { setSubsDeleteAlertVisible(true); return; }
    }, [subsGridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '견적번호', value: serchData.docuNumb, valueChange: (e) => serchDataChange('docuNumb',e), search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '고객명', value: serchData.custName, valueChange: (e) => serchDataChange('custName', e), search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '공사명', value: serchData.prjtName, valueChange: (e) => serchDataChange('prjtName', e), search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
        { type: 'btns', locate: 'after', icon: 'redo', text: 'rev', onClick: () => mainHandleActionClick('revnub'), disabled: authList.auth_inse === 0 },
    ], [serchData, serchDataChange, mainHandleSearch, authList, mainHandleActionClick]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                ins={{ onClick: () => mainHandleActionClick('insert'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => mainHandleActionClick('update'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => mainHandleActionClick('delete'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "docu_numb", caption: "견적서번호" })}
                {CommCol({ dataField: "docu_revi", caption: "REV.NO" })}
                {CommCol({ dataField: "item_coun", caption: "품목수" })}
                {CommCol({ dataField: "quot_gubu", caption: "상태" })}
                {CommCol({ dataField: "cust_name", caption: "고객명" })}
                {CommCol({ dataField: "prjt_name", caption: "공사명" })}
                {CommCol({ dataField: "issu_date", caption: "견적일자" })}
                {DeciCol({ dataField: "quot_kwon", caption: "견적금액" })}
                {CommCol({ dataField: "cust_damd", caption: "담당자"})}
                {CommCol({ dataField: "cust_posi", caption: "담당직위"})}
                {CommCol({ dataField: "cust_tels", caption: "전화번호"})}
                {CommCol({ dataField: "quot_remk", caption: "특이사항"})}
            </MainGrid>
            <QuotRegiHeaderInsert
                visible={mainInsertPopupVisible}
                insertApi={mainInsertApi}
                refresh={mainHandleSearch}
                hiding={mainInsertPopupHide}
            />  
            <QuotRegiHeaderUpdate
                visible={mainUpdatePopupVisible}
                mainGrid={mainGridRef}
                updateApi={mainUpdateApi}
                refresh={mainHandleSearch}
                hiding={mainUpdatePopupHide}
            />            
            <DeleteHeaderAlert 
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={mainDeleteConfirm}
                message={'해당 견적을 삭제하시겠습니까?'}
            />       
            <InserRevHeaderAlert
                grid={mainGridRef}
                visible={mainInsRevPopupVisible}
                hiding={mainInsRevPopupHide}
                confirm={mainInsRevConfirm}
                message={'해당 견적을 복제하시겠습니까?'}
            />        
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => subsHandleActionClick('insert'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => subsHandleActionClick('update'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => subsHandleActionClick('delete'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품코드" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "desc_size", caption: "규격" })}
                {DeciCol({ dataField: "item_qtys", caption: "수량" })}
                {CommCol({ dataField: "item_quni", caption: "단위" })}
                {DeciCol({ dataField: "item_cwon", caption: "단가" })}
                {DeciCol({ dataField: "subs_kwon", caption: "금액" })}
                {CommCol({ dataField: "item_remk", caption: "비고" })}
                {CommCol({ dataField: "user_name", caption: "작성자" })}
                {DateCol({ dataField: "user_date", caption: "작성일" })}
            </SubsGrid>
            <QuotRegiDetailInsert
                visible={subsInsertPopupVisible}
                mainGrid={mainGridRef}
                insertApi={subsInsertApi}
                refresh={subsHandleSearch}
                hiding={subsInsertPopupHide}
            />
            <QuotRegiDetailUpdate
                visible={subsUpdatePopupVisible}
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                updateApi={subsUpdateApi}
                refresh={subsHandleSearch}
                hiding={subsUpdatePopupHide}
            />            
            <DeleteDetailAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={subsDeleteConfirm}
                message={'해당 상세견적을 삭제하시겠습니까?'}
            />         
        </div>
    );
};