import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    insp_numb: '',
    ship_numb: '',
    ship_seri: '',
    insp_gubu: '',
    clie_code: '',
    clie_name: '',
    lots_qtys: 0.00,
    matl_code: '',
    matl_name: '',
    insp_date: fwUtil.date.getsText.date(),    
};

const inspDataSource = fwUtil.grid.get.dataSource();

export function ShipQcisRegiInsert(props) {
    const {
        mainGrid,
        mainRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 검사품목 그리드
    const inspRef = useRef(null);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        console.log(selectedRowData)
        if (selectedRowData) {
            const newData = {
                insp_numb: selectedRowData.insp_numb || '',
                ship_numb: selectedRowData.ship_numb || '',
                ship_seri: selectedRowData.ship_seri || '',
                insp_gubu: selectedRowData.insp_gubu || '',
                clie_code: selectedRowData.clie_code || '',
                clie_name: selectedRowData.clie_name || '',
                lots_qtys: selectedRowData.ship_qtys || 0.00,
                matl_code: selectedRowData.item_code || '',
                matl_name: selectedRowData.item_name || '',
                insp_date: selectedRowData.ship_date || fwUtil.date.getsText.date(),                
            }
            inspDataSource.store().insert({ init: true }).then(() => inspDataSource.reload());
            dataUpdate(newData);
        } else {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }

    }, [dataUpdate, hiding, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const inspData = inspRef?.current?.instance?.getDataSource()?.items();
        const postMatlData = inspData.map((item) => ({
            insp_seri: item.insp_seri || '',
            insp_cate: item.insp_cate || '',
            insp_spec: item.insp_spec || '',
            insp_math: item.insp_math || '',
            insp_resu: item.insp_resu || '',
            item_remk: item.item_remk || '',
            work_name: item.work_name || '',
            item_memo: item.item_memo || '',
            ship_numb: data.ship_numb || '',
            ship_seri: data.ship_seri || '',
            insp_gubu: data.insp_gubu || '',
            clie_code: data.clie_code || '',
            clie_name: data.clie_name || '',
            lots_qtys: data.lots_qtys || 0.00,
            insp_date: data.insp_date ? fwUtil.date.convTime.fmDate(data.insp_date) : fwUtil.date.getsText.date(),             
            matl_code: data.matl_code || '',
            matl_name: data.matl_name || '',                        
        }));        
        const postData = {
            inspNumb: data.insp_numb || '',
            setaMaps: postMatlData || [],
        };

        await fwUtil.apis.submit(insertApi, postData, mainRefresh);
        setLoading(false);
        hiding();
    }, [data, insertApi, mainRefresh, hiding]);

    return (
        <PopupForm
            title={'출하검사 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 300 : isMedium ? 600 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'검사 품목'}
                        gridRef={inspRef} 
                        gridDataSource={inspDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "insp_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "insp_cate", caption: "검사항목", check: true })}
                        {CommCol({ dataField: "insp_spec", caption: "기준(규격)", check: true })}
                        {CommCol({ dataField: "insp_math", caption: "검사방법", check: true })}
                        {CommCol({ dataField: "insp_resu", caption: "검사결과", check: true })}
                        {CommCol({ dataField: "item_remk", caption: "특기사항", check: true })}
                        {CommCol({ dataField: "work_name", caption: "작업자", check: true })}
                        {CommCol({ dataField: "item_memo", caption: "비고", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4 }>
                <GroupItem colCount={isXSmall || isSmall ? 1 : isMedium ? 2 : 4 } colSpan={isXSmall || isSmall ? 1 : isMedium ? 2 : 4 }>
                    {FormText({ value: data.insp_numb, onValueChange: updateField('insp_numb'), label: '검사번호[자동]', disabled: true })}
                </GroupItem>
                {FormText({ value: data.ship_numb, onValueChange: updateField('ship_numb'), label: "출하번호", readOnly: true })}
                {FormText({ value: data.ship_seri, onValueChange: updateField('ship_seri'), label: "출하순번", readOnly: true })}
                {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: "고객번호", readOnly: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: "고객명", readOnly: true })}
                {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: "품목번호", readOnly: true })}
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: "제품명", readOnly: true })}               
                {FormNumb({ value: data.lots_qtys, onValueChange: updateField('lots_qtys'), label: '검사수량', required: true, length: [15, 2] })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자', required: true })}
            </GroupItem>
        </PopupForm>   
    );
}
