import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';
import { fwUtil } from '../../util';

export const createFileSystemProvider = (apiParams) => {
    const { searchApi, createApi, deleteApi, insertApi, dnloadApi, rootFileName } = apiParams;

    return new CustomFileSystemProvider({
        getItems: () => getItems(searchApi, rootFileName),
        createDirectory: (parentDirectory, name) => createDirectory(parentDirectory, name, createApi),
        deleteItem: (item) => deleteItem(item, deleteApi),
        uploadFileChunk: (fileData) => uploadFileChunk(fileData, insertApi, rootFileName),
        downloadItems: (item) => downloadItems(item, dnloadApi),
    });
};

async function getItems(searchApi, rootFileName) {
    if (!rootFileName) {
        return;
    }
    const sendData = { fileIdno: rootFileName };
    const response = await fwUtil.apis.search(searchApi, sendData);
    return response;
}

async function createDirectory(parentDirectory, name, createApi) {
    return await fwUtil.apis.fileax(createApi, { parentDirectory, name });
}

async function deleteItem(item, deleteApi) {
    if (!item) {
        fwUtil.aler.toast.warn('데이터를 찾을 수 없습니다.');
        return;
    }
    return await fwUtil.apis.fileax(deleteApi, { fileIdno: item.dataItem.file_idno, fileSeri: item.dataItem.file_seri });
}

async function uploadFileChunk(fileData, insertApi, rootFileName) {
    const postData = new FormData();
    postData.append('indxKeys', rootFileName);
    postData.append('fileData', fileData);
    return await fwUtil.apis.fileax(insertApi, postData);
}

async function downloadItems(items, dnloadApi) {
    if (!items || items.length === 0) {
        fwUtil.aler.toast.warn('데이터를 찾을 수 없습니다.');
        return;
    }

    const downloadPromises = items.map(async (item) => {
        if (!item || !item.dataItem) {
            fwUtil.aler.toast.warn('데이터 아이템이 유효하지 않습니다.');
            return;
        }
        
        const { file_idno, file_seri } = item.dataItem;
        if (!file_idno || !file_seri) {
            fwUtil.aler.toast.warn('파일 ID 또는 시리얼이 없습니다.');
            return;
        }
        
        return await fwUtil.apis.filedn(dnloadApi, { fileIdno: file_idno, fileSeri: file_seri });
    });

    try {
        const results = await Promise.all(downloadPromises);
        console.log('Download results:', results);
    } catch (error) {
        fwUtil.aler.toast.warn('다운로드 중 오류가 발생했습니다.');
        console.error(error);
    }
}
