import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormNumb, FormRegx, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    cata_code: '',
    catb_code: '',
    catc_code: '', 
    catc_name: '', 
    regi_date: fwUtil.date.getsText.date(), 
    enab_yesn: true,
    cate_hori: 0,
    cate_vert: 0,
    cate_thic: 0,
    cate_leng: 0,
};

export function MatlCatcStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { cateCode: `${selectedRowKey.cata_code}${selectedRowKey.catb_code}${selectedRowKey.catc_code}` };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                cata_code: res.cata_code || '',
                catb_code: res.catb_code || '',
                catc_code: res.catc_code || '',
                catc_name: res.catc_name || '',
                regi_date: res.regi_date || null,
                enab_yesn: res.enab_yesn === 1 ? true : false,
                cate_hori: res.cate_hori || 0,
                cate_vert: res.cate_vert || 0,
                cate_thic: res.cate_thic || 0,
                cate_leng: res.cate_leng || 0,                
            };

            dataUpdate(resData);
        }
    }, [dataUpdate, hiding, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            catcCode: `${data.cata_code || '-'}${data.catb_code || '--'}${data.catc_code || '---'}`,
            catcName: data.catc_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
            cateHori: data.cate_hori || 0,
            cateVert: data.cate_vert || 0,
            cateThic: data.cate_thic || 0,
            cateLeng: data.cate_leng || 0,  
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'소분류 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600 }  
        >
            <GroupItem colCount={isXSmall ? 1 : 2 }>
                {FormText({ value: data.cata_code, onValueChange: updateField('cata_code'), label: '대분류코드', required: true, disabled: true })}
                {FormText({ value: data.cata_code, onValueChange: updateField('cata_code'), label: '중분류코드', required: true, disabled: true })}
                {FormRegx({ value: data.catc_code, onValueChange: updateField('catb_code'), label: '소분류코드', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.catc_name, onValueChange: updateField('catb_name'), label: '소분류명', required: true, disabled: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
                {FormNumb({ value: data.cate_hori, onValueChange: updateField('cate_hori'), label: '가로', length: [10, 0] })}
                {FormNumb({ value: data.cate_vert, onValueChange: updateField('cate_vert'), label: '세로', length: [10, 0] })}
                {FormNumb({ value: data.cate_thic, onValueChange: updateField('cate_thic'), label: '두께', length: [10, 0] })}
                {FormNumb({ value: data.cate_leng, onValueChange: updateField('cate_leng'), label: '길이', length: [10, 0] })}               
            </GroupItem>
        </PopupForm>     
    );
}