import { useScreenSize } from "../../../../utils/media-query";

export function AccountPanelFooter({ suppComp }) {
  const { isXSmall, isSmall } = useScreenSize();

  if (isXSmall || isSmall) {
    return null
  }

  return (
    <div className="account-footer fw-flex-box col a-center">
      <div className="account-footer-wrapper">
        <div className="footer-info fw-flex-box row a-center">
          <div className="company-name fw-flex-box row a-center">
            {suppComp.knam}&nbsp; ({suppComp.enam})&nbsp;&nbsp;
          </div>
          <div className="address fw-flex-box row a-center">
            {suppComp.addr}
          </div>
        </div>
        <div className="contact-info fw-flex-box row a-center">
          <div className="telephone">전화: {suppComp.tele}&nbsp;|&nbsp;</div>
          <div className="fax">팩스: {suppComp.faxs}&nbsp;&nbsp;</div>
          <div className="footer-copyright">
            &copy; {new Date().getFullYear()} {suppComp.knam}. All rights
            reserved.
          </div>
        </div>
      </div>
    </div>
  );
}
