import ClieInfoStat from "./clie/InfoStat";
import CodeInfoStat from "./code/InfoStat";
import MatlInfoStat from "./matl/InfoStat";
import MatlInfoRegi from "./matl/InfoRegi";
import MatlInfoCrea from "./matl/InfoCrea";
import MatlCataStat from "./matl/CataStat";
import MatlCatbStat from "./matl/CatbStat";
import MatlCatcStat from "./matl/CatcStat";
import MatlCatdStat from "./matl/CatdStat";
import ItemInfoStat from "./item/InfoStat";
import ItemInfoRegi from "./item/InfoRegi";
import ItemBomsStat from "./item/BomsStat";
import ItemBomsRegi from "./item/BomsRegi";
import CommWorkStat from "./worker/InfoStat";

const catbCode = ({ acce, gubu, cata }) => {
    const catb = "code";
    const comp = {
        infoStat: () => (<CodeInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O1" mainTitle="관리코드" mainRole="관리" subsTitle="상세코드" subsRole="관리" />),
    };

    return comp;
};

const catbClie = ({ acce, gubu, cata }) => {
    const catb = "clie";
    const comp = {
        infoStat: () => (<ClieInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O2" mainTitle="거래처" mainRole="관리" />),
    }

    return comp;
};

const catbItem = ({ acce, gubu, cata }) => {
    const catb = "item";
    const comp = {
        infoStat: () => (<ItemInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O31" mainTitle="제품" mainRole="현황" />),
        infoRegi: () => (<ItemInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="O32" mainTitle="제품" mainRole="관리" subsTitle="공정" subsRole="현황" />),
    };

    return comp;
};

const catbBoms = ({ acce, gubu, cata }) => {
    const catb = "bom";
    const comp = {
        bomsStat: () => (<ItemBomsStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O33" mainTitle="BOM" mainRole="현황" subsTitle="BOM" subsRole="상세" />),
        bomsRegi: () => (<ItemBomsRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="O34" mainTitle="BOM" mainRole="관리" subsTitle="BOM" subsRole="상세" />),        
    };

    return comp;
};

const catbMatl = ({ acce, gubu, cata }) => {
    const catb = "matl";
    const comp = {
        infoStat: () => (<MatlInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O41" mainTitle="자재" mainRole="현황" />),
        infoRegi: () => (<MatlInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="O42" mainTitle="자재" mainRole="관리" />),
        infoCrea: () => (<MatlInfoCrea acce={acce} gubu={gubu} cata={cata} catb={catb} code="O43" mainTitle="자재" mainRole="생성" />),
        cataStat: () => (<MatlCataStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O44" mainTitle="대분류" mainRole="관리" />),
        catbStat: () => (<MatlCatbStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O45" mainTitle="중분류" mainRole="관리" />),
        catcStat: () => (<MatlCatcStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O46" mainTitle="소분류" mainRole="관리" />),
        catdStat: () => (<MatlCatdStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O47" mainTitle="재질" mainRole="관리" />),
    };

    return comp;
};

const catbWork = ({ acce, gubu, cata }) => {
    const catb = "worker";
    const comp = {
        infoStat: () => (<CommWorkStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="O5" mainTitle="작업자" mainRole="관리" />),
    };

    return comp;
};

export const cataCom = ({ acce, gubu }) => {
    const cata = "com"
    const comp = {
        catbClie: catbClie({ acce: acce, gubu: gubu, cata: cata }),
        catbCode: catbCode({ acce: acce, gubu: gubu, cata: cata }),
        catbMatl: catbMatl({ acce: acce, gubu: gubu, cata: cata }),
        catbItem: catbItem({ acce: acce, gubu: gubu, cata: cata }),
        catbBoms: catbBoms({ acce: acce, gubu: gubu, cata: cata }),
        catbWork: catbWork({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}