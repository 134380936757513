export function convertQcisrDate(data) {
    const result = {};
    if (!data) {
        return;
    }
    data.forEach(item => {
        const dateKey = item.insp_date;

        if (!result[dateKey]) {
            result[dateKey] = {
                insp_date: dateKey,
                insp_qtys: 0,
                judg_qtys: 0,
                judg_perc: 0
            };
        }

        result[dateKey].insp_qtys += parseFloat(item.insp_qtys) || 0;
        result[dateKey].judg_qtys += parseFloat(item.judg_qtys) || 0;

        if (result[dateKey].insp_qtys > 0) {
            result[dateKey].judg_perc = ((result[dateKey].judg_qtys / result[dateKey].insp_qtys) * 100).toFixed(2);
        }
    });

    return Object.values(result).sort((a, b) => new Date(a.insp_date) - new Date(b.insp_date));
}
