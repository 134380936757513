// AserAccoRegi - A/S 정산 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { AserAccoRegiInsert } from './InfoRegi-insert';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { fwUtil } from '../../../../script/util';

export default function AserAccoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/regist/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/regist/insert`;  // 등록 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['mana_numb', 'comp_seri'];           
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []); 
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []); 
    const itemNameChange = useCallback((e) => { setItemName(e) }, []); 
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);   

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, manaNumb: manaNumb, clieName: clieName, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, manaNumb, clieName, itemCode, itemName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);    
    
    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [manaNumb, manaNumbChange, clieName, itemCode, itemName, itemCodeChange, itemNameChange, clieNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                toolbar={{ default: true, items: toolbarItems }}                
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                selection={'multiple'}         
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호", groupIndex: 0 })}
                {CommCol({ dataField: "comp_seri", caption: "점검순번" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "rece_date", caption: "접수일자" })}
                {DateCol({ dataField: "insp_date", caption: "점검일자" })}
                {CommCol({ dataField: "insp_name", caption: "점검자" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "part_kwon", caption: "부품비" })}
                {CommCol({ dataField: "comp_memo", caption: "비고" })}                         
            </PagingGrid>
            <AserAccoRegiInsert
                refresh={handleSearch}
                mainGrid={gridRef}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />       
        </div>
    );
};