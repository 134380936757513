// PremListRegi - 예방정비 항목 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as UpdateAlert, PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { PremListRegiInsert } from './ListRegi-insert';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PremListRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/check/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/check/detail/search`;  // 서브 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/check/list/insert`;    // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/check/list/update`;    // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/check/list/delete`;    // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'main_numb';
    const subsKey = ['main_numb', 'chck_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        subsGridRef?.current?.instance?.deselectAll();
        if (key) {
            const sendData = { mainNumb: key };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        subsGridRef.current.instance.beginCustomLoading();
        const selectedItem = fwUtil.grid.get.sKey(mainGridRef) || '';
        const selectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            chck_seri: item.chck_seri || '',
            chck_part: item.chck_part || '',
            chck_gubu: item.chck_gubu || '',
            chck_term: item.chck_term || '',
            chck_desc: item.chck_desc || '',
            chck_memo: item.chck_memo || '',
        }));
        const postData = { mainNumb: selectedItem, setaMaps: convertdData }
        await fwUtil.apis.submit(subsUpdateApi, postData, subsHandleSearch);
        subsGridRef.current.instance.endCustomLoading();
        subsUpdateAlertHide();
    }, [subsGridRef, subsHandleSearch, subsUpdateAlertHide, subsUpdateApi]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        subsGridRef.current.instance.beginCustomLoading();
        const selectedItem = fwUtil.grid.get.sKey(mainGridRef) || '';
        const selectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            chck_seri: item.chck_seri || '',
        }));
        const postData = { mainNumb: selectedItem, setaMaps: convertdData }
        await fwUtil.apis.submit(subsDeleteApi, postData, subsHandleSearch);
        subsGridRef.current.instance.endCustomLoading();
        subsDeleteAlertHide();
    }, [subsGridRef, subsHandleSearch, subsDeleteAlertHide, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '설비명', value: equiName, valueChange: equiNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [equiName, equiNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsInsertPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('상세코드를 선택 후 시도해주세요.');
                return;
            }
    
            switch (act) {
                case 'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('등록코드를 선택 후 시도해주세요.');
            return;
        }

        handleSubsAction();

    }, [mainGridRef, subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}           
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "main_numb", caption: "관리번호" })}
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => handleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "chck_part", caption: "점검PART", check: true })}
                {CommCol({ dataField: "chck_gubu", caption: "잠검구분", check: true })}
                {CommCol({ dataField: "chck_term", caption: "점검주기", check: true })}
                {CommCol({ dataField: "chck_desc", caption: "점검항목", check: true })}
                {CommCol({ dataField: "chck_memo", caption: "비고", check: true })}      
            </SubsGrid>         
            <PremListRegiInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                targetApi={subsSearchApi}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />
            <UpdateAlert
                grid={subsGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 항목을 수정하시겠습니까?'}
            />   
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};