import React, { useState, useEffect, useCallback, useRef } from 'react';
import { TextBox, DateBox, FileUploader, NumberBox, SelectBox, CheckBox, TextArea, Button, Autocomplete, DateRangeBox } from 'devextreme-react';
import Validator, { CustomRule, RequiredRule } from 'devextreme-react/validator';
import { DrawingButton } from '../custom-drawing/custom-drawing';
import { useScreenSize } from '../../../../utils/media-query';
import { Item } from 'devextreme-react/cjs/form';
import { Button as TextButton } from 'devextreme-react/cjs/text-box';
import { fwUtil } from '../../util';
import { numbBeforeChange } from './form-util';
import './form-item.scss';

const allowImagFileExtension = ['image/jpg', 'image/jpeg', 'image/png'];
const accepImagFileExtension = 'image/jpg, image/jpeg, image/png';
const allowXlsxFileExtension = ['xls', 'xlsx'];
const accepXlsxFileExtension = '.xls,.xlsx';

const formItemPublOpts = (props) => {
    const { value, onValueChange, readOnly, disabled, placeholder } = props;

    return {
        value: value,
        onValueChange: onValueChange,
        readOnly: readOnly,
        disabled: disabled,
        placeholder: placeholder,
        stylingMode: 'outlined',
        elementAttr: { class: 'form-editor' },
        inputAttr: { class: 'form-editor-input' },
    }
}

const FormLabel = ({ label, required }) => {
    if (!label || label.hide) {
        return null;
    }
    const textValue = label.col ? label.text : label;
    const labelText = required ? `${textValue} *` : textValue;

    if (labelText) {
        return (
            <div className={fwUtil.conv.class({ 'fw-form-label': true, 'col': label.col, 'require': required })}><span>{labelText}</span></div>
        )
    }
}

const FormText = (props) => {
    const {
        label, mask, maxLength, required
    } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <TextBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    maxLength={maxLength}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormAuto = (props) => {
    const { label, dataSource, maxLength, mask, required } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <Autocomplete
                    {...formItemPublOpts(props)}
                    openOnFieldClick
                    minSearchLength={0}
                    mask={mask}
                    maxLength={maxLength}
                    dataSource={dataSource}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </Autocomplete>
            </div>
        </Item>
    )
};

const FormMemo = (props) => {
    const { label, required } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-memo-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <TextArea
                    {...formItemPublOpts(props)}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextArea>
            </div>
        </Item>
    )
};

const FormBool = (props) => {
    const { label, value, onValueChange, readOnly, required, disabled } = props;
    const booleanValue = value === 1 || value === true;

    return (
        <Item cssClass={'fw-bool-item'}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <CheckBox
                    value={booleanValue}
                    onValueChange={onValueChange}
                    readOnly={readOnly}
                    disabled={disabled}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                />
            </div>
        </Item>
    )
};

const FormRegx = (props) => {
    const { label, mask, required, rule, message, maxLength } = props;

    const regxMessage = fwUtil.conv.tern(`데이터 형식이 잘못되었습니다.`, message);

    const validateValue = (value) => {
        return value === "" ? true : (rule ? rule.test(value) : false);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-regx-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <TextBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    maxLength={maxLength}
                >
                    <Validator>
                        {required && <RequiredRule />}
                        <CustomRule
                            message={regxMessage}
                            validationCallback={(e) => validateValue(e.value)}
                        />
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormNumb = (props) => {
    const { label, value, length, suffix, readOnly, onValueChange, required, disabled, max } = props;

    let format = '';
    const [integerLength, decimalLength] = length || [null, null];
    const integerPart = '#0';
    const decimalPart = decimalLength !== null && decimalLength !== 0 ? `.${'0'.repeat(decimalLength)}` : '';
    const suffixPart = suffix ? ` ${suffix}` : '';
    const numericValue = typeof value === 'string' ? Number(value) : value;
    format = `${integerPart}${decimalPart}${suffixPart}`;

    const beforeChange = async (e) => {
        const convValue = await numbBeforeChange(e, integerLength, decimalLength);
        onValueChange(convValue);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-numb-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <NumberBox
                    format={format}
                    value={numericValue}
                    onValueChange={beforeChange}
                    readOnly={readOnly}
                    disabled={disabled}
                    min={0}
                    max={max}
                    elementAttr={{ class: 'form-editor' }}
                    inputAttr={{ class: 'form-editor-input' }}
                    stylingMode='outlined'
                    showSpinButtons
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </NumberBox>
            </div>
        </Item>
    )
};

const FormMont = (props) => {
    const { label, mask, required } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const calendarOptions = { maxZoomLevel: 'year' };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <DateBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    pickerType={pickerType}
                    calendarOptions={calendarOptions}
                    type='date'
                    displayFormat={'yyyy-MM'}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>
        </Item>
    )
};

const FormTime = (props) => {
    const { label, mask, required } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';
    const calendarOptions = { visible: false };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <DateBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    pickerType={pickerType}
                    calendarOptions={calendarOptions}
                    type='datetime'
                    displayFormat={'HH:mm:ss'}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>
        </Item>
    )
};

const FormRndt = (props) => {
    const { label, required, value, onValueChange, disabled } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <DateRangeBox
                    value={value}
                    onValueChange={onValueChange}
                    acceptCustomValue={false}
                    disabled={disabled}
                    pickerType={pickerType}
                    labelMode='hidden'
                    stylingMode='outlined'
                    displayFormat={'yyyy-MM-dd'}
                    dateSerializationFormat={'yyyy-MM-dd'}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateRangeBox>
            </div>
        </Item>
    )
};

const FormDate = (props) => {
    const { label, mask, required } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-date-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <DateBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    pickerType={pickerType}
                    type='date'
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>
        </Item>
    )
};

const FormDtme = (props) => {
    const { label, mask, required } = props;
    const { isXSmall } = useScreenSize();
    const pickerType = isXSmall ? 'rollers' : 'calendar';

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-dtme-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <DateBox
                    {...formItemPublOpts(props)}
                    mask={mask}
                    pickerType={pickerType}
                    type='datetime'
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </DateBox>
            </div>
        </Item>
    )
};

const FormSelc = (props) => {
    const { label, defaultValue, items, displayExpr, valueExpr, mask, required } = props;

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-selc-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <SelectBox
                    {...formItemPublOpts(props)}
                    placeholder='선택해주세요'
                    items={items}
                    defaultValue={defaultValue}
                    valueExpr={valueExpr}
                    displayExpr={displayExpr}
                    mask={mask}
                >
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </SelectBox>
            </div>
        </Item>
    )
};

const FormPass = (props) => {
    const { label, maxLength, mask, confirm, password, required } = props;
    const [passwordMode, setPasswordMode] = useState('password');
    const buttonOpt = {
        icon: 'eyeopen',
        stylingMode: 'text',
        onClick: () => {
            setPasswordMode((prev) => (prev === 'text' ? 'password' : 'text'));
        },
    };

    const confirmPassword = useCallback(
        ({ value }) => {
            const currentPassword = password;
            if ((!value || value.trim() === '') && (!currentPassword || currentPassword.trim() === '')) {
                return true;
            }
            return value === currentPassword;
        }, [password]
    );

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-text-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <TextBox
                    {...formItemPublOpts(props)}
                    mode={passwordMode}
                    mask={mask}
                    maxLength={maxLength}
                >
                    <TextButton
                        name={`fw-${label}-password`}
                        location='after'
                        options={buttonOpt}
                    />
                    <Validator>
                        {required && <RequiredRule />}
                        {confirm &&
                            <CustomRule
                                message="비밀번호가 일치하지 않습니다."
                                validationCallback={confirmPassword}
                            />
                        }
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormGrid = (props) => {
    const { label, mask, required, onClick, allowCustomValue } = props;
    const buttonOpt = {
        icon: 'search',
        stylingMode: 'text',
        onClick: onClick,
        disabled: false,
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-grid-item': true, 'accent': required })}>
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <TextBox
                    {...formItemPublOpts(props)}
                    readOnly={!allowCustomValue}
                    mask={mask}
                >
                    <TextButton
                        name={`fw-${label}-grid-opener`}
                        location='after'
                        options={buttonOpt}
                    />
                    <Validator>
                        {required && <RequiredRule />}
                    </Validator>
                </TextBox>
            </div>
        </Item>
    )
};

const FormBtns = (props) => {
    const { icon, text, onClick, disabled } = props;

    return (
        <Item cssClass={'fw-btns-item'}>
            <div className={'fw-btns-wrapper'}>
                <Button
                    icon={icon}
                    text={text}
                    onClick={onClick}
                    disabled={disabled}
                />
            </div>
        </Item>
    )
};

const FormExce = (props) => {
    const {
        label,
        value, onValueChange,
        readOnly, disabled, required
    } = props;

    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileName = file.name;
        const parts = fileName.split(".");
        const extension = parts[parts.length - 1];
        if (!allowXlsxFileExtension.includes(extension)) {
            fwUtil.aler.toast.erro('파일 형식이 옳바르지 않습니다.');
            onValueChange(null);
            return;
        }
        onValueChange(file);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-exce-item': true, 'accent': required, 'null': !value })} colSpan={2} >
            <div className={'fw-input-wrap'}>
                <FormLabel label={label} required={required} />
                <FileUploader
                    selectButtonText="찾아보기"
                    uploadMode='useButtons'
                    disabled={readOnly || disabled}
                    accept={accepXlsxFileExtension}
                    multiple={false}
                    onValueChanged={onValueChanged}
                />
            </div>
        </Item>
    );
};

const FormImag = (props) => {
    const {
        label,
        value, onValueChange, defaultValue,
        readOnly, disabled, required,
        size, circle, allowDrawing,
    } = props;

    const [image, setImage] = useState('');
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const onDropZoneEnter = useCallback(() => setIsDropZoneActive(true), [setIsDropZoneActive]);
    const onDropZoneLeave = useCallback(() => setIsDropZoneActive(false), [setIsDropZoneActive]);
    const targetRef = useRef(null);

    // 이미지 변경 후 적용
    useEffect(() => {
        const file = value;
        if (!file || file === '') {
            setIsDropZoneActive(false);
            setImage('');
            return;
        }
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageDataUrl = e.target.result;
            setImage(imageDataUrl);
            setIsDropZoneActive(false);
        };
        reader.readAsDataURL(file);
    }, [value]);

    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileExtension = file.type.toLowerCase();
        if (!allowImagFileExtension.includes(fileExtension)) {
            fwUtil.aler.toast.erro('파일 형식이 옳바르지 않습니다.');
            return;
        }
        onValueChange(file);
    };

    return (
        <Item cssClass={fwUtil.conv.class({ 'fw-imag-item': true, 'accent': required, 'circle': circle, 'allowDrawing': allowDrawing })}>
            <div className={'form-image-view fw-flex-box'}>
                <div
                    className={`form-image ${!disabled && !readOnly ? 'editable' : ''}`}
                    style={{
                        width: size,
                        height: size,
                        maxHeight: size,
                    }}
                >
                    <div
                        ref={targetRef}
                        className={`fw-dropzone ${isDropZoneActive ? 'dropzone-active' : ''}`}
                        title={!disabled && !readOnly ? '이미지 가져오기' : ''}
                    >
                        {fwUtil.conv.check.nue(value) ? (
                            <div className="fw-dropzone-text fw-flex-box col">
                                {!disabled && !readOnly ? (
                                    <>
                                        {label && <span className='dropzone-label'>*{label}*</span>}
                                        <span>업로드할 파일을 <br />드래그 앤 드롭 하시거나 <br />클릭해 주세요</span>
                                    </>
                                ) : (
                                    defaultValue ? (
                                        <figure className={"fw-default-img-wrapper"} style={{ width: size, height: size }}>
                                            <img src={defaultValue} alt="기본이미지" />
                                        </figure>
                                    ) : (
                                        <>
                                            {label && <span className='dropzone-label'>*{label}*</span>}
                                            <span>표시할 이미지가 <br />없습니다</span>
                                        </>
                                    )
                                )}
                            </div>
                        ) : (
                            <img className='fw-dropzone-image' src={image} alt={label} />
                        )}
                        <i className='dx-icon-photooutline' />
                    </div>
                </div>
                {!disabled && !readOnly &&
                    <FileUploader
                        uploadMode='useButtons'
                        accept={accepImagFileExtension}
                        dialogTrigger={targetRef.current}
                        dropZone={targetRef.current}
                        visible={false}
                        multiple={false}
                        onValueChanged={onValueChanged}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                    />
                }
                {allowDrawing &&
                    <DrawingButton
                        width={size}
                        disabled={readOnly}
                        saveCanvas={(file) => onValueChange(file)}
                    />
                }
            </div>
        </Item>
    );
};

export {
    FormExce,
    FormImag,
    FormText,
    FormAuto,
    FormMemo,
    FormBool,
    FormRegx,
    FormNumb,
    FormMont,
    FormTime,
    FormRndt,
    FormDate,
    FormDtme,
    FormSelc,
    FormGrid,
    FormPass,
    FormBtns,
};