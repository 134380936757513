import React from "react";

export function HomeCardItem(props) { 
    const { title, children, classNames } = props;

    return (
        <div className={`home-card-item-container ${classNames && classNames}`}>
            <div className="home-card-item-header fw-flex-box">
                <div className="home-box-title">{title}</div>
            </div>
            <div className="home-card-item-body">
                {children}
            </div>
        </div>
    )
}