import React, { useState } from "react";
import { useTheme } from "../../contexts/theme";
import { Button } from "devextreme-react";

export default function ModeChanger() {
    const { applyMode, getMode } = useTheme();
    const [activeMode, setActiveMode] = useState(getMode());

    function handleChangeThemeMode(mode) {
        applyMode(mode);
        setActiveMode(mode);
    };

    return (
        <>
            <Button 
                icon='sun'
                onClick={() => handleChangeThemeMode("light")}
                className={activeMode === 'light' && 'active'}
            />
            <Button 
                icon='moon'
                onClick={() => handleChangeThemeMode("dark")}
                className={activeMode === 'dark' && 'active'}
            />
        </>
    )
}

