// PproInfoStat - 구매발주 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PproInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/detail/search`;  // 서브 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'ppro_numb';
    const subsKey = ['ppro_numb', 'ppro_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const [pproNumb, setPproNumb] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const pproNumbChange = useCallback((e) => { setPproNumb(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName, pproNumb: pproNumb };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, clieName, pproNumb, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        const sendData = { pproNumb: keyData };
        await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainGridRef?.current?.instance?.deselectAll();
        subsGridRef?.current?.instance?.deselectAll();
        mainHandleSearch();
        subsHandleSearch();
    }, [mainHandleSearch, subsHandleSearch, mainGridRef, subsGridRef]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.grid.sel.sRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '발주번호', value: pproNumb, valueChange: pproNumbChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, clieNameChange, pproNumb, pproNumbChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, items: toolbarItems }}
                selection={'none'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })}
                {CommCol({ dataField: "damd_name", caption: "발주담당" })}
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })}
                {CommCol({ dataField: "ppro_usag", caption: "용도" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })}
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })}
                {CommCol({ dataField: "clie_damd", caption: "업체담당" })}
                {DateCol({ dataField: "pdel_date", caption: "납품일자" })}
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}                
                {CommCol({ dataField: "insp_damd", caption: "검수자(방법)" })}
                {CommCol({ dataField: "ppro_remk", caption: "특기사항" })}
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })}
                {CommCol({ dataField: "afte_conf", caption: "결재진행" })}
                {DateCol({ dataField: "appr_date", caption: "승인요청일" })}
                {DateCol({ dataField: "rele_date", caption: "발주처리일" })}
                {CommCol({ dataField: "prin_yesn", caption: "발주서출력여부" })}
                {CommCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {CommCol({ dataField: "ppro_cwon", caption: "발주금액" })}                                                
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'none'}
            >           
                {CommCol({ dataField: "ppro_seri", caption: "순번" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재품명" })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격" })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "ppro_pric", caption: "발주단가" })}
                {DeciCol({ dataField: "ppro_cwon", caption: "발주금액" })}
                {CommCol({ dataField: "ppro_memo", caption: "비고" })}
                {CommCol({ dataField: "reqe_dept", caption: "수요처" })}
            </SubsGrid>                      
        </div>
    );
};