import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

export const getStocLoca = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/ware/stocloca/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getStocAdjuMatl = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/mat/stock/adjust`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const getStocList = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/ware/stocloca/list`;
        const data = await fwUtil.apis.search(api);
        let convData = ['선택하세요'];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getStocList.error === ', error);
        return [];
    }
};

export const getStocItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/stock/adjustment/itmegubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getStocItemGubu.error === ', error);
        return [];
    }
};

export const getForwCateList = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/forward/matlcode/category/list`;
        const data = await fwUtil.apis.search(api);
        let convData = ['선택하세요'];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.cate_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getForwCateList.error === ', error);
        return [];
    }
};

export const getForwStocLoca = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/forward/stocloca/list`;
        const data = await fwUtil.apis.search(api);
        let convData = ['선택하세요'];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getForwStocLoca.error === ', error);
        return [];
    }
};

const getAdjuStocList = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/stock/adjustment/stocloca/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getAdjuStocList.error === ', error);
        return [];
    }
};

const getAdjuMatlGubu = async () => {
    try {
        const api = `privat${mesGubu}/set/mat/stock/adjustment/itmegubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = [...convData, ...data.map(item => item.subs_name)];
        }
        return convData; 
    }
    catch (error) {
        console.log('getAdjuMatlGubu.error === ', error);
        return [];
    }
};

const getAdjuMatlList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/mat/stock/adjustment/matlcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        
        return data;
    }
    catch (error) {
        console.log('getAdjuMatlList.error === ', error);
        return [];
    }
};

export const mat = {
    stoc: {
        loca: getStocLoca,
        adju: getStocAdjuMatl,
        list: getStocList,
    },
    item: {
        gubu: getStocItemGubu,
    },
    forw: {
        cateList: getForwCateList,
        stocLoca: getForwStocLoca,
    },
    adju: {
        stoc: getAdjuStocList,
        matl: {
            gubu: getAdjuMatlGubu,
            list: getAdjuMatlList,
        }
    }    
}