import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    addr_ipnb: '',
    addr_yesn: false,
    addr_memo: '',
};

export function AddrStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                addr_ipnb: seleData.addr_ipnb || '',
                addr_yesn: seleData.addr_yesn === 1 ? true : false,
                addr_memo: seleData.addr_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            addrIpnb: data.addr_ipnb || 0,
            addrYesn: data.addr_yesn || false,
            addrMemo: data.addr_memo || ''
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'IP 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.addr_ipnb, onValueChange: updateField('addr_ipnb'), label: '허용 IP', disabled: true, required: true })}                
                {FormBool({ value: data.addr_yesn, onValueChange: updateField('addr_yesn'), label: '사용여부' })}                          
            </GroupItem>
            {FormMemo({ value: data.addr_memo, onValueChange: updateField('addr_memo'), label: '허용사유', required: true })}     
        </PopupForm>        
    );
}