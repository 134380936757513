import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";

export function DashCharCard(props) {
    const { loading, data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText}분 ${arg.seriesName}: ${arg.value} kWh`,
        };
    };

    const series = data.reduce((acc, entry) => {
        for (let i = 1; i <= 10; i++) {
            const sensKey = `equ${i}_sens`;
            const nameKey = `equ${i}_numb`;
    
            if (entry[sensKey] !== undefined && !acc.some(serie => serie.props.valueField === sensKey)) {
                acc.push(
                    SpinSeri({
                        valueField: sensKey,
                        caption: entry[nameKey],
                        args: "time",
                        axis: "elec"
                    })
                );
            }
        }
    
        return acc;
    }, []);
    
    return (
        <DashCard
            title={"시간별 전력량 현황"}
            addClass={'prod'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
                legend
            >
                {CommAxis({ name: "elec", caption: "사용량(kWh)", position: "left", start: 0 })}
                {series}
            </CommChart>
        </DashCard>
    )
}