import React, { useCallback, useEffect, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashMainItemCard } from "./Dash-main-item";
import { DashSubsItemCard } from "./Dash-subs-item";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import "./dash.scss";

const tempMain = [
    { kpis_gubu: "P", kpis_name: "제조리드타임 (단축)", kpis_unit: "Day", kpis_befo: 14, kpis_afte: 10, kpis_valu: 12 },
    { kpis_gubu: "Q", kpis_name: "완제품 불량률 (감소)", kpis_unit: "%", kpis_befo: 9.3, kpis_afte: 7.0, kpis_valu: 8.2 },
];

const tempSubs = [
    { kpis_gubu: "수주량", kpis_mo01: 300, kpis_mo02: 300, kpis_mo03: 300, kpis_mo04: 300, kpis_mo05: 300, kpis_mo06: 300, kpis_mo07: 500, kpis_mo08: 0, kpis_mo09: 500, kpis_mo10: 500, kpis_mo11: 500, kpis_mo12: 500, kpis_tota: 4300 },
    { kpis_gubu: "생산량", kpis_mo01: 50, kpis_mo02: 200, kpis_mo03: 300, kpis_mo04: 300, kpis_mo05: 300, kpis_mo06: 300, kpis_mo07: 400, kpis_mo08: 500, kpis_mo09: 500, kpis_mo10: 500, kpis_mo11: 500, kpis_mo12: 500, kpis_tota: 4350 },
    { kpis_gubu: "납품수", kpis_mo01: 0, kpis_mo02: 100, kpis_mo03: 300, kpis_mo04: 350, kpis_mo05: 250, kpis_mo06: 250, kpis_mo07: 250, kpis_mo08: 400, kpis_mo09: 450, kpis_mo10: 450, kpis_mo11: 450, kpis_mo12: 450, kpis_tota: 3900 },
    { kpis_gubu: "수주잔고", kpis_mo01: 300, kpis_mo02: 500, kpis_mo03: 500, kpis_mo04: 450, kpis_mo05: 500, kpis_mo06: 550, kpis_mo07: 650, kpis_mo08: 200, kpis_mo09: 250, kpis_mo10: 300, kpis_mo11: 350, kpis_mo12: 400, kpis_tota: 0 },
    { kpis_gubu: "작업일수", kpis_mo01: 20, kpis_mo02: 18, kpis_mo03: 21, kpis_mo04: 21, kpis_mo05: 21, kpis_mo06: 21, kpis_mo07: 21, kpis_mo08: 17, kpis_mo09: 20, kpis_mo10: 19, kpis_mo11: 22, kpis_mo12: 21, kpis_tota: 242 },
    { kpis_gubu: "평균 리드타임", kpis_mo01: 0, kpis_mo02: 18, kpis_mo03: 17, kpis_mo04: 16, kpis_mo05: 14, kpis_mo06: 14, kpis_mo07: 14, kpis_mo08: 14, kpis_mo09: 14, kpis_mo10: 14, kpis_mo11: 14, kpis_mo12: 14, kpis_tota: 163 },
    { kpis_gubu: "불량수", kpis_mo01: 8, kpis_mo02: 26, kpis_mo03: 32, kpis_mo04: 30, kpis_mo05: 28, kpis_mo06: 29, kpis_mo07: 38, kpis_mo08: 46, kpis_mo09: 45, kpis_mo10: 44, kpis_mo11: 38, kpis_mo12: 39, kpis_tota: 403 },
    { kpis_gubu: "불량률", kpis_mo01: 16.0, kpis_mo02: 13.0, kpis_mo03: 10.7, kpis_mo04: 10.0, kpis_mo05: 9.3, kpis_mo06: 9.7, kpis_mo07: 9.5, kpis_mo08: 9.2, kpis_mo09: 9.0, kpis_mo10: 8.6, kpis_mo11: 7.6, kpis_mo12: 7.8, kpis_tota: 9.3 },
    { kpis_gubu: "합격수", kpis_mo01: 42, kpis_mo02: 174, kpis_mo03: 268, kpis_mo04: 270, kpis_mo05: 272, kpis_mo06: 271, kpis_mo07: 362, kpis_mo08: 454, kpis_mo09: 455, kpis_mo10: 456, kpis_mo11: 462, kpis_mo12: 461, kpis_tota: 3947 },
]

export default function KpisInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/sale/search`;  // KPI 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/purs/search`;  // 산출근거 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [mainData, setMainData] = useState([]);
    const [subsData, setSubsData] = useState([]);
    const mainDataUpdate = useCallback((e) => setMainData(e), []);
    const subsDataUpdate = useCallback((e) => setSubsData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        setPageLoading(true);
        //const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn };
        //await fwUtil.apis.search(mainSearchApi, sendData, mainDataUpdate);
        mainDataUpdate(tempMain)
        setPageLoading(false);
    }, [dateValue, duraYesn, mainSearchApi, mainDataUpdate]);

    const subsHandleSearch = useCallback(async (keyData) => {
        setPageLoading(true);
        //const sendData = { kpisGubu: keyData };
        //await fwUtil.apis.search(subsSearchApi, sendData, subsDataUpdate);
        subsDataUpdate(tempSubs);
        setPageLoading(false);
    }, [subsSearchApi, subsDataUpdate]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col kpis">
            <FwAppLoader loading={loading} />
            <CommDash
                title={title}
                dashRef={dashRef}                
                duraYesn={duraYesn}                
                dateValue={dateValue}
                duraYesnChange={duraYesnChange}                
                dateValueChange={dateValueChange}
                search={mainHandleSearch}
                exp={{ fileName: "KPI현황", disabled: authList.auth_dnlo === 0 }}
                toolbar
            >
                <DashMainItemCard loading={pageLoading} mainData={mainData} subsSearch={subsHandleSearch} />
                <DashSubsItemCard loading={pageLoading} data={subsData} />
            </CommDash>
        </div>
    )
}