import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react';
import { useEffect, useState } from 'react';
import { LicenseHtml } from './popup-license';

const PopupToolbar = (props) => {
    const {
        title, type, onSubmit, onClose
    } = props;

    return (
        <div className='fw-popup-toolbar'>
            <Toolbar>
                {title &&
                    <ToolbarItem location='before'>
                        <span className='fw-popup-toolbar-title'>{title}</span>
                    </ToolbarItem>
                }
                <ToolbarItem location='after'>
                    <Button text={`${type}`} stylingMode='contained' type='default' onClick={onSubmit} />
                </ToolbarItem>
                <ToolbarItem location='after'>
                    <Button text='닫기' stylingMode='outlined' type='default' onClick={onClose} />
                </ToolbarItem>
            </Toolbar>
        </div>
    )
}

const licenseTabData = [
    { liceIdno: 1, titlName: '업데이트 내역' },
    { liceIdno: 2, titlName: '오픈소스' },
];

const parseUpdateText = (text) => {
    const sections = text.split('//');
    return sections.map(section => {
        const [versionLine, ...details] = section.trim().split('\n');
        return {
            version: versionLine,
            details
        };
    }).filter(update => update.version);
};

function LicenseComp({ data }) {
    const [updates, setUpdates] = useState([]);

    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const response = await fetch('/license-config/update-history.txt');
                const text = await response.text();

                const parsedUpdates = parseUpdateText(text);
                setUpdates(parsedUpdates);
            } catch (error) {
                console.error('Error fetching updates:', error);
            }
        };
        fetchUpdates();
    }, []);

    if (data.liceIdno === 1) {
        return (
            <div className='app-info-box-container'>
                <div className='app-info-box-title'>{data.titlName || '항목없음'}</div>
                <div className='app-info-box-detail'>
                    <ul className='app-info-box-list'>
                        {updates.map((update, index) => (
                            <li key={index} className='list-items'>
                                <h3 className='list-title'>{update.version}</h3>
                                <ul>
                                    {update.details.map((detail, i) => (
                                        <li key={i} className='list-detail'>{detail}</li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }

    if (data.liceIdno === 2) {
        return (
            <div className='app-info-box-container'>
                <div className='app-info-box-title'>{data.titlName || '항목없음'}</div>
                <div className='app-info-box-detail' dangerouslySetInnerHTML={{ __html: LicenseHtml }} />
            </div>
        )
    }

}

export {
    PopupToolbar,
    LicenseComp,
    licenseTabData
}

