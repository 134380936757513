import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    mana_numb: '',
    clie_code: '',
    clie_name: '',
    item_code: '',
    item_name: '',
    cont_date: fwUtil.date.getsText.date(),
    aser_memo: '',
};

const setBiz = set.mes.biz;

export function AserInfoRegiInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 제품 그리드
    const itemGridRef = useRef(null);
    const [itemData, setItemData] = useState([]);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const itemKeyExpr = 'item_code';
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';    
    // 그리드 팝업
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 제품 조회
    const itemHandleSearch = useCallback(async() => {
        const sendData = { itemCode: "", itemName: "" };
        const res = await setBiz.aser.info.item(sendData);
        itemGridUpdate(res);
    }, [itemGridUpdate]);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await setBiz.aser.info.clie(sendData)
        clieGridUpdate(res);
    }, [clieGridUpdate]);    

    // 제품 팝업 열릴 때
    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 제품 팝업 더블 클릭
    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);    

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            manaNumb: data.mana_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            contDate: data.cont_date ? fwUtil.date.convTime.fmDate(data.cont_date) : null,
            aserMemo: data.aser_memo || '',            
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'제품 선택'}   
            type={'선택'}
            visible={itemPopupVisible}  
            shown={itemPopupOnShowing}  
            hiding={itemPopupHiding}
        >   
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_gubu", caption: "제품구분" })}
            </ScrollGrid>
        </PopupGrid>        
        <PopupGrid
            title={'고객 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                toolbar={{ default: false }}
                onRowDblClick={clieOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'A/S 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '고객번호', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', readOnly: true })}         
                {FormGrid({ value: data.item_code, onClick: () => setItemPopupVisible(true), label: '제품번호', required: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', readOnly: true })}
                {FormDate({ value: data.cont_date, onValueChange: updateField('cont_date'), label: '계약일자' })}     
            </GroupItem>
            {FormMemo({ value: data.aser_memo, onValueChange: updateField('aser_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}