// RoutQcisChan - 공정 지시 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { RoutQcisChanUpdate } from './QcisChan-update';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function RoutQcisChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/inspect/change/search`;  // 조회 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/inspect/change/update`;  // 등록 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/inspect/change/delete`;  // 등록 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'insp_numb';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);           
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [workNumb, setWorkNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);
    // 삭제 팝업창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, workNumb: workNumb };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, workNumb, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '작업지시번호', value: workNumb, valueChange: workNumbChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [workNumb, workNumbChange, handleSearch]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const seleData = fwUtil.grid.get.sData(gridRef);
        const postData = { 
            inspNumb: seleData.insp_numb,
            workNumb: seleData.work_numb,
            itemCode: seleData.item_code,
            routNumb: seleData.rout_numb,
            routSeri: seleData.rout_seri,
            itemName: seleData.item_name,
            routName: seleData.rout_name,
            inspQtys: seleData.insp_qtys,
            judgQtys: seleData.judg_qtys,
            inspDamd: seleData.insp_damd,
            inspDate: seleData.insp_date,
            judgYesn: seleData.judg_yesn,
            infeCa01: seleData.infe_ca01,
            infeCa02: seleData.infe_ca02,
            infeCa03: seleData.infe_ca03,
            infeCa04: seleData.infe_ca04,
            infeCa05: seleData.infe_ca05,
            ca05Memo: seleData.ca05_memo,
            stocLoca: seleData.stoc_loca,
            clieCode: seleData.clie_code,
            clieName: seleData.clie_name,
        }
        await fwUtil.apis.submit(deleteApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        handleSearch();
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
            } else if (act === 'del') {
                setDeleteAlertVisible(true);
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);
    
    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_inse === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_inse === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "insp_gubu", caption: "검사구분" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "item_code", caption: "품번" })}
                {CommCol({ dataField: "item_name", caption: "품명" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {DeciCol({ dataField: "insp_qtys", caption: "검사수량" })}
                {DeciCol({ dataField: "judg_qtys", caption: "판정수량" })}
                {CommCol({ dataField: "insp_damd", caption: "검사자" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {BoolCol({ dataField: "judg_yesn", caption: "불합격여부" })}
                {BoolCol({ dataField: "infe_ca01", caption: "불량원인:소재", width: 160 })}
                {BoolCol({ dataField: "infe_ca02", caption: "불량원인:치수", width: 160 })}
                {BoolCol({ dataField: "infe_ca03", caption: "불량원인:외관", width: 160 })}
                {BoolCol({ dataField: "infe_ca04", caption: "불량원인:셋팅", width: 160 })}
                {BoolCol({ dataField: "infe_ca05", caption: "불량원인:탭/나사", width: 160 })}
                {BoolCol({ dataField: "infe_ca06", caption: "불량원인:전/후처리", width: 180 })}
                {BoolCol({ dataField: "infe_ca07", caption: "불량원인:기타", width: 160 })}
                {CommCol({ dataField: "ca07_memo", caption: "불량원인:기타사유" })}
                {CommCol({ dataField: "insp_memo", caption: "비고" })}
            </PagingGrid>
            <RoutQcisChanUpdate
                mainGrid={gridRef}
                refresh={handleSearch}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 검사를 삭제하시겠습니까?'}
            />
        </div>
    );
};