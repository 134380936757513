// BomRegist - BOM 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ItemBomsRegiInsert } from './BomsRegi-insert';
import { ItemBomsRegiUpdate } from './BomsRegi-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ItemBomsRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `/${acce}/${gubu}/${cata}/${catb}/register/header/search`;  // 메인 조회 api
    const mainTargetApi = `/${acce}/${gubu}/${cata}/${catb}/register/target`;         // 메인 타겟 api
    const subsTargetApi = `/${acce}/${gubu}/${cata}/${catb}/register/target/item`;    // 메인 상세 api
    const mainInsertApi = `/${acce}/${gubu}/${cata}/${catb}/register/insert`;         // 메인 등록 api
    const mainUpdateApi = `/${acce}/${gubu}/${cata}/${catb}/register/update`;         // 메인 수정 api
    const mainDeleteApi = `/${acce}/${gubu}/${cata}/${catb}/register/delete`;         // 메인 삭제 api
    const subsSearchApi = `/${acce}/${gubu}/${cata}/${catb}/register/detail/search`;  // 상세 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'item_code';
    const subsKey = ['item_code', 'matl_code'];
    const deleKey = (data) => {
        return { 
            itemCode: data, 
        }
    };    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState(""); 
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, itemCode, itemName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { itemCode: key };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) { subsHandleSearch(key); }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = mainGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.apis.delete(mainDeleteApi, mainGridRef, deleKey, mainHandleSearch);
        }
        mainDeleteAlertHide();

    }, [mainGridRef, mainHandleSearch, mainDeleteAlertHide, mainDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품코드', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const mainHandleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);

        if (mainSelectedRowKey) {
            if (act === 'upd') {
                setMainUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setMainDeleteAlertVisible(true);
                return;
            }                
        } else {
            fwUtil.aler.toast.nsWarn('BOM을 선택 후 시도해주세요.', 'warning', 2000);
        };

    }, [mainGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => mainHandleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => mainHandleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "BOM제품번호" })}
                {CommCol({ dataField: "item_name", caption: "품명및규격" })}
                {DeciCol({ dataField: "boms_qtys", caption: "구성수량" })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용유무" })}
                {CommCol({ dataField: "boms_memo", caption: "비고" })}                                     
            </MainGrid>
            <ItemBomsRegiInsert
                mainRefresh={mainHandleSearch}
                subsRefresh={subsHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <ItemBomsRegiUpdate
                mainRefresh={mainHandleSearch}
                subsRefresh={subsHandleSearch}
                mainGrid={mainGridRef}
                mainTargetApi={mainTargetApi}
                subsTargetApi={subsTargetApi}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 BOM을 삭제하시겠습니까?'}
            />   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                selection={'single'}
                toolbar={{ default: false }}
            >
                {CommCol({ dataField: "matl_code", caption: "구성품번", allowFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "matl_name", caption: "구성품명", allowFiltering: false, allowSorting: false })}
                {DeciCol({ dataField: "boms_qtys", caption: "구성수량", allowFiltering: false, allowSorting: false })}
                {CommCol({ dataField: "matl_gubu", caption: "품목구분", allowFiltering: false, allowSorting: false })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
                {CommCol({ dataField: "matl_memo", caption: "비고", allowFiltering: false, allowSorting: false })}
            </SubsGrid>                            
        </div>
    );
};