import React from "react";
import { CheckBox, DateRangeBox } from "devextreme-react"

const ToolbarDateRangeItem = React.memo(function ToolbarDateRangeItem({ dateValue, dateValueChange, duraYesn, duraYesnChange }) {
    if (!dateValueChange || !duraYesnChange) {
        return;
    }

    return (
        <div className='fw-header-date-search-panel fw-flex-box row'>
            <DateRangeBox
                width={245}
                value={dateValue}
                onValueChanged={dateValueChange}
                acceptCustomValue={false}
                labelMode='hidden'
                disabled={!duraYesn}
                stylingMode='outlined'
                displayFormat={'yyyy-MM-dd'}
                dateSerializationFormat={'yyyy-MM-dd'}
            />
            <CheckBox
                value={duraYesn}
                onValueChanged={duraYesnChange}
                elementAttr={{ 'aria-label': 'Handle value change' }}
            />
        </div>
    )
});

export { ToolbarDateRangeItem };