import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    item_code: '',
    item_name: '',
    blue_gubu: '',
    dist_dept: '',
    mana_dept: '',
    enab_yesn: true,
    rece_date: fwUtil.date.getsText.date(),
    blue_memo: '',    
}

const setTec = set.mes.tec;

export function BluePrinRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 선택값
    const [gubuList, setGubuList] = useState([]);
    const gubuListChange = useCallback((e) => setGubuList(e), []);
    // 제품 그리드
    const itemGridRef = useRef(null);
    const clieGridRef = useRef(null);
    const [itemData, setItemData] = useState([]);
    const [clieData, setClieData] = useState([]);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const itemKeyExpr = 'item_code';   
    const clieKeyExpr = 'clie_code';   
    // 그리드 팝업
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);
    // 그리드 조회 조건
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');  
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []); 

    const fetchData = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([setTec.blue.gubu()]);
            gubuListChange(gubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [gubuListChange]);

    // 제품 조회
    const itemHandleSearch = useCallback(async() => {
        const sendData = { itemCode: itemCode, itemName: itemName };
        const res = await setTec.blue.item(sendData);
        itemGridUpdate(res);
    }, [itemCode, itemName, itemGridUpdate]);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: '', clieName: '' };
        const res = await setTec.blue.clie(sendData);
        clieGridUpdate(res);
    }, [clieGridUpdate]);    

    // 제품 팝업 열릴 때
    const itemPopupOnShowing = useCallback(async() => {
        await itemHandleSearch();
    }, [itemHandleSearch]);

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(async() => {
        await clieHandleSearch();
    }, [clieHandleSearch]);    

    // 제품 팝업 더블 클릭
    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]); 

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);     

    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: itemHandleSearch },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, itemHandleSearch]);   

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchData();
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {       
                mana_numb: seleData.mana_numb || defaultData.mana_numb,
                item_code: seleData.item_code || defaultData.item_code,
                item_name: seleData.item_name || defaultData.item_name,
                clie_code: seleData.clie_code || defaultData.clie_code,
                clie_name: seleData.clie_name || defaultData.clie_name,
                publ_dept: seleData.publ_dept || defaultData.publ_dept,
                dist_dept: seleData.dist_dept || defaultData.dist_dept,
                mana_dept: seleData.mana_dept || defaultData.mana_dept,
                blue_gubu: seleData.blue_gubu || defaultData.blue_gubu,
                enab_yesn: seleData.enab_yesn || defaultData.enab_yesn,
                rece_date: seleData.rece_date || defaultData.rece_date,
                blue_memo: seleData.blue_memo || defaultData.blue_memo,                
            };

            dataUpdate(resData);
        }
    }, [fetchData, dataUpdate, hiding, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {      
            manaNumb : data.mana_numb || '',
            itemCode : data.item_code || '',
            itemName : data.item_name || '',
            clieCode : data.clie_code || '',
            clieName : data.clie_name || '',            
            publDept : data.publ_dept || '',
            distDept : data.dist_dept || '',
            manaDept : data.mana_dept || '',
            blueGubu : data.blue_gubu || '',
            enabYesn : data.enab_yesn || false,
            receDate : data.rece_date ||fwUtil.date.getsText.date(),
            blueMemo : data.blue_memo || '',            
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'제품 선택'}   
            type={'선택'}
            visible={itemPopupVisible}  
            shown={itemPopupOnShowing}  
            hiding={itemPopupHiding}
        >   
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
            </ScrollGrid>
        </PopupGrid>          
        <PopupGrid
            title={'업체 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'업체'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "업체번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
            </ScrollGrid>
        </PopupGrid>                         
        <PopupForm
            title={'도면 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormGrid({ value: data.item_code, onClick: () => setItemPopupVisible(true), label: '제품번호', required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}                
                <GroupItem colSpan={isXSmall ? 1 : 2}>
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', readOnly: true })} 
                </GroupItem>
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '고객사번호', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객사명', readOnly: true })}                 
                {FormDate({ value: data.rece_date, onValueChange: updateField('rece_date'), label: '접수일자' })}
                {FormSelc({ value: data.blue_gubu, onValueChange: updateField('blue_gubu'), label: '도면구분', items: gubuList, required: true })}
                {FormText({ value: data.dist_dept, onValueChange: updateField('dist_dept'), label: '배포부서' })}
                {FormText({ value: data.mana_dept, onValueChange: updateField('mana_dept'), label: '관리부서' })}
            </GroupItem>
            {FormMemo({ value: data.blue_memo, onValueChange: updateField('blue_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}