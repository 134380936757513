import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    matl_code: '',
    matl_name: '',
    item_gubu: '',
    stoc_loca: '',
    orig_qtys: 0.00,
    adju_qtys: 0.00,
    orig_weig: 0.000,
    adju_weig: 0.000,
    orig_pric: 0.00,
    adju_pric: 0.00,
    adju_memo: '',
    adju_date: fwUtil.date.getsText.date(),    
};

export function StocAdjuRegiUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
            if (!seleData) {
                fwUtil.aler.toast.s1Erro();
                hiding();
                return;
            }
            const updatedData = {
                matl_code: seleData.matl_code || '',
                matl_name: seleData.matl_name || '',
                item_gubu: seleData.item_gubu || '',
                stoc_loca: seleData.stoc_loca || '',
                orig_qtys: seleData.stoc_qtys || 0.00,
                adju_qtys: 0.00,
                orig_weig: seleData.stoc_weig || 0.000,
                adju_weig: 0.000,
                orig_pric: seleData.stoc_qpri || 0.00,
                adju_pric: 0.00,
                adju_memo: seleData.adju_memo || '',
                adju_date: fwUtil.date.getsText.date(),                    
            }
            dataUpdate(updatedData);
        } catch (error) {
            
        }
    }, [mainGrid, hiding, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            itemGubu: data.item_gubu || '',
            stocLoca: data.stoc_loca || '',
            origQtys: data.orig_qtys || 0.00,
            adjuQtys: data.adju_qtys || 0.00,
            origWeig: data.orig_weig || 0.000,
            adjuWeig: data.adju_weig || 0.000,
            origPric: data.orig_pric || 0.00,
            adjuPric: data.adju_pric || 0.00,
            adjuMemo: data.adju_memo || '',
            adjuDate: data.adju_date || fwUtil.date.getsText.date(),
        }

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'재고조정'}
                type={'조정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall ? '100%' : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '자재번호', disabled: true, required: true })}
                    {FormText({ value: data.stoc_loca, onValueChange: updateField('stoc_loca'), label: '저장위치', required: true })}
                    {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '자재명', disabled: true })}
                    {FormText({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '품목분류', disabled: true })}
                    {FormNumb({ value: data.orig_qtys, onValueChange: updateField('orig_qtys'), label: '조정전수량', disabled: true })}
                    {FormNumb({ value: data.adju_qtys, onValueChange: updateField('adju_qtys'), label: '조정후수량', length: [12, 2] })}
                    {data.item_gubu !== '완제품' && FormNumb({ value: data.orig_weig, onValueChange: updateField('orig_weig'), label: '조정전중량', disabled: true })}
                    {data.item_gubu !== '완제품' && FormNumb({ value: data.adju_weig, onValueChange: updateField('adju_weig'), label: '조정후중량', length: [12, 3] })}
                    {FormNumb({ value: data.orig_pric, onValueChange: updateField('orig_pric'), label: '조정전단가', disabled: true })}
                    {FormNumb({ value: data.adju_pric, onValueChange: updateField('adju_pric'), label: '조정후단가', length: [15, 2] })}
                    {FormText({ value: data.adju_memo, onValueChange: updateField('adju_memo'), label: '조정사유' })}
                    {FormDate({ value: data.adju_date, onValueChange: updateField('adju_date'), label: '조정일자' })}
                </GroupItem>
            </PopupForm>
        </>
    );
}