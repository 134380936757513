import React, { useCallback, useContext, useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { NavigationProvider, useNavigation } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { ThemeProvider, useTheme } from './focuswin/script/contexts/theme';
import { SessionProvider, SessionContext } from './focuswin/script/contexts/session';
import { useScreenSizeClass } from './utils/media-query';
import { PopupWarn } from './focuswin/script/components/popup/popup';
import LoadPanel from 'devextreme-react/load-panel';
import MesContent from './Content-mes';
import NonAuthContent from './Content-nAuth';
import './focuswin/style/focuswin.icons.base.scss';
import './focuswin/style/focuswin.variables.css';
import './focuswin/style/focuswin.theme.base.scss';

// 한글 딕셔너리 추가
import koMessages from "./ko.json";
import { locale, loadMessages } from "devextreme/localization";

// 라이센스
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';

config({ licenseKey });

// 언어 설정
loadMessages(koMessages);
locale("ko");

// App 컴포넌트 정의
function App() {
  const { user, loading, signOut } = useAuth();
  const { setTheme, getTheme } = useTheme();
  const { routeCheck, getRouteList } = useNavigation();
  const { sessStat, setSessStat } = useContext(SessionContext);
  const [routeErrorVisible, setRouteErrorVisible] = useState(false);
  const [sessionErrorVisible, setSessionErrorVisible] = useState(false);
  const routeErrorHide = useCallback(() => { setRouteErrorVisible(false) }, []);
  const sessionErrorHide = useCallback(() => { setSessionErrorVisible(false);}, []);

  useEffect(() => {
    const theme = getTheme();
    const [accentKey, modeKey] = theme.split('.');
    const accent = accentKey || "blue";
    const mode = modeKey ? `${modeKey}-mode` : "light-mode";
    const appBody = document.body;
    appBody.className = "";
    appBody.classList.add(accent);
    appBody.classList.add(mode);
    setTheme(theme)
  }, [setTheme, getTheme]);

  useEffect(() => {
    if (routeCheck) {
      setRouteErrorVisible(true);
    } else {
      routeErrorHide();
    }
  }, [routeCheck, routeErrorHide]);

  useEffect(() => {
    if (user && !sessStat) {
      setSessionErrorVisible(true);
    }
  }, [user, sessStat]);

  const onSessErrorClick = useCallback(() => {
    signOut();
    setSessStat(true);
    sessionErrorHide();
  }, [signOut, setSessStat, sessionErrorHide]);

  return (
    <>
      {loading ? ( <LoadPanel visible={true} /> ) : 
      user ? ( <MesContent /> ) : ( <NonAuthContent /> )}
      {!loading &&
        <PopupWarn
          closeHide
          visible={routeErrorVisible}
          hiding={routeErrorHide}
          confirm={getRouteList}
          confirmText={"재시도"}
          message={"시스템 메뉴 구성 중 오류가 발생했습니다. "}
        />
      }
      <PopupWarn
        closeHide
        visible={sessionErrorVisible}
        hiding={sessionErrorHide}
        confirm={onSessErrorClick}
        confirmText={"확인"}
        message={"세션 정보가 유효하지 않습니다. 로그인 화면으로 돌아갑니다."}
      />      
    </>
  );
};

// Root 컴포넌트 정의
export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <NavigationProvider>
            <SessionProvider>
              <div className={`app ${screenSizeClass}`}>
                <div id={'fw-app'}>
                  <App />
                </div>
              </div>
            </SessionProvider>
          </NavigationProvider>
        </ThemeProvider>
      </AuthProvider>
    </Router>
  );
}
