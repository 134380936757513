import { Summary } from "devextreme-react/cjs/data-grid";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DateCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";
import { GroupSumItem, TotalCntItem } from "../../../../script/components/data-grid/data-grid-body/grid-body-item";
import { fwUtil } from "../../../../script/util";

const equiGroupRender = (data) => {

    if (!data && (data && data.length < 1)) {
        return;
    }

    if (!data.summaryItems) {
        return;
    }
    const cntsColumn = data?.totalItem?.cells?.length;
    const kwonColumn = data?.summaryItems[0];

    if (!cntsColumn || !kwonColumn) {
        return;
    }

    return (
        <div className='fw-summary-group'>
            <div className='fw-group-title'>
                {data.displayValue}
            </div>
            <div className='fw-group-detail'>
                총 <b>{cntsColumn}</b> 건
            </div>
            <div className='fw-group-detail'>
                총 {kwonColumn.columnCaption} : <b>{fwUtil.conv.to.krLocales(kwonColumn.value)}</b> 건
            </div>
        </div>
    )
}

export function DashMainCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"설비별 고장 현황"}
            addClass={'main'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={["equi_numb", "main_seri"]}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호", groupIndex: 0, groupRender: equiGroupRender })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {CommCol({ dataField: "main_seri", caption: "점검순번" })}
                {DateCol({ dataField: "main_date", caption: "점검일자" })}
                {DeciCol({ dataField: "main_kwon", caption: "수리/부품비" })}
                {CommCol({ dataField: "clie_name", caption: "정비업체명" })}
                {CommCol({ dataField: "brok_desc", caption: "고장내역" })}
                {CommCol({ dataField: "main_desc", caption: "정비내역" })}
                <Summary>
                    {TotalCntItem({ dataField: "equi_numb", caption: "정비", suffix: "건" })}
                    {GroupSumItem({ dataField: "main_kwon", suffix: "원" })}
                </Summary>                
            </ScrollGrid>
        </DashCard>
    )
}