import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormNumb, FormDate, FormGrid, FormText, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    plan_numb: '',
    plan_date: fwUtil.date.getsText.date(),
    plan_qtys: 0,
    item_code: '',
    item_name: '',
    orde_numb: '',
    work_numb: '',
    work_date: fwUtil.date.getsText.pDate(-5),
    work_year: false,
    plan_memo: '',
}

const setPlan = set.mes.mfg.plan;

const dateList = ['계획일자', '작업지시예정일'];

export function PlanInfoRegiMainInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const ordeGridRef = useRef(null);
    const itemGridRef = useRef(null);
    const [ordeData, setClieData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const ordeKeyExpr = 'orde_numb';
    const itemKeyExpr = 'item_code';
    // 그리드 조회 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [dateGubu, setDateGubu] = useState("납기일자")
    const [clieName, setClieName] = useState("");
    const [ordeItem, setOrdeItem] = useState("");
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const ordeItemChange = useCallback((e) => { setOrdeItem(e) }, []);
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []);
    // 그리드 팝업
    const [ordePopupVisible, setOrdePopupVisible] = useState(false);
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const ordePopupHiding = useCallback(() => setOrdePopupVisible(false), []);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 팝업 조회
    const ordeHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, dateGubu: dateGubu, clieName: clieName, itemName: ordeItem };
        const res = await setPlan.orde(sendData);
        clieGridUpdate(res);
    }, [dateValue, duraYesn, dateGubu, clieName, ordeItem, clieGridUpdate]);

    const itemHandleSearch = useCallback(async () => {
        const sendData = { itemCode: itemCode, itemName: itemName };
        const res = await setPlan.item(sendData);
        itemGridUpdate(res);
    }, [itemCode, itemName, itemGridUpdate]);

    // 그리드 추가 툴바 아이템
    const ordeToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: ordeHandleSearch, items: dateList },
        { type: 'text', locate: 'before', caption: '고객명', value: clieName, valueChange: clieNameChange, search: ordeHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: ordeItem, valueChange: ordeItemChange, search: ordeHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: ordeHandleSearch },
    ], [dateGubu, dateGubuChange, clieName, clieNameChange, ordeItem, ordeItemChange, ordeHandleSearch]);

    const itemToolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: itemHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: itemHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: itemHandleSearch }
    ], [itemCode, itemName, itemCodeChange, itemNameChange, itemHandleSearch]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        dataUpdate(defaultData)
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true)
        const postData = {
            planNumb: data.plan_numb || defaultData.plan_numb,
            ordeNumb: data.orde_numb || defaultData.orde_numb,
            workNumb: data.work_numb || defaultData.work_numb,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            planDate: data.plan_date || defaultData.plan_date,
            planQtys: data.plan_qtys || defaultData.plan_qtys,
            workDate: data.work_date || defaultData.work_date,
            workYear: data.work_year || defaultData.work_year,
            planMemo: data.plan_memo || defaultData.plan_memo,
        };
        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const ordePopupOnShowing = useCallback(() => {
        ordeHandleSearch();
    }, [ordeHandleSearch]);

    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 팝업 더블 클릭
    const ordeOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, orde_numb: e.data.orde_numb, item_code: e.data.item_code, item_name: e.data.item_name, item_spec: e.data.item_desc, item_pric: e.data.orde_pric, ypla_qtys: e.data.orde_qtys, ypla_kwon: e.data.orde_kwon });
        ordePopupHiding();
    }, [data, dataUpdate, ordePopupHiding]);

    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, item_code: e.data.item_code, item_name: e.data.item_name, item_spec: e.data.item_desc, item_pric: e.data.item_pric });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    const ordeGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'수주'}
                role={'목록'}
                gridDataSource={ordeData}
                gridRef={ordeGridRef}
                mainKey={ordeKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : isMedium ? 860 : 1200}
                height={600}
                hideColumnChooser
                onRowDblClick={ordeOnRowDblClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                toolbar={{ default: true, items: ordeToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {NumbCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {CommCol({ dataField: "orde_unit", caption: "수주단위" })}
                {NumbCol({ dataField: "orde_pric", caption: "수주단가" })}
                {NumbCol({ dataField: "orde_kwon", caption: "수주금액" })}
                {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
            </ScrollGrid>
        )
    }, [dateValue, dateValueChange, duraYesn, duraYesnChange, ordeData, ordeGridRef, ordeKeyExpr, ordeOnRowDblClick, ordeToolbarItems, isXSmall, isSmall, isMedium]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, items: itemToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {CommCol({ dataField: "item_unit", caption: "제품단위" })}
                {CommCol({ dataField: "qtys_weig", caption: "제품중량" })}
                {CommCol({ dataField: "item_pric", caption: "제품단가" })}
                {CommCol({ dataField: "item_gubu", caption: "제품구분" })}
            </ScrollGrid>
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, itemToolbarItems, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'수주 선택'}
                visible={ordePopupVisible}
                shown={ordePopupOnShowing}
                hiding={ordePopupHiding}
            >
                {ordeGrid}
            </PopupGrid>
            <PopupGrid
                title={'제품 선택'}
                visible={itemPopupVisible}
                shown={itemPopupOnShowing}
                hiding={itemPopupHiding}
            >
                {itemGrid}
            </PopupGrid>
            <PopupForm
                title={'생산계획 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.plan_numb, onValueChange: updateField('plan_numb'), label: '생산계획번호', disabled: true })}
                    {FormGrid({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호', onClick: () => setOrdePopupVisible(true) })}
                    {FormGrid({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', onClick: () => setItemPopupVisible(true), required: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_name'), label: '제 품 명', disabled: true })}
                    {FormDate({ value: data.plan_date, onValueChange: updateField('plan_date'), label: '생산계획일', required: true })}
                    {FormNumb({ value: data.plan_qtys, onValueChange: updateField('plan_qtys'), label: '계획수량', required: true, length: [5, 0] })}
                    {FormDate({ value: data.work_date, onValueChange: updateField('work_date'), label: '생산예정일', required: true })}
                </GroupItem>
                {FormMemo({ value: data.ypla_memo, onValueChange: updateField('plan_memo'), label: '비고' })}
            </PopupForm>
        </>
    );
}