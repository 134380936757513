import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { GroupSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";
import { Summary } from "devextreme-react/cjs/data-grid";
import { fwUtil } from "../../../../script/util";

const itemGroupRender = (data) => {
    if (!data && (data && data.length < 1)) {
        return;
    }

    if (!data.summaryItems) {
        return;
    }
    const ordeColumn = data.summaryItems[0];
    const kwonColumn = data.summaryItems[1];

    if (!ordeColumn || !kwonColumn) {
        return;
    }

    return (
        <div className='fw-summary-group'>
            <div className='fw-group-title'>
                {data.displayValue}
            </div>
            <div className='fw-group-detail'>
                총 {ordeColumn.columnCaption} : <b>{ordeColumn.value}</b> 
            </div>
            <div className='fw-group-detail'>
                총 {kwonColumn.columnCaption} : <b>{fwUtil.conv.to.krLocales(kwonColumn.value)}</b> 원
            </div>
        </div>
    )
}

export function DashItemGridCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"제품별 현황"}
            addClass={'item'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={["item_code", "clie_code"]}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "item_name", caption: "제품명", groupIndex: 0, groupRender: itemGroupRender })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DeciCol({ dataField: "orde_qtys", caption: "수량" })}
                {DeciCol({ dataField: "orde_kwon", caption: "금액", suffix: "원" })}
                <Summary>
                    {GroupSumItem({ dataField: "orde_qtys", suffix: "개" })}
                    {GroupSumItem({ dataField: "orde_kwon", suffix: "원" })}
                </Summary>
            </ScrollGrid>
        </DashCard>
    )
}