import { HomeCardItem } from "./item/Home-card";
import { CommChart } from "../../../script/components/chart/charts";
import { CommAxis, SpinSeri } from "../../../script/components/chart/charts-item";

export function HomeSalePursChar(props) {
  const { title, data } = props;

  const tooltipTamplate = (arg) => {
    return {
      text: `${arg.seriesName}: ${arg.valueText}`,
    };
  };

  return (
    <HomeCardItem title={title} classNames={'size-large'}>
      <CommChart
        dataSource={data}
        legend={{ vertical: "top", horizontal: "center" }}
        tooltip={{ location: "edge", template: tooltipTamplate }}
      >
        {CommAxis({ name: "kwon", caption: "금액(원)", position: "left" })}
        {SpinSeri({ valueField: "prof_kwon", caption: "차익", color: "#3CB371", args: "dash_date", axis: 'kwon' })}
        {SpinSeri({ valueField: "ppro_kwon", caption: "매입", color: "#1E90FF", args: "dash_date", axis: 'kwon' })}
        {SpinSeri({ valueField: "sale_kwon", caption: "매출", color: "#FF6347", args: "dash_date", axis: 'kwon' })}
      </CommChart>
    </HomeCardItem>
  );
}
