// MatlCatdStat - 재질 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { MatlCatdStatInsert } from './CatdStat-insert';
import { MatlCatdStatUpdate } from './CatdStat-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function MatlCatdStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/cated/search`;  // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/cated/target`;  // 타겟 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/cated/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/cated/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/cated/delete`;  // 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'cate_code';
    const deleKey = (data) => {
        return { catdCode: data }
    };          
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [cateName, setCateName] = useState("");  
    const cateNameChange = useCallback((e) => { setCateName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { cateName: cateName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [cateName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '재질명', value: cateName, valueChange: cateNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [cateName, cateNameChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "cate_code", caption: "재질코드", width: 100 })}
                {CommCol({ dataField: "cate_name", caption: "재질명" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용유무" })}
                {DateCol({ dataField: "regi_date", caption: "등록일자" })}
            </PagingGrid>
            <MatlCatdStatInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <MatlCatdStatUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 재질을 삭제하시겠습니까?'}
            />             
        </div>
    );
};