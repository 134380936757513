// OrdeInfoHist - 수주 변경내역
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Column } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function OrdeInfoHist(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/history/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single'
    const mainKey = 'hist_numb';   
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [clieName, setClieName] = useState("");
    const [citeName, setCiteName] = useState("");         
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const citeNameChange = useCallback((e) => { setCiteName(e) }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName, citeName: citeName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, clieName, citeName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '고객제품명', value: citeName, valueChange: citeNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [clieName, clieNameChange, citeName, citeNameChange, handleSearch]);
    
    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(gridRef, key, isSelected);
    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                mainKey={mainKey}
                gridSize={gridSize}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_code", caption: "고객품목번호" })}
                {CommCol({ dataField: "cite_name", caption: "고객품명" })}
                {CommCol({ dataField: "cite_spec", caption: "고객규격" })}
                {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                <Column caption='납기일자' cssClass='before-after-col'>
                    {DateCol({ dataField: "deli_adat", caption: "변경 전", before: true })}
                    {DateCol({ dataField: "deli_bdat", caption: "변경 후", after: true })}
                </Column>
                <Column caption='수주수량' cssClass='before-after-col'>
                    {DeciCol({ dataField: "orde_aqty", caption: "변경 전", before: true })}                    
                    {DeciCol({ dataField: "orde_bqty", caption: "변경 후", after: true })}                    
                </Column>        
                {CommCol({ dataField: "orde_unit", caption: "단위" })}
                {DeciCol({ dataField: "orde_pric", caption: "수주단가" })}
                {DeciCol({ dataField: "orde_kwon", caption: "수주금액" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "pays_cond", caption: "결재조건" })}
                {CommCol({ dataField: "orde_memo", caption: "비고" })}        
            </PagingGrid> 
        </div>
    );
};