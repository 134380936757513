import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DtmeCol, NumbCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashGridCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"전체 현황"}
            addClass={'item'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={"sens_keys"}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호", groupIndex: 0, })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
                {DtmeCol({ dataField: "sens_date", caption: "일자" })}
                {NumbCol({ dataField: "sens_valu", caption: "전력량" })}
            </ScrollGrid>
        </DashCard>
    )
}