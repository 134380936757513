import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getAserClieList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/biz/aser/register/clie/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getAserClieList.error === ', error);
        return [];
    }
};

const getAserItemList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/biz/aser/register/item/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getAserItemList.error === ', error);
        return [];
    }
};

const getCompPartList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/biz/ascomp/register/part/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getCompPartList.error === ', error);
        return [];
    }
};

export const biz = {
    aser: {
        info: {
            clie: getAserClieList,
            item: getAserItemList
        },
        comp: {
            part: getCompPartList,
        },
    },
}