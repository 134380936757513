import React, { useState, useCallback, useMemo, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { AddingGrid, ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { Button } from 'devextreme-react';
import { CommCol, DeciCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { set } from '../../../../../api/set';

const defaultData = {
    mana_numb: '',
    comp_seri: '',
    clie_code: '',
    clie_name: '',
    item_code: '',
    item_name: '',
    rece_date: fwUtil.date.getsText.date(),
    insp_date: fwUtil.date.getsText.date(),
    insp_name: '',
    insp_kwon: 0.00,
    comp_memo: '',
}

const setComp = set.mes.biz.aser.comp;

const partDataSource = fwUtil.grid.get.dataSource();
const partKeyExpr = ['item_code', 'matl_code']; 

export function AserCompUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 구성품목 그리드
    const partRef = useRef(null);
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);  
    // 팝업 그리드
    const partGridRef = useRef(null);
    const [partData, setPartData] = useState([]);
    const partGridUpdate = useCallback((e) => setPartData(e), []);
    // 그리드 팝업
    const [partPopupVisible, setPartPopupVisible] = useState(false);
    const partPopupHiding = useCallback(() => setPartPopupVisible(false), []);   

    // 팝업 조회
    const partHandleSearch = useCallback(async() => {
        const sendData = { itemCode: data?.item_code, matlCode: matlCode, matlName: matlName };
        const res = await setComp.part(sendData);;
        partGridUpdate(res);
    }, [data, matlCode, matlName, partGridUpdate]);

    // 그리드 추가 툴바 아이템
    const partToolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '부품번호', value: matlCode, valueChange: matlCodeChange, search: partHandleSearch },
        { type: 'text', locate: 'before', caption: '부품명', value: matlName, valueChange: matlNameChange, search: partHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: partHandleSearch }
    ], [matlCode, matlName, matlCodeChange, matlNameChange, partHandleSearch]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            partDataSource.store().insert({ init: true }).then(() => partDataSource.reload());
            const sendData = { manaNumb: seleData.mana_numb, compSeri: seleData.comp_seri };
            const partRes = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                mana_numb: seleData.mana_numb || '',
                comp_seri: seleData.comp_seri || '',
                clie_code: seleData.clie_code || '',
                clie_name: seleData.clie_name || '',
                item_code: seleData.item_code || '',
                item_name: seleData.item_name || '',
                rece_date: seleData.rece_date || fwUtil.date.getsText.date(),
                insp_date: seleData.insp_date || fwUtil.date.getsText.date(),
                insp_name: seleData.insp_name || '',
                insp_kwon: seleData.insp_kwon || 0,
                comp_memo: seleData.comp_memo || '',                
            };

            for (const part of partRes) {
                const newData = {
                    item_code: part.item_code || '',
                    matl_code: part.matl_code || '',
                    matl_name: part.matl_name || '',
                    matl_upri: part.matl_upri || 0,
                    matl_qtys: part.matl_qtys || 0,
                    matl_kwon: part.matl_kwon || 0, 
                    aspa_memo: part.aspa_memo || 0, 
                };
                partDataSource.store().insert(newData);
            }
            
            partDataSource.reload();
            dataUpdate(resData);
        }
    }, [hiding, dataUpdate, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const partData = partRef?.current?.instance?.getDataSource()?.items();
        const postPartData = partData.map((item) => ({
            manaNumb: data.mana_numb || "",
            compSeri: data.comp_seri || "",
            matlCode: item.matl_code || "",
            matlName: item.matl_name || "",
            matlUpri: item.matl_upri || 0,
            matlQtys: item.matl_qtys || 0,
            aspaMemo: item.aspa_memo || "",           
        }));                
        const postData = {
            manaNumb: data.mana_numb || '',
            compSeri: data.comp_seri || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            receDate: data.rece_date ? fwUtil.date.getsText.date(data.rece_date) : fwUtil.date.getsText.date(),
            inspDate: data.insp_date ? fwUtil.date.getsText.date(data.insp_date) : fwUtil.date.getsText.date(),
            inspName: data.insp_name || '',
            inspKwon: data.insp_kwon || 0.00,
            compMemo: data.comp_memo || '',
            setaMaps: postPartData || [],
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    // 팝업 열릴 때
    const partPopupOnShowing = useCallback(() => {
        partHandleSearch();
    }, [partHandleSearch]);

    // 팝업 더블 클릭
    const partOnRowDblClick = useCallback((e) => {
        const partData = partRef?.current?.instance?.getDataSource()?.items();
        if (!fwUtil.conv.check.nua(partData) && partData.some(part => part.matl_code === e.data.matl_code)) {
            fwUtil.aler.toast.warn('이미 등록된 자재입니다!');
            return;
        }
        const newPartData = {
            item_code: e.data.item_code,
            matl_code: e.data.matl_code,
            matl_name: e.data.matl_name,
            matl_upri: e.data.matl_upri,
            matl_qtys: 1,
            matl_kwon: e.data.matl_upri,
        }
        partDataSource.store().insert(newPartData);

        partDataSource.reload();
        partPopupHiding();
    }, [partPopupHiding]);

    const partGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'교체부품'}
                role={'목록'}
                gridDataSource={partData}
                gridRef={partGridRef}
                mainKey={partKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={partOnRowDblClick}
                toolbar={{ default: false, items: partToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "부품번호" })}
                {CommCol({ dataField: "matl_name", caption: "부품명" })}
                {DeciCol({ dataField: "matl_upri", caption: "부품단가" })}
            </ScrollGrid>            
        )
    }, [partData, partGridRef, partOnRowDblClick, partToolbarItems, isXSmall, isSmall]);

    const calcKwon = (e) => {
        const matlUpri = parseFloat(e.matl_upri) || 0;
        const matlQtys = parseFloat(e.matl_qtys) || 0;
        return matlUpri * matlQtys;
    };

    return (
        <> 
        <PopupGrid
            title={'교체품목 등록'}   
            visible={partPopupVisible}  
            shown={partPopupOnShowing}  
            hiding={partPopupHiding}
        >   
            {partGrid}
        </PopupGrid>         
        <PopupForm
            title={'운영/점검 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : isLarge ? 1200 : 600 }
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'교체부품'}
                        gridRef={partRef} 
                        gridDataSource={partDataSource}
                        allowEdit
                        allowUpdate
                        allowDelete
                        additionTool={
                            <Button
                                icon={'search'}
                                onClick={() => setPartPopupVisible(true)}
                            />
                        }
                    >
                        {CommCol({ dataField: "matl_code", caption: "부품코드", allowFiltering: false, allowSorting: false, allowEditing: false, width: 200 })}
                        {CommCol({ dataField: "matl_name", caption: "부품명", allowFiltering: false, allowSorting: false, allowEditing: false, width: 200 })}
                        {NumbCol({ dataField: "matl_upri", caption: "부품단가", allowFiltering: false, allowSorting: false, check: true, width: 150 })}
                        {NumbCol({ dataField: "matl_qtys", caption: "부품수량", allowFiltering: false, allowSorting: false, check: true, width: 100 })}
                        {NumbCol({ dataField: "matl_kwon", caption: "부품금액", allowFiltering: false, allowSorting: false, allowEditing: false, width: 150, calcValue: (e) => calcKwon(e) })}
                        {CommCol({ dataField: "aspa_memo", caption: "비고", allowFiltering: false, allowSorting: false, check: true })}
                    </AddingGrid>
                </div>
            }
        >
            <GroupItem colCount={isXSmall ? 1 : isLarge ? 4 : 2 }>
                {FormText({ value: data.mana_numb, onValueChange: updateField('mana_numb'), label: '관리번호', required: true, disabled: true })}
                {FormText({ value: data.comp_seri, onValueChange: updateField('comp_seri'), label: '점검순번[자동입력]', disabled: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', required: true, disabled: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', required: true, disabled: true })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '점검일자', required: true })}
                {FormDate({ value: data.rece_date, onValueChange: updateField('rece_date'), label: '접수일자' })}
                {FormText({ value: data.insp_name, onValueChange: updateField('insp_name'), label: '점검자', required: true })}
                {FormNumb({ value: data.insp_kwon, onValueChange: updateField('insp_kwon'), label: '출장비', length: [15, 2] })}
            </GroupItem>
            {FormMemo({ value: data.comp_memo, onValueChange: updateField('comp_memo'), label: '비고' })}
        </PopupForm>
        </>
    );
}