import { Chart, PieChart } from "devextreme-react";
import { CommonAxisSettings as CommCommAxisSets, Legend as CommLegend, Tooltip as CommTooltip } from "devextreme-react/cjs/chart";
import { Legend as PiesLegend, Tooltip as PiesTooltip } from "devextreme-react/cjs/pie-chart";
import { fwUtil } from "../../util";
import './charts.scss';

const CommChart = (props) => {
    const {
        title, role, additonTitle,
        width, height, rotate,
        charRef, dataSource,
        palette, argsMargin,
        legend, tooltip,
        customizePoint,
        children,
    } = props;

    const mainTitle = `${title}${role ? " " + role : ""}${additonTitle ? " " + additonTitle : ""}`;
    const chartHeight = title ? height - 32 : height;
    const axisMarginAble = fwUtil.conv.tern(true, argsMargin);

    return (
        <div className="fw-chart-content comm">
            {title &&
                <div className="fw-chart-header">
                    <span>{mainTitle}</span>
                </div>
            }
            <div className="fw-chart-body">
                <Chart
                    width={width}
                    height={chartHeight}
                    ref={charRef}
                    dataSource={dataSource}
                    palette={palette}
                    rotated={rotate}
                    customizePoint={customizePoint}
                >
                    {children}
                    <CommCommAxisSets
                        valueMarginsEnabled={axisMarginAble}
                    />
                    {legend ?
                        (
                            <CommLegend
                                verticalAlignment={legend.vertical}
                                horizontalAlignment={legend.horizontal}
                            />
                        ) :
                        (
                            <CommLegend
                                visible={false}
                            />
                        )
                    }
                    {tooltip &&
                        <CommTooltip
                            enabled
                            location={tooltip.location}
                            customizeTooltip={tooltip.template}
                        />
                    }
                </Chart>
            </div>
        </div>
    );
}

const PiesChart = (props) => {
    const { type, dataSource, children, legend, tooltip, palette } = props;

    return (
        <div className="fw-chart-content pies">
            <PieChart
                type={type || "pie"}
                dataSource={dataSource}
                palette={palette}
                innerRadius={0.3}
            >
                {children}
                {legend ?
                    (
                        <PiesLegend
                            verticalAlignment={legend.vertical}
                            horizontalAlignment={legend.horizontal}
                        />
                    ) :
                    (
                        <PiesLegend
                            visible={false}
                        />
                    )
                }
                {tooltip &&
                    <PiesTooltip
                        enabled
                        location={tooltip.location}
                        customizeTooltip={tooltip.template}
                    />
                }
            </PieChart>
        </div>
    )
}

export {
    CommChart,
    PiesChart
}