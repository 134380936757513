import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    insp_date: fwUtil.date.getsText.date(),
    insp_damd: '',
    insp_numb: '',
    item_code: '',
    item_name: '',
    insp_qtys: 0,
    judg_qtys: 0,
    insp_memo: '',
    pros_numb: '',
    stoc_loca: '',
    clie_code: '',
    clie_name: '',
    judg_yesn: false,    
};

export function ProsQcisChanUpdate(props) {
    const {
        mainGrid,
        refresh,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {            
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const newData = {
                insp_gubu: '입고',
                insp_date: seleData.insp_date || defaultData.insp_date,
                insp_damd: seleData.insp_damd || defaultData.insp_damd,
                insp_numb: seleData.insp_numb || defaultData.insp_numb,
                item_code: seleData.item_code || defaultData.item_code,
                item_name: seleData.item_name || defaultData.item_name,
                insp_qtys: seleData.insp_qtys || defaultData.insp_qtys,
                judg_qtys: seleData.judg_qtys || defaultData.judg_qtys,
                insp_memo: seleData.insp_memo || defaultData.insp_memo,
                pros_numb: seleData.pros_numb || defaultData.pros_numb,
                stoc_loca: seleData.stoc_loca || defaultData.stoc_loca,
                clie_code: seleData.clie_code || defaultData.clie_code,
                clie_name: seleData.clie_name || defaultData.clie_name,                
            }
            
            dataUpdate(newData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            inspDate: data.insp_date || defaultData.insp_date,
            inspDamd: data.insp_damd || defaultData.insp_damd,
            inspNumb: data.insp_numb || defaultData.insp_numb,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            inspQtys: data.insp_qtys || defaultData.insp_qtys,
            judgQtys: data.judg_qtys || defaultData.judg_qtys,
            inspMemo: data.insp_memo || defaultData.insp_memo,
            prosNumb: data.pros_numb || defaultData.pros_numb,
            stocLoca: data.stoc_loca || defaultData.stoc_loca,
            clieCode: data.clie_code || defaultData.clie_code,
            clieName: data.clie_name || defaultData.clie_name,
        }

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);    

    return (
        <>           
        <PopupForm
            title={'수입검사 변경'}       
            type={'변경'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormText({ value: data.insp_numb, onValueChange: updateField('insp_numb'), label: "검사번호", disabled: true, required: true })}
                {FormText({ value: data.insp_gubu, onValueChange: updateField('insp_gubu'), label: "검사구분", disabled: true })}
                {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: "품번", disabled: true })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: "품명", disabled: true })}
                {FormText({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: '검사자', required: true })}
                {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자' })}                
                {FormNumb({ value: data.insp_qtys, onValueChange: updateField('insp_qtys'), label: "검사수량", length: [15, 0] })}
                {FormNumb({ value: data.judg_qtys, onValueChange: updateField('judg_qtys'), label: "불량수량", length: [15, 0] })}
            </GroupItem>
            {FormMemo({ value: data.insp_memo, onValueChange: updateField('insp_memo'), label: "비고" })}
        </PopupForm>   
        </>
    );
}