// StocItemStat - 제품재고 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Summary } from 'devextreme-react/data-grid';
import { TotalCntItem, TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const seleItems = ["재고량 > 0", "재고량 = 0", "전체"];

export default function StocItemStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/item/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['item_code', 'stoc_loca'];    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [seleGubu, setSeleGubu] = useState("재고량 > 0");      
    const [itemCode, setItemCode] = useState("");    
    const [itemName, setItemName] = useState("");    
    const seleGubuChange = useCallback((e) => { setSeleGubu(e) }, []);
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { seleGubu: seleGubu, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
    }, [seleGubu, itemCode, itemName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '재고량', value: seleGubu, valueChange: seleGubuChange, search: handleSearch, items: seleItems },
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [seleGubu, seleGubuChange, itemCode, itemCodeChange, itemName, itemNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}   
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품코드" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {CommCol({ dataField: "item_quli", caption: "재질" })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량" })}
                {CommCol({ dataField: "stoc_unit", caption: "재고단위" })}
                {DeciCol({ dataField: "stoc_qpri", caption: "재고단가" })}
                {DeciCol({ dataField: "stoc_kwon", caption: "재고금액" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {CommCol({ dataField: "stoc_memo", caption: "비고" })}
                <Summary >
                    {TotalCntItem({ dataField: 'item_code', caption: "재고 품목수" })}
                    {TotalSumItem({ dataField: 'stoc_kwon', caption: "재고 총금액" })}
                </Summary>                
            </PagingGrid> 
        </div>
    );
};