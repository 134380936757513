// UserPwrdRese - 사용자 암호 초기화
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as ResetAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function UserPwrdRese(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    const resetsApi = `${acce}/${gubu}/${cata}/${catb}/reset`;   // 초기화 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'user_idno';    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [userName, setUserName] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    // 암호 초기화 팝업
    const [resetAlertVisible, setResetAlertVisible] = useState(false);
    const resetAlertHide = useCallback(() => { setResetAlertVisible(false); }, [setResetAlertVisible]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, userName: userName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, userName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 암호 초기화
    const handleResetBtnsClick = useCallback(() => {
        if ((fwUtil.grid.get.sKey(gridRef))) {
            setResetAlertVisible(true);
        } else {
            fwUtil.aler.toast.nsWarn();
        }
    }, []);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: " 사용자명", value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'refresh', text: '초기화', hidden: false, disabled: authList.auth_upda === 0, onClick: handleResetBtnsClick }
    ], [userName, userNameChange, handleSearch, authList.auth_upda, handleResetBtnsClick]);

    const resetPwrd = useCallback(async (keys) => {
        if (keys) {
            try {
                console.log('reset start');
                const keyData = { userIdno: keys };
                const axiosInstance = fwUtil.axiosInstanceCreate();
                const response = await axiosInstance.post(resetsApi, keyData);
                fwUtil.aler.toast.res(response.data);
                console.log('reset response === ', response);
            } catch (error) {
                fwUtil.aler.toast.erro('암호 초기화 중 오류가 발생했습니다');
                console.log('error === ', error);
            } finally {
                handleSearch();
                resetAlertHide();
            }
        }
    }, [resetAlertHide, handleSearch, resetsApi]);    

    const resetPwrdClick = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (Array.isArray(selectedRowKey)) {
            selectedRowKey.forEach(key => resetPwrd(key));
        } else if (selectedRowKey) {
            resetPwrd(selectedRowKey);
        } else {
            fwUtil.aler.toast.erro('선택된 값이 없습니다');
            resetAlertHide();
        }

    }, [gridRef, resetAlertHide, resetPwrd]);    

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "user_idno", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "성명[한글]" })}
                {CommCol({ dataField: "user_enam", caption: "성명[영문]" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {DateCol({ dataField: "regi_date", caption: "입사일" })}
                {DateCol({ dataField: "resi_date", caption: "퇴사일" })}
                {CommCol({ dataField: "tele_numb", caption: "전 화" })}
                {CommCol({ dataField: "cell_numb", caption: "휴대폰" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩 스" })}
                {CommCol({ dataField: "mail_idno", caption: "E-Mail" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_name", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}
            </PagingGrid>
            <ResetAlert
                grid={gridRef}
                visible={resetAlertVisible}
                hiding={resetAlertHide}
                confirm={resetPwrdClick}
                message={'해당 사용자의 비밀번호를 초기화 하시겠습니까?'}
            />                
        </div>
    );
};