import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormNumb, FormText, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    work_numb: '',
    item_code: '',
    item_name: '',
    item_quli: '',
    plan_numb: '',
    orde_numb: '',
    item_memo: '',
    work_qtys: 0,
}

export function WorkOrdeRegiSubsUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                work_numb: seleData.work_numb || defaultData.work_numb,
                item_code: seleData.item_code || defaultData.item_code,
                item_name: seleData.item_name || defaultData.item_name,
                item_quli: seleData.item_quli || defaultData.item_quli,
                plan_numb: seleData.plan_numb || defaultData.plan_numb,
                orde_numb: seleData.orde_numb || defaultData.orde_numb,
                item_memo: seleData.item_memo || defaultData.item_memo,   
                work_qtys: seleData.work_qtys || defaultData.work_qtys,             
            };
            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || defaultData.work_numb,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            itemQuli: data.item_quli || defaultData.item_quli,
            planNumb: data.plan_numb || defaultData.plan_numb,
            ordeNumb: data.orde_numb || defaultData.orde_numb,
            itemMemo: data.item_memo || defaultData.item_memo,
            workQtys: data.work_qtys || defaultData.work_qtys,
        };
        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'작업지시 품목 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.plan_numb, onValueChange: updateField('plan_numb'), label: '계획번호', disabled: true })}
                    {FormText({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호', disabled: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', required: true, disabled: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', disabled: true })}
                    {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true })}
                    {FormNumb({ value: data.work_qtys, onValueChange: updateField('work_qtys'), label: '지시수량(공정일괄수정)', length: [12, 2] })}
                </GroupItem>
                {FormMemo({ value: data.item_memo, onValueChange: updateField('item_memo'), label: '비고' })} 
            </PopupForm>
        </>
    );
}