import React, { useMemo, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../contexts/auth';
import { Popup } from 'devextreme-react';
import { PopupAler, PopupLice } from '../popup/popup';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import ColorChanger from '../change-theme/ColorChanger';
import ModeChanger from '../change-theme/ModeChanger';
import { useScreenSize } from '../../../../utils/media-query';
import './UserPanel.scss';

export default function UserPanel({ menuMode }) {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const { isXSmall } = useScreenSize();
  const [themePopupVisible, setThemePopupVisible] = useState(false);
  const [signoutPopupVisible, setSignoutPopupVisible] = useState(false);
  const [licensePopupVisible, setLicensePopupVisible] = useState(false);
  const themeHide = useCallback(() => { setThemePopupVisible(false); }, []);
  const signoutHide = useCallback(() => { setSignoutPopupVisible(false); }, []);
  const licenseHide = useCallback(() => { setLicensePopupVisible(false); }, []);

  const menuItems = useMemo(() => ([
    {
      text: '내정보',
      icon: 'user',
      onClick: () => navigate('/profile')
    },
    {
      text: '로그아웃',
      icon: 'runner',
      onClick: () => setSignoutPopupVisible(true)
    },
    {
      text: '설정',
      icon: 'preferences',
      onClick: () => setThemePopupVisible(true)
    },
    {
      text: '앱 정보',
      icon: 'errorcircle',
      onClick: () => setLicensePopupVisible(true)
    }    
  ]), [navigate, setSignoutPopupVisible, setThemePopupVisible, setLicensePopupVisible]);

  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <img className={'user-image'} src={user.avatarUrl} alt='사용자 이미지' />
        </div>
        <div className={'user-name'}>{user.userName} {user.posiName} {user.deptName ? `(${user.deptName})` : ""}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          cssClass={'user-menu'}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
      <Popup
        visible={themePopupVisible}
        onHiding={themeHide}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title="설정"
        width={'auto'}
        height={'auto'}
      >
        <div className={'popup-content fw-flex-box col'}>
          <div className={'preference-row fw-flex-box row'}>
            <div className={'row-title'}>테마 색상 팔레트</div>
            <div className={'row-content fw-flex-box row'}>
              <ColorChanger />
            </div>
          </div>
          <div className={'preference-row fw-flex-box row'}>
            <div className={'row-title'}>모드 설정</div>
            <div className={'row-content switch-content fw-flex-box row'}>
              <ModeChanger />
            </div>
          </div>
        </div>
      </Popup>
      <PopupAler
        visible={signoutPopupVisible}
        hiding={signoutHide}
        confirm={signOut}
        message={'로그아웃 하시겠습니까?'}
      />
      <PopupLice
        fullSize={isXSmall}
        visible={licensePopupVisible}
        hiding={licenseHide}
      />      
    </div>
  );
}
