import { fwUtil } from "../../../script/util";

const defaultSalePursData = [
    { dash_date: fwUtil.date.getsText.pDate(6), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.pDate(5), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.pDate(4), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.pDate(3), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.pDate(2), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.pDate(1), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
    { dash_date: fwUtil.date.getsText.date(), sale_kwon: 0, ppro_kwon: 0, prof_kwon: 0 },
]

export function convertSalePursData(receivedData) {
    if (!receivedData) {
        return;
    }
    const updatedData = [...defaultSalePursData];
    for (let i = 0; i < receivedData.length; i++) {
        const item = receivedData[i];
        
        for (let j = 0; j < updatedData.length; j++) {
            if (updatedData[j].dash_date === item.dash_date) {
                updatedData[j] = {
                    dash_date: item.dash_date,
                    sale_kwon: parseFloat(item.sale_kwon),
                    ppro_kwon: parseFloat(item.ppro_kwon),
                    prof_kwon: parseFloat(item.prof_kwon),
                };
                break;
            }
        }
    }

    return updatedData;
}