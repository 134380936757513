import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormImag, FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    user_idno: '',
    user_name: '',
    user_enam: '',
    tele_numb: '',
    cell_numb: '',
    faxs_numb: '',
    posi_code: '',
    posi_name: '',
    dept_code: '',
    dept_name: '',
    mail_idno: '',
    regi_date: fwUtil.date.getsText.date(),
    resi_date: null,
    pict_imag: null,
    sign_imag: null,
};

const setUser = set.mes.sys;

export function UserInfoRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 부서, 직위 리스트
    const [deptList, setDeptList] = useState([]);
    const [posiList, setPosiList] = useState([]);
    const deptChange = useCallback((e) => setDeptList(e), []);
    const posiChange = useCallback((e) => setPosiList(e), []);

    const fetchData = useCallback(async () => {
        try {
            const [deptResult, posiResult] = await Promise.all([setUser.dept(), setUser.posi()]);
            deptChange(deptResult);
            posiChange(posiResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [deptChange, posiChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { userIdno: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                user_idno: res.user_idno || '',
                user_name: res.user_name || '',
                user_enam: res.user_enam || '',
                tele_numb: res.tele_numb || '',
                cell_numb: res.cell_numb || '',
                faxs_numb: res.faxs_numb || '',
                posi_code: res.posi_code || '',
                posi_name: res.posi_name || '',
                dept_code: res.dept_code || '',
                dept_name: res.dept_name || '',
                mail_idno: res.mail_idno || '',
                regi_date: res.regi_date || fwUtil.date.getsText.date(),
                resi_date: res.resi_date || null,
                pict_imag: res.pict_imag || null,
                sign_imag: res.sign_imag || null,
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate, fetchData]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        if (field === 'dept_name') {
            const selectedDept = deptList.find(list => list.dept_name === value);
            if (selectedDept) {
                dataUpdate({ ...data, [field]: value, dept_code: selectedDept.dept_code });
            };
            return;
        };

        if (field === 'posi_name') {
            const selectedPosi = posiList.find(list => list.posi_name === value);
            if (selectedPosi) {
                dataUpdate({ ...data, [field]: value, posi_code: selectedPosi.posi_code });
            };
            return;
        };        

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = new FormData();

        postData.append('userIdno', data.user_idno || '');
        postData.append('userName', data.user_name || '');
        postData.append('userEnam', data.user_enam || '');
        postData.append('teleNumb', data.tele_numb || '');
        postData.append('cellNumb', data.cell_numb || '');
        postData.append('faxsNumb', data.faxs_numb || '');
        postData.append('posiCode', data.posi_code || '');
        postData.append('posiName', data.posi_name || '');
        postData.append('deptCode', data.dept_code || '');
        postData.append('deptName', data.dept_name || '');
        postData.append('mailIdno', data.mail_idno || '');
        postData.append('regiDate', data.regi_date || null);
        postData.append('resiDate', data.resi_date || null);
        postData.append('pictImag', data.pict_imag || null);
        postData.append('signImag', data.sign_imag || null);

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'사용자 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? '100%' : isMedium ? 700 : 1050}  
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 3} cssClass='fw-form-image-row head center'>
                {FormImag({ value: fwUtil.conv.to.buffToBlob(data.pict_imag), onValueChange: updateField('pict_imag'), label: '사용자 이미지', labelHide: true, size: 180, circle: true })}
                <GroupItem colSpan={isLarge ? 2 : 1} colCount={isLarge ? 2 : 1}>
                    <GroupItem >
                        {FormText({ value: data.user_idno, onValueChange: updateField('user_idno'), label: '아이디', required: true, disabled: true })}
                        {FormText({ value: data.user_name, onValueChange: updateField('user_name'), label: '성명[한글]', required: true })}
                        {FormText({ value: data.user_enam, onValueChange: updateField('user_enam'), label: '성명[영문]', required: true })}
                    </GroupItem>
                    <GroupItem>
                        {FormText({ value: data.cell_numb, onValueChange: updateField('cell_numb'), label: '휴대폰', mask: '000-0000-0000' })}
                        {FormText({ value: data.tele_numb, onValueChange: updateField('tele_numb'), label: '전화', placeholder: "-포함 입력" })}
                        {FormText({ value: data.faxs_numb, onValueChange: updateField('faxs_numb'), label: '팩스', placeholder: "-포함 입력" })}
                    </GroupItem>                    
                </GroupItem>
            </GroupItem>
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 3} cssClass='fw-form-image-row body'>
                {FormImag({ value: fwUtil.conv.to.buffToBlob(data.sign_imag), onValueChange: updateField('sign_imag'), label: '서명이미지', size: 180, allowDrawing: true })}
                <GroupItem colSpan={isLarge ? 2 : 1} colCount={isLarge ? 2 : 1}>
                    <GroupItem>
                        {FormSelc({ value: data.dept_name, onValueChange: updateField('dept_name'), label: '부서', items: deptList, displayExpr: 'dept_name', valueExpr: 'dept_name' })}
                        {FormSelc({ value: data.posi_name, onValueChange: updateField('posi_name'), label: '직급', items: posiList, displayExpr: 'posi_name', valueExpr: 'posi_name' })}
                        {FormRegx({ value: data.mail_idno, onValueChange: updateField('mail_idno'), label: '이메일', rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}   
                    </GroupItem>
                    <GroupItem>
                        {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '입사일', required: true })}
                        {FormDate({ value: data.resi_date, onValueChange: updateField('resi_date'), label: '퇴사일' })}
                    </GroupItem>                
                </GroupItem>
            </GroupItem>
        </PopupForm>
    );
}