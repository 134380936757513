import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from '../../../../../contexts/auth';
import { fwUtil } from "../../../../script/util";
import { PageTabPanel } from "../../../../script/components/tab-panel/tab-panel-body/panel-body";
import { CommTabs } from "../../../../script/components/tab-panel/tab-panel-item/panel-item";
import { menuFunc } from "./infoUtil";
import MenuInfoRegiMES from "./InfoRegi-mes";
import MenuInfoRegiAPS from "./InfoRegi-aps";
import './infoRegi.scss';
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";

export default function MenuInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole,
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/update`;  // 수정 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 탭
    const tabRef = useRef(null);
    const [tabIndex, setTabIndex] = useState(0);
    // 트리
    const mainKey = 'menu_code';
    const highKey = 'menu_high';
    const mesRef = useRef(null);
    const apsRef = useRef(null);
    const [mesData, setMesData] = useState([]);
    const mesDataUpdate = useCallback((e) => setMesData(e), []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const menuData = await fwUtil.apis.search(searchApi);
        if (!menuData || !menuData.mes) {
            fwUtil.aler.toast.s1Erro();
            return;
        } else {
            mesDataUpdate(menuData.mes);
        }
        setPageLoading(false);
    }, [searchApi, mesDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const mesReorder = useCallback(async (e, act) => {
        let changeResult = null;
        if (act) {
            changeResult = menuFunc.btnsClic(e, act);
        } else {
            changeResult = menuFunc.ordeChan(e);
        }
        if (!changeResult) return;

        const { from, to } = changeResult;
        let updatedMenu = [];

        if (from.menu_part === to.menu_part) {
            updatedMenu = menuFunc.menuChan(mesData, from, to, 'menu_sort');
        } else {
            updatedMenu = menuFunc.menuChan(mesData, from, to, 'menu_part');
        }

        mesDataUpdate(updatedMenu);
    }, [mesData, mesDataUpdate]);

    const updateMenuList = useCallback(async () => {
        let sendData = null;
        if (tabIndex === 0) {
            sendData = { menuGubu: "MES", setaMaps: mesData };
        } 
        if (sendData) {
            await fwUtil.apis.submit(updateApi, sendData);
            handleSearch();
        } else {
            fwUtil.aler.toast.a1Erro();
        }
    }, [updateApi, mesData, tabIndex, handleSearch]);

    return (
        <>
            <FwAppLoader loading={loading} />
            <PageTabPanel
                title={mainTitle}
                role={mainRole}
                loading={pageLoading}
                tabRef={tabRef}
                index={tabIndex}
                indexChange={setTabIndex}
                upd={{ disabled: authList.auth_upda === 0, onClick: updateMenuList }}
            >
                {CommTabs({ title: 'MES', icon: 'preferences', render: () => MenuInfoRegiMES(mesData, mesRef, mainKey, highKey, mesReorder) })}
            </PageTabPanel>
        </>
    )
};