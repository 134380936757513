// ShipReqeChan - 출하 지시 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ShipReqeChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props; 

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/change/search`;  // 조회 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/change/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/change/delete`;  // 삭제 api       
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['reqe_numb', 'reqe_seri'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    // 삭제 경고창
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 수정 경고창
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, mainGridDataUpdate);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate, searchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = mainSelectedData.map((item) => ({
            reqeNumb: item.reqe_numb,
            reqeSeri: item.reqe_seri,
            reqeQtys: item.reqe_qtys,
            reqeRemk: item.reqe_remk,            
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(updateApi, postData, mainHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsUpdateAlertHide();
    }, [mainGridRef, mainHandleSearch, subsUpdateAlertHide, updateApi]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        const mainSelectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = mainSelectedData.map((item) => ({
            reqeNumb: item.reqe_numb,
            reqeSeri: item.reqe_seri,
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(deleteApi, postData, mainHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        mainHandleSearch();
        subsDeleteAlertHide();
    }, [mainGridRef, mainHandleSearch, subsDeleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, itemName, clieNameChange, itemNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const subsSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        if (subsSelectedRowKey) {
            switch (act) {
                case'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        }

    }, []);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'multiple'}
                edit
            >
                {CommCol({ dataField: "reqe_numb", caption: "지시번호", allowEditing: false })}
                {CommCol({ dataField: "reqe_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "clie_name", caption: "고객명", allowEditing: false })}
                {CommCol({ dataField: "item_code", caption: "제품번호", allowEditing: false })}
                {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                {DateCol({ dataField: "reqe_date", caption: "지시일자", allowEditing: false })}
                {DeciCol({ dataField: "orde_qtys", caption: "수주수량", allowEditing: false })}
                {DeciCol({ dataField: "deli_qtys", caption: "기출하량", allowEditing: false })}
                {DeciCol({ dataField: "stoc_qtys", caption: "재고수량", allowEditing: false })}
                {DeciCol({ dataField: "reqe_qtys", caption: "지시수량", check: true, maxLength: 10 })}
                {CommCol({ dataField: "reqe_remk", caption: "특기사항", check: true })}
            </PagingGrid>
            <UpdateAlert
                grid={mainGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 지시를 수정하시겠습니까?'}
            />                     
            <DeleteAlert
                grid={mainGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 지시를 삭제하시겠습니까?'}
            />                      
        </div>
    );
};