import PursInfoStat from "./purs/InfoStat";
import PursInfoRegi from "./purs/InfoRegi";
import PrusInfoChan from "./purs/InfoChan";
import PProInfoRegi from "./ppro/InfoRegi";
import PProInfoRepo from "./ppro/InfoRepo";
import PproInfoStat from "./ppro/InfoStat";
import MproInfoRegi from "./mpro/InfoRegi";
import MproInfoRepo from "./mpro/InfoRepo";
import MproInfoStat from "./mpro/InfoStat";

const catbPurs = ({ acce, gubu, cata }) => {
    const catb = "purs";
    const comp = {
        infoStat: () => (<PursInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C11" mainTitle="요청" mainRole="현황" />),
        InfoRegi: () => (<PursInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C12" mainTitle="요청" mainRole="등록" />),
        InfoChan: () => (<PrusInfoChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="C13" mainTitle="요청" mainRole="변경" />),
    }

    return comp;
};

const catbPpro = ({ acce, gubu, cata }) => {
    const catb = "ppro";
    const comp = {
        infoStat: () => (<PproInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C14" mainTitle="발주" mainRole="현황" subsTitle="발주" subsRole="상세" />),
        InfoRegi: () => (<PProInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C15" mainTitle="발주" mainRole="등록" subsTitle="발주" subsRole="상세" />),
        InfoRepo: () => (<PProInfoRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="C16" mainTitle="발주서" mainRole="출력" subsTitle="발주" subsRole="상세" />),
    }

    return comp;
};


const catbMpro = ({ acce, gubu, cata }) => {
    const catb = "mpro";
    const comp = {
        infoStat: () => (<MproInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="C31" mainTitle="발주" mainRole="현황" subsTitle="발주" subsRole="상세" />),
        InfoRegi: () => (<MproInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="C32" mainTitle="발주" mainRole="등록" subsTitle="발주" subsRole="상세" />),
        InfoRepo: () => (<MproInfoRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="C33" mainTitle="발주서" mainRole="출력" subsTitle="발주" subsRole="상세" />),
    }

    return comp;
};


export const cataPro = ({ acce, gubu }) => {
    const cata = "pro"
    const comp = {
        catbPpro: catbPpro({ acce: acce, gubu: gubu, cata: cata }),
        catbPurs: catbPurs({ acce: acce, gubu: gubu, cata: cata }),
        catbMpro: catbMpro({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}