import { useCallback, useEffect, useRef, useState } from "react";
import { FileUploader } from "devextreme-react";
import { DrawingButton } from "../../../script/components/custom-drawing/custom-drawing";
import { fwUtil } from "../../../script/util";

const allowImagFileExtension = ['image/jpg', 'image/jpeg', 'image/png'];
const accepImagFileExtension = 'image/jpg, image/jpeg, image/png'

const ImagItem = (props) => {
    const { 
        label, 
        value, onValueChange, defaultValue,
        readOnly, disabled, required,
        size, circle, allowDrawing,
    } = props;

    const [image, setImage] = useState('');
    const [isDropZoneActive, setIsDropZoneActive] = useState(false);
    const onDropZoneEnter = useCallback(() => setIsDropZoneActive(true), [setIsDropZoneActive]);
    const onDropZoneLeave = useCallback(() => setIsDropZoneActive(false), [setIsDropZoneActive]);
    const targetRef = useRef(null);

    // 이미지 변경 후 적용
    useEffect(() => {
        const file = value;
        if (!file || file === '') {
            setIsDropZoneActive(false);
            setImage('');
            return;
        } 
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageDataUrl = e.target.result;
            setImage(imageDataUrl);
            setIsDropZoneActive(false);
        };
        reader.readAsDataURL(file);
    }, [value]);

    const onValueChanged = (e) => {
        if (!e.value || !e.value[0]) return;
        const file = e.value[0];
        const fileExtension = file.type.toLowerCase();
        if (!allowImagFileExtension.includes(fileExtension)) {
            fwUtil.aler.toast.erro('파일 형식이 옳바르지 않습니다.');
            return;
        }
        onValueChange(file);
    };

    return (
        <div cssClass={fwUtil.conv.class({ 'fw-imag-item': true, 'accent': required, 'circle': circle, 'allowDrawing': allowDrawing })}>
            <div className={'form-image-view fw-flex-box'}>
                <div 
                    className={`form-image ${!disabled && !readOnly ? 'editable' : ''}`}
                    style={{
                        width: size,
                        height: size,
                        maxHeight: size,
                    }}
                >
                    <div
                        ref={targetRef}
                        className={`fw-dropzone ${isDropZoneActive ? 'dropzone-active' : ''}`}
                        title={ !disabled && !readOnly ? '이미지 가져오기' : '' }
                    >
                        {fwUtil.conv.check.nue(value) ? (
                            <div className="fw-dropzone-text fw-flex-box col">
                            {!disabled && !readOnly ? (
                                <>
                                {label && <span className='dropzone-label'>*{label}*</span>}
                                <span>업로드할 파일을 <br />드래그 앤 드롭 하시거나 <br />클릭해 주세요</span>
                                </>
                            ) : (
                                defaultValue ? (
                                <figure className={"fw-default-img-wrapper"} style={{ width: size, height: size }}>
                                    <img src={defaultValue} alt="기본이미지" />                                    
                                </figure>
                                ) : (
                                <span>표시할 이미지가 <br />없습니다</span>
                                )
                            )}
                            </div>
                        ) : (
                            <img className='fw-dropzone-image' src={image} alt={label} />
                        )}                       
                        <i className='dx-icon-photooutline' />
                    </div>
                </div>
                { !disabled && !readOnly &&
                    <FileUploader
                        uploadMode='useButtons'
                        accept={accepImagFileExtension}
                        dialogTrigger={targetRef.current}
                        dropZone={targetRef.current}
                        visible={false}
                        multiple={false}
                        onValueChanged={onValueChanged}
                        onDropZoneEnter={onDropZoneEnter}
                        onDropZoneLeave={onDropZoneLeave}
                    />
                }
                { allowDrawing &&
                    <DrawingButton 
                        width={size}
                        disabled={readOnly}
                        saveCanvas={(file) => onValueChange(file)}
                    />
                }
            </div>            
        </div>
    );
};

export {
    ImagItem
}