import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    cate_code: '', 
    cate_name: '', 
    regi_date: fwUtil.date.getsText.date(), 
    enab_yesn: true
};

export function MatlCatdStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { cateCode: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                cate_code: res.cate_code || '',
                cate_name: res.cate_name || '',
                regi_date: res.regi_date || null,
                enab_yesn: res.enab_yesn === 1 ? true : false,
            };

            dataUpdate(resData);
        }
    }, [dataUpdate, hiding, mainGrid, targetApi]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            catdCode: data.cate_code || '',
            catdName: data.cate_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'재질 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600 }  
        >
            <GroupItem colCount={isXSmall ? 1 : 2 }>
                {FormText({ value: data.cate_code, onValueChange: updateField('cate_code'), label: '재질코드', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormText({ value: data.cate_name, onValueChange: updateField('cate_name'), label: '재질명', required: true, disabled: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
            </GroupItem>
        </PopupForm>     
    );
}