// AserCompRegi - A/S 운영/점검 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupWarn } from '../../../../script/components/popup/popup';
import { AserCompInsert } from './InfoRegi-insert';
import { AserCompUpdate } from './InfoRegi-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function AserCompRegi(props) {
    const {
        acce, gubu, cata, catb,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/search`;  // 서브 조회 api
    const subsTargetApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/target`;  // 서브 타겟 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/insert`;  // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/update`;  // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/detail/delete`;  // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'mana_numb';
    const subsKey = ['mana_numb', 'comp_seri'];
    const deleKey = (data) => {
        return { 
            manaNumb: data.mana_numb, 
            manaSeri: data.comp_seri 
        }
    };       
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const clieNameChange = useCallback((e) => { setClieName(e) }, []); 
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []); 
    const itemNameChange = useCallback((e) => { setItemName(e) }, []); 
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);   
    // 수정/삭제 경고창 
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => { setAlertVisible(false); }, []);   

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { manaNumb: manaNumb, clieName: clieName, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [manaNumb, clieName, itemCode, itemName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { manaNumb: key };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        } else {
            const keyData = fwUtil.grid.get.sKey(mainGridRef);
            const sendData = { manaNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList);
    }, [authCheck, setLoading, setAuthList]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        const gridInstance = subsGridRef?.current?.instance;
        if (gridInstance) {
            await fwUtil.apis.delete(subsDeleteApi, subsGridRef, deleKey, subsHandleSearch);
        }
        deleteAlertHide();
    }, [subsGridRef, subsHandleSearch, deleteAlertHide, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [manaNumb, manaNumbChange, clieName, itemCode, itemName, itemCodeChange, itemNameChange, clieNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const subsHandleActionClick = useCallback((act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowData = fwUtil.grid.get.sData(subsGridRef);

        if (mainSelectedRowKey) {
            if (act === 'ins') {
                setInsertPopupVisible(true);
                return;
            }
            if (subsSelectedRowData) {
                if (subsSelectedRowData.pays_yesn) {
                    setAlertVisible(true);
                } else {
                    if (act === 'upd') {
                        setUpdatePopupVisible(true);
                        return;
                    }
                    if (act === 'del') {
                        setDeleteAlertVisible(true);
                        return;
                    }         
                }
            } else {
                fwUtil.aler.toast.warn('상세 항목을 선택 후 시도해주세요.');
            }
        } else {
            fwUtil.aler.toast.warn('A/S계약을 선택 후 시도해주세요.');
        };

    }, [mainGridRef, subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "cont_date", caption: "계약일자" })}
                {CommCol({ dataField: "aser_memo", caption: "비고" })}                                                    
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => subsHandleActionClick('ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => subsHandleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => subsHandleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "comp_seri", caption: "점검순번" })}
                {DateCol({ dataField: "rece_date", caption: "접수일자" })}
                {DateCol({ dataField: "insp_date", caption: "점검일자" })}
                {CommCol({ dataField: "insp_name", caption: "점검자" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "part_kwon", caption: "부품비" })}
                {BoolCol({ dataField: "pays_yesn", caption: "정산여부" })}
                {CommCol({ dataField: "comp_memo", caption: "비고" })}
            </SubsGrid>         
            <AserCompInsert
                refresh={subsHandleSearch}
                mainGrid={mainGridRef}
                insertApi={subsInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <AserCompUpdate
                refresh={subsHandleSearch}
                mainGrid={subsGridRef}
                updateApi={subsUpdateApi}
                targetApi={subsTargetApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />
            <DeleteAlert
                grid={subsGridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 항목을 삭제하시겠습니까?'}
            />
            <PopupWarn
                closeHide
                visible={alertVisible}
                hiding={alertHide}
                confirm={alertHide}
                confirmText={"확인"}
                message={
                    <>
                        정산처리된 항목은 수정/삭제가 불가능합니다.<br />
                        A/S정산 변경을 이용해주세요.
                    </>
                }
            />                  
        </div>
    );
};