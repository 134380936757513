import { DropDownButton } from "devextreme-react";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { useCallback, useState } from "react";
import { CommChart, PiesChart } from "../../../../script/components/chart/charts";
import { BarsSeri, PiesSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

const dropGroup = ["매출", "매입"];

function customizeLabel(arg) {
    return `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}원`;
}
export function DashItemClieCard(props) {
    const { loading, data } = props;
    const [selectedGroup, setSelectedGroup] = useState(dropGroup[0]);
    const selectedGroupChange = useCallback((e) => setSelectedGroup(e.item), []);
    const saleData = data.filter(item => item.sale_kwon > 0);
    const pursData = data.filter(item => item.purs_kwon > 0);

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}원`,
        };
    };

    return (
        <DashCard
            title={"업체별 현황"}
            addClass={'clie'}
            loading={loading}
            headerContent={
                <div className='dash-card-filter'>
                    <DropDownButton stylingMode='text' useSelectMode items={dropGroup} selectedItemKey={selectedGroup} onSelectionChanged={selectedGroupChange} />
                </div>
            }
        >
            <PiesChart
                dataSource={selectedGroup === "매출" ? saleData : pursData}
                type={"doughnut"}
                palette={"Soft Blue"}
                legend={{ vertical: "top", horizontal: "right" }}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {selectedGroup !== "매입" && PiesSeri({ name: "매출", valueField: "clie_name", args: "sale_kwon", label: { text: customizeLabel } })}
                {selectedGroup !== "매출" && PiesSeri({ name: "매입", valueField: "clie_name", args: "purs_kwon", label: { text: customizeLabel } })}
            </PiesChart>
            <CommChart
                dataSource={selectedGroup === "매출" ? saleData : pursData}
                tooltip={{ location: "edge", template: tooltipTamplate }}
                rotate
            >
                {selectedGroup !== "매입" && BarsSeri({ valueField: "sale_kwon", caption: "매출", color: "#FF7D69", args: "clie_name" })}
                {selectedGroup !== "매출" && BarsSeri({ valueField: "purs_kwon", caption: "매입", color: "#4EACFF", args: "clie_name" })}
            </CommChart>
        </DashCard>
    )
}