import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { Item } from 'devextreme-react/cjs/data-grid';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { set } from '../../../../../api/set';

const defaultData = {
    mpro_numb: '',
    clie_code: '',
    clie_name: '',
    clie_teln: '',
    clie_faxn: '',
    clie_damd: '',
    mdel_date: null,
    pays_cond: '',
    insp_damd: '',
    deli_plac: '',
    mpro_remk: '',
    damd_name: '',
    mpro_date: fwUtil.date.getsText.date(),
    mpro_usag: '',
    afte_conf: false,
};

const setMpro = set.mes.pro.mpro;

export function MproInfoRegiMainUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 담당자, 지불조건 리스트
    const [damdList, setDamdList] = useState([]);
    const [paysList, setPaysList] = useState([]);
    const damdListChange = useCallback((e) => setDamdList(e), []);    
    const paysListChange = useCallback((e) => setPaysList(e), []); 
    // 납품업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';
    // 그리드 조회 조건
    const [clieName, setClieName] = useState('');
    const clieNameChange = useCallback((e) => setClieName(e), []);
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 납품업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieName: clieName };
        const res = await setMpro.clie(sendData);
        clieGridUpdate(res);
    }, [clieName, clieGridUpdate]);

    // 그리드 추가 툴바 아이템
    const toolbarBeforeItems = useCallback(() => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "업체명", value: clieName, valueChange: clieNameChange, search: clieHandleSearch })}
                {SearchButton({ search: clieHandleSearch })}
            </Item>
        );
    }, [clieName, clieNameChange, clieHandleSearch]);

    // 납품업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 납품업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 
            clie_code: e.data.clie_code, 
            clie_name: e.data.clie_name, 
            clie_damd: e.data.damd_name, 
            clie_teln: e.data.damd_teln, 
            clie_faxn: e.data.damd_faxn 
        });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const fetchData = useCallback(async () => {
        try {
            const [damdResult, paysResult] = await Promise.all([setMpro.damd(), setMpro.pays()]);
            if (damdResult && damdResult.length > 0) {
                damdListChange(damdResult);
            }
            if (paysResult && paysResult.length > 0) {
                paysListChange(paysResult);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [damdListChange, paysListChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { mproNumb: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                mpro_numb: res.mpro_numb || '',
                clie_code: res.clie_code || '',
                clie_name: res.clie_name || '',
                clie_teln: res.clie_teln || '',
                clie_faxn: res.clie_faxn || '',
                clie_damd: res.clie_damd || '',
                mdel_date: res.mdel_date || null,
                pays_cond: res.pays_cond || '',
                insp_damd: res.insp_damd || '',
                deli_plac: res.deli_plac || '',
                mpro_remk: res.mpro_remk || '',
                damd_name: damdList && damdList.length > 0 ? damdList[0] : '',
                mpro_date: res.mpro_date || fwUtil.date.getsText.date(),
                mpro_usag: res.mpro_usag || '',
                afte_conf: res.afte_conf === '후결' ? true : false,
            };

            dataUpdate(resData);
        }
    }, [fetchData, hiding, mainGrid, targetApi, dataUpdate, damdList]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mproNumb: data.mpro_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            clieTeln: data.clie_teln || '',
            clieFaxn: data.clie_faxn || '',
            clieDamd: data.clie_damd || '',
            mdelDate: data.mdel_date ? fwUtil.date.convTime.fmDate(data.mdel_date) : null,
            paysCond: data.pays_cond || '',
            inspDamd: data.insp_damd || '',
            deliPlac: data.deli_plac || '',
            mproRemk: data.mpro_remk || '',
            damdName: data.damd_name || '',
            mproDate: data.mpro_date ? fwUtil.date.convTime.fmDate(data.mpro_date) : fwUtil.date.getsText.date(),
            mproUsag: data.mpro_usag || '',
            afteConf: data.afte_conf ? '후결' : '미결',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'납품업체 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'업체'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false, before: toolbarBeforeItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "업체코드" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "damd_name", caption: "담당자" })}
                {CommCol({ dataField: "damd_teln", caption: "전화번호" })}
                {CommCol({ dataField: "damd_faxn", caption: "팩스번호" })}
            </ScrollGrid>
        </PopupGrid>                   
        <PopupForm
            title={'발주 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2} >
                {FormText({ value: data.mpro_numb, onValueChange: updateField('mpro_numb'), label: "발주번호", disabled: true })}
                {FormBool({ value: data.afte_conf, onValueChange: updateField('afte_conf'), label: "후결" })}                
                {FormSelc({ value: data.damd_name, onValueChange: updateField('damd_name'), label: "발주담당자", items: damdList, required: true })}
                {FormDate({ value: data.mpro_date, onValueChange: updateField('mpro_date'), label: "발주일자", required: true })}                
                {FormText({ value: data.clie_damd, onValueChange: updateField('clie_damd'), label: "담당자" })}
                {FormText({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: "검수자(방법)" })}
                {FormText({ value: data.clie_teln, onValueChange: updateField('clie_teln'), label: "전화번호", placeholder: "-포함 입력" })}
                {FormText({ value: data.clie_faxn, onValueChange: updateField('clie_faxn'), label: "팩스번호", placeholder: "-포함 입력" })}                
                <GroupItem colSpan={isXSmall ? 1 : 2 }>
                    {FormMemo({ value: data.mpro_usag, onValueChange: updateField('mpro_usag'), label: "용도" })}
                </GroupItem>
                {FormGrid({ value: data.clie_name, onClick: () => setCliePopupVisible(true), label: "발주업체", required: true })}
                {FormDate({ value: data.mdel_date, onValueChange: updateField('mdel_date'), label: "납품일자" })}
                {FormSelc({ value: data.pays_cond, onValueChange: updateField('pays_cond'), label: "지불조건", items: paysList, required: true })}
                {FormText({ value: data.deli_plac, onValueChange: updateField('deli_plac'), label: "납품장소" })}
            </GroupItem>
            {FormMemo({ value: data.mpro_remk, onValueChange: updateField('mpro_remk'), label: "특기사항" })}
        </PopupForm>            
        </>
    );
}