import React from "react";
import { Button, Column } from 'devextreme-react/tree-list';
import { ReOrdingTree } from "../../../../script/components/tree-list/tree-body";
import { menuFunc } from "./infoUtil";

export default function MenuInfoRegiMES(menuData, menuRef, mainKey, highKey, reorder) {

    if (!menuData) {
        return (
            <div className="fw-page-tab-body">
                <div className="erro-message">
                    표시할 데이터가 없습니다
                </div>
            </div>
        )
    }

    return (
        <div className="fw-page-tab-body">
            <ReOrdingTree
                treeInstance={menuRef}
                dataSource={menuData}
                keyExpr={mainKey}
                parentIdExpr={highKey}
                onDragChange={menuFunc.dragChan}
                onReorder={reorder}
            >
                <Column dataField={'menu_name'} caption="메뉴명" />
                <Column dataField={'menu_code'} caption="메뉴코드" />
                <Column dataField={'menu_high'} caption="상위메뉴코드" />
                <Column dataField={'menu_part'} caption="상위정렬코드" />
                <Column dataField={'menu_sort'} caption="하위정렬코드" />
                <Column type={'buttons'}>
                    <Button
                        icon="spinup" cssClass={'fw-cell-button'}
                        onClick={(e) => reorder(e, "up")}
                    />
                    <Button
                        icon="spindown" cssClass={'fw-cell-button'}
                        onClick={(e) => reorder(e, "down")}
                    />
                </Column>
            </ReOrdingTree>
        </div>
    )
};