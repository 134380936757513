import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupFile } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { createFileSystemProvider } from '../../../../script/components/file-manager/file-manager';

const allowedFileExtensions = [];

export function BluePrinRegiFileMn(props) {
    const {
        mainGrid,
        createApi, searchApi, insertApi, deleteApi, dnloadApi,
        visible, hiding,
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    const [rootFileName, setRootFileName] = useState("");
    const rootFileNameUpdate = useCallback((e) => setRootFileName(e), [setRootFileName]);
    const fileManagerRef = useRef(null);

    const fileSystemProvider = createFileSystemProvider({
        searchApi,
        createApi,
        insertApi,
        deleteApi,
        dnloadApi,
        rootFileName,
    });

    // 열릴 때
    const onShown = useCallback(async () => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else { 
            setLoading(true);
            let indxKeys = '';
            if (!fwUtil.conv.check.nue(seleData.indx_keys)) { indxKeys = seleData.indx_keys }
            else if (!fwUtil.conv.check.nue(rootFileName)) { indxKeys = rootFileName }
            else {
                const res = await fwUtil.apis.fileax(createApi, { manaNumb: seleData.mana_numb });
                const resData = res.data;
                const message = resData.message || '파일저장키가 존제 하지 않습니다.';
                const status = resData.status;
                const fileIdno = resData?.dataSet[0]?.file_idno;
                if (status && fileIdno) { 
                    indxKeys = fileIdno; 
                } else { 
                    fwUtil.aler.toast.warn(message,2000);
                    hiding(); 
                }
            }
            rootFileNameUpdate(indxKeys);
        }
    }, [hiding, mainGrid, createApi, rootFileName, rootFileNameUpdate]);

    const onHiding = useCallback(() => {
        fileManagerRef.current = null; // 레퍼 초기화
        rootFileNameUpdate(''); // 키값 초기화
        hiding();
    }, [hiding, rootFileNameUpdate]);

    return (
        <PopupFile
            title={'도면 파일관리'}
            loading={loading}
            setLoading={setLoading}
            visible={visible}
            shown={onShown}
            hiding={onHiding}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 700}
            fileManagerRef={fileManagerRef}
            fileSystemProvider={fileSystemProvider}
            allowedFileExtensions={allowedFileExtensions}
            rootFileName={rootFileName}
        />
    );
}
