import React, { createContext, useEffect, useState } from 'react';
import { fwUtil } from '../util';

export const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
    const [sessStat, setSessStat] = useState(true);

    useEffect(() => {
        const interval = setInterval(async () => {
            await fwUtil.apis.touchs('privat/mes/sys/sess/touch', setSessStat);
        }, 1 * 60 * 60 * 1000);

        return () => clearInterval(interval);
    }, [setSessStat]);

    return (
        <SessionContext.Provider value={{ sessStat, setSessStat }}>
            {children}
        </SessionContext.Provider>
    );
};
