import UserInfoRegi from "./user/InfoRegi";
import UserPwrdRese from "./user/PwrdRese";
import UserAuthChan from "./user/AuthChan";
import LogsInfoStat from "./log/InfoStat";
import KpisInfoStat from "./kpi/Dash";
import MenuInfoRegi from "./menu/InfoRegi";
import PathInfoStat from "./path/InfoStat";
import HrmsInfoStat from "./hr/InfoStat";

const catbUser = ({ acce, gubu, cata }) => {
    const catbInfo = "info";
    const catbPass = "pass";
    const catbAuth = "auth";
    
    const comp = {
        infoRegi: () => (<UserInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catbInfo} code="R11" mainTitle="계정" mainRole="관리" />),
        pwrdRese: () => (<UserPwrdRese acce={acce} gubu={gubu} cata={cata} catb={catbPass} code="R12" mainTitle="암호" mainRole="변경" />),
        authChan: () => (<UserAuthChan acce={acce} gubu={gubu} cata={cata} catb={catbAuth} code="R13" mainTitle="권한" mainRole="관리" />),
    }

    return comp;
};

const catbHrms = ({ acce, gubu, cata }) => {
    const catb = "hr";
    const comp = {
        infoStat: () => (<HrmsInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="R2" deptTitle="부서" posiTitle="직위" mainRole="관리" />),
    }

    return comp;
};

const catbKpis = ({ acce, gubu, cata }) => {
    const catb = "kpi";
    const comp = {
        infoStat: () => (<KpisInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="R3" title="KPI 현황" />),
    }

    return comp;
};

const catbLogs = ({ acce, gubu, cata }) => {
    const catb = "log";
    const comp = {
        infoStat: () => (<LogsInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="R4" mainTitle="로그" mainRole="관리" />),
    }

    return comp;
};

const catbMenu = ({ acce, gubu, cata }) => {
    const catb = "menu";
    const comp = {
        infoRegi: () => (<MenuInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="R5" mainTitle="메뉴" mainRole="이동" />),
    }

    return comp;
};

const catbAddr = ({ acce, gubu, cata }) => {
    const catb = "path";
    const comp = {
        infoStat: () => (<PathInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="R6" ipnoTitle="IP" urisTitle="URI" mainRole="관리" />),
    }

    return comp;
};

export const cataSys = ({ acce, gubu }) => {
    const cata = "sys"
    const comp = {
        catbUser: catbUser({ acce: acce, gubu: gubu, cata: cata }),
        catbHrms: catbHrms({ acce: acce, gubu: gubu, cata: cata }),
        catbKpis: catbKpis({ acce: acce, gubu: gubu, cata: cata }),
        catbLogs: catbLogs({ acce: acce, gubu: gubu, cata: cata }),
        catbMenu: catbMenu({ acce: acce, gubu: gubu, cata: cata }),
        catbAddr: catbAddr({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}