import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    ware_date: fwUtil.date.getsText.date(),
    stoc_loca: '',
    insp_okey: true,
};

const mproDataSource = fwUtil.grid.get.dataSource();

export function MproFullRegiInsert(props) {
    const {
        mainGrid,
        mainRefresh, subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 발주 그리드
    const mproRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            mproDataSource.store().insert({ init: true }).then(() => mproDataSource.reload());
            const mproSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const mpro of mproSelected) {
                const newData = {
                    mpro_numb: mpro.mpro_numb || '', 
                    clie_name: mpro.clie_name || '', 
                    pdel_date: mpro.pdel_date || null, 
                    pays_cond: mpro.pays_cond || '', 
                    deli_plac: mpro.deli_plac || '', 
                    mpro_remk: mpro.mpro_remk || '', 
                    damd_name: mpro.damd_name || '', 
                    mpro_date: mpro.mpro_date || null, 
                    mpro_usag: mpro.mpro_usag || '', 
                    mpro_stat: mpro.mpro_stat || '', 
                };
                mproDataSource.store().insert(newData);
            }
            
            mproDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mproData = mproRef?.current?.instance?.getDataSource()?.items();
        const postMproData = mproData.map((item) => ({
            mproNumb: item.mpro_numb || "",  
            wareDate: data.ware_date || fwUtil.date.getsText.date(),       
            stocLoca: data.stoc_loca || '',
            inspOkey: true,
        }));        
        const postData = {
            setaMaps: postMproData || [],
        };

        const refresh = () => {
            mainRefresh();
            subsRefresh();
        };

        const allHiding = () => {
            alertHide();
            hiding();
        }
        await fwUtil.apis.action(insertApi, postData, refresh, allHiding);
        setLoading(false);
    }, [data, insertApi, mainRefresh, subsRefresh, hiding, alertHide, mproRef]);

    return (
        <>
        <PopupForm
            title={'전체입고'}       
            type={'입고'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : '90%' } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'발주목록'}
                        gridRef={mproRef} 
                        gridDataSource={mproDataSource}
                    >
                        {CommCol({ dataField: "mpro_numb", caption: "발주번호" })} 
                        {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                        {DateCol({ dataField: "pdel_date", caption: "납품일자" })} 
                        {CommCol({ dataField: "pays_cond", caption: "지불형태" })} 
                        {CommCol({ dataField: "deli_plac", caption: "납품장소" })} 
                        {CommCol({ dataField: "mpro_remk", caption: "특기사항" })} 
                        {CommCol({ dataField: "damd_name", caption: "발주담당자" })} 
                        {DateCol({ dataField: "mpro_date", caption: "발주일자" })} 
                        {CommCol({ dataField: "mpro_usag", caption: "용도" })} 
                        {CommCol({ dataField: "mpro_stat", caption: "진행상태" })}   
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                {FormDate({ value: data.ware_date, onValueChange: updateField('ware_date'), label: '등록일자' })}
                    {FormText({ value: data.stoc_loca, onValueChange: updateField('stoc_loca'), label: '저장위치', required: true })}
               
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            warning={"전체입고 시 해당 발주서의 모든 발주자재가 입고처리됩니다."}
            message={'해당 발주를 전체입고 하시겠습니까?'}
        />  
        </>
    );
    /*<GroupItem colCount={2}>
        {FormBool({ value: data.insp_okey, onValueChange: updateField('insp_okey'), label: '입고검사 확인' })}
    </GroupItem>*/
}