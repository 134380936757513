import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigation } from './contexts/navigation';
import { intrComp } from './focuswin/script/comp-data';
import { SideNavOuterToolbar as SideNavBarLayout } from './focuswin/script/components';

export default function MesContent() {
  const { mesRouteList, routeLoading } = useNavigation();

  if (routeLoading) { return; }

  return (
    <>
      <SideNavBarLayout title={intrComp.snam} menu={"mes"} >
        <Routes>
          {mesRouteList.map(({ path, element }) => ( <Route key={path} path={path} element={element} /> ))}
          <Route path='*' element={<Navigate to='/mes/home' />} />
        </Routes>       
      </SideNavBarLayout>
    </>
  );
}

