import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormAuto, FormDate, FormGrid, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, SelcCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    docu_numb: "",                    // varchar(9)   견적번호("QU"+YYMM+XXXX) PK
    docu_revi: "",                    // varchar(1)   REV. NO PK
    cust_code: "",                    // varchar(10)  고객코드
    cust_name: "",                    // varchar(50)  고객명
    cust_damd: "",                    // varchar(50)  담당자
    cust_dept: "",                    // varchar(50)  부서명
    cust_posi: "",                    // varchar(50)  담당직위
    cust_tels: "",                    // varchar(50)  전화번호
    cust_faxs: "",                    // varchar(50)  팩스번호
    cust_mail: "",                    // varchar(50)  이메일
    prjt_name: "",                    // varchar(50)  공사명
    quot_gubu: "작성",                    // varchar(10)  진행상태
    issu_date: fwUtil.date.getsText.date(),                  // datetime     견적일자
    corp_prof: 0,                     // int(3)       기업이윤(%)
    quot_cond: "",                    // varchar(50)  유효기간
    deli_days: "",                    // varchar(50)  납품기간
    pays_cond: "",                    // varchar(50)  지급조건
    deli_cond: "",                    // varchar(50)  인도조건
    deli_plac: "",                    // varchar(50)  인도장소
    insp_cond: "",                    // varchar(50)  검사조건
    guar_cond: "",                    // varchar(50)  이행보증조건
    quot_remk: "",                    // text         특이사항
};

const setOrde = set.mes.sal.orde;

export function QuotRegiHeaderUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);

    // 제품구분
    const [quotGubuList, setQuotGubuList] = useState([]);
    const quotGubuListChange = useCallback((e) => setQuotGubuList(e), []);

    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';
    // 그리드 조회 조건
    const [clieName, setClieName] = useState('');
    const clieNameChange = useCallback((e) => setClieName(e), []);
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);
    // 팝업 조회
    const clieHandleSearch = useCallback(async () => {
        const sendData = { clieName: clieName };
        const res = await setOrde.clie(sendData);
        clieGridUpdate(res);
    }, [clieName, clieGridUpdate]);

    // 그리드 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '거래처명', value: clieName, valueChange: clieNameChange, search: clieHandleSearch },
        { type: 'btns', locate: 'after', icon: 'search', text: '검색', onClick: clieHandleSearch }
    ], [clieName, clieNameChange, clieHandleSearch]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        quotGubuListChange(['작성', '제출']);
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        if (!selectedRowData) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                docu_numb: selectedRowData.docu_numb || '',
                docu_revi: selectedRowData.docu_revi || '',
                cust_code: selectedRowData.cust_code || '',
                cust_name: selectedRowData.cust_name || '',
                cust_damd: selectedRowData.cust_damd || '',
                cust_dept: selectedRowData.cust_dept || '',
                cust_posi: selectedRowData.cust_posi || '',
                cust_tels: selectedRowData.cust_tels || '',
                cust_faxs: selectedRowData.cust_faxs || '',
                cust_mail: selectedRowData.cust_mail || '',
                prjt_name: selectedRowData.prjt_name || '',
                quot_gubu: selectedRowData.quot_gubu || '작성',
                issu_date: selectedRowData.issu_date || fwUtil.date.getsText.date(),
                corp_prof: selectedRowData.corp_prof || '0',
                quot_cond: selectedRowData.quot_cond || '',
                deli_days: selectedRowData.deli_days || '',
                pays_cond: selectedRowData.pays_cond || '',
                deli_cond: selectedRowData.deli_cond || '',
                deli_plac: selectedRowData.deli_plac || '',
                insp_cond: selectedRowData.insp_cond || '',
                guar_cond: selectedRowData.guar_cond || '',
                quot_remk: selectedRowData.quot_remk || '',
            };
            console.log('updatedData: ',updatedData);
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, dataUpdate, quotGubuListChange]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            docuNumb: data.docu_numb || '',
            docuRevi: data.docu_revi || '',
            custCode: data.cust_code || '',
            custName: data.cust_name || '',
            custDamd: data.cust_damd || '',
            custDept: data.cust_dept || '',
            custPosi: data.cust_posi || '',
            custTels: data.cust_tels || '',
            custFaxs: data.cust_faxs || '',
            custMail: data.cust_mail || '',
            prjtName: data.prjt_name || '',
            quotGubu: data.quot_gubu || '작성',
            issuDate: data.issu_date || fwUtil.date.getsText.date(),
            corpProf: data.corp_prof || '0',
            quotCond: data.quot_cond || '',
            deliDays: data.deli_days || '',
            paysCond: data.pays_cond || '',
            deliCond: data.deli_cond || '',
            deliPlac: data.deli_plac || '',
            inspCond: data.insp_cond || '',
            guarCond: data.guar_cond || '',
            quotRemk: data.quot_remk || '',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    // 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, cust_code: e.data.clie_code, cust_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const clieGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "고객구분" })}
                {CommCol({ dataField: "clie_name", caption: "고객명[한글]" })}
                {CommCol({ dataField: "clie_enam", caption: "고객명[영문]" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
            </ScrollGrid>
        )
    }, [clieData, clieGridRef, clieKeyExpr, clieOnRowDblClick, toolbarItems, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'고객 선택'}
                visible={cliePopupVisible}
                shown={cliePopupOnShowing}
                hiding={cliePopupHiding}
            >
                {clieGrid}
            </PopupGrid>
            <PopupForm
                title={'견적등록 - 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 1000}
            >
                <GroupItem colCount={isXSmall ? 1 : 5}>
                    <GroupItem colSpan={3} cssClass='remk-form quot-data'>
                        <GroupItem colCount={isXSmall ? 1 : 3} >
                            {FormText({ value: data.docu_numb, onValueChange: updateField('docu_numb'), label: "견적번호", required: true, disabled: true })}
                            {FormText({ value: data.docu_revi, onValueChange: updateField('docu_revi'), label: "REV.NO", required: true, disabled: true })}
                            {FormSelc({ value: data.quot_gubu, onValueChange: updateField('quot_gubu'), label: "진행상태", required: true, items: quotGubuList, })}
                            <GroupItem colCount={isXSmall ? 1 : 1} colSpan={3}>
                                {FormText({ value: data.prjt_name, onValueChange: updateField('prjt_name'), label: "공 사 명" })}
                            </GroupItem>
                        </GroupItem>
                        <GroupItem colCount={isXSmall ? 1 : 2}>
                            {FormNumb({ value: data.quot_kwon, onValueChange: updateField('quot_kwon'), label: "견적금액", disabled: true, length: [15, 2] })}
                            <GroupItem colCount={isXSmall ? 1 : 2}>
                                {FormText({ value: data.corp_prof, onValueChange: updateField('corp_prof'), label: '기업이윤(%)', disabled: true })}
                                {FormNumb({ value: data.item_coun, onValueChange: updateField('item_coun'), label: '품 목 수', disabled: true, length: [5, 0] })}
                            </GroupItem>
                        </GroupItem>
                        <GroupItem colCount={isXSmall ? 1 : 3}>
                            {FormDate({ value: data.issu_date, onValueChange: updateField('issu_date'), label: "견적일자", required: true })}
                            {FormText({ value: data.quot_cond, onValueChange: updateField('quot_cond'), label: "유효기간" })}
                            {FormText({ value: data.deli_days, onValueChange: updateField('deli_days'), label: "납품기간" })}
                            {FormText({ value: data.pays_cond, onValueChange: updateField('pays_cond'), label: "지급조건" })}
                            {FormText({ value: data.deli_cond, onValueChange: updateField('deli_cond'), label: "인도조건" })}
                            {FormText({ value: data.deli_plac, onValueChange: updateField('deli_plac'), label: "인도장소" })}
                            {FormText({ value: data.insp_cond, onValueChange: updateField('insp_cond'), label: "검사조건" })}
                            {FormText({ value: data.guar_cond, onValueChange: updateField('guar_cond'), label: "이행보증" })}
                        </GroupItem>
                        {FormMemo({ value: data.quot_remk, onValueChange: updateField('quot_remk'), label: "특이사항" })}
                    </GroupItem>
                    <GroupItem colSpan={2} cssClass='remk-form quot-clie'>
                        <GroupItem colCount={isXSmall ? 1 : 3}>
                            <GroupItem colCount={isXSmall ? 1 : 1} colSpan={2}>
                                {FormText({ value: data.cust_name, onValueChange: updateField('cust_name'), label: "고 객 명", disabled: true })}
                            </GroupItem>
                            <GroupItem colCount={isXSmall ? 1 : 1} >
                                {FormGrid({ value: data.cust_code, onValueChange: updateField('cust_code'), label: "고객코드", disabled: true, onClick: () => setCliePopupVisible(true) })}
                            </GroupItem>
                        </GroupItem>
                        {FormText({ value: data.cust_damd, onValueChange: updateField('cust_damd'), label: "담 당 자", })}
                        {FormText({ value: data.cust_dept, onValueChange: updateField('cust_dept'), label: "부 서 명", })}
                        {FormText({ value: data.cust_posi, onValueChange: updateField('cust_posi'), label: "담당직위", })}
                        {FormText({ value: data.cust_tels, onValueChange: updateField('cust_tels'), label: "전화번호", })}
                        {FormText({ value: data.cust_faxs, onValueChange: updateField('cust_faxs'), label: "팩스번호", })}
                        {FormText({ value: data.cust_mail, onValueChange: updateField('cust_mail'), label: 'E-Mail', rule: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
                    </GroupItem>
                </GroupItem >
            </PopupForm>
        </>
    );
}