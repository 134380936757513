import IntrLogo from '../assets/intr-comp-logo.png';
import SuppLogo from '../assets/supp-comp-logo.png';

export const intrComp = {
    logo: IntrLogo,
    snam: "AQUA - SYSTEM",
    knam: "아쿠아시스템",
    enam: "aqua system",
    catc: ""
}

export const suppComp = {
    logo: SuppLogo,
    snam: "FOCUSWIN",
    knam: "(주)포커스윈",
    enam: "focuswin",
    tele: "055-224-6633~4",
    faxs: "070-4275-5487",
    addr: "경남 창원시 마산회원구 내서읍 광려천남로 59 (경남로봇랜드재단 618호)"
};