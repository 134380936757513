import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    posi_code: '',
    posi_name: '',
    posi_enam: '',
    sort_seri: '0',
    enab_yesn: true,
};

export function PosiStatInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            posiCode: data.posi_code || '',
            posiName: data.posi_name || '',
            posiEnam: data.posi_enam || '',
            sortSeri: data.sort_seri || '0',
            enabYesn: data.enab_yesn || false,            
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'직위코드 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.posi_code, onValueChange: updateField('posi_code'), label: '직위코드', mask: '9999', required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부', })}    
                {FormText({ value: data.posi_name, onValueChange: updateField('posi_name'), label: '직위명[한]', })}
                {FormText({ value: data.posi_enam, onValueChange: updateField('posi_enam'), label: '직위명[영]', })}
                {FormNumb({ value: data.sort_seri, onValueChange: updateField('sort_seri'), label: '정렬순서', length: [4, 0] })}                     
            </GroupItem>
        </PopupForm>        
    );
}