// AserAccoChan - A/S 정산 변경
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { AserAccoChanUpdate } from './InfoChan-update';

const dateGubuList = ["청구일자", "정산일자"];

const accoGroupRender = (data) => {
    if (!data && (data && data.length < 1)) {
        return;
    }

    if (!data?.data?.items || data.data.items.length < 1) {
        return;
    }

    const clieColumn = data.data.items[0].clie_name;
    const itemColumn = `${data.data.items[0].item_code} - ${data.data.items[0].item_name}`;

    if (!clieColumn || !itemColumn) {
        return;
    }

    return (
        <div className='fw-summary-group'>
            <div className='fw-group-title'>
                {data.displayValue}
            </div>
            <div className='fw-group-detail'>
                업체: <b>{clieColumn}</b>
            </div>
            <div className='fw-group-detail'>
                제품: <b>{itemColumn}</b>
            </div>
        </div>
    )
}

export default function AserAccoChan(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/change/search`;  // 조회 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/change/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/change/delete`;  // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['mana_numb', 'comp_seri'];    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [dateGubu, setDateGubu] = useState("청구일자");
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []); 
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []); 
    const itemNameChange = useCallback((e) => { setItemName(e) }, []); 
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);   
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);           

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, dateGubu: dateGubu, manaNumb: manaNumb, clieName: clieName, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, dateGubu, manaNumb, clieName, itemCode, itemName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 경고 확인 클릭
    const onActionClick = useCallback(async (api) => {
        gridRef.current.instance.beginCustomLoading();
        const seleData = fwUtil.grid.get.sData(gridRef);
        if (seleData) {
            const postData ={
                manaNumb: seleData.mana_numb,
                compSeri: seleData.comp_seri,
                billDate: seleData.bill_date ? fwUtil.date.convTime.fmDate(seleData.bill_date) : fwUtil.date.getsText.date(),
                billKwon: seleData.bill_kwon,
                paysDate: seleData.pays_date ? fwUtil.date.convTime.fmDate(seleData.pays_date) : fwUtil.date.getsText.date(),
                paysKwon: seleData.pays_kwon,
                asacMemo: seleData.asac_memo, 
            };
            await fwUtil.apis.submit(api, postData, handleSearch);
        } else {
            fwUtil.aler.toast.d1Erro();
        }


        gridRef.current.instance.endCustomLoading();
        handleSearch();
        deleteAlertHide();
    }, [handleSearch, deleteAlertHide]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'selc', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: handleSearch, items: dateGubuList },
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [dateGubu, dateGubuChange, manaNumb, manaNumbChange, clieName, itemCode, itemName, itemCodeChange, itemNameChange, clieNameChange, handleSearch]);

    // 등록 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }            
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);  

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                toolbar={{ default: true, items: toolbarItems }}              
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}   
                exp={{ disabled: authList.auth_dnlo === 0 }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호", groupIndex: 0, groupRender: accoGroupRender })}
                {CommCol({ dataField: "comp_seri", caption: "순번" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "part_kwon", caption: "부품비" })}
                {DateCol({ dataField: "bill_date", caption: "청구일자" })}
                {DeciCol({ dataField: "bill_kwon", caption: "청구금액" })}
                {DateCol({ dataField: "pays_date", caption: "정산일자" })}
                {DateCol({ dataField: "pays_kwon", caption: "정산금액" })}
                {CommCol({ dataField: "asac_memo", caption: "비고" })}                               
            </PagingGrid>    
            <AserAccoChanUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />    
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={() => onActionClick(deleteApi)}
                message={'해당 정산을 취소하시겠습니까?'}
            />                    
        </div>
    );
};