import ProsQcisRegi from "./pros/QcisRegi";
import ProsQcisStat from "./pros/QcisStat";
import RoutQcisChan from "./rout/QcisChan";
import RoutQcisRegi from "./rout/QcisRegi";
import RoutQcisStat from "./rout/QcisStat";
import ShipQcisChan from "./ship/QcisChan";
import ShipQcisRegi from "./ship/QcisRegi";
import ShipQcisStat from "./ship/QcisStat";
import ReportQcisRegi from "./report/QcisRegi";
import ReportQcisStat from "./report/QcisStat";
import ProsQcisChan from "./pros/QcisChan";

const catbPros = ({ acce, gubu, cata }) => {
    const catb = "pros";
    const comp = {
        qcisStat: () => (<ProsQcisStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="E11" mainTitle="검사" mainRole="현황" />),
        qcisRegi: () => (<ProsQcisRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="E12" mainTitle="검사" mainRole="등록" />),
        qcisChan: () => (<ProsQcisChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="E13" mainTitle="검사" mainRole="변경" />),
    };

    return comp;
};

const catbRout = ({ acce, gubu, cata }) => {
    const catb = "rout";
    const comp = {
        qcisStat: () => (<RoutQcisStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="E21" mainTitle="검사" mainRole="현황" subsTitle="검사" subsRole="상세" />),
        qcisRegi: () => (<RoutQcisRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="E22" mainTitle="검사" mainRole="등록" />),
        qcisChan: () => (<RoutQcisChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="E23" mainTitle="검사" mainRole="변경" subsTitle="검사" subsRole="상세" />),
    };

    return comp;
};

const catbShip = ({ acce, gubu, cata }) => {
    const catb = "ship";
    const comp = {
        qcisStat: () => (<ShipQcisStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="E31" mainTitle="검사" mainRole="현황" subsTitle="검사" subsRole="상세" />),
        qcisRegi: () => (<ShipQcisRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="E32" mainTitle="검사" mainRole="등록" />),
        qcisChan: () => (<ShipQcisChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="E33" mainTitle="검사" mainRole="변경" subsTitle="검사" subsRole="상세" />),
    };

    return comp;
};

const catbReport = ({ acce, gubu, cata }) => {
    const catb = "report";
    const comp = {
        qcisStat: () => (<ReportQcisStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="E41" mainTitle="성적서" mainRole="현황" />),
        qcisRegi: () => (<ReportQcisRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="E42" mainTitle="성적서" mainRole="등록" />),
    };

    return comp;
};

export const cataQci = ({ acce, gubu }) => {
    const cata = "qci"
    const comp = {
        catbPros: catbPros({ acce: acce, gubu: gubu, cata: cata }),
        catbRout: catbRout({ acce: acce, gubu: gubu, cata: cata }),
        catbShip: catbShip({ acce: acce, gubu: gubu, cata: cata }),
        catbReport: catbReport({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}