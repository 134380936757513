import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';
import { FormBool, FormGrid, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-item';

const defaultData = {
    work_numb: '',
    item_code: '',
    rout_numb: '',
    rout_name: '',
    mach_name: '',
    rout_desc: '',
    work_qtys: 0.00,
    insp_code: '',
    work_gubu: '',
    work_name: '',
    last_rout: false,
    rout_memo: '',    
};

const setWork = set.mes.mfg.work;

export function WorkRoutRegiInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const routGridRef = useRef(null);
    const [routData, setRoutData] = useState([]);
    const routGridUpdate = useCallback((e) => setRoutData(e), []);
    const routKeyExpr = 'rout_numb';
    // 그리드 팝업
    const [routPopupVisible, setRoutPopupVisible] = useState(false);
    const routPopupHiding = useCallback(() => setRoutPopupVisible(false), []);
    // 공정구분
    const [workGubuList, setWorkGubuList] = useState([]);
    const workGubuListChange = useCallback((e) => setWorkGubuList(e), []);    
    // 팝업 조회
    const routHandleSearch = useCallback(async () => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        const sendData = { itemCode: seleData?.item_code };
        const res = await setWork.rout.info(sendData);
        routGridUpdate(res);
    }, [routGridUpdate, mainGrid]);

    const fetchData = useCallback(async () => {
        try {
            const [workGubuResult] = await Promise.all([setWork.rout.gubu()]);
            workGubuListChange(workGubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [workGubuListChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                work_numb: seleData.work_numb || defaultData.work_numb,
                item_code: seleData.item_code || defaultData.item_code,
                rout_numb: seleData.rout_numb || defaultData.rout_numb,
                rout_name: seleData.rout_name || defaultData.rout_name,
                mach_name: seleData.mach_name || defaultData.mach_name,
                rout_desc: seleData.rout_desc || defaultData.rout_desc,
                work_qtys: seleData.work_qtys || defaultData.work_qtys,
                insp_code: seleData.insp_code || defaultData.insp_code,
                work_gubu: seleData.work_gubu || defaultData.work_gubu,
                work_name: seleData.work_name || defaultData.work_name,
                last_rout: seleData.last_rout || defaultData.last_rout,
                rout_memo: seleData.rout_memo || defaultData.rout_memo,                
            };
            
            dataUpdate(resData);
        }
    }, [fetchData, hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || defaultData.work_numb,
            itemCode: data.item_code || defaultData.item_code,
            routNumb: data.rout_numb || defaultData.rout_numb,
            routName: data.rout_name || defaultData.rout_name,
            machName: data.mach_name || defaultData.mach_name,
            routDesc: data.rout_desc || defaultData.rout_desc,
            workQtys: data.work_qtys || defaultData.work_qtys,
            inspCode: data.insp_code || defaultData.insp_code,
            workGubu: data.work_gubu || defaultData.work_gubu,
            workName: data.work_name || defaultData.work_name,
            lastRout: data.last_rout || defaultData.last_rout,
            routMemo: data.rout_memo || defaultData.rout_memo,            
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const routPopupOnShowing = useCallback(() => {
        routHandleSearch();
    }, [routHandleSearch]);

    // 팝업 더블 클릭
    const routOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, rout_numb: e.data.rout_numb, rout_name: e.data.rout_name, work_gubu: e.data.work_gubu, last_rout: e.data.last_rout });
        routPopupHiding();
    }, [data, dataUpdate, routPopupHiding]);

    const routGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'공정'}
                role={'목록'}
                gridDataSource={routData}
                gridRef={routGridRef}
                mainKey={routKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={routOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {CommCol({ dataField: "work_gubu", caption: "작업구분" })}
                {BoolCol({ dataField: "last_rout", caption: "완료공정" })}               
            </ScrollGrid>
        )
    }, [routData, routGridRef, routKeyExpr, routOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'공정 선택'}
                visible={routPopupVisible}
                shown={routPopupOnShowing}
                hiding={routPopupHiding}
            >
                {routGrid}
            </PopupGrid>
            <PopupForm
                title={'작업지시 공정 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.work_numb, onValueChange: updateField("work_numb"), label: "작업지시번호", disabled: true, required: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField("item_code"), label: "제품번호", disabled: true, required: true })}
                    {FormGrid({ value: data.rout_numb, onClick: () => setRoutPopupVisible(true), label: "공정번호", required: true })}
                    {FormText({ value: data.rout_name, onValueChange: updateField("rout_name"), label: "공정명", disabled: true })}
                    {FormSelc({ value: data.work_gubu, onValueChange: updateField("work_gubu"), label: "작업구분", items: workGubuList })}
                    {FormBool({ value: data.last_rout, onValueChange: updateField("last_rout"), label: "완료공정여부", disabled: true })}
                    {FormText({ value: data.mach_name, onValueChange: updateField("mach_name"), label: "장비명" })}
                    {FormText({ value: data.rout_desc, onValueChange: updateField("rout_desc"), label: "공정내용" })}
                    {FormNumb({ value: data.work_qtys, onValueChange: updateField("work_qtys"), label: "작업수량", length: [12, 2] })}
                    {FormText({ value: data.insp_code, onValueChange: updateField("insp_code"), label: "검사코드", maxLength: 10 })}
                    {FormText({ value: data.work_name, onValueChange: updateField("work_name"), label: "작업장" })}
                </GroupItem>
                {FormMemo({ value: data.rout_memo, onValueChange: updateField("rout_memo"), label: "비고" })}
            </PopupForm>
        </>
    );
}
