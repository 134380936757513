import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    main_gubu: '', 
    main_code: '', 
    main_yesn: true, 
    main_name: '', 
    main_memo: '',
};

export function CodeInfoStatMainUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { mainCode: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                main_gubu: res.main_gubu || '',
                main_code: res.main_code || '',
                main_yesn: res.main_yesn || false,
                main_name: res.main_name || '',
                main_memo: res.main_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainCode: data.main_code || '',
            mainYesn: data.main_yesn || false,
            mainName: data.main_name || '',
            mainMemo: data.main_memo || '',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
            <PopupForm
                title={'관리코드 수정'}       
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}  
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormText({ value: data.main_gubu, onValueChange: updateField('main_gubu'), label: '업무분류', disabled: true, required: true })}
                    </GroupItem>
                    {FormText({ value: data.main_code, onValueChange: updateField('main_code'), label: '관리코드', disabled: true })}  
                    {FormBool({ value: data.main_yesn, onValueChange: updateField('main_yesn'), label: '사용여부' })}  
                </GroupItem>
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', required: true })}
                {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
            </PopupForm>            
    );
}