import React from 'react';
import { SelectBox } from 'devextreme-react';
import { Button, Column } from 'devextreme-react/data-grid';
import { fwUtil } from '../../../util';
import { coluUtil } from './grid-column-util';

const defaultAlignment = 'left';

const CountCol = (props) => {
    const { groupIndex } = props;

    const rowIndexTemplate = (cellInfo) => coluUtil.template.count(cellInfo);

    return (
        <Column
            fixed        
            width={60}
            minWidth={60}            
            caption={'순번'}
            dataType={'string'}
            alignment={'center'}
            groupIndex={groupIndex}
            allowSorting={false}
            allowResizing={false}            
            allowHeaderFiltering={false}
            cellRender={(cell) => rowIndexTemplate(cell)}
        />
    );
};

const CommCol = (props) => {
    const { width, maxLength, groupIndex, groupRender } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'string'}
            width={width}
            alignment={defaultAlignment}
            groupIndex={groupIndex}
            groupCellRender={groupRender}
            editorOptions={{ maxLength: maxLength }}            
        />
    );
};

const TimeCol = (props) => {
    const { width } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'date'}
            format={'HH:mm:ss'}
            alignment={defaultAlignment}
            width={width}
        />
    );
};

const MontCol = (props) => {
    const { width } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'date'}
            format={'yyyy-MM'}            
            alignment={defaultAlignment}
            width={width}
        />
    );
};

const DateCol = (props) => {
    const { width } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'date'}
            format={'yyyy-MM-dd'}            
            alignment={defaultAlignment}
            width={width}
        />
    );
};

const DtmeCol = (props) => {
    const { width } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'date'}
            format={'yyyy-MM-dd HH:mm:ss'}
            alignment={defaultAlignment}
            width={width}
        />
    );
};


const NumbCol = (props) => {
    const { dataField, width, maxLength, suffix, calcValue } = props;

    const calculateCellValue = (data) => {
        let fielddata = data && data[dataField];
        return fwUtil.conv.check.nub(suffix) ? fielddata : fielddata + ' ' + suffix;
    };

    const widthValue = fwUtil.conv.tern('auto', width);

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'number'}
            alignment={'right'}
            width={widthValue}
            editorOptions={{ max: Math.pow(10, maxLength) - 1, min: 0 }}
            calculateDisplayValue={!calcValue && calculateCellValue}
            calculateCellValue={calcValue && calcValue}
        />
    );
};

const DeciCol = (props) => {
    const { dataField, width, suffix, customCellValue, maxLength, excel } = props;

    const calculateCellValue = (data) => {
        let fielddata = data && data[dataField];
        if (customCellValue) {
            fielddata = customCellValue(data);
        }
        let toNumber = '0.0';
        if (typeof fielddata === 'number' && !isNaN(fielddata)) {
            toNumber = excel ? fielddata : fielddata.toLocaleString();
        } else {
            let parsedFloat = parseFloat(fielddata);
            if (!isNaN(parsedFloat)) {
                toNumber = excel ? parsedFloat : parsedFloat.toLocaleString();
            }
        }
        return fwUtil.conv.check.nub(suffix) ? toNumber : toNumber + " " + suffix;
    };

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'number'}
            alignment={'right'}
            width={width}
            editorOptions={{ max: Math.pow(10, maxLength) - 1, min: 0 }}
            calculateDisplayValue={calculateCellValue}
        />
    );
};

const BoolCol = (props) => {
    const { dataField, width, falseText, trueText, headerCellRender } = props;

    const widthValue = fwUtil.conv.tern(120, width);
    const falseTextValue = fwUtil.conv.tern('false', falseText);
    const trueTextValue = fwUtil.conv.tern('true', trueText);

    const calculateCellValue = (data) => {
        if (data && data[dataField]) {
            if (data[dataField] === 1 || data[dataField] === true) {
                return true;
            }
        }
        return false;
    };

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'boolean'}
            alignment={'center'}
            width={widthValue}
            minWidth={widthValue}
            calculateCellValue={calculateCellValue}
            falseText={falseTextValue}
            trueText={trueTextValue}
            headerCellRender={headerCellRender}
        />
    );
};

const SelcCol = (props) => {
    const { width, placeholder, items } = props;

    const placeholderMessage = fwUtil.conv.tern(placeholder, '선택해주세요');

    const selcEditorRender = (cell) => {
        const onValueChanged = (e) => cell.setValue(e.value);
        return (
            <SelectBox
                {...cell.column.lookup}            
                defaultValue={cell.value}
                onValueChanged={onValueChanged}
                placeholder={placeholderMessage}
                items={items}
            />
        );
    };

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'string'}
            width={width}
            alignment={defaultAlignment}
            editCellRender={selcEditorRender}
        />
    );
};

const GridCol = (props) => {
    const { width, placeholder, onClick } = props;

    const placeholderMessage = fwUtil.conv.tern(placeholder, '선택해주세요');

    const selcEditorRender = (cell) => {

        return (
            <div className='fw-grid-btns-col'>
                <div className='fw-grid-value'>{cell.value || placeholderMessage}</div>
                <i className='dx-icon-search' onClick={() => onClick(cell)}></i>
            </div>
        );
    };

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            dataType={'string'}
            width={width}
            alignment={defaultAlignment}
            cellRender={selcEditorRender}
        />
    );
};

const GridBtnsCol = (props) => {
    const { width, onClick, allowDelete } = props;

    return (
        <Column
            {...coluUtil.opts.comm(props)}
            type='buttons'
            width={width}
        >
            {allowDelete && <Button name='delete' />}
            <Button
                hint='제품검색'
                icon='search'
                onClick={onClick}
            />
        </Column>
    );
};

export {
    CountCol,
    CommCol,
    TimeCol,
    MontCol,
    DateCol,
    DtmeCol,
    NumbCol,
    DeciCol,
    BoolCol,
    SelcCol,
    GridCol,
    GridBtnsCol,
}