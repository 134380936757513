import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    clie_code: '',
    clie_name: '',
    ship_date: fwUtil.date.getsText.date(),
    ship_memo: '',
};

const shipDataSource = fwUtil.grid.get.dataSource();

export function ShipInfoRegiInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 수주 그리드
    const shipRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            shipDataSource.store().insert({ init: true }).then(() => shipDataSource.reload());
            const shipSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const ship of shipSelected) {
                const newData = {
                    reqe_numb: ship.reqe_numb || '',
                    reqe_seri: ship.reqe_seri || '',
                    stoc_loca: ship.stoc_loca || '',
                    orde_numb: ship.orde_numb || '',
                    clie_code: ship.clie_code || '',
                    clie_name: ship.clie_name || '',
                    item_code: ship.item_code || '',
                    item_name: ship.item_name || '',
                    item_spec: ship.item_spec || '',
                    deli_date: ship.deli_date || null,
                    orde_qtys: ship.orde_qtys || 0.000,
                    deli_qtys: ship.deli_qtys || 0.000,
                    stoc_qtys: ship.stoc_qtys || 0.000,
                    ship_qtys: ship.ship_qtys || 0.000,
                    ship_pric: ship.ship_pric || ship.item_pric || 0.00,
                    taxs_kwon: ship.taxs_kwon || 0.00,
                    ship_remk: ship.ship_remk || ''
                };
                shipDataSource.store().insert(newData);
            }
            
            shipDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const shipData = shipRef?.current?.instance?.getDataSource()?.items();
        const postShipData = shipData.map((item) => ({
            shipDate: data.ship_date || fwUtil.date.getsText.date(),
            shipMemo: data.ship_memo || '',
            ordeNumb: item.orde_numb || '',
            reqeNumb: item.reqe_numb || '',
            reqeSeri: item.reqe_seri || '',
            stocLoca: item.stoc_loca || '',            
            clieCode: item.clie_code || '',
            clieName: item.clie_name || '',
            itemCode: item.item_code || '',
            itemName: item.item_name || '',
            itemSpec: item.item_spec || '',
            shipQtys: item.ship_qtys || 0.000,
            shipPric: item.ship_pric || 0.00,
            shipKwon: item.ship_kwon || 0.00,
            taxsKwon: item.taxs_kwon || 0.00,
            shipRemk: item.ship_remk || '',
        }));        
        const postData = {
            shipNumb: '',
            setaMaps: postShipData || [],
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        mainGrid?.current?.instance?.deselectAll();
        alertHide();
        hiding();
    }, [data, insertApi, refresh, hiding, alertHide, shipRef, mainGrid]);    

    const calcKwon = (e) => {
        const shipPric = parseFloat(e.ship_pric) || 0;
        const shipQtys = parseFloat(e.ship_qtys) || 0;
        const total = shipPric * shipQtys;
        
        return new Intl.NumberFormat('ko-KR').format(total);
    };

    return (
        <>           
        <PopupForm
            title={'출하 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'수주목록'}
                        gridRef={shipRef} 
                        gridDataSource={shipDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "clie_name", caption: "고객명", allowEditing: false })}
                        {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                        {CommCol({ dataField: "item_spec", caption: "규격", allowEditing: false })}
                        {DateCol({ dataField: "deli_date", caption: "납기일자", allowEditing: false })}
                        {DeciCol({ dataField: "ship_qtys", caption: "출하수량", check: true })}
                        {DeciCol({ dataField: "ship_pric", caption: "출하단가", check: true })}
                        {NumbCol({ dataField: "ship_kwon", caption: "출하금액", allowEditing: false, calcValue: (e) => calcKwon(e) })}
                        {DeciCol({ dataField: "taxs_kwon", caption: "세액", check: true })}
                        {CommCol({ dataField: "ship_remk", caption: "특기사항", check: true })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.ship_date, onValueChange: updateField('ship_date'), label: '납품일자' })}
                {FormText({ value: data.ship_memo, onValueChange: updateField('ship_memo'), label: '비고' })}
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 건들을 출하 처리 하시겠습니까?'}
        />  
        </>
    );
}