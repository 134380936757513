import React from "react";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashSubsItemCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"산출근거"}
            addClass={'subs'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={"kpis_gubu"}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "kpis_gubu", caption: "구분", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo01", caption: "1월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo02", caption: "2월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo03", caption: "3월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo04", caption: "4월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo05", caption: "5월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo06", caption: "6월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo07", caption: "7월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo08", caption: "8월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo09", caption: "9월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo10", caption: "10월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo11", caption: "11월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_mo12", caption: "12월", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_tota", caption: "합계", allowFiltering: false, allowSorting: false, allowResizing: false })}
            </ScrollGrid>
        </DashCard>
    )
}