import { fwUtil } from "../../focuswin/script/util";
import { apsGubu } from "../set";

const getItemList = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/plan/year/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemGubu = async () => {
    try {
        const api = `privat${apsGubu}/set/plan/year/register/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getYplaItem = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/plan/year/register/yplaitem/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getYplaRout = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/plan/year/rout/register/itemrout/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getYplaEqui = async (sendData) => {
    try {
        const api = `privat${apsGubu}/set/com/equ/equi/register/equicode/list`;
        const data = await fwUtil.apis.search(api, sendData);
    
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const pln = {
    ypln: {
        item: {
            info: getItemList,
            gubu: getItemGubu,
            ypla: getYplaItem,
        },
        rout: getYplaRout,
        equi: getYplaEqui,
    },
};