// PlanInfoRegi - 생산계획 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { PlanInfoRegiMainInsert } from './InfoRegi-main-insert';
import { PlanInfoRegiMainUpdate } from './InfoRegi-main-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PlanInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/search`; // 메인 조회 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/insert`; // 메인 등록 api
    const mainUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/update`; // 메인 수정 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/delete`; // 메인 삭제 api 
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "sing";
    const mainKey = ['plan_numb'];               
    const mainDeleKey = (data) => { return { planNumb: data.plan_numb } };
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), [setMainGridData]);        
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const [duraYesn, setDuraYesn] = useState(true);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const [planNumb, setPlanNumb] = useState("");
    const planNumbChange = useCallback((e) => { setPlanNumb(e) }, []);
    const [itemCode, setItemCode] = useState("");
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const [itemName, setItemName] = useState("");    
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, planNumb: planNumb, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef);
    }, [dateValue, duraYesn, planNumb, itemCode, itemName, mainGridDataUpdate, mainGridRef, mainSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
    }, []);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(mainDeleteApi, mainGridRef , mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
    }, [mainGridRef, mainHandleSearch, mainDeleteAlertHide, mainDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '생산계획번호', value: planNumb, valueChange: planNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [planNumb, planNumbChange, itemCode, itemCodeChange, itemName, itemNameChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('생산계획을 선택 후 시도해주세요.');
            return;
        }

        handleMainAction();
    }, [mainGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                ins={{ onClick: () => setMainInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "plan_numb", caption: "생산계획번호" })}
                {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {NumbCol({ dataField: "plan_qtys", caption: "계획수량" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {DateCol({ dataField: "work_date", caption: "생산예정일" })}
                {CommCol({ dataField: "plan_memo", caption: "비    고" })}     
            </MainGrid>
            <PlanInfoRegiMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <PlanInfoRegiMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 계획을 삭제하시겠습니까?'}
            />                     
        </div>
    );
};