import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    allo_cont: '',
    allo_yesn: false,
    allo_path: '',
};

export function UrisStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                allo_cont: seleData.allo_cont || '',
                allo_yesn: seleData.allo_yesn === 1 ? true : false,
                allo_path: seleData.allo_path || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            alloCont: data.allo_cont || 0,
            alloYesn: data.allo_yesn || false,
            alloPath: data.allo_path || ''
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'URI 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.allo_cont, onValueChange: updateField('allo_cont'), label: '일련번호', disabled: true })}                
                {FormBool({ value: data.allo_yesn, onValueChange: updateField('allo_yesn'), label: '사용여부' })}                            
            </GroupItem>
            {FormText({ value: data.allo_path, onValueChange: updateField('allo_path'), label: '허용 URI', required: true })}    
        </PopupForm>        
    );
}