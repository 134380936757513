import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    main_numb: '',
    main_seri: '',
    equi_numb: '',
    equi_name: '',
    brok_date: null,
    brok_desc: '',
    next_date: null,
    self_main: false,
    clie_code: '',
    clie_name: '',
    main_date: fwUtil.date.getsText.date(),
    main_desc: '',
    main_kwon: 0.00,
    main_memo: '',
}

const setMain = set.mes.equ.main;

export function MaintInfoRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';    
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await setMain.clie(sendData);
        clieGridUpdate(res);
    }, [clieGridUpdate]);    

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);    

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                main_numb: seleData.main_numb || '',
                main_seri: seleData.main_seri || '',
                equi_numb: seleData.equi_numb || '',
                equi_name: seleData.equi_name || '',
                brok_date: seleData.brok_date || null,
                brok_desc: seleData.brok_desc || '',
                next_date: seleData.next_date || null,
                self_main: seleData.self_main || false,
                clie_code: seleData.clie_code || '',
                clie_name: seleData.clie_name || '',
                main_date: seleData.main_date || fwUtil.date.getsText.date(),
                main_desc: seleData.main_desc || '',
                main_kwon: seleData.main_kwon || 0.00,
                main_memo: seleData.main_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        if (field === 'self_main') {
            if (value) {
                dataUpdate({ ...data, [field]: value, clie_code: '10000', clie_name: '아쿠아시스템' });
            } else {
                dataUpdate({ ...data, [field]: value });
            }
            return;
        }
        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainNumb: data.main_numb || '',
            mainSeri: data.main_seri || '',
            equiNumb: data.equi_numb || '',
            brokDate: data.brok_date ? fwUtil.date.convTime.fmDate(data.brok_date) : null,
            brokDesc: data.brok_desc || '',
            nextDate: data.next_date ? fwUtil.date.convTime.fmDate(data.next_date) : null,
            selfMain: data.self_main || false,
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            mainDate: data.main_date ? fwUtil.date.convTime.fmDate(data.main_date) : null,
            mainDesc: data.main_desc || '',
            mainKwon: data.main_kwon || 0.00,
            mainMemo: data.main_memo || '',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupGrid
            title={'정비업체 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'정비업체'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "업체코드" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'고장정비 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.main_numb, onValueChange: updateField('main_numb'), label: '관리번호', disabled: true })}                
                {FormText({ value: data.main_seri, onValueChange: updateField('main_seri'), label: '순번', disabled: true })}                
                {FormText({ value: data.equi_numb, onValueChange: updateField('equi_numb'), label: '설비번호', required: true, disabled: true })}
                {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', disabled: true })}
                {FormDate({ value: data.brok_date, onValueChange: updateField('brok_date'), label: '고장일자', required: true })}
                {FormDate({ value: data.next_date, onValueChange: updateField('next_date'), label: '차기점검일' })}
                <GroupItem colSpan={isXSmall ? 1 : 2}>
                    {FormMemo({ value: data.brok_desc, onValueChange: updateField('brok_desc'), label: '고장내역', required: true })}
                </GroupItem>
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '정비업체코드', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '정비업체명', readOnly: true })}
                {FormDate({ value: data.main_date, onValueChange: updateField('main_date'), label: '정비일자', required: true })}
                {FormBool({ value: data.self_main, onValueChange: updateField('self_main'), label: '자체정비' })}
                <GroupItem colSpan={isXSmall ? 1 : 2}>
                    {FormMemo({ value: data.main_desc, onValueChange: updateField('main_desc'), label: '정비내역', required: true })}
                </GroupItem>                                
                {FormNumb({ value: data.main_kwon, onValueChange: updateField('main_kwon'), label: '수리/부품비', length: [12, 2] })}
            </GroupItem>
            {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}