import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashMainCard } from "./Dash-main";
import { DashOperCard } from "./Dash-oper";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import "./dash.scss";

export default function EquiInfoDash(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/maint/search`;  // 상단 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/oper/search`;   // 하단 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [mainDashData, setMainDashData] = useState([]);
    const [subsDashData, setSUbsDashData] = useState([]);
    const mainDashDataUpdate = useCallback((e) => setMainDashData(e), []);
    const subsDashDataUpdate = useCallback((e) => setSUbsDashData(e), []);
    // 검색 조건
    const [equiMont, setEquiMont] = useState(fwUtil.date.getsText.month());  
    const [equiName, setEquiName] = useState("");
    const equiMontChange = useCallback((e) => { setEquiMont(e) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { equiMont: equiMont, equiName: equiName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainDashDataUpdate);
        await fwUtil.apis.search(subsSearchApi, sendData, subsDashDataUpdate);
        setPageLoading(false);
    }, [equiMont, equiName, mainSearchApi, subsSearchApi, mainDashDataUpdate, subsDashDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'year', locate: 'before', caption: '생산년월', value: equiMont, valueChange: equiMontChange, search: handleSearch },        
        { type: 'text', locate: 'before', caption: '제품명', value: equiName, valueChange: equiNameChange, search: handleSearch },
    ], [equiMont, equiName, equiMontChange, equiNameChange, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col equi">
            <FwAppLoader loading={loading} />                  
            <CommDash
                title={title}
                dashRef={dashRef}                
                search={handleSearch}                
                exp={{ fileName: "설비현황 대쉬보드", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <DashMainCard loading={pageLoading} data={mainDashData} />
                <DashOperCard loading={pageLoading} data={subsDashData} />
            </CommDash>
        </div>
    )
}