export const bankList = [
    "한국은행",
    "KB국민은행",
    "우리은행",
    "SC제일은행",
    "한국씨티은행",
    "하나은행",
    "신한은행",
    "케이뱅크",
    "카카오뱅크",
    "토스뱅크",
    "한국산업은행",
    "중소기업은행",
    "한국수출입은행",
    "수협은행",
    "NH농협은행",
    "대구은행",
    "부산은행",
    "경남은행",
    "광주은행",
    "전북은행",
    "제주은행"
];