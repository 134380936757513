import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommAxis } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

export function DashOperCard(props) {
    const { loading, data } = props;
    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
        };
    };

    return (
        <DashCard
            title={"설비별 가동 현황"}
            addClass={'oper'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommAxis({ name: "elec", caption: "전력량(kWh)", position: "left", start: 0 })}
                {BarsSeri({ valueField: "usag_elec", caption: "사용전력량", color: "#4EACFF", args: "main_numb", axis: "elec" })}
            </CommChart>
        </DashCard>
    )
}