// aps
import { com as apsCom } from './aps/set-com';
import { pln as apsPln } from './aps/set-pln';

// mes
import { biz as mesBiz } from './mes/set-biz';
import { com as mesCom } from './mes/set-com';
import { tec as mesTec } from './mes/set-tec';
import { equ as mesEqu } from './mes/set-equ';
import { mat as mesMat } from './mes/set-mat';
import { mfg as mesMfg } from './mes/set-mfg';
import { pro as mesPro } from './mes/set-pro';
import { qci as mesQci } from './mes/set-qci';
import { sal as mesSal } from './mes/set-sal';
import { sys as mesSys } from './mes/set-sys';

export const mesGubu = '/mes';
export const apsGubu = '/aps';

export const set = {
    aps: {
        com: apsCom,
        pln: apsPln,
    },
    mes: {
        biz: mesBiz,
        com: mesCom,
        tec: mesTec,
        equ: mesEqu,
        mat: mesMat,
        mfg: mesMfg,
        pro: mesPro,
        qci: mesQci,
        sal: mesSal,
        sys: mesSys,
    }
}