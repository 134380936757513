// PproWareNList - 구매 미입고 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PproWareNist(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/notin/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'ppro_keys'; 
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [prosNumb, setProsNumb] = useState("");
    const [matlCode, setMatlCode] = useState("");
    const [matlName, setMatlName] = useState("");
    const [clieName, setClieName] = useState("");
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { matlCode: matlCode, prosNumb: prosNumb, matlName: matlName, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [matlCode, prosNumb, matlName, clieName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '발주번호', value: prosNumb, valueChange: prosNumbChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재번호', value: matlCode, valueChange: matlCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [prosNumb, prosNumbChange, matlCode, matlCodeChange, matlName, matlNameChange, clieName, clieNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "ppro_keys", caption: "관리번호" })} 
                {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                {CommCol({ dataField: "matl_code", caption: "자재번호" })} 
                {CommCol({ dataField: "matl_name", caption: "자재명" })} 
                {DateCol({ dataField: "ppro_date", caption: "발주일자" })} 
                {CommCol({ dataField: "pdel_date", caption: "납품요구일" })} 
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })} 
                {DeciCol({ dataField: "rece_qtys", caption: "입고수량" })} 
                {DeciCol({ dataField: "nwar_qtys", caption: "미입고량", check: true })} 
                {CommCol({ dataField: "damd_name", caption: "발주담당" })} 
                {CommCol({ dataField: "reqe_dept", caption: "수요처" })} 
                {CommCol({ dataField: "ppro_remk", caption: "참고사항" })} 
                {CommCol({ dataField: "ppro_stat", caption: "진행상태" })} 
            </PagingGrid>        
        </div>
    );
};