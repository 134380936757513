// AserCompStat - A/S 운영/점검 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function AserCompStat(props) {
    const {
        acce, gubu, cata, catb,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/detail/search`;  // 서브 조회 api  
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = ['mana_numb', 'comp_seri'];
    const subsKey = ['mana_numb', 'comp_seri', 'matl_code'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []); 
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []); 
    const itemNameChange = useCallback((e) => { setItemName(e) }, []); 
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, manaNumb: manaNumb, clieName: clieName, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, manaNumb, clieName, itemCode, itemName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { manaNumb: key.mana_numb, compSeri: key.comp_seri };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        } else {
            const keyData = fwUtil.grid.get.sKey(mainGridRef);
            const sendData = { manaNumb: keyData.mana_numb, compSeri: keyData.comp_seri };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList);
    }, [authCheck, setLoading, setAuthList]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: mainHandleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [manaNumb, manaNumbChange, clieName, itemCode, itemName, itemCodeChange, itemNameChange, clieNameChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호", groupIndex: 0 })}
                {CommCol({ dataField: "comp_seri", caption: "점검순번" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DateCol({ dataField: "rece_date", caption: "접수일자" })}
                {DateCol({ dataField: "insp_date", caption: "점검일자" })}
                {CommCol({ dataField: "insp_name", caption: "점검자" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "part_kwon", caption: "부품비" })}
                {BoolCol({ dataField: "pays_yesn", caption: "정산여부" })}
                {CommCol({ dataField: "comp_memo", caption: "비고" })}                                      
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "matl_code", caption: "부품코드" })}
                {CommCol({ dataField: "matl_name", caption: "부품명" })}
                {DeciCol({ dataField: "matl_qtys", caption: "수량" })}
                {DeciCol({ dataField: "matl_upri", caption: "단가" })}
                {DeciCol({ dataField: "matl_kwon", caption: "금액" })}
                {CommCol({ dataField: "aspa_memo", caption: "비고" })}
            </SubsGrid>         
        </div>
    );
};