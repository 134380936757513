import { useCallback, useEffect, useState } from "react";
import { Button, ButtonGroup, Popup } from "devextreme-react"
import { fwUtil } from "../../util";
import './custom-drawing.scss';

const DrawingButton = (props) => {
    const { width, saveCanvas, disabled } = props;
    const [drawingPopupVisible, setDrawingPopupVisible] = useState(false);
    const drawingPopupHide = useCallback(() => setDrawingPopupVisible(false), [setDrawingPopupVisible]);

    const handleDrawingButtonClick = () => {
        if (isCanvasSupported()) {
            setDrawingPopupVisible(true);
        } else {
            fwUtil.aler.toast.info("캔버스를 지원하지 않는 브라우저입니다!");
        }
    };

    const isCanvasSupported = () => {
        const elem = document.createElement('canvas');
        return !!(elem.getContext && elem.getContext('2d'));
    };

    return (
        <div className="fw-drawing-button-container">
            <Button
                text="그리기"
                icon="palette"
                onClick={handleDrawingButtonClick}
                disabled={disabled}
                width={width}
            />        
            <DrawingPopup
                drawingPopupVisible={drawingPopupVisible}
                drawingPopupHide={drawingPopupHide}
                saveCanvas={saveCanvas}
            />
        </div>
    )
};

const DrawingPopup = (props) => {
    const { drawingPopupVisible, drawingPopupHide, saveCanvas } = props;
    const [selectedColor, setSelectedColor] = useState('#000000');
    const [drawingCanvas, setDrawingCanvas] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    // 도구 전환
    const [tool, setTool] = useState('pen');
    const toolChange = useCallback((e) => {setTool(e.itemData.role)}, []);

    useEffect(() => {
        if (tool === 'pen' || tool === 'brush') {
            setSelectedColor('#000000');
        } else if (tool === 'eraser') {
            setSelectedColor('#FFFFFF');
        }
    }, [tool]);

    const handleHideDrawingPopup = () => {
        setDrawingCanvas(null);
        drawingPopupHide();
    }

    // 캔버스 드로잉
    const handleCanvasMouseDown = (e) => {
        setIsDrawing(true);
        const canvas = drawingCanvas;
        const ctx = canvas.getContext('2d');
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        ctx.beginPath();
        ctx.strokeStyle = selectedColor;
        ctx.lineWidth = tool === 'pen' ? 2 : 4;
        ctx.globalCompositeOperation = tool === 'eraser' ? 'destination-out' : 'source-over';
        ctx.moveTo(x, y);
    };
    const handleCanvasMouseMove = (e) => {
        if (isDrawing) {
            const canvas = drawingCanvas;
            const ctx = canvas.getContext('2d');
            const rect = canvas.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            ctx.lineTo(x, y);
            ctx.stroke();
        }
    };
    const handleCanvasMouseUp = () => {
        setIsDrawing(false);
    };

    // 저장
    const handleSave = (file) => {
        saveCanvas(file);
        handleHideDrawingPopup();
    };

    return (
            <Popup
                visible={drawingPopupVisible}
                onHiding={handleHideDrawingPopup}
                hideOnOutsideClick={true}
                showCloseButton={true}
                title={`그리기`}
                width={'auto'}
                height={'auto'}
                enableBodyScroll={false}                
            >
                <div className="fw-canvas-container fw-flex-box col">
                    <PaletteTools 
                        toolChange={toolChange} 
                        canvas={drawingCanvas} 
                        saveCanvas={handleSave}
                    />
                    <canvas
                        className="fw-canvas"
                        ref={(canvas) => setDrawingCanvas(canvas)}
                        onMouseDown={handleCanvasMouseDown}
                        onMouseMove={handleCanvasMouseMove}
                        onMouseUp={handleCanvasMouseUp}
                        width={300}
                        height={300}
                        style={{ border: '1px solid black' }}
                    />
                </div>
            </Popup>
    );
};

const PaletteTools = (props) => {
    const { canvas, toolChange, saveCanvas } = props;
    const paletteItems = [
        {
            icon: 'edit',
            role: 'pen',
            hint: '펜', 
        },
        {
            icon: 'background',
            role: 'brush',
            hint: '브러쉬', 
        },        
        {
            icon: 'clearformat',
            role: 'eraser',
            hint: '지우개', 
        },
    ];
    
    const onResetBtnClick = () => {
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const onSaveBtnClick = () => {
        const dataUrl = canvas.toDataURL('image/png');
        const blob = dataURItoBlob(dataUrl);
        const fileName = 'sign-imag.png';
        const imageFile = new File([blob], fileName, { type: 'image/png' });
        saveCanvas(imageFile);
    };

    return (
        <div className="fw-palette-tools-container fw-flex-box row">
            <ButtonGroup 
                items={paletteItems}
                keyExpr={'role'}
                stylingMode="outlined"
                defaultSelectedItemKeys={['pen']}
                onItemClick={toolChange}
            />
            <div className="fw-palette-wrapper">
                <Button 
                    icon="trash"
                    hint="초기화"
                    stylingMode="outlined"
                    onClick={onResetBtnClick}
                />
                <Button 
                    icon="save"
                    hint="저장"
                    stylingMode="outlined"
                    onClick={onSaveBtnClick}
                />   
            </div>         
        </div>
    )
}

const dataURItoBlob = (dataUrl) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export {
    DrawingButton
};

