import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashItemGridCard } from "./Dash-item-grid";
import { DashClieGridCard } from "./Dash-clie-grid";
import { DashShipCharCard } from "./Dash-ship-char";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import { convertShipDate } from "./Dash-util";
import "./dash.scss";

export default function ShipInfoDash(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [dashData, setDashData] = useState([]);
    const [dateData, setDateData] = useState([]);
    const dashDataUpdate = useCallback((e) => setDashData(e), []);
    const dateDataUpdate = useCallback((e) => setDateData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        setPageLoading(true);
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName, itemName: itemName };
        const res = await fwUtil.apis.search(searchApi, sendData);
        const dateRes = convertShipDate(res);
        dashDataUpdate(res);
        dateDataUpdate(dateRes);
        setPageLoading(false);
    }, [dateValue, duraYesn, clieName, itemName, searchApi, dashDataUpdate, dateDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
    ], [clieName, clieNameChange, itemName, itemNameChange, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col ship">
            <FwAppLoader loading={loading} /> 
            <CommDash
                title={title}
                dashRef={dashRef}                
                duraYesn={duraYesn}                
                dateValue={dateValue}
                duraYesnChange={duraYesnChange}                
                dateValueChange={dateValueChange}
                search={handleSearch}
                exp={{ fileName: "납품 대쉬보드", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <div className="fw-dash-row-wrapper">
                    <DashItemGridCard loading={pageLoading} data={dashData} />
                    <DashClieGridCard loading={pageLoading} data={dashData} />
                </div>
                <DashShipCharCard loading={pageLoading} data={dateData} />
            </CommDash>
        </div>
    )
}