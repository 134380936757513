import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    bill_date: fwUtil.date.getsText.date(),
    pays_date: fwUtil.date.getsText.date(),
    asac_memo: '',
};

const accoDataSource = fwUtil.grid.get.dataSource();

export function AserAccoRegiInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 수주 그리드
    const accoRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.mData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            accoDataSource.store().insert({ init: true }).then(() => accoDataSource.reload());
            for (const acco of seleData) {
                const newData = {
                    mana_numb: acco.mana_numb || '',
                    comp_seri: acco.comp_seri || '',
                    clie_code: acco.clie_code || '',
                    clie_name: acco.clie_name || '',
                    item_code: acco.item_code || '',
                    item_name: acco.item_name || '',
                    insp_name: acco.insp_name || '',
                    insp_kwon: acco.insp_kwon || 0,
                    part_kwon: acco.part_kwon || 0,
                    bill_kwon: parseFloat(acco.insp_kwon) + parseFloat(acco.part_kwon),
                    pays_kwon: parseFloat(acco.insp_kwon) + parseFloat(acco.part_kwon),                    
                };
                accoDataSource.store().insert(newData);
            }
            
            accoDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const accoData = accoRef?.current?.instance?.getDataSource()?.items();
        const postAccoData = accoData.map((item) => ({
            manaNumb: item.mana_numb || '',
            compSeri: item.comp_seri || '',
            clieCode: item.clie_code || '',
            clieName: item.clie_name || '',
            itemCode: item.item_code || '',
            itemName: item.item_name || '',
            billKwon: item.bill_kwon || 0,
            paysKwon: item.pays_kwon || 0,
            billDate: data.bill_date ? fwUtil.date.convTime.fmDate(data.bill_date) : fwUtil.date.getsText.date(),
            paysDate: data.pays_date ? fwUtil.date.convTime.fmDate(data.pays_date) : fwUtil.date.getsText.date(),
            asacMemo: data.asac_memo || '',
        }));
        const postData = {
            setaMaps: postAccoData || [],
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        mainGrid?.current?.instance?.deselectAll();
        alertHide();
        hiding();
    }, [data, insertApi, refresh, hiding, alertHide, accoRef, mainGrid]);    

    return (
        <>           
        <PopupForm
            title={'A/S정산 등록'}
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'정산 대기목록'}
                        gridRef={accoRef} 
                        gridDataSource={accoDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "clie_name", caption: "고객명", allowEditing: false })}
                        {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                        {CommCol({ dataField: "insp_name", caption: "점검자", allowEditing: false })}
                        {DeciCol({ dataField: "insp_kwon", caption: "출장비", allowEditing: false })}
                        {DeciCol({ dataField: "part_kwon", caption: "부품비", allowEditing: false })}
                        {DeciCol({ dataField: "bill_kwon", caption: "청구금액", before: true, width: 200 })}
                        {DeciCol({ dataField: "pays_kwon", caption: "정산금액", after: true, width: 200 })}
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.bill_date, onValueChange: updateField('bill_date'), label: '청구일자', required: true })}
                {FormDate({ value: data.pays_date, onValueChange: updateField('pays_date'), label: '정산일자', required: true })}
            </GroupItem>
            {FormMemo({ value: data.asac_memo, onValueChange: updateField('asac_memo'), label: '비고' })}
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 정산 처리 하시겠습니까?'}
        />  
        </>
    );
}