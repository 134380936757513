import React from 'react';
import { Item as TabItem } from 'devextreme-react/tab-panel';

const CommTabs = (props) => {
    const { title, icon, render } = props;

    return (
        <TabItem
            title={title}
            icon={icon}
            render={render}
        />
    );
};

export {
    CommTabs,
}