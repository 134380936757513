import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { fwUtil } from '../focuswin/script/util';

function AuthProvider(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [_userMenu, setMenu] = useState([]);

  useEffect(() => {
    (async function () {
      const userResult = await getUser();
      if (userResult.isOk) {
        setUser(userResult.data);
        setMenu(userResult.userMenu);
      };
      setLoading(false);
    })();
  }, []);

  const getMenu = useCallback(() => _userMenu, [_userMenu]);

  const authCheck = async (setLoading, setAuthList) => {
    const currentMenu = _userMenu.find(menu => menu.menu_path === location.pathname);

    if (currentMenu && currentMenu.auth_acce) {
      const currentAuth = {
        auth_acce: currentMenu.auth_acce || 0,
        auth_dele: currentMenu.auth_dele || 0,
        auth_dnlo: currentMenu.auth_dnlo || 0,
        auth_inse: currentMenu.auth_inse || 0,
        auth_sele: currentMenu.auth_sele || 0,
        auth_upda: currentMenu.auth_upda || 0,
        auth_uplo: currentMenu.auth_uplo || 0,     
      }
      setAuthList && setAuthList(currentAuth);
      setLoading(false);
    } else {
      navigate('/home');
      fwUtil.aler.toast.erro('잘못된 접근입니다');
      return;
    }
  };  

  // 로그인
  const signIn = useCallback(async (userIdno, passWord) => {
    const result = await sendSignInRequest(userIdno, passWord);
    if (result.isOk) {
      setUser(result.data);
      setMenu(result.userMenu);
    }
    return result;
  }, []);

  // 로그아웃
  const signOut = useCallback(async () => {
    const result = await sendSignOutRequest();
    if (result.isOk) {
      setUser(undefined);
      sessionStorage.removeItem('gubu');
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, setLoading, getMenu, authCheck }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
