import defaultUser from '../utils/default-user';
import { fwUtil } from '../focuswin/script/util';

// 로그인
export async function signIn(userIdno, passWord) {
  const loginData = {
    userIdno: userIdno,
    passWord: passWord
  };
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post('/privat/mes/sys/login/login', loginData)
    if (response.data.status === true) {
      defaultUser.userIdno = response.data.userIdno;
      defaultUser.userName = response.data.userName;
      defaultUser.deptName = response.data.deptName;
      defaultUser.posiName = response.data.posiName;
      defaultUser.pictImag = response.data.pictImag;
      const mesMenuList = response.data.userMenu;
      return {
        isOk: true, data: defaultUser, userMenu: mesMenuList
      };
    } else {
      return {
        isOk: false,
        message: "로그인에 실패했습니다"
      };
    }
  } catch (error) {
    console.error("api-signIn-error");
    return {
      isOk: false,
      message: "로그인 서버 연결에 문제가 발생했습니다."
    };
  }
};

export async function signOut() {
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post('/privat/mes/sys/login/logout')
    if (response.status === 200) {
      return {
        isOk: true
      };
    } else {
      return {
        isOk: false
      };
    }
  } catch (error) {
    console.error("api-signOut-error");
    return {
      isOk: false
    };
  }
};

// 사용자 정보 가져오기
export async function getUser() {
  const axiosInstance = fwUtil.axiosInstanceCreate();
  try {
    const response = await axiosInstance.post('/privat/mes/sys/login/check')
    if (response.data.status === true) {
      defaultUser.userIdno = response.data.userIdno;
      defaultUser.userName = response.data.userName;
      defaultUser.deptName = response.data.deptName;
      defaultUser.posiName = response.data.posiName;
      const mesMenuList = response.data.userMenu;
      return {
        isOk: true, data: defaultUser, userMenu: mesMenuList, 
      };
    } else {
      return {
        isOk: false
      };
    }
  } catch (error) {
    console.error("api-getUser-error");
    return {
      isOk: false
    };
  }
};

// 계정 생성
export async function createAccount(userIdno, passWord) {
  try {
    console.log(userIdno, passWord);

    /*
    아이디 중복 환인 API 요청 작성

    응답 형태 
      isOk(성공여부 : true of false)
      message
    */
    if (userIdno === "dupl") {
      return {
        isOk: false,
        message: "이미 사용 중인 아이디입니다. 다른 아이디를 입력해주세요",
      }
    }

    /*
    계정 생성 인증 요청 API 요청 작성

    응답 형태 
      isOk(성공여부 : true of false)
      message
    */
    return {
      isOk: true,
      message: "인증 요청에 성공했습니다."
    };
  }
  catch {
    return {
      isOk: false,
      message: "인증 요청에 오류가 발생했습니다."
    };
  }
};