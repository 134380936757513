import { fwUtil } from "../../../util";

const onToolbarPreparing = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.forEach(function (item) {
        if (item.name === "columnChooserButton" && item.options) {
            item.options.text = "열선택기"
            item.showText = "always";
            item.options.stylingMode = 'contained'
        }
        if (item.name === "exportButton" && item.options) {
            item.options.text = "다운로드"
            item.showText = "always";
            item.options.stylingMode = 'contained'
        }
    })
}

const commGridOpts = (props, type) => {
    const { title } = props;
    const fileDate = fwUtil.date.getsText.nate();
    const fileName = `${title}현황_${fileDate}`;

    return {
        className: `fw-${type}-grid fw-grid`,
        noDataText: '표시할 정보가 없습니다',        
        showBorders: true,
        showRowLines: true,        
        showColumnLines: true,    
        repaintChangesOnly: true,   
        allowColumnResizing: true,                 
        allowColumnReordering: true,
        onToolbarPreparing: onToolbarPreparing,        
        onExporting: fwUtil.data.export(fileName),
    }
}

const autoPageGridHeight = (height, gridSize) => {
    if (height) {
        return { gridHeight: height };
    }

    let contentHeight = fwUtil.height.window - fwUtil.height.header - fwUtil.height.footer;
    let gridHeight;
    let gridPageSize;
    let gridPageHeight;

    if (gridSize === "dual") {
        gridHeight = contentHeight / 2 - fwUtil.height.grid.header - fwUtil.height.grid.footer;
        gridPageSize = Math.floor(gridHeight / fwUtil.height.grid.row.body);
        gridPageHeight = fwUtil.height.grid.row.body * (gridPageSize - 1) + fwUtil.height.grid.header + fwUtil.height.grid.row.head;
    } else {
        gridHeight = contentHeight - fwUtil.height.grid.header - fwUtil.height.grid.footer;
        gridPageSize = Math.floor(gridHeight / fwUtil.height.grid.row.body);
        gridPageHeight = fwUtil.height.grid.row.body * (gridPageSize - 1) + fwUtil.height.grid.header + fwUtil.height.grid.row.head;
    }

    return { girdHeight: gridPageHeight, gridPageSize: gridPageSize }
}

const handlePageChanged = (gridRef) => { gridRef?.current?.instance?.deselectAll(); };

export const gridUtil = {
    handlePageChanged: handlePageChanged,
    opts: {
        comm: commGridOpts,
    },
    calc: {
        height: {
            page: autoPageGridHeight
        }
    }
    
}