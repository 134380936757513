// ShipInfoStat - 납품 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Summary } from 'devextreme-react/data-grid';
import { TotalCstItem, TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ShipInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/detail/search`;  // 서브 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'ship_numb';
    const subsKey = ['ship_numb', 'ship_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { shipNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객사명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, clieNameChange, mainHandleSearch]);
    
    // 총 금액 계산
    const calculateCustomSummary = useCallback((options) => {
        if (options.name === 'custom') {
            
            if (options.summaryProcess === 'start') {
                options.kwonResult = 0;
                options.taxsResult = 0;
            }
            if (options.summaryProcess === 'calculate') {
                options.kwonResult += parseInt(options.value.ship_kwon);
                options.taxsResult += parseInt(options.value.taxs_kwon);
            }
            if (options.summaryProcess === 'finalize') {
                var result = (options.kwonResult + options.taxsResult);
                options.totalValue = result;
            }
        }
    }, []);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                toolbar={{ default: true, items: toolbarItems }}
                exp={{ disabled: authList.auth_dnlo === 0 }}    
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {DateCol({ dataField: "ship_date", caption: "출하일자" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DeciCol({ dataField: "item_coun", caption: "품목수" })}
                {DeciCol({ dataField: "ship_kwon", caption: "공급가액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_memo", caption: "비고" })}               
                <Summary calculateCustomSummary={calculateCustomSummary}>
                    {TotalSumItem({ dataField: 'ship_kwon', caption: "공급가액" })}
                    {TotalSumItem({ dataField: 'taxs_kwon', caption: "부가세액" })}
                    {TotalCstItem({ dataField: 'ship_memo', caption: "출하 총금액" })}
                </Summary>                        
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}    
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "ship_seri", caption: "순번" })}
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_spec", caption: "규격" })}
                {DeciCol({ dataField: "ship_qtys", caption: "출하수량" })}
                {DeciCol({ dataField: "ship_pric", caption: "출하단가" })}
                {DeciCol({ dataField: "ship_kwon", caption: "출하금액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_remk", caption: "특기사항" })}
            </SubsGrid>               
        </div>
    );
};