// MproInfoRegi - 외주발주 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert, PopupAler as UpdateAler } from '../../../../script/components/popup/popup';
import { MproInfoRegiMainInsert } from './InfoRegi-main-insert';
import { MproInfoRegiMainUpdate } from './InfoRegi-main-update';
import { MproInfoRegiSubsSelect } from './InfoRegi-subs-select';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const convClos = (clos) => {
    return clos === "발주진행" ? 0 : clos === "입고완료" ? 1 : 2;
};

const setMpro = set.mes.pro.mpro;

export default function MproInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/header/search`;  // 메인 조회 api
    const mainTargetApi = `${acce}/${gubu}/${cata}/${catb}/register/header/target`;  // 메인 타겟 api
    const mainInsertApi = `${acce}/${gubu}/${cata}/${catb}/register/header/insert`;  // 메인 등록 api
    const mainUpdateApi = `${acce}/${gubu}/${cata}/${catb}/register/header/update`;  // 메인 수정 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/register/header/delete`;  // 메인 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/search`;  // 서브 조회 api
    const subsSelectApi = `${acce}/${gubu}/${cata}/${catb}/register/select/search`;  // 서브 품목 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/insert`;  // 서브 등록 api
    const subsUpdateApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/update`;  // 서브 수정 api
    const subsDeleteApi = `${acce}/${gubu}/${cata}/${catb}/register/detail/delete`;  // 서브 삭제 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = 'mpro_numb';
    const subaKey = ['mpro_numb', 'mpro_seri'];
    const subbKey = ['work_numb', 'mpro_seri'];
    const mainDeleKey = (data) => {
        return { 
            mproNumb: data, 
        }
    };    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [closGubu, setClosGubu] = useState("");
    const [dateGubu, setDateGubu] = useState("");
    const [clieName, setClieName] = useState("");
    const [mproNumb, setMproNumb] = useState("");    
    const [closGubuList, setClosGubuList] = useState([]);
    const [dateGubuList, setDateGubuList] = useState([]);    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const closGubuChange = useCallback((e) => { setClosGubu(e) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const mproNumbChange = useCallback((e) => { setMproNumb(e) }, []);
    const closGubuListChange = useCallback((e) => setClosGubuList(e), []);    
    const dateGubuListChange = useCallback((e) => setDateGubuList(e), []);    
    // 삭제 경고창
    const [mainDeleteAlertVisible, setMainDeleteAlertVisible] = useState(false);
    const mainDeleteAlertHide = useCallback(() => { setMainDeleteAlertVisible(false); }, []);
    const [subsDeleteAlertVisible, setSubsDeleteAlertVisible] = useState(false);
    const subsDeleteAlertHide = useCallback(() => { setSubsDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [mainInsertPopupVisible, setMainInsertPopupVisible] = useState(false);
    const mainInsertPopupHide = useCallback(() => { setMainInsertPopupVisible(false); }, []);
    const [subsSelectPopupVisible, setSubsSelectPopupVisible] = useState(false);
    const subsSelectPopupHide = useCallback(() => { setSubsSelectPopupVisible(false); }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);    
    const [subsUpdateAlertVisible, setSubsUpdateAlertVisible] = useState(false);
    const subsUpdateAlertHide = useCallback(() => { setSubsUpdateAlertVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [closResult, dateResult] = await Promise.all([setMpro.clos(), setMpro.date()]);
                if (closResult && closResult.length > 0) {
                    closGubuListChange(closResult);
                    closGubuChange(closResult[0]);
                }   
                if (dateResult && dateResult.length > 0) {
                    dateGubuListChange(dateResult);
                    dateGubuChange(dateResult[0]);
                }                  
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [closGubuChange, closGubuListChange, dateGubuChange, dateGubuListChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        if (closGubu && dateGubu) {
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, closGubu: convClos(closGubu), dateGubu: dateGubu, clieName: clieName, mproNumb: mproNumb };
            await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
        }
    }, [dateValue, duraYesn, closGubu, dateGubu, clieName, mproNumb, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        const sendData = { mproNumb: keyData };
        await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi]);

    const totalSearch = useCallback(async () => {
        await mainHandleSearch();
        await subsHandleSearch();
    }, [mainHandleSearch, subsHandleSearch]);

    useEffect(() => {
        mainGridRef?.current?.instance?.deselectAll();
        subsGridRef?.current?.instance?.deselectAll();
        mainHandleSearch();
        subsHandleSearch();
    }, [mainHandleSearch, subsHandleSearch, closGubu, dateGubu, mainGridRef, subsGridRef]);

    const mainGridClick = useCallback(({ key, isSelected }) => {
        if (key) {
            fwUtil.grid.sel.sRow(mainGridRef, key, isSelected);
            subsHandleSearch();
        }
    }, [subsHandleSearch, mainGridRef]);

    // 수정 경고 확인 클릭
    const onUpdateClick = useCallback(async () => {
        subsGridRef.current.instance.beginCustomLoading();
        const selectedMpro = fwUtil.grid.get.sKey(mainGridRef) || '';
        const selectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            mproSeri: item.mpro_seri || '',
            routNumb: item.rout_numb || '',
            routName: item.rout_name || '',
            mproQtys: item.mpro_qtys || 0.000,
            mproPric: item.mpro_pric || 0.00,
            recePlac: item.rece_plac || '',
            mproMemo: item.mpro_memo || '',
        }));
        const postData = { mproNumb: selectedMpro, setaMaps: convertdData }
        await fwUtil.apis.submit(subsUpdateApi, postData, subsHandleSearch);
        subsGridRef.current.instance.endCustomLoading();
        subsUpdateAlertHide();
    }, [subsGridRef, subsHandleSearch, subsUpdateAlertHide, subsUpdateApi]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async (type) => {
        if (type === 'main') {
            const gridInstance = mainGridRef?.current?.instance;
            if (gridInstance) {
                await fwUtil.apis.delete(mainDeleteApi, mainGridRef , mainDeleKey, mainHandleSearch);
            }
            mainDeleteAlertHide();
        } else {
            subsGridRef.current.instance.beginCustomLoading();
            const selectedMpro = fwUtil.grid.get.sKey(mainGridRef) || '';
            const selectedData = subsGridRef.current.instance.getSelectedRowsData() || [];
            const convertdData = selectedData.map((item) => ({
                mproSeri: item.mpro_seri || '',
            }));
            const postData = { mproNumb: selectedMpro, setaMaps: convertdData }
            await fwUtil.apis.submit(subsDeleteApi, postData, subsHandleSearch);
            subsGridRef.current.instance.endCustomLoading();
            subsDeleteAlertHide();
        }

    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainDeleteAlertHide, subsDeleteAlertHide, mainDeleteApi, subsDeleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '진행구분', value: closGubu, valueChange: closGubuChange, search: totalSearch, items: closGubuList },
        { type: 'selc', locate: 'before', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: totalSearch, items: dateGubuList },
        { type: 'text', locate: 'before', caption: '고객명', value: clieName, valueChange: clieNameChange, search: totalSearch },
        { type: 'text', locate: 'before', caption: '발주번호', value: mproNumb, valueChange: mproNumbChange, search: totalSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: totalSearch }
    ], [closGubu, closGubuChange, closGubuList, dateGubu, dateGubuChange, dateGubuList, clieName, clieNameChange, mproNumb, mproNumbChange, totalSearch ]);


    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'ins':
                    setMainInsertPopupVisible(true);
                    break;
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                case 'del':
                    setMainDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };
        
        const handleSubsAction = () => {
            if (act === 'ins') {
                setSubsSelectPopupVisible(true);
                return;
            }
    
            if (!subsSelectedRowKey) {
                fwUtil.aler.toast.warn('상세코드를 선택 후 시도해주세요.');
                return;
            }
    
            switch (act) {              
                case 'upd':
                    setSubsUpdateAlertVisible(true);
                    break;
                case 'del':
                    setSubsDeleteAlertVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            if (type === 'main' && act !== 'ins') {
                fwUtil.aler.toast.warn('발주를 선택 후 시도해주세요.');
                return;
            }
        }

        if (closGubu === "발주진행") {
            if (type === 'main' ) {
                handleMainAction();
            } else if (type === 'subs') {
                handleSubsAction();
            }
        } else {
            fwUtil.aler.toast.warn('발주진행 조회에서만 가능합니다');
        }

    }, [mainGridRef, subsGridRef, closGubu]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                onRowClick={mainGridClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => handleActionClick('main', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('main', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}                
                toolbar={{ default: true, items: toolbarItems }}
                selection={'none'}
            >
                {CommCol({ dataField: "mpro_numb", caption: "발주번호" })}
                {CommCol({ dataField: "damd_name", caption: "발주담당" })}
                {DateCol({ dataField: "mpro_date", caption: "발주일자" })}
                {CommCol({ dataField: "mpro_usag", caption: "용도" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })}
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })}
                {CommCol({ dataField: "clie_damd", caption: "업체담당" })}
                {DateCol({ dataField: "mdel_date", caption: "납품일자" })}
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "insp_damd", caption: "검수자(방법)" })}
                {CommCol({ dataField: "mpro_remk", caption: "특기사항" })}
                {CommCol({ dataField: "mpro_stat", caption: "진행상태" })}
                {CommCol({ dataField: "afte_conf", caption: "결재진행" })}
                {DateCol({ dataField: "appr_date", caption: "승인요청일" })}
                {DateCol({ dataField: "rele_date", caption: "발주처리일" })}
                {CommCol({ dataField: "prin_yesn", caption: "발주서출력여부" })}
                {CommCol({ dataField: "mpro_qtys", caption: "발주수량" })}
                {CommCol({ dataField: "mpro_cwon", caption: "발주금액" })}                                                
            </MainGrid>
            <MproInfoRegiMainInsert
                refresh={mainHandleSearch}
                insertApi={mainInsertApi}
                visible={mainInsertPopupVisible}
                hiding={mainInsertPopupHide}
            />
            <MproInfoRegiMainUpdate
                refresh={mainHandleSearch}
                mainGrid={mainGridRef}
                targetApi={mainTargetApi}
                updateApi={mainUpdateApi}
                visible={mainUpdatePopupVisible}
                hiding={mainUpdatePopupHide}
            />
            <DeleteAlert
                grid={mainGridRef}
                visible={mainDeleteAlertVisible}
                hiding={mainDeleteAlertHide}
                confirm={() => onDeleteClick('main')}
                message={'해당 발주를 삭제하시겠습니까?'}
            />   
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={fwUtil.grid.get.sKey(mainGridRef) ? subaKey : subbKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                ins={{ onClick: () => handleActionClick('subs', 'ins'), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('subs', 'upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('subs', 'del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false }}
                selection={'multiple'}
                edit
            >           
                {CommCol({ dataField: "mpro_seri", caption: "순번", allowEditing: false })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호", allowEditing: false })}
                {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
                {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격", allowEditing: false })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위", allowEditing: false })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호", check: true })}
                {CommCol({ dataField: "rout_name", caption: "공정명", check: true })}
                {DeciCol({ dataField: "mpro_qtys", caption: "발주수량", check: true })}
                {DeciCol({ dataField: "mpro_pric", caption: "발주단가", check: true })}
                {DeciCol({ dataField: "mpro_cwon", caption: "발주금액", allowEditing: false })}
                {CommCol({ dataField: "rece_plac", caption: "입고처", check: true })}
                {CommCol({ dataField: "mpro_memo", caption: "비고", check: true })}                
            </SubsGrid>         
            <MproInfoRegiSubsSelect
                mainGrid={mainGridRef}
                refresh={subsHandleSearch}
                selectApi={subsSelectApi}
                insertApi={subsInsertApi}
                visible={subsSelectPopupVisible}
                hiding={subsSelectPopupHide}
            />  
            <UpdateAler
                grid={subsGridRef}
                visible={subsUpdateAlertVisible}
                hiding={subsUpdateAlertHide}
                confirm={onUpdateClick}
                message={'해당 발주 상세품목를 수정하시겠습니까?'}
            />  
            <DeleteAlert
                grid={subsGridRef}
                visible={subsDeleteAlertVisible}
                hiding={subsDeleteAlertHide}
                confirm={() => onDeleteClick('subs')}
                message={'해당 발주 상세품목을 삭제하시겠습니까?'}
            />                      
        </div>
    );
};