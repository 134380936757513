// ProcloseCanc - 매입마감 취소
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancelAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ProcloseCanc(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/cancel/search`;  // 조회 api
    const cancelApi = `${acce}/${gubu}/${cata}/${catb}/cancel/delete`;  // 타겟 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'mana_numb';    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 취소 알림창
    const [cancelAlertVisible, setCancelAlertVisible] = useState(false);
    const cancelAlertHide = useCallback(() => { setCancelAlertVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, clieName, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 취소 경고 확인 클릭
    const onCancelClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            manaNumb: item.mana_numb,
            wareNumb: item.ware_numb,
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(cancelApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        cancelAlertHide();
    }, [gridRef, cancelApi, handleSearch, cancelAlertHide]);

    // 취소 버튼 클릭
    const handleCancelClick = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);
        if (selectedRowKey) {
            setCancelAlertVisible(true);
            return;
        } else {
            fwUtil.aler.toast.nsWarn();
        };
    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객사명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'trash', text: '취소', onClick: handleCancelClick, disabled: authList.auth_dele === 0,  },
    ], [authList, clieName, clieNameChange, handleSearch, handleCancelClick]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}             
                toolbar={{ default: true, items: toolbarItems } }
                selection={'multiple'}
            >
                {CommCol({ dataField: "mana_numb", caption: "매입마감번호" })}
                {DateCol({ dataField: "clos_date", caption: "확정일자" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {DateCol({ dataField: "ware_date", caption: "입고일자" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고중량" })}
                {DeciCol({ dataField: "ware_cwon", caption: "입고금액" })}
                {CommCol({ dataField: "rece_plac", caption: "입고처" })}
                {CommCol({ dataField: "ware_numb", caption: "입고번호" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "proc_memo", caption: "비고" })}
            </PagingGrid>        
            <CancelAlert 
                grid={gridRef}
                visible={cancelAlertVisible}
                hiding={cancelAlertHide}
                confirm={onCancelClick}
                message={'해당 매입마감를 취소하시겠습니까?'}
            />               
        </div>
    );
};