import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";

export function DashItemCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"전체 현황"}
            addClass={'item'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={"item_code"}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "plan_numb", caption: "계획번호" })}
                {CommCol({ dataField: "plan_date", caption: "계획작성일" })}
                {CommCol({ dataField: "work_date", caption: "작업일자" })}
                {CommCol({ dataField: "item_name", caption: "제 품 명" })}
                {DeciCol({ dataField: "plan_qtys", caption: "계획수량", before: true })}                
                {DeciCol({ dataField: "work_qtys", caption: "생산수량", before: true })}    
                {DeciCol({ dataField: "infe_qtys", caption: "불량수량", after: true })}              
            </ScrollGrid>
        </DashCard>
    )
}