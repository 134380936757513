import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { ScrollGrid } from "../../../../script/components/data-grid/data-grid-body/grid-bodys";
import { GroupSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';
import { CommCol, DeciCol } from "../../../../script/components/data-grid/data-grid-column/grid-column";
import { Summary } from "devextreme-react/cjs/data-grid";
import { fwUtil } from "../../../../script/util";

const qcisGroupRender = (data) => {
    if (!data && (data && data.length < 1)) {
        return;
    }

    if (!data.summaryItems) {
        return;
    }
    const inspColumn = data.summaryItems[0];
    const judgColumn = data.summaryItems[1];

    if (!inspColumn || !judgColumn) {
        return;
    }

    return (
        <div className='fw-summary-group'>
            <div className='fw-group-title'>
                {data.displayValue}
            </div>
            <div className='fw-group-detail'>
                총 {inspColumn.columnCaption} : <b>{inspColumn.value}</b> 건
            </div>
            <div className='fw-group-detail'>
                총 {judgColumn.columnCaption} : <b>{fwUtil.conv.to.krLocales(judgColumn.value)}</b> 건
            </div>
        </div>
    )
}

export function DashQcisGridCard(props) {
    const { loading, data } = props;

    return (
        <DashCard
            title={"검사별 현황"}
            addClass={'qcis'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={data}
                height={250}
                mainKey={["item_code", "insp_gubu"]}
                selection={'single'}
                direction={'vertical'}
            >
                {CommCol({ dataField: "insp_gubu", caption: "검사구분", groupIndex: 0, groupRender: qcisGroupRender })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "insp_qtys", caption: "검사수량" })}
                {DeciCol({ dataField: "judg_qtys", caption: "판정수량" })}
                <Summary>
                    {GroupSumItem({ dataField: "insp_qtys", suffix: "건" })}
                    {GroupSumItem({ dataField: "judg_qtys", suffix: "건" })}
                </Summary>
            </ScrollGrid>
        </DashCard>
    )
}