import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    dept_code: '',
    dept_name: '',
    dept_enam: '',
    sort_seri: '0',
    enab_yesn: true,
};

export function DeptStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;
    
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowData = fwUtil.grid.get.sData(mainGrid);
        console.log(selectedRowData)
        if (!selectedRowData) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                dept_code: selectedRowData.dept_code || '',
                dept_name: selectedRowData.dept_name || '',
                dept_enam: selectedRowData.dept_enam || '',
                sort_seri: selectedRowData.sort_seri || '0',
                enab_yesn: selectedRowData.enab_yesn === 1 ? true : false,
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };	    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            deptCode: data.dept_code || '',
            deptName: data.dept_name || '',
            deptEnam: data.dept_enam || '',
            sortSeri: data.sort_seri || '0',
            enabYesn: data.enab_yesn || false,  
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return ( 
        <PopupForm
            title={'부서코드 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.dept_code, onValueChange: updateField('dept_code'), label: '부서코드', disabled: true, required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부', })}    
                {FormText({ value: data.dept_name, onValueChange: updateField('dept_name'), label: '부서명[한]', })}
                {FormText({ value: data.dept_enam, onValueChange: updateField('dept_enam'), label: '부서명[영]', })}
                {FormNumb({ value: data.sort_seri, onValueChange: updateField('sort_seri'), label: '정렬순서', length: [4, 0] })}      
            </GroupItem>
        </PopupForm>        
    );
}