// ShipQcisStat - 출하검사 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function ShipQcisStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props; 

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/inspect/status/search`;  // 조회 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'insp_numb';
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [inspNumb, setInspNumb] = useState("");
    const [shipNumb, setShipNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const inspNumbChange = useCallback((e) => { setInspNumb(e) }, []);
    const shipNumbChange = useCallback((e) => { setShipNumb(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, inspNumb: inspNumb, shipNumb: shipNumb };        
        await fwUtil.apis.search(searchApi, sendData, mainGridDataUpdate);
    }, [dateValue, duraYesn, inspNumb, shipNumb, mainGridDataUpdate, searchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '검사번호', value: inspNumb, valueChange: inspNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '출하번호', value: shipNumb, valueChange: shipNumbChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [inspNumb, inspNumbChange, shipNumb, shipNumbChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "insp_numb", caption: "검사번호" })}
                {CommCol({ dataField: "work_numb", caption: "출하지시번호" })}
                {CommCol({ dataField: "rout_seri", caption: "순번" })}
                {CommCol({ dataField: "insp_gubu", caption: "검사구분" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "품번" })}
                {CommCol({ dataField: "item_name", caption: "품명" })}
                {CommCol({ dataField: "stoc_loca", caption: "저장위치" })}
                {DeciCol({ dataField: "insp_qtys", caption: "검사수량" })}
                {DeciCol({ dataField: "judg_qtys", caption: "판정수량" })}
                {CommCol({ dataField: "insp_damd", caption: "검사자" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {CommCol({ dataField: "ca05_memo", caption: "불량사유" })}
                {CommCol({ dataField: "insp_memo", caption: "비고" })}
            </PagingGrid>              
        </div>
    );
};