import React, { useState, useRef, useCallback, useContext, useEffect } from 'react';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';

import { useAuth } from '../../../../contexts/auth';
import { fwUtil } from '../../util';
import { SessionContext } from '../../contexts/session';

export default function LoginForm() {
  const { signIn } = useAuth();
  const { setSessStat } = useContext(SessionContext);
  const [loading, setLoading] = useState(false);
  const formData = useRef({ userIdno: '', passWord: '', rememberMe: true });

  useEffect(() => {
    const rememberedUserId = localStorage.getItem('rememberUserId');
    if (rememberedUserId) {
      formData.current.userIdno = rememberedUserId;
    }
  }, []);

  // 로그인
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { userIdno, passWord } = formData.current;
    setLoading(true);

    const result = await signIn(userIdno, passWord);
    if (formData?.current?.rememberMe) {
      localStorage.setItem('rememberUserId', formData?.current?.userIdno);
    };

    if (!result.isOk) {
      setLoading(false);
      setSessStat(true);
      fwUtil.aler.toast.erro(result.message);
    }
  }, [signIn, setSessStat]);

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'userIdno'}
          editorType={'dxTextBox'}
          editorOptions={idEditorOptions}
        >
          <RequiredRule message="아이디를 입력해주세요" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'passWord'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="비밀번호를 입력해주세요" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : '로그인'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const idEditorOptions = { stylingMode: 'underlined', placeholder: '아이디', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'underlined', placeholder: '비밀번호', mode: 'password' };
const rememberMeEditorOptions = { text: '아이디 저장', elementAttr: { class: 'form-text' } };
