import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";

export function DashQcisCharCard(props) {
    const { loading , data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${arg.value}`,
        };
    };
    console.log(data)
    return (
        <DashCard
            title={"총 불량 현황"}
            addClass={'qcis-char'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommAxis({ name: "perc", caption: "불량률(%)", position: "left", start: 0 })}
                {CommAxis({ name: "qtys", caption: "수량(건)", position: "right", start: 0 })}
                {BarsSeri({ valueField: "insp_qtys", caption: "검사수량", color: "#4EACFF", axis: "qtys", args: "insp_date" })}
                {BarsSeri({ valueField: "judg_qtys", caption: "불량수량", color: "#ff5d5d", axis: "qtys", args: "insp_date" })}
                {SpinSeri({ valueField: "judg_perc", caption: "불량률", color: "#FFA726", axis: "perc", args: "insp_date" })}        
            </CommChart>
        </DashCard>
    )
}