import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormMont, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    deli_numb: '',
    ship_numb: '',
    clie_code: '',
    clie_name: '',
    clos_mont: fwUtil.date.getsText.month(),
    dedu_kwon: 0.00,
    comp_date: fwUtil.date.getsText.date(),
    deli_kwon: 0.00,
    vats_yesn: true,
    coll_date: fwUtil.date.getsText.date(),
    clos_gubu: '',
    sale_remk: '',
    sale_memo: '',
};

export function SaleInfoRegiInsert(props) {
    const {
        mainGrid,
        mainRefresh,
        subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const shipSelected = mainGrid.current.instance.getSelectedRowsData() || [];
        if (shipSelected.length < 0) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                ...defaultData,
                ship_numb: shipSelected[0].ship_numb || '',
                clie_code: shipSelected[0].clie_code || '',
                clie_name: shipSelected[0].clie_name || '',
                dedu_kwon: shipSelected[0].deli_kwon || 0.00,
                deli_kwon: shipSelected[0].deli_kwon || 0.00,
            }
            dataUpdate(updatedData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		


    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            deliNumb: data.deli_numb || '',
            shipNumb: data.ship_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            closMont: data.clos_mont ? fwUtil.date.convTime.fmMont(data.clos_mont) : fwUtil.date.getsText.month(),
            deduKwon: data.dedu_kwon || 0.00,
            compDate: data.comp_date || fwUtil.date.getsText.date(),
            deliKwon: data.deli_kwon || 0.00,
            vatsYesn: data.vats_yesn || false,
            collDate: data.coll_date || fwUtil.date.getsText.date(),
            closGubu: data.clos_gubu || '',
            saleRemk: data.sale_remk || '',
            saleMemo: data.sale_memo || '',
        };

        const refresh = () => {
            mainRefresh();
            subsRefresh();
        };
        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, mainRefresh, subsRefresh, hiding]);

    return (
            <PopupForm
                title={'매출 등록'}       
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}  
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.ship_numb, onValueChange: updateField('ship_numb'), label: '납품번호', disabled: true })}
                    {FormText({ value: data.deli_numb, onValueChange: updateField('deli_numb'), label: '납품서번호' })}
                    {FormText({ value: data.clie_code, onValueChange: updateField('clie_code'), label: '고객코드', disabled: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명' })}
                    {FormMont({ value: data.clos_mont, onValueChange: updateField('clos_mont'), label: '기성월' })}
                    {FormNumb({ value: data.dedu_kwon, onValueChange: updateField('dedu_kwon'), label: '기성금액', length: [15, 2] })}
                    {FormDate({ value: data.comp_date, onValueChange: updateField('comp_date'), label: '납품완료일' })}
                    {FormNumb({ value: data.deli_kwon, onValueChange: updateField('deli_kwon'), label: '납품금액', length: [15, 2] })}
                    {FormDate({ value: data.coll_date, onValueChange: updateField('coll_date'), label: '수금일자' })}
                    {FormBool({ value: data.vats_yesn, onValueChange: updateField('vats_yesn'), label: '부가세적용' })}
                </GroupItem>
                {FormText({ value: data.clos_gubu, onValueChange: updateField('clos_gubu'), label: '기성구분' })}
                {FormMemo({ value: data.sale_remk, onValueChange: updateField('sale_remk'), label: '특기사항' })}
                {FormMemo({ value: data.sale_memo, onValueChange: updateField('sale_memo'), label: '비고' })}                
            </PopupForm>            
    );
}