import React from 'react';
import './dash-card.scss';
import { LoadIndicator } from 'devextreme-react';

export const DashCard = (props) => {
    const { title, addClass, loading = false, children, headerContent } = props;

    return (
        <div className={`dash-card ${addClass}`}>
            <div className='header fw-flex-box row a-center'>
                {title && <div className='title'>{title}</div>}
                {headerContent}
            </div>
            <div className='card-contents'>{children}</div>
            {loading && <LoadIndicator visible={true} className='fw-card-loading' />}
        </div>
    );
};