// ProsQcisRegi - 수입 검사 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ProsQcisRegiInsert } from './QcisRegi-insert';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        ppro_numb: "PP24060002",
        clie_name: "영풍전자",
        work_numb: "WO24080001",
        item_code: "5X5X35-KEY-ROUND",
        item_name: "KEY",
        rout_numb: "010",
        rout_name: "황삭, 정삭",
        insp_qtys: 150,
    },
    {
        ppro_numb: "PP24060003",
        clie_name: "영풍전자",
        work_numb: "WO24080002",
        item_code: "5X5X35-KEY-ROUND",
        item_name: "KEY",
        rout_numb: "020",
        rout_name: "열처리",
        insp_qtys: 200,
    }
];

export default function ProsQcisRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/register/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/register/insert`;  // 등록 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'ppro_numb';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);           
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [pproNumb, setPproNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const pproNumbChange = useCallback((e) => { setPproNumb(e) }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, pproNumb: pproNumb };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, pproNumb, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '발주번호', value: pproNumb, valueChange: pproNumbChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [pproNumb, pproNumbChange, handleSearch]);

    // 등록 버튼 클릭
    const handleInsertClick = useCallback(() => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            setInsertPopupVisible(true);
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);
    
    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: handleInsertClick, disabled: authList.auth_inse === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "ppro_numb", caption: "발주번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "work_numb", caption: "작업지시번호" })}
                {CommCol({ dataField: "item_code", caption: "품목코드" })}
                {CommCol({ dataField: "item_name", caption: "품목명" })}
                {CommCol({ dataField: "rout_numb", caption: "공정번호" })}
                {CommCol({ dataField: "rout_name", caption: "공정명" })}
                {DeciCol({ dataField: "insp_qtys", caption: "검사수량" })}
            </PagingGrid>
            <ProsQcisRegiInsert
                mainGrid={gridRef}
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
        </div>
    );
};