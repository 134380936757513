import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormImag, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

import { set } from '../../../../../api/set';

const defaultData = {
    main_numb: '',
    equi_numb: '',
    equi_gubu: '',
    equi_name: '',
    menu_comp: '',
    equi_type: '',
    equi_loca: '',
    equi_seno: '',
    equi_spec: '',
    reci_date: fwUtil.date.getsText.date(),
    insp_cycl: '',
    uses_dept: '',
    main_name: '',
    subs_name: '',
    menu_date: fwUtil.date.getsText.date(),
    equi_memo: '',
    equi_imgs: null,
};

const setEqui = set.mes.equ.equi;

export function EquiInfoStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 설비 구분 리스트
    const [gubuList, setGubuList] = useState([]);
    const gubuChange = useCallback((e) => setGubuList(e), []);

    const fetchData = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([setEqui.gubu()]);
            gubuChange(gubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [gubuChange]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        fetchData();
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { mainNumb: selectedRowKey };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                main_numb: res.main_numb || '',
                equi_numb: res.equi_numb || '',
                equi_gubu: res.equi_gubu ? res.equi_gubu.replace(/^\d{2} : /, '') : '',
                equi_name: res.equi_name || '',
                menu_comp: res.menu_comp || '',
                equi_type: res.equi_type || '',
                equi_loca: res.equi_loca || '',
                equi_seno: res.equi_seno || '',
                equi_spec: res.equi_spec || '',
                reci_date: res.reci_date || fwUtil.date.getsText.date(),
                insp_cycl: res.insp_cycl || '',
                uses_dept: res.uses_dept || '',
                main_name: res.main_name || '',
                subs_name: res.subs_name || '',
                menu_date: res.menu_date || fwUtil.date.getsText.date(),
                equi_memo: res.equi_memo || '',
                equi_imgs: res.equi_imgs || null,                
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate, fetchData]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;
        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = new FormData();

        postData.append('mainNumb', data.main_numb || '' );
        postData.append('equiNumb', data.equi_numb || '' );
        postData.append('equiGubu', data.equi_gubu || '' );
        postData.append('equiName', data.equi_name || '' );
        postData.append('menuComp', data.menu_comp || '' );
        postData.append('equiType', data.equi_type || '' );
        postData.append('equiLoca', data.equi_loca || '' );
        postData.append('equiSeno', data.equi_seno || '' );
        postData.append('equiSpec', data.equi_spec || '' );
        postData.append('reciDate', data.reci_date || null );
        postData.append('inspCycl', data.insp_cycl || '' );
        postData.append('usesDept', data.uses_dept || '' );
        postData.append('mainName', data.main_name || '' );
        postData.append('subsName', data.subs_name || '' );
        postData.append('menuDate', data.menu_date || null );
        postData.append('equiMemo', data.equi_memo || '' );
        postData.append('equiImgs', data.equi_imgs || null );

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'설비 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? '100%' : isMedium ? 700 : 1050}  
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 3} cssClass='fw-form-image-row head center'>
                {FormImag({ value: fwUtil.conv.to.buffToBlob(data.equi_imgs), onValueChange: updateField('equi_imgs'), label: '설비이미지', labelHide: true, size: 200 })}
                <GroupItem colSpan={isLarge ? 2 : 1} colCount={isLarge ? 2 : 1}>
                    <GroupItem >
                        {FormText({ value: data.equi_numb, onValueChange: updateField('equi_numb'), label: '관리번호', required: true, disabled: true })}
                        {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', required: true })}
                        {FormText({ value: data.equi_seno, onValueChange: updateField('equi_seno'), label: '기기번호' })}
                    </GroupItem>
                    <GroupItem>
                        {FormText({ value: data.menu_comp, onValueChange: updateField('menu_comp'), label: '제작회사' })}
                        {FormSelc({ value: data.equi_gubu, onValueChange: updateField('equi_gubu'), label: '설비구분', items: gubuList, required: true })}
                        {FormText({ value: data.equi_type, onValueChange: updateField('equi_type'), label: '규격/형식' })}
                    </GroupItem>                    
                </GroupItem>
            </GroupItem>
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 3} cssClass='fw-form-image-row body'>
                {FormText({ value: data.equi_loca, onValueChange: updateField('equi_loca'), label: '설치장소' })}
                {FormDate({ value: data.reci_date, onValueChange: updateField('reci_date'), label: '설치일자' })}
                {FormText({ value: data.uses_dept, onValueChange: updateField('uses_dept'), label: '사용부서' })}
                {FormText({ value: data.insp_cycl, onValueChange: updateField('insp_cycl'), label: '점검주기' })}
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '담당자(정)' })}
                {FormText({ value: data.subs_name, onValueChange: updateField('subs_name'), label: '담당자(부)' })}
                {FormDate({ value: data.menu_date, onValueChange: updateField('menu_date'), label: '제작일자' })}
            </GroupItem>
            {FormMemo({ value: data.equi_spec, onValueChange: updateField('equi_spec'), label: '용량/공칭능력' })}
            {FormMemo({ value: data.equi_memo, onValueChange: updateField('equi_memo'), label: '비고' })}
        </PopupForm>
    );
}