// AserAccoStat - A/S 정산 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import { Summary } from 'devextreme-react/cjs/data-grid';
import { TotalSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';

const dateGubuList = ["청구일자", "정산일자"];

export default function AserAccoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = ['mana_numb', 'comp_seri'];    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [dateGubu, setDateGubu] = useState("청구일자");
    const [manaNumb, setCsarNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []); 
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []); 
    const itemNameChange = useCallback((e) => { setItemName(e) }, []); 
    const manaNumbChange = useCallback((e) => { setCsarNumb(e) }, []);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, dateGubu: dateGubu, manaNumb: manaNumb, clieName: clieName, itemCode: itemCode, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [dateValue, duraYesn, dateGubu, manaNumb, clieName, itemCode, itemName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'selc', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: handleSearch, items: dateGubuList },
        { locate: 'before', type: 'text', caption: '관리번호', value: manaNumb, valueChange: manaNumbChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: handleSearch },
        { locate: 'before', type: 'text', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [dateGubu, dateGubuChange, manaNumb, manaNumbChange, clieName, itemCode, itemName, itemCodeChange, itemNameChange, clieNameChange, handleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                toolbar={{ default: true, items: toolbarItems }}                 
                exp={{ disabled: authList.auth_dnlo === 0 }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호", groupIndex: 0 })}
                {CommCol({ dataField: "comp_seri", caption: "순번" })}
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {DeciCol({ dataField: "insp_kwon", caption: "출장비" })}
                {DeciCol({ dataField: "part_kwon", caption: "부품비" })}
                {DateCol({ dataField: "bill_date", caption: "청구일자" })}
                {DeciCol({ dataField: "bill_kwon", caption: "청구금액" })}
                {CommCol({ dataField: "pays_date", caption: "정산일자" })}
                {DateCol({ dataField: "pays_kwon", caption: "정산금액" })}
                {CommCol({ dataField: "asac_memo", caption: "비고" })}    
                <Summary >
                    {TotalSumItem({ dataField: 'bill_kwon', caption: "청구 총금액" })}
                    {TotalSumItem({ dataField: 'pays_kwon', caption: "정산 총금액" })}
                </Summary>                              
            </PagingGrid>         
        </div>
    );
};