// SaleInfoCanc - 매출 취소
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancelAler } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function SaleInfoCanc(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/header/search`;  // 메인 조회 api
    const mainDeleteApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/delete`;  // 메인 삭제 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/change/detail/search`;  // 서브 조회 api   
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = ['sale_numb', 'ship_numb'];
    const subsKey = ['ship_numb', 'ship_seri'];    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    // 취소 팝업창
    const [mainCancelAlertVisible, setMainCancelAlertVisible] = useState(false);
    const mainCancelAlertHide = useCallback(() => { setMainCancelAlertVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, clieName: clieName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, clieName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        const keyData = key;
        if (keyData) {
            const sendData = { shipNumb: keyData.ship_numb };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 취소 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        mainGridRef.current.instance.beginCustomLoading();
        subsGridRef.current.instance.beginCustomLoading();
        const selectedData = mainGridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            saleNumb: item.sale_numb,
            shipNumb: item.ship_numb,
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(mainDeleteApi, postData, mainHandleSearch);
        mainGridRef.current.instance.endCustomLoading();
        subsGridRef.current.instance.endCustomLoading();
        mainGridRef.current.instance.deselectAll();
        subsHandleSearch();
        mainCancelAlertHide();
    }, [mainGridRef, subsGridRef, mainHandleSearch, subsHandleSearch, mainCancelAlertHide, mainDeleteApi]);    

    // 취소 버튼 클릭
    const handleCancelClick = useCallback(() => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);

        if (mainSelectedRowKey) {
            setMainCancelAlertVisible(true)
        } else {
            fwUtil.aler.toast.nsWarn();
        }

    }, [mainGridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '고객사명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'btns', locate: 'after', icon: 'trash', text: '취소', hidden: false, disabled: authList.auth_dele === 0, onClick: handleCancelClick },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch },
    ], [clieName, clieNameChange, mainHandleSearch, authList, handleCancelClick]);
    
    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}
                toolbar={{ default: true, items: toolbarItems }} 
                selection={'multiple'}
            >
                {CommCol({ dataField: "sale_numb", caption: "매출번호" })}   
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {DateCol({ dataField: "clos_mont", caption: "기성월" })}
                {CommCol({ dataField: "comp_date", caption: "확정일자" })}
                {CommCol({ dataField: "clos_gubu", caption: "기성구분" })}
                {DeciCol({ dataField: "deli_kwon", caption: "납품금액" })}
                {DeciCol({ dataField: "dedu_kwon", caption: "기성금액" })}
                {DeciCol({ dataField: "vats_kwon", caption: "부가세액" })}
                {DateCol({ dataField: "coll_date", caption: "수금예정일" })}
                {CommCol({ dataField: "ship_numb", caption: "출하번호" })}
                {CommCol({ dataField: "dele_numb", caption: "납품서번호" })}
                {CommCol({ dataField: "sale_remk", caption: "특기사항" })}
                {CommCol({ dataField: "sale_memo", caption: "비고" })}       
            </MainGrid>
            <CancelAler
                grid={mainGridRef}
                visible={mainCancelAlertVisible}
                hiding={mainCancelAlertHide}
                confirm={onDeleteClick}
                message={'해당 매입을 취소하시겠습니까?'}
            />
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "ship_numb", caption: "매출번호" })}
                {CommCol({ dataField: "ship_seri", caption: "순번" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_spec", caption: "규격" })}
                {DeciCol({ dataField: "ship_qtys", caption: "닙픔수량" })}
                {DeciCol({ dataField: "ship_pric", caption: "닙픔단가" })}
                {DeciCol({ dataField: "ship_kwon", caption: "납품금액" })}
                {DeciCol({ dataField: "taxs_kwon", caption: "세액" })}
                {CommCol({ dataField: "ship_remk", caption: "특기사항" })}
            </SubsGrid>                     
        </div>
    );
};