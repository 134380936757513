// BluePrinRegi - 도면 정보
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { BluePrinRegiInsert } from './InfoRegi-insert';
import { BluePrinRegiUpdate } from './InfoRegi-update';
import { BluePrinRegiFileMn } from './InfoRegi-fileMn';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const convStat = (stat) => {
    return stat === "사용중"? 1 : stat === "미사용"? 0 : 2;
};

const enabList = ['사용중', '미사용', '전체'];

export default function BluePrinRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const gridSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/search`;            // 메인 조회 api
    const gridInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/insert`;            // 메인 정보 api
    const gridUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/update`;            // 메인 수정 api
    const gridDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/delete`;            // 메인 삭제 api
    const fileCreateApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/main/insert`;  // 파일 생성 api
    const fileSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/search`;       // 파일 조회 api
    const fileInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/upload`;       // 파일 등록 api
    const fileUpdateApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/update`;       // 파일 수정 api
    const fileDeleteApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/delete`;       // 파일 삭제 api    
    const fileDnloadApi = `${acce}/${gubu}/${cata}/${catb}/regist/file/download`;     // 파일 다운 api    

    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'mana_numb';
    const deleKey = (data) => {
        return { manaNumb: data }
    };       
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [enabYesn, setEnabYesn] = useState("전체");       
    const [itemCode, setItemCode] = useState("");
    const [itemName, setItemName] = useState("");
    const [clieCode, setClieCode] = useState("");
    const [clieName, setClieName] = useState("");
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);        
    const itemCodeChange = useCallback((e) => { setItemCode(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const clieCodeChange = useCallback((e) => { setClieCode(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);    
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 정보 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    
    // 파일관리 팝업창
    const [fileManagePopupVisible, setFileManagePopupVisible] = useState(false);
    const fileManagePopupHide = useCallback(() => { handleSearch(); setFileManagePopupVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { itemCode: itemCode, itemName: itemName, clieName: clieName, clieCode: clieCode, enabYesn: convStat(enabYesn), };
        await fwUtil.apis.search(gridSearchApi, sendData, gridDataUpdate, gridRef);
    }, [itemCode, itemName, clieCode, clieName, enabYesn, gridDataUpdate, gridRef, gridSearchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(gridDeleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, gridDeleteApi]);

    // 정보/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
            if (act === 'file') {
                setFileManagePopupVisible(true);
                return;
            }              
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '사용여부', value: enabYesn, valueChange: enabYesnChange, search: handleSearch, items: enabList },          
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange },
        { type: 'text', locate: 'before', caption: '업체번호', value: clieCode, valueChange: clieCodeChange },
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange },      
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'folder', text: '파일관리', onClick: () => handleActionClick('file') },
    ], [itemCode, itemCodeChange, itemName, itemNameChange, clieCode, clieCodeChange, clieName, clieNameChange, enabYesn, enabYesnChange, handleSearch, handleActionClick]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}  
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mana_numb", caption: "관리번호" })}
                {CommCol({ dataField: "clie_code", caption: "업체번호" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}                
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "dist_dept", caption: "배포부서" })}
                {CommCol({ dataField: "mana_dept", caption: "관리부서" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {DateCol({ dataField: "rece_date", caption: "접수일자" })}
                {CommCol({ dataField: "blue_memo", caption: "비고" })}
            </PagingGrid>
            <BluePrinRegiFileMn
                mainGrid={gridRef} 
                createApi={fileCreateApi}
                searchApi={fileSearchApi}
                insertApi={fileInsertApi}
                updateApi={fileUpdateApi}
                deleteApi={fileDeleteApi}
                dnloadApi={fileDnloadApi}
                visible={fileManagePopupVisible}
                hiding={fileManagePopupHide}
            />            
            <BluePrinRegiInsert
                refresh={handleSearch}
                insertApi={gridInsertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <BluePrinRegiUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                updateApi={gridUpdateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 도면 항목을 삭제하시겠습니까?'}
            />             
        </div>
    );
};