import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormRegx, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    cata_code: '', 
    catb_code: '', 
    catb_name: '', 
    regi_date: fwUtil.date.getsText.date(), 
    enab_yesn: true
};

export function MatlCatbStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { cateCode: `${selectedRowKey.cata_code}${selectedRowKey.catb_code}` };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                cata_code: res.cata_code || '',
                catb_code: res.catb_code || '',
                catb_name: res.catb_name || '',
                regi_date: res.regi_date || null,
                enab_yesn: res.enab_yesn === 1 ? true : false,
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            catbCode: `${data.cata_code || '-'}${data.catb_code || '--'}`,
            catbName: data.catb_name || '',
            regiDate: data.regi_date || null,
            enabYesn: data.enab_yesn || false,
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'중분류 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600 }  
        >
            <GroupItem colCount={isXSmall ? 1 : 2 }>
                {FormText({ value: data.cata_code, onValueChange: updateField('cata_code'), label: '대분류코드', required: true, disabled: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormRegx({ value: data.catb_code, onValueChange: updateField('catb_code'), label: '중분류코드', required: true, rule: /^[a-zA-Z0-9]{2}$/, message: "영어 숫자 조합 2자리를 입력해주세요!", disabled: true })}
                {FormText({ value: data.catb_name, onValueChange: updateField('catb_name'), label: '중분류명', required: true, disabled: true })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
            </GroupItem>
        </PopupForm>     
    );
}