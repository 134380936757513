import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { storageKey } from '../../../utils/theme-constants';
import { fwUtil } from '../util';

const ThemeContext = createContext({});
const useTheme = () => useContext(ThemeContext);

// 사용가능한 테마 색상 배열
const colors = [
    { text: "스카이 블루", value: "blue", prim: "#6192FF", seco: "rgb(137 163 221)" },
    { text: "파일 그린", value: "green", prim: "#5fc362", seco: "rgb(150 189 152)" },
    { text: "모카", value: "moka", prim: "#c59d6c", seco: "rgb(163 145 124)" },
    { text: "바이올렛", value: "purple", prim: "#a65ee9", seco: "rgb(166 137 193)" },
    { text: "틸 그린", value: "teal", prim: "#008080", seco: "rgb(128 165 167)" },
];

function ThemeProvider({ theme, ...props }) {
    const [_theme, setTheme] = useState(theme);

    // 테마 정보 가져오는 함수
    const getTheme = useCallback(() => _theme || window.localStorage[storageKey] || "blue.light.compact", [_theme]);

    // 테마 색상 가져오는 함수
    const getAccent = useCallback(() => {
        const currentTheme = getTheme();
        // eslint-disable-next-line no-useless-escape        
        const matchResult = currentTheme.match(/^([^\.]+)\.([^\.]+)\.([^\.]+)$/);
        if (matchResult && matchResult.length === 4) {
            return matchResult[1];
        } else {
            return 'blue';
        }
    }, [getTheme]);
    

    // 테마 모드 가져오는 함수
    const getMode = useCallback(() => {
        const currentTheme = getTheme();
        // eslint-disable-next-line no-useless-escape
        const matchResult = currentTheme.match(/^([^\.]+)\.([^\.]+)\.([^\.]+)$/);
        if (matchResult && matchResult.length === 4) {
            return matchResult[2];
        } else {
            return 'light';
        }
    }, [getTheme]);

    // 테마 스타일 가져오는 함수
    const getStyle = useCallback(() => {
        const currentTheme = getTheme();
        // eslint-disable-next-line no-useless-escape
        const matchResult = currentTheme.match(/^([^\.]+)\.([^\.]+)\.([^\.]+)$/);
        if (matchResult && matchResult.length === 4) {
            return matchResult[3];
        } else {
            return 'compact';
        }
    }, [getTheme]);    

    // 특정 테마에 대한 색상 가져오는 함수
    const getAccentColor = useCallback((color, depth) => {
        const themeColor = colors.find(item => item.value === color);
        return themeColor ? themeColor[depth] : null;
    }, []);

    // 테마 적용 함수 1. 색상 변수값 저장
    const applyBaseTheme = useCallback((accent, mode, style) => {
        const primaryColor = getAccentColor(accent, 'prim');
        const secondaryColor = getAccentColor(accent, 'seco');
        document.documentElement.style.setProperty('--primary-accent-color', primaryColor);
        document.documentElement.style.setProperty('--secondary-accent-color', secondaryColor);
        if (mode === 'light') {
            document.documentElement.style.setProperty('--base-text-color', 'rgba(0,0,0,.87)');
            document.documentElement.style.setProperty('--base-icon-color', 'rgba(0,0,0,.87)');
            document.documentElement.style.setProperty('--base-bck-color', '#f1f1f1de');
            document.documentElement.style.setProperty('--base-border-color', '#ebebebde');
            document.documentElement.style.setProperty('--primary-depth-color', '#fff');
            document.documentElement.style.setProperty('--secondary-depth-color', '#ffffff');
            document.documentElement.style.setProperty('--tertiary-depth-color', '#e3e3e3de');
            document.documentElement.style.setProperty('--mode-accent-color', '#f2c138');
        } else {
            document.documentElement.style.setProperty('--base-text-color', 'rgba(255, 255, 255, .87)');
            document.documentElement.style.setProperty('--base-icon-color', 'rgba(255, 255, 255, .87)');
            document.documentElement.style.setProperty('--base-bck-color', '#616161');
            document.documentElement.style.setProperty('--base-border-color', '#595959');
            document.documentElement.style.setProperty('--primary-depth-color', '#373737');
            document.documentElement.style.setProperty('--secondary-depth-color', '#3D3D3D');
            document.documentElement.style.setProperty('--tertiary-depth-color', '#575757');
            document.documentElement.style.setProperty('--mode-accent-color', '#25afee');
        }
        const newTheme = `${accent}.${mode}.${style}`        
        window.localStorage[storageKey] = newTheme;
        setTheme(newTheme);
    }, [getAccentColor, setTheme]);

    // 테마 적용 함수 0. 로컬 저장 및 호출
    const applyTheme = useCallback((theme) => {
        let currentAccent = 'blue';
        let currentMode = 'light';
        let currentStyle = 'compact';
        
        if (theme) {
            const matchResult = theme.match(/^([^.]+)\.([^.]+)\.([^.]+)$/);

            if (matchResult && matchResult.length === 4) {
                currentAccent = matchResult[1];
                currentMode = matchResult[2];
                currentStyle = matchResult[3];
            }
        } else if (window.localStorage[storageKey]) {
            const matchResult = window.localStorage[storageKey].match(/^([^.]+)\.([^.]+)\.([^.]+)$/)
            if (matchResult && matchResult.length === 4) {
                currentAccent = matchResult[1];
                currentMode = matchResult[2];
                currentStyle = matchResult[3];
            }
        }
        applyBaseTheme(currentAccent, currentMode, currentStyle);
    }, [applyBaseTheme]);

    // 테마 색상 변경
    const applyColor = useCallback((color) => {
        const mode = getMode();
        const style = getStyle();
        const theme = `${color}.${mode}.${style}`;
        setTheme(theme);
    }, [getMode, getStyle]);

    // 테마 모드 변경
    const applyMode = useCallback((mode) => {
        const accent = getAccent();
        const style = getStyle();
        const theme = `${accent}.${mode}.${style}`;
        setTheme(theme);
        if (mode === "light") {
            fwUtil.aler.toast.info("라이트 모드가 활성화 되었습니다.");
        } else if (mode === "dark") {
            fwUtil.aler.toast.info("다크 모드가 활성화 되었습니다.");
        }
    }, [getAccent, getStyle]);

    useEffect(() => {
        applyTheme(_theme);
    }, [_theme, applyTheme]);

    return (
        <ThemeContext.Provider
            value={{ colors, getTheme, setTheme, applyColor, applyMode, getAccent, getMode }}
            {...props}
        />
    );
}

export {
    ThemeProvider,
    useTheme
}