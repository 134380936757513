import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, GridBtnsCol, DeciCol, SelcCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    item_code: '',
    item_name: '',
    boms_qtys: 1.000,
    regi_date: fwUtil.date.getsText.date(),
    item_gubu: '',
    enab_yesn: false,
    boms_memo: '',
};

const setBoms = set.mes.com.boms;

const matlDataSource = fwUtil.grid.get.dataSource();

export function ItemBomsRegiInsert(props) {
    const {
        mainRefresh, subsRefresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isLarge } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 구성품목 그리드
    const matlRef = useRef(null);
    const [matlGridKey, setMatlGridKey] = useState('');
    const matlGridKeyChange = useCallback((e) => setMatlGridKey(e), []);
    // 팝업 그리드
    const matlGridRef = useRef(null);
    const itemGridRef = useRef(null);
    const [matlData, setMatlData] = useState([]);
    const [itemData, setItemData] = useState([]);
    const matlGridUpdate = useCallback((e) => setMatlData(e), []);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const matlKeyExpr = 'item_code';
    const itemKeyExpr = 'item_code';
    // 그리드 조회 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const [matlGubu, setMatlGubu] = useState('');
    const [itemCode, setItemCode] = useState('');
    const [itemName, setItemName] = useState('');
    const [itemGubu, setItemGubu] = useState('');
    const [matlGubuList, setMatlGubuList] = useState([]);
    const [itemGubuList, setItemGubuList] = useState([]);
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const matlGubuChange = useCallback((e) => setMatlGubu(e), []);
    const itemCodeChange = useCallback((e) => setItemCode(e), []);
    const itemNameChange = useCallback((e) => setItemName(e), []);
    const itemGubuChange = useCallback((e) => setItemGubu(e), []);
    const matlGubuListChange = useCallback((e) => setMatlGubuList(e), []);
    const itemGubuListChange = useCallback((e) => setItemGubuList(e), []);
    // 그리드 팝업
    const [matlPopupVisible, setMatlPopupVisible] = useState(false);
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const matlPopupHiding = useCallback(() => setMatlPopupVisible(false), []);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);

    // 팝업 조회
    const matlHandleSearch = useCallback(async() => {
        const sendData = { itemCode: matlCode, itemName: matlName, itemGubu: matlGubu };
        const res = await setBoms.matl.info(sendData);
        matlGridUpdate(res);
    }, [matlCode, matlName, matlGubu, matlGridUpdate]);
    
    const itemHandleSearch = useCallback(async() => {
        const sendData = { itemCode: itemCode, itemName: itemName, itemGubu: itemGubu };
        const res = await setBoms.item.info(sendData);
        itemGridUpdate(res);
    }, [itemCode, itemName, itemGubu, itemGridUpdate]);

    // 그리드 추가 툴바 아이템
    const matlToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '제품구분', value: matlGubu, valueChange: matlGubuChange, search: matlHandleSearch, items: matlGubuList },
        { type: 'text', locate: 'before', caption: '구성품번', value: matlCode, valueChange: matlCodeChange, search: matlHandleSearch },
        { type: 'text', locate: 'before', caption: '구성품명', value: matlName, valueChange: matlNameChange, search: matlHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: matlHandleSearch }
    ], [matlCode, matlCodeChange, matlName, matlNameChange, matlGubu, matlGubuChange, matlGubuList, matlHandleSearch]);

    const itemToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '제품구분', value: itemGubu, valueChange: itemGubuChange, search: itemHandleSearch, items: itemGubuList },
        { type: 'text', locate: 'before', caption: '제품번호', value: itemCode, valueChange: itemCodeChange, search: itemHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: itemHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: itemHandleSearch }
    ], [itemCode, itemCodeChange, itemName, itemNameChange, itemGubu, itemGubuChange, itemGubuList, itemHandleSearch]);

    const fetchData = useCallback(async () => {
        try {
            const [matlGubuResult, itemGubuResult] = await Promise.all([setBoms.matl.gubu(), setBoms.item.gubu()]);
            matlGubuListChange(matlGubuResult);
            matlGubuChange(matlGubuResult[0]);
            itemGubuListChange(itemGubuResult);
            itemGubuChange(itemGubuResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [matlGubuListChange, matlGubuChange, itemGubuListChange, itemGubuChange]);

    // 열릴 때
    const onShowing = useCallback(() => {
        matlDataSource.store().insert({ init: true }).then(() => matlDataSource.reload());
        fetchData();
        dataUpdate(defaultData);
    }, [fetchData, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const matlData = matlRef?.current?.instance?.getDataSource()?.items();
        const postMatlData = matlData.map((item) => ({
            itemCode: data.item_code || "",
            matlCode: item.matl_code || "",
            matlName: item.matl_name || "",
            matlGubu: item.matl_gubu || "원자재",
            bomsQtys: item.boms_qtys || 0.000,
            regiDate: data.regi_date || fwUtil.date.getsText.date(),
            matlMemo: item.matl_memo || "",
        }));        
        const postData = {
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            itemGubu: data.item_gubu || '',
            bomsQtys: data.boms_qtys || 1,
            regiDate: data.regi_date || fwUtil.date.getsText.date(),
            enabYesn: data.enab_yesn || false,
            bomsMemo: data.boms_memo || '',
            setaMaps: postMatlData || [],
        };

        const refresh = () => {
            mainRefresh();
            subsRefresh();
        };
        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, mainRefresh, subsRefresh, hiding]);

    // 구성 품목 설정 클릭
    const onMatlGridSearchClick = useCallback((e) => {
        matlGridKeyChange(e.row.data.rowKey);
        setMatlPopupVisible(true);
    }, [matlGridKeyChange, setMatlPopupVisible]);

    useEffect(() => {
        matlGubu && matlHandleSearch();
    }, [matlGubu, matlHandleSearch]);

    useEffect(() => {
        itemGubu && itemHandleSearch();
    }, [itemGubu, itemHandleSearch]);

    // 팝업 열릴 때
    const matlPopupOnShowing = useCallback(() => {
        matlHandleSearch();
    }, [matlHandleSearch]);

    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 팝업 더블 클릭
    const matlOnRowDblClick = useCallback((e) => {
        const gridItemCode = { matl_code: e.data.item_code };
        const gridItemName = { matl_name: e.data.item_name };
        const gridItemGubu = { matl_gubu: e.data.item_gubu };
        matlDataSource.store().update(matlGridKey, gridItemCode);
        matlDataSource.store().update(matlGridKey, gridItemName);
        matlDataSource.store().update(matlGridKey, gridItemGubu);

        matlDataSource.reload();
        matlPopupHiding();
    }, [matlPopupHiding, matlGridKey]);

    const itemOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 'item_code': e.data.item_code, 'item_name': e.data.item_name, 'item_gubu': e.data.item_gubu });
        itemPopupHiding();
    }, [data, dataUpdate, itemPopupHiding]);

    const matlGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'구성품목'}
                role={'목록'}
                gridDataSource={matlData}
                gridRef={matlGridRef}
                mainKey={matlKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={matlOnRowDblClick}
                toolbar={{ default: false, items: matlToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "구성품번" })}
                {CommCol({ dataField: "item_name", caption: "구성품명" })}
                {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
            </ScrollGrid>            
        )
    }, [matlData, matlGridRef, matlKeyExpr, matlOnRowDblClick, matlToolbarItems, isXSmall, isSmall]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품번호'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                toolbar={{ default: false, items: itemToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
            </ScrollGrid>
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, itemToolbarItems, isXSmall, isSmall]);

    return (
        <>
        <PopupGrid
            title={'구성품목 등록'}   
            visible={matlPopupVisible}  
            shown={matlPopupOnShowing}  
            hiding={matlPopupHiding}
        >   
            {matlGrid}
        </PopupGrid> 
        <PopupGrid
            title={'제품번호 설정'}   
            visible={itemPopupVisible}  
            shown={itemPopupOnShowing}  
            hiding={itemPopupHiding}
        >   
            {itemGrid}
        </PopupGrid>           
        <PopupForm
            title={'BOM 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : isLarge ? 1200 : 600 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'구성품목'}
                        gridRef={matlRef} 
                        gridDataSource={matlDataSource}
                        allowEdit
                        allowInsert
                        allowUpdate
                        allowDelete
                    >
                        {CommCol({ dataField: "matl_code", caption: "구성품번", allowFiltering: false, allowSorting: false })}
                        {CommCol({ dataField: "matl_name", caption: "구성품명", allowFiltering: false, allowSorting: false })}
                        {SelcCol({ dataField: "matl_gubu", caption: "품목구분", items: matlGubuList, allowFiltering: false, allowSorting: false })}
                        {DeciCol({ dataField: "boms_qtys", caption: "구성수량", allowFiltering: false, allowSorting: false, check: true })}
                        {CommCol({ dataField: "matl_memo", caption: "비고", allowFiltering: false, allowSorting: false, check: true })}      
                        {GridBtnsCol({ onClick: onMatlGridSearchClick, allowDelete: true })}     
                    </AddingGrid>
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isLarge ? 4 : 2 }>
                {FormGrid({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', onClick: () => setItemPopupVisible(true), required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용유무' })}
                {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '품명/규격', disabled: true })}
                {FormText({ value: data.item_gubu, onValueChange: updateField('item_gubu'), label: '품목구분', disabled: true })}
                {FormNumb({ value: data.boms_qtys, onValueChange: updateField('boms_qtys'), label: '구성수량', disabled: true, length: [15, 3] })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자', required: true })}
            </GroupItem>
            {FormMemo({ value: data.boms_memo, onValueChange: updateField('boms_memo'), label: '비고' })}
        </PopupForm>   
        </>
    );
}