// ClieInfoStat - 거래처 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { ClieInfoStatInsert } from './InfoStat-insert';
import { ClieInfoStatUpdate } from './InfoStat-update';
import { ClieInfoStatUpload } from './InfoStat-upload';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const enabList = ['사용중', '미사용'];

export default function ClieInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;          // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/target`;          // 타겟 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/insert`;          // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/update`;          // 수정 api
    const uploadApi = `${acce}/${gubu}/${cata}/${catb}/excel/datasave`;  // 업로드 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/delete`;          // 삭제 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'clie_code';                  
    const deleKey = (data) => {
        return { clieCode: data }
    };    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);  
    // 검색 조건
    const [clieName, setClieName] = useState("");    
    const [enabYesn, setEnabYesn] = useState("사용중");    
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const enabYesnChange = useCallback((e) => { setEnabYesn(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);   
    // 업로드 팝업창
    const [uploadPopupVisible, setUploadPopupVisible] = useState(false);
    const uploadPopupHide = useCallback(() => { setUploadPopupVisible(false); }, []);
    
    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { clieName: clieName, enabYesn: enabYesn === "사용중" ? true : false };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [clieName, enabYesn, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);


    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { locate: 'before', type: 'text', caption: '거래처명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { locate: 'before', type: 'selc', caption: '사용여부', value: enabYesn, valueChange: enabYesnChange, search: handleSearch, items: enabList },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'upload', text: '업로드', onClick: () => setUploadPopupVisible(true) },
    ], [clieName, clieNameChange, enabYesn, enabYesnChange, handleSearch, setUploadPopupVisible]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "거래처번호" })}
                {CommCol({ dataField: "clie_name", caption: "거래처명(한글)" })}
                {CommCol({ dataField: "addr_knam", caption: "주소(한글)" })}  
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "업체구분" })}
                {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                {CommCol({ dataField: "pres_name", caption: "대표자명" })}
                {CommCol({ dataField: "clie_sano", caption: "사업자번호" })}
                {CommCol({ dataField: "upta_name", caption: "업태" })}
                {CommCol({ dataField: "jong_moks", caption: "종목" })}                              
                {CommCol({ dataField: "work_code", caption: "생산지시코드" })}
                {CommCol({ dataField: "damd_name", caption: "담당자명" })}
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "mail_idno", caption: "이메일ID" })}
                {CommCol({ dataField: "damd_teln", caption: "담당자전화" })}
                {CommCol({ dataField: "damd_faxn", caption: "담당자팩스" })}
                {CommCol({ dataField: "damd_cell", caption: "담당자H.P" })}
                {CommCol({ dataField: "tele_numb", caption: "전화번호" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩스번호" })}
                {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                {CommCol({ dataField: "clie_enam", caption: "거래처명(영문)" })}
                {CommCol({ dataField: "addr_enam", caption: "주소(영문)" })}
                {CommCol({ dataField: "home_page", caption: "홈페이지 URL" })}
                {CommCol({ dataField: "bank_name", caption: "거래은행" })}
                {CommCol({ dataField: "acco_numb", caption: "계좌번호" })}
                {CommCol({ dataField: "hold_name", caption: "예금주" })}
                {CommCol({ dataField: "clie_memo", caption: "메모" })}
                {CommCol({ dataField: "clie_sert", caption: "인증서보유현황" })}
                {DateCol({ dataField: "eval_date", caption: "업체평가일" })}
                {CommCol({ dataField: "eval_scor", caption: "평가점수" })}
                {CommCol({ dataField: "eval_term", caption: "평가주기" })}
                {CommCol({ dataField: "eval_memo", caption: "평가비고" })}
                {CommCol({ dataField: "taxb_name", caption: "세금계산서 담당자" })}
                {CommCol({ dataField: "taxb_teln", caption: "세금계산서 전화" })}
                {CommCol({ dataField: "taxb_mail", caption: "세금계산서 이메일" })}
            </PagingGrid>
            <ClieInfoStatInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <ClieInfoStatUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />   
            <ClieInfoStatUpload
                refresh={handleSearch}
                uploadApi={uploadApi}
                visible={uploadPopupVisible}
                hiding={uploadPopupHide}
            />
            <DeleteAlert 
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 거래처를 삭제하시겠습니까?'}
            />             
        </div>
    );
};