import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    main_numb: '',
    equi_numb: '',
    equi_name: '',
    cali_seri: '',
    clie_code: '',
    clie_name: '',
    cali_numb: '',
    cali_date: fwUtil.date.getsText.date(),
    cali_ndat: fwUtil.date.getsText.date(),
    cali_desc: '',
    cali_memo: '',
}

const setCali = set.mes.equ.cali;

export function CalibInfoRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 업체 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';    
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 업체 조회
    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await setCali.clie(sendData);
        clieGridUpdate(res);
    }, [clieGridUpdate]);    

    // 업체 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 업체 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);    

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                main_numb: seleData.main_numb || '',
                equi_numb: seleData.equi_numb || '',
                equi_name: seleData.equi_name || '',
                cali_seri: seleData.cali_seri || '',
                clie_code: seleData.clie_code || '',
                clie_name: seleData.clie_name || '',
                cali_numb: seleData.cali_numb || '',
                cali_date: seleData.cali_date || fwUtil.date.getsText.date(),
                cali_ndat: seleData.cali_ndat || fwUtil.date.getsText.date(),
                cali_desc: seleData.cali_desc || '',
                cali_memo: seleData.cali_memo || '',
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            mainNumb: data.main_numb || '',
            equiNumb: data.equi_numb || '',
            equiName: data.equi_name || '',
            caliSeri: data.cali_seri || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            caliNumb: data.cali_numb || '',
            caliDate: data.cali_date || fwUtil.date.getsText.date(),
            caliNdat: data.cali_ndat || fwUtil.date.getsText.date(),
            caliDesc: data.cali_desc || '',
            caliMemo: data.cali_memo || '',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, onHiding);
        setLoading(false);
    }, [data, updateApi, refresh, onHiding]);

    return (
        <>
        <PopupGrid
            title={'교정기관 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            showing={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'교정기관'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "기관코드" })}
                {CommCol({ dataField: "clie_name", caption: "기관명" })}
            </ScrollGrid>
        </PopupGrid>               
        <PopupForm
            title={'검교정 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.equi_numb, onValueChange: updateField('equi_numb'), label: '설비번호', required: true, disabled: true })}
                {FormText({ value: data.equi_name, onValueChange: updateField('equi_name'), label: '설비명', disabled: true })}
                {FormText({ value: data.main_numb, onValueChange: updateField('main_numb'), label: '등록순번', disabled: true })}
                {FormText({ value: data.cali_seri, onValueChange: updateField('cali_seri'), label: '순번', disabled: true })}
                {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '교정기관코드', required: true })}
                {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '교정기관명', readOnly: true })}
                {FormDate({ value: data.cali_date, onValueChange: updateField('cali_date'), label: '교정일자' })}           
                {FormDate({ value: data.cali_ndat, onValueChange: updateField('cali_ndat'), label: '차기교정일' })}
                <GroupItem colSpan={isXSmall ? 1 : 2}>
                    {FormText({ value: data.cali_numb, onValueChange: updateField('cali_numb'), label: '교정번호' })}
                </GroupItem>
            </GroupItem>
            {FormMemo({ value: data.cali_desc, onValueChange: updateField('cali_desc'), label: '교정내용' })}
            {FormMemo({ value: data.cali_memo, onValueChange: updateField('cali_memo'), label: '비고' })}
        </PopupForm>        
        </>
    );
}