import notify from "devextreme/ui/notify";
import { fwUtil } from "../util";

const defaultTime = 2000;

function notifyMessage(mes, status, time) {
    const times = fwUtil.conv.tern(2000, time);
    notify(mes, status, times);
};

function notifyRespons(response) {
    if (response) {
        if (response.status) {
            notify(`${response.message}`, 'success', 2000);
        } else {
            notify(`${response.message}`, 'error', 2000);
        }
    }
};

export const toast = {
    res: (res) => notifyRespons(res),
    info: (mes, time) => notifyMessage(mes, 'info', fwUtil.conv.tern(defaultTime, time)),
    warn: (mes, time) => notifyMessage(mes, 'warning', fwUtil.conv.tern(defaultTime, time)),
    erro: (mes, time) => notifyMessage(mes, 'error', fwUtil.conv.tern(defaultTime, time)),
    nsWarn: () => notifyMessage('데이터를 선택 후 시도해주세요', 'warning', defaultTime),
    s1Erro: () => notifyMessage('데이터 조회에 실패하였습니다', 'error', defaultTime),
    a1Erro: () => notifyMessage('데이터 전송에 실패하였습니다', 'error', defaultTime),
    d1Erro: () => notifyMessage('데이터 삭제에 실패하였습니다', 'error', defaultTime),
}