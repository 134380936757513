// AddrStat - IP 관리
import React, { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { AddrStatInsert } from './AddrStat-insert';
import { AddrStatUpdate } from './AddrStat-update ';
import { fwUtil } from '../../../../script/util';

const convStat = (stat) => {
    return stat === "사용중"? 0 : stat === "미사용"? 1 : 2;
};

const statList = ['사용중', '미사용', '전체'];

export default function AddrStat(props) {
    const {
        acce, gubu, cata, catb,
        mainTitle, mainRole, authList,
        gridSize
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/addr/search`;  // 조회 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/addr/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/addr/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/addr/delete`;  // 삭제 api
    // 그리드
    const mainKey = 'addr_ipnb';
    const deleKey = (data) => {
        return { addrIpnb: data }
    }     
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);        
    // 검색 조건
    const [stat, SetStat] = useState(statList[0]);
    const statChange = useCallback((e) => { SetStat(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { addrYesn: convStat(stat) };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef);
    }, [stat, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch, stat]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: "사용여부", value: stat, valueChange: statChange, items: statList, width: 100 },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stat, statChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    return (
        <div className='fw-horizeon-grid'>
            <div className='fw-grid-page fw-flex-box row'>
                <PagingGrid
                    title={mainTitle}
                    role={mainRole}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    hideColumnChooser
                    ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "addr_ipnb", caption: "허용IP", width: 140 })}
                    {BoolCol({ dataField: "addr_yesn", caption: "사용여부", allowFiltering: false, allowSorting: false, width: 80 })}
                    {CommCol({ dataField: "addr_memo", caption: "허용사유", minWidth: 140 })}
                </PagingGrid>                
                <AddrStatInsert
                    refresh={handleSearch}
                    insertApi={insertApi}
                    visible={insertPopupVisible}
                    hiding={insertPopupHide}
                />
                <AddrStatUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={updateApi}
                    visible={updatePopupVisible}
                    hiding={updatePopupHide}
                />            
                <DeleteAlert 
                    grid={gridRef}
                    visible={deleteAlertVisible}
                    hiding={deleteAlertHide}
                    confirm={onDeleteClick}
                    message={'해당 IP를 삭제하시겠습니까?'}
                />                 
            </div>            
        </div>
    );
};