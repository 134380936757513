const defaultData = {
    matl_code: '',
    matl_name: '',
    clie_code: '',
    clie_name: '',
    sale_kwon: 0.00,
    purs_kwon: 0.00,
};

export function combineSalePursData(saleData, pursData) {
    const combinedData = {};

    if (!saleData || !pursData) {
        return [];
    }

    saleData.forEach(sale => {
        const code = sale.clie_code;
        if (sale.sale_kwon > 0) {
            if (!combinedData[code]) {
                combinedData[code] = {
                    ...defaultData,
                    clie_code: code,
                    clie_name: sale.clie_name,
                };
            }
            combinedData[code].sale_kwon += parseFloat(sale.sale_kwon) || 0;
        }
    });

    pursData.forEach(purs => {
        const code = purs.clie_code;
        if (!combinedData[code]) {
            combinedData[code] = {
                ...defaultData,
                clie_code: code,
                clie_name: purs.clie_name,
            };
        }
        combinedData[code].purs_kwon += parseFloat(purs.ppro_cwon) || 0;
    });

    return Object.values(combinedData);
}
