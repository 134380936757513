import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../../contexts/auth";
import { HomeUserInfo } from "./Home-user";
import { HomeConnList } from "./Home-conn";
import { HomeTaskList } from "./Home-task";
import { HomeSalePursChar } from "./Home-sale-purs";
import { fwUtil } from "../../../script/util";
import { convertSalePursData } from "./Home-util";
import './home.scss';

const defaultUserData = {
    user_idno: '',
    user_name: '',
    user_enam: '',
    tele_numb: '',
    cell_numb: '',
    faxs_numb: '',
    posi_name: '',
    dept_name: '',
    mail_idno: '',
};

const userTargetApi = 'privat/mes/sys/info/registration/target';
const connSearchApi = 'privat/mes/home/connect/search';
const taskSearchApi = 'privat/mes/home/task/search';
const salePursSearchApi = 'privat/mes/home/salepurs/search';

export default function TempHome() {
    const { user } = useAuth();
    // 페이지 상태값
    const [pageHeight, setPageHeight] = useState(798);
    // 데이터
    const [userData, setUserData] = useState(defaultUserData);
    const [connData, setConnData] = useState();
    const [taskData, setTaskData] = useState();
    const [salePursData, setSalePursData] = useState();
    const userDataUpdate = useCallback((e) => setUserData(e), [setUserData]);
    const connDataUpdate = useCallback((e) => setConnData(e), [setConnData]);
    const taskDataUpdate = useCallback((e) => setTaskData(e), [setTaskData]);
    const salePursDataUpdate = useCallback((e) => setSalePursData(e), [setSalePursData]);

    const totaHandleSearch = useCallback(async() => {
        if (!user) {
            fwUtil.aler.toast.s1Erro();
        } else {
            const sendData = { userIdno: user.userIdno };
            const res = await fwUtil.apis.search(userTargetApi, sendData) || [];
            const resData = {
                user_idno: res.user_idno || '',
                user_name: res.user_name || '',
                user_enam: res.user_enam || '',
                tele_numb: res.tele_numb || '',
                cell_numb: res.cell_numb || '',
                faxs_numb: res.faxs_numb || '',
                posi_name: res.posi_name || '',
                dept_name: res.dept_name || '',
                mail_idno: res.mail_idno || '',
            };
            userDataUpdate(resData);
        }

        await fwUtil.apis.search(connSearchApi, null, connDataUpdate);
        await fwUtil.apis.search(taskSearchApi, null, taskDataUpdate);
        const sendData = { start: fwUtil.date.getsText.pDate(6), compl: fwUtil.date.getsText.date() }
        const res = await fwUtil.apis.search(salePursSearchApi, sendData);
        const conv = convertSalePursData(res);
        salePursDataUpdate(conv);
    }, [user, userDataUpdate, connDataUpdate, taskDataUpdate, salePursDataUpdate]);

    useEffect(() => {
        totaHandleSearch();
    }, [totaHandleSearch]);

    useEffect(() => {
        const windowHeight = window.innerHeight > 900 ? window.innerHeight : 900;
        const headerHeight = 48 + 53;
        const footerHeight = 70 + 20;

        const updateHeight = windowHeight - headerHeight - footerHeight;
        setPageHeight(updateHeight);
    }, []);

    return (
        <>
            <div className="fw-home-page fw-flex-box col">
                <div className="fw-home-header fw-flex-box row a-center">
                    <div className="fw-home-title">메인화면</div>
                </div>
                <div className="fw-home-body fw-flex-box" style={{ height: pageHeight }}>
                    <HomeUserInfo user={userData} />
                    <div className="fw-home-body-detail">
                        <HomeConnList title={"접속자 명단"} data={connData} />
                        <HomeTaskList title={"대기 중 업무"} data={taskData} />
                        <HomeSalePursChar title={"최근 7일간 매출/매입"} data={salePursData} />
                    </div>
                </div>
            </div>
        </>
    )
}