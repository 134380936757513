// OrdeInfoStat - 수주 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const closList = ['진행', '완료', '전체'];
const dateList = ['납기일자', '등록일자'];    

export default function OrdeInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'orde_numb';     
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [closGubu, setClosGubu] = useState("전체")
    const [dateGubu, setDateGubu] = useState("납기일자")
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const closGubuChange = useCallback((e) => { setClosGubu(e) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []); 
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, closGubu: closGubu === "완료" ? 0 : closGubu === "진행" ? 1 : 2, dateGubu: dateGubu, clieName: clieName, itemName: itemName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, closGubu, dateGubu, clieName, itemName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '진행구분', value: closGubu, valueChange: closGubuChange, search: handleSearch, items: closList },
        { type: 'selc', locate: 'before', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: handleSearch, items: dateList },
        { type: 'text', locate: 'before', caption: '고객명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: itemName, valueChange: itemNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [closGubu, closGubuChange, dateGubu, dateGubuChange, clieName, clieNameChange, itemName, itemNameChange, handleSearch]);
    
    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "orde_numb", caption: "수주번호" })}
                {CommCol({ dataField: "clie_code", caption: "고객코드" })}
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "품목번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_code", caption: "고객품목번호" })}
                {CommCol({ dataField: "cite_name", caption: "고객품명" })}
                {CommCol({ dataField: "cite_spec", caption: "고객규격" })}
                {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
                {DeciCol({ dataField: "orde_qtys", caption: "오더수량" })}
                {CommCol({ dataField: "orde_unit", caption: "단위" })}
                {DeciCol({ dataField: "orde_pric", caption: "수주단가" })}
                {DeciCol({ dataField: "orde_kwon", caption: "수주금액" })}
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })}
                {CommCol({ dataField: "pays_cond", caption: "결재조건" })}
                {CommCol({ dataField: "orde_memo", caption: "비고" })}
            </PagingGrid> 
        </div>
    );
};