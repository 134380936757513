import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri, CommArgs, CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

const tempData = [
    { prod_mont: "01", plan_qtys: 10, prod_qtys: 8, plan_pric: 500000, prod_pric: 400000 },
    { prod_mont: "02", plan_qtys: 12, prod_qtys: 11, plan_pric: 600000, prod_pric: 550000 },
    { prod_mont: "03", plan_qtys: 15, prod_qtys: 14, plan_pric: 750000, prod_pric: 700000 },
    { prod_mont: "04", plan_qtys: 13, prod_qtys: 12, plan_pric: 650000, prod_pric: 600000 },
    { prod_mont: "05", plan_qtys: 18, prod_qtys: 16, plan_pric: 900000, prod_pric: 800000 },
    { prod_mont: "06", plan_qtys: 20, prod_qtys: 19, plan_pric: 1000000, prod_pric: 950000 },
    { prod_mont: "07", plan_qtys: 17, prod_qtys: 16, plan_pric: 850000, prod_pric: 800000 },
    { prod_mont: "08", plan_qtys: 19, prod_qtys: 17, plan_pric: 950000, prod_pric: 850000 },
    { prod_mont: "09", plan_qtys: 22, prod_qtys: 20, plan_pric: 1100000, prod_pric: 1000000 },
    { prod_mont: "10", plan_qtys: 25, prod_qtys: 23, plan_pric: 1250000, prod_pric: 1150000 },
    { prod_mont: "11", plan_qtys: 24, prod_qtys: 22, plan_pric: 1200000, prod_pric: 1100000 },
    { prod_mont: "12", plan_qtys: 28, prod_qtys: 26, plan_pric: 1400000, prod_pric: 1300000 }
];

export function DashProdCard(props) {
    const { loading, data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
        };
    };

    return (
        <DashCard
            title={"제품별 계획/생산 현황"}
            addClass={'prod'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommArgs({ field: "prod_mont", categories: ["01","02","03","04","05","06","07","08","09","10","11","12"] })}
                {CommAxis({ name: "qtys", caption: "수량(건)", position: "left", start: 0 })}
                {BarsSeri({ valueField: "plan_qtys", caption: "계획수량", color: "#4EACFF", args: "plan_mont", axis: "qtys" })}
                {BarsSeri({ valueField: "work_qtys", caption: "생산수량", color: "#4EACFF", args: "work_mont", axis: "qtys" })}  
                {BarsSeri({ valueField: "infe_qtys", caption: "생산수량", color: "#ff5d5d", args: "work_mont", axis: "qtys" })}        
            </CommChart>
        </DashCard>
    )
}