// ReportQcisStat - 성적서 현황
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Item } from 'devextreme-react/data-grid';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { SearchButton, ToolbarTextBox } from '../../../../script/components/toolbar/toolbar-items';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

const tempData = [
    {
        orde_numb: "123456",
        repo_gubu: "보고구분1",
        clie_code: "C001",
        clie_name: "고객명1",
        cite_name: "제품명1",
        cite_spec: "규격1",
        insp_date: "2024-06-15",
        file_qtys: 3,
        indx_keys: "파일키1",
        repo_memo: "비고1"
    },
    {
        orde_numb: "789012",
        repo_gubu: "보고구분2",
        clie_code: "C002",
        clie_name: "고객명2",
        cite_name: "제품명2",
        cite_spec: "규격2",
        insp_date: "2024-06-20",
        file_qtys: 1,
        indx_keys: "파일키2",
        repo_memo: "비고2"
    }
];  

export default function ReportQcisStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/status/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'orde_numb';  
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [clieName, setClieName] = useState("");
    const [itemName, setItemName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const itemNameChange = useCallback((e) => { setItemName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, itemName: itemName, clieName: clieName };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate);
        gridDataUpdate(tempData);
    }, [dateValue, duraYesn, itemName, clieName, gridDataUpdate, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);


    // 추가 툴바 아이템
    const toolbarBeforeItems = () => {
        return (
            <Item location='before' locateInMenu='auto'>
                {ToolbarTextBox({ caption: "제품명", value: itemName, valueChange: itemNameChange, search: handleSearch })}
                {ToolbarTextBox({ caption: "고객명", value: clieName, valueChange: clieNameChange, search: handleSearch })}
                {SearchButton({ search: handleSearch })}
            </Item>
        )
    };

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(gridRef, key, isSelected);
    }, [gridRef]);

    

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, before: toolbarBeforeItems }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "cite_name", caption: "제품명" })}
                {CommCol({ dataField: "cite_spec", caption: "규격" })}
                {DateCol({ dataField: "insp_date", caption: "검사일자" })}
                {CommCol({ dataField: "file_qtys", caption: "첨부파일수" })}
                {CommCol({ dataField: "indx_keys", caption: "파일키" })}
                {CommCol({ dataField: "repo_memo", caption: "비고" })}
            </PagingGrid>        

        </div>
    );
};