import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getCodeGubu = async () => {
    try {
        const api =  `privat${mesGubu}/sys/set/maincode/index/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.bizs_gubu);
        }
        return convData;        
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getClieGubu = async () => {
    try {
        const api = `/privat${mesGubu}/sys/set/client/gubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.send_gubu);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getClieVehi = async () => {
    try {
        const api = `privat${mesGubu}/sys/set/client/vehicle/List`;
        const data = await fwUtil.apis.search(api);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getClieVehiMatl = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/client/vehicle/matlcode`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getClieVehiGubu = async () => {
    try {
        const api = `privat${mesGubu}/sys/set/client/vehicle/itemgubu`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getMatlCata = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/matlcode/cata/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/item/register/itemgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getItemWorkGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/item/register/workgubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData; 
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsMatl = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/com/bom/register/itemcode/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsMatlGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/blist`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getBomsItemGubu = async () => {
    try {
        const api = `privat${mesGubu}/com/bom/register/gubucode/alist`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const com = {
    code: {
        gubu: getCodeGubu
    },
    clie: {
        gubu: getClieGubu,
        vehi: {
            info: getClieVehi,
            matl: getClieVehiMatl,
            gubu: getClieVehiGubu
        }
    },
    matl: {
        cata: getMatlCata
    },
    item: {
        gubu: getItemGubu,
        work: getItemWorkGubu
    },
    boms: {
        matl: {
            info: getBomsMatl,
            gubu: getBomsMatlGubu,
        },
        item: {
            info: getBomsItem,
            gubu: getBomsItemGubu,
        },
    },
}