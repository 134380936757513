import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormExce } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    xlsx_save: null,    
}

export function ClieInfoStatUpload(props) {
    const {
        refresh,
        uploadApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 닫힐 때
    const onHiding = useCallback(() => {
        dataUpdate(defaultData);
        hiding();
    }, [dataUpdate, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = new FormData();

        postData.append('xlsxSave', data.xlsx_save || null);

        await fwUtil.apis.action(uploadApi, postData, refresh, onHiding);
        setLoading(false);
    }, [data, uploadApi, refresh, onHiding]);

    return (
        <>  
        <PopupForm
            title={'거래처 업로드'}
            type={'업로드'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={onHiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
            warning={'업로드시 업체구분을 공통코드 A01에 맞춰 대문자 한 자로 작성해주세요!'}
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormExce({ value: data.xlsx_save, onValueChange: updateField('xlsx_save'), label: '거래처 엑셀 파일' })}
            </GroupItem>
        </PopupForm>        
        </>
    );
}