import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getDept = async () => {
    try {
        const api = `privat${mesGubu}/sys/set/deptcode/list`;
        const data = await fwUtil.apis.search(api);
        return data;
    }
    catch (error) {
        console.log('getDeptList.error === ', error);
        return [];
    }
};

const getPosi = async () => {
    try {
        const api = `privat${mesGubu}/sys/set/position/list`;
        const data = await fwUtil.apis.search(api);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const sys = {
    dept: getDept,
    posi: getPosi,
}