// MproPartRegi - 구매 부분 입고
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { MproPartRegiInsert } from './PartRegi-insert';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function MproPartRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/part/header/search`; // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/regist/part/detail/search`; // 서브 조회 api
    const subsInsertApi = `${acce}/${gubu}/${cata}/${catb}/regist/part/detail/insert`; // 서브 등록 api    
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'mpro_numb';               
    const subsKey = ['mpro_numb', 'mpro_seri'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [mproNumb, setMproNumb] = useState("");
    const [clieName, setClieName] = useState("");
    const [workNumb, setWorkNumb] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const mproNumbChange = useCallback((e) => { setMproNumb(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const workNumbChange = useCallback((e) => { setWorkNumb(e) }, []);

    // 등록 팝업창
    const [subsInsertPopupVisible, setSubsInsertPopupVisible] = useState(false);
    const subsInsertPopupHide = useCallback(() => { setSubsInsertPopupVisible(false); }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, mproNumb: mproNumb, workNumb: workNumb, clieName: clieName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, mproNumb, workNumb, clieName, mainSearchApi, mainGridDataUpdate]);

    const subsHandleSearch = useCallback(async () => {
        const keyData = fwUtil.grid.get.lKey(mainGridRef);
        if (keyData) {
            const sendData = { mproNumb: keyData };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef, null);
        }
    }, [mainGridRef, subsGridDataUpdate, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '발주번호', value: mproNumb, valueChange: mproNumbChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange, search: mainHandleSearch },
        { type: 'text', locate: 'before', caption: '작업지시번호', value: workNumb, valueChange: workNumbChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [mproNumb, mproNumbChange, clieName, clieNameChange, workNumb, workNumbChange, mainHandleSearch]);
    
    // 등록 버튼 클릭
    const subsHandleInsertClick = useCallback((act) => {
        const subsSelectedRowKey = fwUtil.grid.get.sKey(subsGridRef);

        if (subsSelectedRowKey) {
            setSubsInsertPopupVisible(true);
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [subsGridRef]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                onRowClick={mainGridClick}        
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "mpro_numb", caption: "발주번호" })} 
                {CommCol({ dataField: "clie_name", caption: "업체명" })} 
                {CommCol({ dataField: "clie_teln", caption: "업체전화" })} 
                {CommCol({ dataField: "clie_faxn", caption: "업체팩스" })} 
                {CommCol({ dataField: "clie_damd", caption: "업체담당자" })} 
                {DateCol({ dataField: "pdel_date", caption: "납품일자" })} 
                {CommCol({ dataField: "pays_cond", caption: "지불형태" })} 
                {CommCol({ dataField: "deli_plac", caption: "납품장소" })} 
                {CommCol({ dataField: "mpro_remk", caption: "특기사항" })} 
                {CommCol({ dataField: "damd_name", caption: "발주담당자" })} 
                {DateCol({ dataField: "mpro_date", caption: "발주일자" })} 
                {CommCol({ dataField: "mpro_usag", caption: "용도" })} 
                {CommCol({ dataField: "mpro_stat", caption: "진행상태" })}                              
                {CommCol({ dataField: "afte_conf", caption: "결재진행" })}                              
                {DateCol({ dataField: "rele_date", caption: "발주처리일" })}                             
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData} 
                ins={{ onClick: subsHandleInsertClick, disabled: authList.auth_inse === 0 }}  
                exp={{ disabled: authList.auth_dnlo === 0 }} 
                toolbar={{ default: false }}
                selection={'multiple'}
            >
                {CommCol({ dataField: "mpro_seri", caption: "순번" })}
                {CommCol({ dataField: "matl_code", caption: "자재번호" })}
                {CommCol({ dataField: "matl_name", caption: "자재품명" })}
                {CommCol({ dataField: "matl_spec", caption: "자재규격" })}
                {DeciCol({ dataField: "mpro_qtys", caption: "발주수량" })}
                {DeciCol({ dataField: "rece_qtys", caption: "입고수량" })}
                {CommCol({ dataField: "puro_unit", caption: "구매단위" })}
                {DeciCol({ dataField: "mpro_pric", caption: "발주단가" })}
                {DeciCol({ dataField: "mpro_cwon", caption: "발주금액" })}
                {CommCol({ dataField: "rece_plac", caption: "납품장소" })}
                {CommCol({ dataField: "mpro_memo", caption: "비고" })}
            </SubsGrid>      
            <MproPartRegiInsert
                mainGrid={mainGridRef}
                subsGrid={subsGridRef}
                subsRefresh={subsHandleSearch}
                insertApi={subsInsertApi}
                visible={subsInsertPopupVisible}
                hiding={subsInsertPopupHide}
            />                      
        </div>
    );
};