import { DropDownButton } from "devextreme-react";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { useCallback, useState } from "react";
import { CommChart } from "../../../../script/components/chart/charts";
import { CommAxis, SpinSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

const dropGroup = ["매출", "매입", "전체"];

export function DashSalePursCard(props) {
    const { loading, data } = props;
    const [selectedGroup, setSelectedGroup] = useState(dropGroup[0]);
    const selectedGroupChange = useCallback((e) => setSelectedGroup(e.item), []);

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}원`,
        };
    };

    return (
        <DashCard
            title={"총 매출/매입 현황"}
            addClass={'sale-purs'}
            loading={loading}
            headerContent={
                <div className='dash-card-filter'>
                    <DropDownButton stylingMode='text' useSelectMode items={dropGroup} selectedItemKey={selectedGroup} onSelectionChanged={selectedGroupChange} />
                </div>
            }
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {CommAxis({ name: "kwon", caption: "금액(원)", position: "left" })}
                {selectedGroup !== "매입" && SpinSeri({ valueField: "sale_kwon", caption: "매출", color: "#FF6347", args: "dash_date", axis: 'kwon' })}
                {selectedGroup !== "매출" && SpinSeri({ valueField: "ppro_kwon", caption: "매입", color: "#1E90FF", args: "dash_date", axis: 'kwon' })}
                {selectedGroup === "전체" && SpinSeri({ valueField: "prof_kwon", caption: "차익", color: "#3CB371", args: "dash_date", axis: 'kwon' })}
            </CommChart>
        </DashCard>
    )
}