// UserInfoRegi - 사용자 정보 등록
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as DeleteAlert } from '../../../../script/components/popup/popup';
import { UserInfoPublDetail } from './InfoPubl-detail';
import { UserInfoRegiInsert } from './InfoRegi-insert';
import { UserInfoRegiUpdate } from './InfoRegi-update';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function UserInfoRegi(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/registration/search`;  // 조회 api
    const targetApi = `${acce}/${gubu}/${cata}/${catb}/registration/target`;  // 타겟 api
    const insertApi = `${acce}/${gubu}/${cata}/${catb}/registration/insert`;  // 등록 api
    const updateApi = `${acce}/${gubu}/${cata}/${catb}/registration/update`;  // 수정 api
    const deleteApi = `${acce}/${gubu}/${cata}/${catb}/registration/delete`;  // 삭제 api

    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'single';
    const mainKey = 'user_idno';
    const deleKey = (data) => {
        return { userIdno: data }
    }    
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 키
    const [keyData, setKeyData] = useState('');
    const keyDataChange = useCallback((e) => setKeyData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [userName, setUserName] = useState("");    
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    // 삭제 경고창
    const [deleteAlertVisible, setDeleteAlertVisible] = useState(false);
    const deleteAlertHide = useCallback(() => { setDeleteAlertVisible(false); }, []);
    // 상세 패널
    const [isPanelOpened, setPanelOpened] = useState(false);
    const panelOpendChange = useCallback(() => {
        setPanelOpened(!isPanelOpened);
        gridRef.current?.instance.option('focusedRowIndex', -1);
    }, [isPanelOpened]);
    // 등록 팝업창
    const [insertPopupVisible, setInsertPopupVisible] = useState(false);
    const insertPopupHide = useCallback(() => { setInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [updatePopupVisible, setUpdatePopupVisible] = useState(false);
    const updatePopupHide = useCallback(() => { setUpdatePopupVisible(false); }, []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, userName: userName };
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, userName, gridDataUpdate, gridRef, searchApi]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 행 클릭
    const onRowClick = useCallback(({ key }) => {
        keyDataChange(key);
        setPanelOpened(true);
    }, [keyDataChange, setPanelOpened]);

    // 삭제 경고 확인 클릭
    const onDeleteClick = useCallback(async () => {
        await fwUtil.apis.delete(deleteApi, gridRef, deleKey, handleSearch);
        deleteAlertHide();
    }, [gridRef, handleSearch, deleteAlertHide, deleteApi]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '사용자명', value: userName, valueChange: userNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userName, userNameChange, handleSearch]);  

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setUpdatePopupVisible(true);
                return;
            }
            if (act === 'del') {
                setDeleteAlertVisible(true);
                return;
            }  
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                onRowClick={onRowClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                ins={{ onClick: () => setInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                del={{ onClick: () => handleActionClick('del'), disabled: authList.auth_dele === 0 }}
                exp={{ disabled: authList.auth_dnlo === 0 }}
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "user_idno", caption: "사용자ID" })}
                {CommCol({ dataField: "user_name", caption: "성명[한글]" })}
                {CommCol({ dataField: "user_enam", caption: "성명[영문]" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {DateCol({ dataField: "regi_date", caption: "입사일" })}
                {DateCol({ dataField: "resi_date", caption: "퇴사일" })}
                {CommCol({ dataField: "tele_numb", caption: "전 화" })}
                {CommCol({ dataField: "cell_numb", caption: "휴대폰" })}
                {CommCol({ dataField: "faxs_numb", caption: "팩 스" })}
                {CommCol({ dataField: "mail_idno", caption: "E-Mail" })}
                {DateCol({ dataField: "user_date", caption: "최초등록일" })}
                {CommCol({ dataField: "modi_name", caption: "최종수정자" })}
                {DateCol({ dataField: "modi_date", caption: "최종수정일" })}
            </PagingGrid>
            <UserInfoPublDetail 
                gridRef={gridRef}
                keyData={keyData}
                refresh={handleSearch}
                tar={{ api: targetApi }}
                upd={{ api: updateApi, disabled: authList.auth_upda === 0 }}
                isPanelOpened={isPanelOpened}
                panelOpendChange={panelOpendChange}
                authList={authList}
            />
            <UserInfoRegiInsert
                refresh={handleSearch}
                insertApi={insertApi}
                visible={insertPopupVisible}
                hiding={insertPopupHide}
            />
            <UserInfoRegiUpdate
                refresh={handleSearch}
                mainGrid={gridRef}
                targetApi={targetApi}
                updateApi={updateApi}
                visible={updatePopupVisible}
                hiding={updatePopupHide}
            />            
            <DeleteAlert
                grid={gridRef}
                visible={deleteAlertVisible}
                hiding={deleteAlertHide}
                confirm={onDeleteClick}
                message={'해당 사용자를 삭제하시겠습니까?'}
            />                 
        </div>
    );
};