import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';


const defaultData = {
    matl_code: '',
    matl_name: '',
    matl_spec: '',
    matl_leng: 0,
    matl_quli: '',
    puro_unit: '',
    stoc_unit: '',
    matl_gubu: '원재료',
    matl_upri: 0,
    safe_qtys: 0.000,
    unit_weig: 0.000,
    qtys_weig: 0.000,
    regi_date: fwUtil.date.getsText.date(),
    enab_yesn: true,
    matl_memo: '',
};

export function MatlInfoRegiUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                matl_code: seleData.matl_code || '',
                matl_name: seleData.matl_name || '',
                matl_spec: seleData.matl_spec || '',
                matl_leng: seleData.matl_leng || 0,
                matl_quli: seleData.matl_quli || '',
                puro_unit: seleData.puro_unit || '',
                stoc_unit: seleData.stoc_unit || '',
                matl_gubu: seleData.matl_gubu || '',
                matl_upri: seleData.matl_upri || 0,
                forw_qtys: seleData.forw_qtys || 0.000,
                forw_mont: seleData.forw_mont || 0,
                forw_days: seleData.forw_days || 0,                  
                safe_qtys: seleData.safe_qtys || 0.000,
                unit_weig: seleData.unit_weig || 0.000,
                qtys_weig: seleData.qtys_weig || 0.000,
                regi_date: seleData.regi_date || null,
                enab_yesn: seleData.enab_yesn || false,
                matl_memo: seleData.matl_memo || '',
            };

            dataUpdate(resData);
        }
    }, [dataUpdate, hiding, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            matlCode: data.matl_code || '',
            matlName: data.matl_name || '',
            matlSpec: data.matl_spec || '',
            matlLeng: data.matl_leng || 0,
            matlQuli: data.matl_quli || '',
            puroUnit: data.puro_unit || '',
            stocUnit: data.stoc_unit || '',
            matlGubu: data.matl_gubu || '',
            matlUpri: data.matl_upri || 0,
            forwQtys: data.forw_qtys || 0.000,
            forwMont: data.forw_mont || 0,
            forwDays: data.forw_days || 0,              
            safeQtys: data.safe_qtys || 0.000,
            unitWeig: data.unit_weig || 0.000,
            qtysWeig: data.qtys_weig || 0.000,
            regiDate: data.regi_date ? fwUtil.date.convTime.fmDate(data.regi_date) : null,
            enabYesn: data.enab_yesn || false,
            matlMemo: data.matl_memo || '',
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'자재 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 900 }  
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : 3 }>
                {FormText({ value: data.matl_code, onValueChange: updateField('matl_code'), label: '자재코드', required: true, disabled: true })}
                {FormText({ value: data.matl_name, onValueChange: updateField('matl_name'), label: '자재명', required: true })}
                {FormBool({ value: data.enab_yesn, onValueChange: updateField('enab_yesn'), label: '사용여부' })}
                {FormDate({ value: data.regi_date, onValueChange: updateField('regi_date'), label: '등록일자' })}
                {FormText({ value: data.puro_unit, onValueChange: updateField('puro_unit'), label: '구매단위', required: true })}
                {FormText({ value: data.stoc_unit, onValueChange: updateField('stoc_unit'), label: '저장단위', required: true })}
                {FormSelc({ value: data.matl_gubu, onValueChange: updateField('matl_gubu'), label: '품목계정', items: ['원재료', '부재료'] })}
                {FormNumb({ value: data.matl_upri, onValueChange: updateField('matl_upri'), label: '자재단가', length: [10, 0] })}
                {FormNumb({ value: data.safe_qtys, onValueChange: updateField('safe_qtys'), label: '안전재고수량', length: [15, 3] })}
                {FormNumb({ value: data.forw_qtys, onValueChange: updateField('forw_qtys'), label: '자동불출수량', length: [15, 3] })}
                {FormNumb({ value: data.forw_mont, onValueChange: updateField('forw_mont'), label: '자동불출주기(개월)', length: [5, 0] })}
                {FormNumb({ value: data.forw_days, onValueChange: updateField('forw_days'), label: '자동불출주기(일)', length: [5, 0] })}                
                {FormNumb({ value: data.unit_weig, onValueChange: updateField('unit_weig'), label: '단위중량(m당)', length: [15, 3] })}
                {FormNumb({ value: data.qtys_weig, onValueChange: updateField('qtys_weig'), label: '단위중량(개당)', length: [15, 3] })}
                {FormNumb({ value: data.matl_leng, onValueChange: updateField('matl_leng'), label: '길이', length: [10, 0] })}
            </GroupItem>
            {FormText({ value: data.matl_quli, onValueChange: updateField('matl_quli'), label: '재질' })}
            {FormText({ value: data.matl_spec, onValueChange: updateField('matl_spec'), label: '사양(가로*세로*두께)' })}
            {FormMemo({ value: data.matl_memo, onValueChange: updateField('matl_memo'), label: '비고' })}
        </PopupForm>     
    );
}