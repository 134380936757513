import React from "react";
import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { useCallback } from "react";
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { CommChart } from "../../../../script/components/chart/charts";
import { BarsSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

const tooltipTamplate = (arg) => {
    return {
        text: `${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}`,
    };
};

export function DashMainItemCard(props) {
    const { loading, mainData, subsSearch } = props;

    const mainGridClick = useCallback(({ key }) => {
        key && subsSearch(key);
    }, [subsSearch]);

    return (
        <DashCard
            title={"KPI 현황"}
            addClass={'main'}
            loading={loading}
        >
            <ScrollGrid
                gridDataSource={mainData}
                height={250}
                mainKey={"kpis_gubu"}
                selection={'single'}
                direction={'vertical'}
                onRowClick={mainGridClick}
            >
                {CommCol({ dataField: "kpis_gubu", caption: "분야", allowFiltering: false, allowSorting: false, allowResizing: false, width: 50 })}
                {CommCol({ dataField: "kpis_name", caption: "핵심성과지표(KPI)", allowFiltering: false, allowSorting: false, allowResizing: false, width: 140 })}
                {CommCol({ dataField: "kpis_unit", caption: "단위", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_befo", caption: "기존(구축 전)", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_afte", caption: "목표(구축 후)", allowFiltering: false, allowSorting: false, allowResizing: false })}
                {DeciCol({ dataField: "kpis_valu", caption: "측정값", allowFiltering: false, allowSorting: false, allowResizing: false })}
            </ScrollGrid>
            <CommChart
                dataSource={mainData}
                tooltip={{ location: "edge", template: tooltipTamplate }}
                legend={{ vertical: "bottom", horizontal: "center" }}
                rotate
            >
                {BarsSeri({ valueField: "kpis_valu", caption: "현재", color: "#A4F590", args: "kpis_name" })}
                {BarsSeri({ valueField: "kpis_afte", caption: "목표", color: "#8EB5FF", args: "kpis_name" })}
                {BarsSeri({ valueField: "kpis_befo", caption: "기존", color: "#FF8686", args: "kpis_name" })}
            </CommChart>
        </DashCard>
    )
}