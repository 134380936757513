import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormGrid, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, NumbCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { set } from '../../../../../api/set';

const defaultData = {
    ppro_numb: '',
    clie_code: '',
    clie_name: '',
    clie_teln: '',
    clie_faxn: '',
    clie_damd: '',
    pdel_date: null,
    pays_cond: '',
    orde_numb: '',
    insp_damd: '',
    deli_plac: '',
    ppro_remk: '',
    damd_name: '',
    ppro_date: fwUtil.date.getsText.date(),
    ppro_usag: '',
    afte_conf: false,
};

const setPpro = set.mes.pro.ppro;

const dateList = ['납기일자', '등록일자'];

export function PproInfoRegiMainInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 담당자, 지불조건 리스트
    const [damdList, setDamdList] = useState([]);
    const [paysList, setPaysList] = useState([]);
    const damdListChange = useCallback((e) => setDamdList(e), []);    
    const paysListChange = useCallback((e) => setPaysList(e), []); 
    // 그리드
    const ordeGridRef = useRef(null);
    const clieGridRef = useRef(null);
    const [ordeData, setOrdeData] = useState([]);
    const [clieData, setClieData] = useState([]);
    const ordeGridUpdate = useCallback((e) => setOrdeData(e), []);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const ordeKeyExpr = 'orde_numb';
    const clieKeyExpr = 'clie_code';
    // 그리드 조회 조건
    const [clieName, setClieName] = useState('');
    const clieNameChange = useCallback((e) => setClieName(e), []);
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);
    const [dateGubu, setDateGubu] = useState("납기일자")
    const [ordeClie, setOrdeClie] = useState("");
    const [ordeItem, setOrdeItem] = useState("");

    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const dateGubuChange = useCallback((e) => { setDateGubu(e) }, []); 
    const ordeClieChange = useCallback((e) => { setOrdeClie(e) }, []);
    const ordeItemChange = useCallback((e) => { setOrdeItem(e) }, []);      
    // 그리드 팝업
    const [ordePopupVisible, setOrdePopupVisible] = useState(false);
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const ordePopupHiding = useCallback(() => setOrdePopupVisible(false), []);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    // 조회
    const ordeHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, dateGubu: dateGubu, clieName: ordeClie, itemName: ordeItem };
        const res = await setPpro.orde(sendData);
        ordeGridUpdate(res);
    }, [dateValue, duraYesn, dateGubu, ordeClie, ordeItem, ordeGridUpdate]);

    const clieHandleSearch = useCallback(async() => {
        const sendData = { clieName: clieName };
        const res = await setPpro.clie(sendData);
        clieGridUpdate(res);
    }, [clieName, clieGridUpdate]);

    // 그리드 추가 툴바 아이템
    const ordeToolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '일자구분', value: dateGubu, valueChange: dateGubuChange, search: ordeHandleSearch, items: dateList },
        { type: 'text', locate: 'before', caption: '고객명', value: ordeClie, valueChange: ordeClieChange, search: ordeHandleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: ordeItem, valueChange: ordeItemChange, search: ordeHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: ordeHandleSearch },
    ], [dateGubu, dateGubuChange, ordeClie, ordeClieChange, ordeItem, ordeItemChange, ordeHandleSearch]);

    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange, search: clieHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: clieHandleSearch },
    ], [clieName, clieNameChange, clieHandleSearch]);

    // 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    const ordePopupOnShowing = useCallback(() => {
        ordeHandleSearch();
    }, [ordeHandleSearch]);

    // 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, 
            clie_code: e.data.clie_code, 
            clie_name: e.data.clie_name, 
            clie_damd: e.data.damd_name, 
            clie_teln: e.data.damd_teln, 
            clie_faxn: e.data.damd_faxn 
        });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const ordeOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, orde_numb: e.data.orde_numb, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        ordePopupHiding();
    }, [data, dataUpdate, ordePopupHiding]);

    const fetchData = useCallback(async () => {
        try {
            const [damdResult, paysResult] = await Promise.all([setPpro.damd(), setPpro.pays()]);
            if (damdResult && damdResult.length > 0) {
                damdListChange(damdResult);
            }
            if (paysResult && paysResult.length > 0) {
                paysListChange(paysResult);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [damdListChange, paysListChange]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchData();
        dataUpdate(defaultData);
    }, [fetchData, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            pproNumb: data.ppro_numb || '',
            clieCode: data.clie_code || '',
            clieName: data.clie_name || '',
            clieTeln: data.clie_teln || '',
            clieFaxn: data.clie_faxn || '',
            clieDamd: data.clie_damd || '',
            pdelDate: data.pdel_date ? fwUtil.date.convTime.fmDate(data.pdel_date) : null,
            paysCond: data.pays_cond || '',
            ordeNumb: data.orde_numb || '',
            inspDamd: data.insp_damd || '',
            deliPlac: data.deli_plac || '',
            pproRemk: data.ppro_remk || '',
            damdName: data.damd_name || '',
            pproDate: data.ppro_date ? fwUtil.date.convTime.fmDate(data.ppro_date) : fwUtil.date.getsText.date(),
            pproUsag: data.ppro_usag || '',
            afteConf: data.afte_conf ? '후결' : '미결',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    const ordeGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'수주'}
                role={'목록'}
                gridDataSource={ordeData}
                gridRef={ordeGridRef}
                mainKey={ordeKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : isMedium ? 860 : 1200}
                height={600}
                hideColumnChooser
                onRowDblClick={ordeOnRowDblClick}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                toolbar={{ default: true, items: ordeToolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_name", caption: "고객명" })}
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
                {CommCol({ dataField: "item_desc", caption: "규격" })}
                {NumbCol({ dataField: "orde_qtys", caption: "수주수량" })}
                {CommCol({ dataField: "orde_unit", caption: "수주단위" })}
                {NumbCol({ dataField: "orde_pric", caption: "수주단가" })}
                {NumbCol({ dataField: "orde_kwon", caption: "수주금액" })}       
                {DateCol({ dataField: "orde_date", caption: "수주일자" })}
                {DateCol({ dataField: "deli_date", caption: "납기일자" })}
            </ScrollGrid>
        )
    }, [dateValue, dateValueChange, duraYesn, duraYesnChange, ordeData, ordeGridRef, ordeKeyExpr, ordeOnRowDblClick, ordeToolbarItems, isXSmall, isSmall, isMedium]);

    return (
        <>
        <PopupGrid
            title={'수주 선택'}
            visible={ordePopupVisible}
            shown={ordePopupOnShowing}
            hiding={ordePopupHiding}
        >
            {ordeGrid}
        </PopupGrid>        
        <PopupGrid
            title={'납품업체 선택'}   
            type={'선택'}
            visible={cliePopupVisible}  
            shown={cliePopupOnShowing}  
            hiding={cliePopupHiding}
        >   
            <ScrollGrid
                title={'업체'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "업체코드" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "damd_name", caption: "담당자" })}
                {CommCol({ dataField: "damd_teln", caption: "전화번호" })}
                {CommCol({ dataField: "damd_faxn", caption: "팩스번호" })}
            </ScrollGrid>
        </PopupGrid>                   
        <PopupForm
            title={'발주 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 900}
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : 3} >
                {FormText({ value: data.ppro_numb, onValueChange: updateField('ppro_numb'), label: "발주번호[자동]", disabled: true })}
                {FormGrid({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호', onClick: () => setOrdePopupVisible(true) })}
                {FormBool({ value: data.afte_conf, onValueChange: updateField('afte_conf'), label: "후결" })}                
                {FormSelc({ value: data.damd_name, onValueChange: updateField('damd_name'), label: "발주담당자", items: damdList, required: true })}
                {FormDate({ value: data.ppro_date, onValueChange: updateField('ppro_date'), label: "발주일자", required: true })}                
                {FormText({ value: data.clie_damd, onValueChange: updateField('clie_damd'), label: "담당자" })}
                {FormText({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: "검수자(방법)" })}
                {FormText({ value: data.clie_teln, onValueChange: updateField('clie_teln'), label: "전화번호", placeholder: "-포함 입력" })}
                {FormText({ value: data.clie_faxn, onValueChange: updateField('clie_faxn'), label: "팩스번호", placeholder: "-포함 입력" })}                
                <GroupItem colSpan={isXSmall ? 1 : isSmall ? 2 : 3}>
                    {FormMemo({ value: data.ppro_usag, onValueChange: updateField('ppro_usag'), label: "용도" })}
                </GroupItem>
                {FormGrid({ value: data.clie_name, onClick: () => setCliePopupVisible(true), label: "납품업체", required: true })}
                {FormDate({ value: data.pdel_date, onValueChange: updateField('pdel_date'), label: "납품일자" })}
                {FormSelc({ value: data.pays_cond, onValueChange: updateField('pays_cond'), label: "지불조건", items: paysList, required: true })}
                {FormText({ value: data.deli_plac, onValueChange: updateField('deli_plac'), label: "납품장소" })}
            </GroupItem>
            {FormMemo({ value: data.ppro_remk, onValueChange: updateField('ppro_remk'), label: "특기사항" })}
        </PopupForm>            
        </>
    );
}