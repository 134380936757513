import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    // 출하지시정보
    insp_numb: '',
    stoc_loca: '',
    work_numb: '', 
    rout_seri: '',
    clie_code: '',
    clie_name: '',      
    item_code: '',
    item_name: '',
    insp_qtys: 0,
    // 불량정보
    insp_date: fwUtil.date.getsText.date(),
    insp_damd: '',
    judg_qtys: 0,
    infe_ca01: false,
    infe_ca02: false,
    infe_ca03: false,
    infe_ca04: false,
    infe_ca05: false,
    infe_ca06: false,
    infe_ca07: true,
    ca07_memo: '',  
};

export function ShipQcisChanUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedMainRowData = fwUtil.grid.get.sData(mainGrid);
        if (selectedMainRowData) {
            const newData = {
                insp_numb: selectedMainRowData.insp_numb || defaultData.insp_numb,
                work_numb: selectedMainRowData.work_numb || defaultData.work_numb,
                rout_seri: selectedMainRowData.rout_seri || defaultData.rout_seri,
                item_code: selectedMainRowData.item_code || defaultData.item_code,
                item_name: selectedMainRowData.item_name || defaultData.item_name,
                insp_qtys: selectedMainRowData.insp_qtys || defaultData.insp_qtys,
                judg_qtys: selectedMainRowData.judg_qtys || defaultData.judg_qtys,
                insp_damd: selectedMainRowData.insp_damd || defaultData.insp_damd,
                insp_date: selectedMainRowData.insp_date || defaultData.insp_date,
                judg_yesn: selectedMainRowData.judg_yesn || defaultData.judg_yesn,
                infe_ca01: selectedMainRowData.infe_ca01 || defaultData.infe_ca01,
                infe_ca02: selectedMainRowData.infe_ca02 || defaultData.infe_ca02,
                infe_ca03: selectedMainRowData.infe_ca03 || defaultData.infe_ca03,
                infe_ca04: selectedMainRowData.infe_ca04 || defaultData.infe_ca04,
                infe_ca05: selectedMainRowData.infe_ca05 || defaultData.infe_ca05,
                infe_ca06: selectedMainRowData.infe_ca06 || defaultData.infe_ca06,
                infe_ca07: true,
                ca07_memo: selectedMainRowData.ca07_memo || defaultData.ca07_memo,
                stoc_loca: selectedMainRowData.stoc_loca || defaultData.stoc_loca,
                clie_code: selectedMainRowData.clie_code || defaultData.clie_code,
                clie_name: selectedMainRowData.clie_name || defaultData.clie_name,                
            }
            dataUpdate(newData);
        } else {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, hiding, mainGrid]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            inspNumb: data.insp_numb || defaultData.insp_numb,
            workNumb: data.work_numb || defaultData.work_numb,
            routSeri: data.rout_seri || defaultData.rout_seri,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            inspQtys: data.insp_qtys || defaultData.insp_qtys,
            judgQtys: data.judg_qtys || defaultData.judg_qtys,
            inspDamd: data.insp_damd || defaultData.insp_damd,
            inspDate: data.insp_date || defaultData.insp_date,
            judgYesn: data.judg_yesn || defaultData.judg_yesn,
            infeCa01: data.infe_ca01 || defaultData.infe_ca01,
            infeCa02: data.infe_ca02 || defaultData.infe_ca02,
            infeCa03: data.infe_ca03 || defaultData.infe_ca03,
            infeCa04: data.infe_ca04 || defaultData.infe_ca04,
            infeCa05: data.infe_ca05 || defaultData.infe_ca05,
            infeCa06: data.infe_ca06 || defaultData.infe_ca06,
            infeCa07: data.judg_qtys > 0,
            ca07Memo: data.ca07_memo || defaultData.ca07_memo,
            stocLoca: data.stoc_loca || defaultData.stoc_loca,
            clieCode: data.clie_code || defaultData.clie_code,
            clieName: data.clie_name || defaultData.clie_name,  
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'공정검사 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? '100%' : isMedium ? 600 : 1200 }
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 4}>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form insp-info'>
                    {FormText({ value: data.insp_numb, onValueChange: updateField('insp_numb'), label: '검사번호', disabled: true })}
                    {FormText({ value: data.stoc_loca, onValueChange: updateField('stoc_loca'), label: '저장위치', disabled: true })}
                    {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '출하지시번호', disabled: true })}
                    {FormText({ value: data.rout_seri, onValueChange: updateField('rout_seri'), label: '출하지시순번', disabled: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '업체명', disabled: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '품명', disabled: true })}
                    {FormText({ value: data.insp_qtys, onValueChange: updateField('insp_qtys'), label: '검사수량', disabled: true })}
                </GroupItem>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form insp-judg'>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자' })}
                        {FormText({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: '검사자' })}
                        {FormNumb({ value: data.judg_qtys, onValueChange: updateField('judg_qtys'), label: '불합격수량' })}
                        {FormBool({ value: data.judg_qtys > 0, onValueChange: updateField('infe_ca07'), label: '기타', disabled: true })}
                    </GroupItem>                    
                    <GroupItem colSpan={isXSmall ? 1 : 2}>
                        {FormMemo({ value: data.ca07_memo, onValueChange: updateField('ca07_memo'), label: '불량사유', disabled: data.judg_qtys <= 0 })}
                    </GroupItem>
                </GroupItem>      
            </GroupItem>
        </PopupForm> 
        </>
    );
}