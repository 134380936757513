import { TabPanel, LoadPanel, Button  } from "devextreme-react";
import './panel-body.scss';

const PageTabPanel = (props) => {
    const { 
        title, role, height, tabRef,
        loading, children,
        refresh, exp, upd,
        index, indexChange
    } = props;

    const tabTitle = `${title} ${role}`

    return (
        <>
        <div className="fw-page-tab-header">
            <div className="fw-page-tab-header-wrapper">
                <div className="fw-page-tab-title">{tabTitle}</div>
                <div className="fw-page-tab-item-wrapper">
                    { refresh &&
                    <Button 
                        icon='refresh'
                        text='화면갱신'
                        onClick={refresh}
                    />                
                    }
                    { exp &&
                    <Button 
                        icon='download'
                        text='다운로드'
                        disabled={exp.disabled}
                        onClick={exp.onClick && exp.onClick}
                    />
                    }
                    { upd &&
                    <Button
                        icon='edit'
                        text='수정'
                        disabled={upd.disabled}
                        onClick={upd.onClick && upd.onClick}                    
                    />
                    }
                </div>                
            </div>            
        </div>
        <TabPanel
            className="fw-page-tab-panel"
            ref={tabRef}
            height={height}
            tabsPosition="left"
            loop
            animationEnabled
            swipeEnabled={false}
            selectedIndex={index}
            onSelectedIndexChange={indexChange}
        >
            {children}
        </TabPanel>
        <LoadPanel container='.content' visible={loading} position={{ of: '.layout-body' }} />      
        </>
    )
}

export {
    PageTabPanel,
}