import React, { useState, useEffect } from 'react';
import MainImg from '../../../assets/intr-comp-main.png';
import { LoadPanel } from 'devextreme-react';
import { AccountPanelFooter } from './account-footer';
import './account-panel.scss';

export default function AccountPanel({ title, description, children, intrComp, suppComp }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const mainImg = new Image();
    const intrLogoImg = new Image();
    const suppLogoImg = new Image();

    const handleImageLoad = () => {
      setLoading(false);
    };

    mainImg.src = MainImg;
    intrLogoImg.src = intrComp.logo;
    suppLogoImg.src = suppComp.logo;

    mainImg.onload = intrLogoImg.onload = suppLogoImg.onload = handleImageLoad;

    return () => {
      mainImg.onload = null;
      intrLogoImg.onload = null;
      suppLogoImg.onload = null;
    };
  }, [intrComp.logo, suppComp.logo]);

  return (
    <>
      <div className={'fw-account-page fw-flex-box'}>
        {loading ? (
          <LoadPanel />
        ) : (
          <div className={'fw-account-wrapper fw-flex-box'}>
            <div className={'fw-main-content fw-flex-box col'}>
              <img src={MainImg} alt='수요기업 로고' className={'main-img'} />
              <div className='fw-flex-box row a-center logo-content'>
                <div className={'logo-text'}>
                  <div className={'logo-detail'}>{intrComp.catc}</div>
                  <div className={'logo-title'}>{intrComp.knam}</div>
                </div>
              </div>
            </div>
            <div className={'fw-content'}>
              <div className={'header'}>
                <div className={'title'}>{title}</div>
                <div className={'description'}>{description}</div>
              </div>
              {children}
            </div>
          </div>
        )}
      </div>
      <AccountPanelFooter suppComp={suppComp} />
    </>
  );
}