import AserInfoRegi from "./aser/InfoRegi";
import AserCompRegi from "./comp/InfoRegi";
import AserAccoStat from "./acco/InfoStat";
import AserAccoRegi from "./acco/InfoRegi";
import AserAccoChan from "./acco/InfoChan";
import AserCompStat from "./comp/InfoStat";

const catbAser = ({ acce, gubu, cata }) => {
    const catb = "aser";
    const comp = {
        infoRegi: () => (<AserInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N1" mainTitle="A/S" mainRole="관리" />),
    }

    return comp;
};

const catbComp = ({ acce, gubu, cata }) => {
    const catb = "comp";
    const comp = {
        infoStat: () => (<AserCompStat acce={acce} gubu={gubu} cata={cata} catb={catb} mainTitle="운영점검" mainRole="현황" subsTitle="소모부품" subsRole="현황" />),
        infoRegi: () => (<AserCompRegi acce={acce} gubu={gubu} cata={cata} catb={catb} mainTitle="운영점검" mainRole="관리" subsTitle="운영점검" subsRole="상세" />),
    }

    return comp;
};

const catbAcco = ({ acce, gubu, cata }) => {
    const catb = "acco";
    const comp = {
        infoStat: () => (<AserAccoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="N41" mainTitle="정산" mainRole="현황" />),
        infoRegi: () => (<AserAccoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="N42" mainTitle="정산" mainRole="등록" />),
        infoChan: () => (<AserAccoChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="N43" mainTitle="정산" mainRole="변경" />),
    }

    return comp;
};

export const cataBiz = ({ acce, gubu }) => {
    const cata = "biz"
    const comp = {
        catbAser: catbAser({ acce: acce, gubu: gubu, cata: cata }),
        catbComp: catbComp({ acce: acce, gubu: gubu, cata: cata }),
        catbAcco: catbAcco({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}