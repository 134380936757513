import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getBlueClieList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/tec/blue/regist/clie/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getBlueClieList.error === ', error);
        return [];
    }
};

const getBlueItemList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/tec/blue/regist/item/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getBlueItemList.error === ', error);
        return [];
    }
};

const getBlueGubuList = async () => {
    try {
        const api =  `privat${mesGubu}/set/tec/blue/regist/gubu/list`;
        const data = await fwUtil.apis.search(api);
        let convData = [];
        if (data && data.length > 0) {
            convData = data.map(item => item.subs_name);
        }
        return convData;        
    }
    catch (error) {
        console.log('getBlueGubuList.error === ', error);
        return [];
    }
};

const getDesiClieList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/tec/desi/regist/clie/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getDesiClieList.error === ', error);
        return [];
    }
};

const getDesiItemList = async (sendData) => {
    try {
        const api = `privat${mesGubu}/set/tec/desi/regist/item/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getDesiItemList.error === ', error);
        return [];
    }
};


export const tec = {
    blue: {
        clie: getBlueClieList,
        item: getBlueItemList,
        gubu: getBlueGubuList,
    },
    desi: {
        clie: getDesiClieList,
        item: getDesiItemList,
    },    
}