import ShipReqeStat from "./shipreqe/InfoStat";
import ShipReqeRegi from "./shipreqe/InfoRegi";
import ShipReqeChan from "./shipreqe/InfoChan";
import PlanInfoRegi from "./plan/InfoRegi";
import ProductsRegi from "./product/InfoRegi";
import ProductsStat from "./product/InfoStat";
import WorkOrdeRegi from "./work-order/InfoRegi";
import WorkRoutRegi from "./work-order/RoutRegi";
import WorkOrdeRepo from "./work-order/InfoRepo";
import StocItemStat from "./stock/ItemStat";
import StocWorkStat from "./stock/WorkStat";

const catbShip = ({ acce, gubu, cata }) => {
    const catb = "shipreqe";
    const comp = {
        infoStat: () => (<ShipReqeStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D51" mainTitle="출하지시" mainRole="현황" subsTitle="출하지시" subsRole="상세" />),
        infoRegi: () => (<ShipReqeRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D52" mainTitle="출하지시" mainRole="등록" />),
        infoChan: () => (<ShipReqeChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="D53" mainTitle="출하지시" mainRole="변경" />),
    };

    return comp;
};

const catbPlan = ({ acce, gubu, cata }) => {
    const catb = "plan";
    const comp = {
        infoRegi: () => (<PlanInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D1" mainTitle="생산계획" mainRole="현황" subsTitle="월별 생산게획" subsRole="현황" />),
    };

    return comp;
};

const catbWork = ({ acce, gubu, cata }) => {
    const catb = "work";
    const comp = {
        infoRegi: () => (<WorkOrdeRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D21" mainTitle="작업지시" mainRole="등록" subsTitle="작업지시 품목" subsRole="등록" />),
        routRegi: () => (<WorkRoutRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D22" mainTitle="작업지시 품목" mainRole="등록" subsTitle="작업지시 공정" subsRole="등록" />),
        infoRepo: () => (<WorkOrdeRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="D23" mainTitle="지시서" mainRole="출력" subsTitle="작업" subsRole="상세" />),
    };

    return comp;
};

const catbProd = ({ acce, gubu, cata }) => {
    const catb = "prod";
    const comp = {
        infoStat: () => (<ProductsStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D31" mainTitle="작업일보" mainRole="현황" subsTitle="공정별 작업일보" subsRole="현황" />),
        infoRegi: () => (<ProductsRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="D32" mainTitle="작업일보" mainRole="관리" subsTitle="공정별 작업일보" subsRole="관리" />),
    };

    return comp;
};

const catbStoc = ({ acce, gubu, cata }) => {
    const catb = "stock";
    const comp = {
        itemStat: () => (<StocItemStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D41" mainTitle="제품" mainRole="현황" />),        
        workStat: () => (<StocWorkStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="D42" mainTitle="재공품" mainRole="현황" />),    
    }

    return comp;
};


export const cataMfg = ({ acce, gubu }) => {
    const catMfg = 'mfg'
    const catMat = 'mat'
    const comp = {
        catbShip: catbShip({ acce: acce, gubu: gubu, cata: catMfg }),
        catbPlan: catbPlan({ acce: acce, gubu: gubu, cata: catMfg }),
        catbWork: catbWork({ acce: acce, gubu: gubu, cata: catMfg }),
        catbProd: catbProd({ acce: acce, gubu: gubu, cata: catMfg }),
        catbStoc: catbStoc({ acce: acce, gubu: gubu, cata: catMat }),
    }

    return comp;
}