import axios from 'axios';
import * as apisUtil from './utility/util-apis';
import * as convUtil from './utility/util-conv';
import * as dataUtil from './utility/util-data';
import * as dateUtil from './utility/util-date';
import * as gridUtil from './utility/util-grid';
import * as alerUtil from './utility/util-aler';

function axiosInstanceCreate(configs) {
    let axiosConfig = { ...configs, withCredentials: true };

    // 개발 환경일 때만 baseURL 설정
    if (process.env.NODE_ENV === 'development') {
        axiosConfig.baseURL = 'https://localhost';         // 개발 환경의 baseURL
    } else if (process.env.NODE_ENV === 'test') {
        axiosConfig.baseURL = 'http://192.168.0.05:8888';  // 테스트 환경 baseURL
    }

    //const axiosInstance = axios.create({ ...configs, withCredentials: true }); // 빌드할 때
    //const axiosInstance = axios.create({ ...configs, baseURL: 'https://localhost', withCredentials: true }); // 개발할 때
    //const axiosInstance = axios.create({ baseURL: 'http://192.168.0.05:8888', withCredentials: true });
    //const axiosInstance = axios.create({ baseURL: 'http://focuswin.iptime.org:8888', withCredentials: true });

    const axiosInstance = axios.create(axiosConfig);

    axiosInstance.interceptors.request.use(
        (config) => {
            return config;
        },
        (error) => {
            return Promise.reject(error);
        });

    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

const apis = {
    create: apisUtil.creatApi,
    touchs: apisUtil.touchData,
    search: apisUtil.searchData,
    submit: apisUtil.submitData,
    action: apisUtil.actionData,
    delete: apisUtil.deleteData,
    fileax: apisUtil.fileAxio,
    filedn: apisUtil.fileDown,
};

const conv = {
    to: convUtil.to,
    tern: convUtil.ternFunc,
    class: convUtil.makeClass,
    deepObj: convUtil.deepObjEqual,
    check: convUtil.check,
}

const data = {
    report: dataUtil.reportData,
    export: dataUtil.exportData,
    valdit: dataUtil.valditData,
    alerts: dataUtil.alertsData,
    screen: dataUtil.screenData,
}

const date = {
    getsText: dateUtil.getsText,
    getsList: dateUtil.getsList,
    convTime: dateUtil.convTime,
}

const grid = {
    sel: gridUtil.sel,
    get: gridUtil.get,
};

const aler = {
    toast: alerUtil.toast,
}

const height = {
    window: window.innerHeight > 850 ? window.innerHeight : 850,
    header: 48,
    footer: 50,
    grid: {
        header: 53,
        footer: 56,
        row: {
            head: 42.5,
            body: 37,
        }
    }
}

export const fwUtil = {
    axiosInstanceCreate: axiosInstanceCreate,
    height: height,
    apis: apis,
    conv: conv,
    data: data,
    date: date,
    grid: grid,
    aler: aler,
}