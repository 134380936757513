// PremResuStat - 결과 현황
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol} from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PremResuStat(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
        subsTitle, subsRole,
    } = props; 

    // api
    const mainSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/header/search`;  // 메인 조회 api
    const subsSearchApi = `${acce}/${gubu}/${cata}/${catb}/status/detail/search`;  // 서브 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = 'dual';
    const mainKey = ['main_numb', 'prem_seri'];                      // 메인 데이터 키
    const subsKey = ['main_numb', 'prem_seri', 'chck_seri'];         // 서브 데이터 키    
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);        
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [equiName, setEquiName] = useState("");
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const equiNameChange = useCallback((e) => { setEquiName(e) }, []);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, equiName: equiName };        
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
    }, [dateValue, duraYesn, equiName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async (key) => {
        if (key) {
            const sendData = { mainNumb: key.main_numb, premSeri: key.prem_seri };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
    }, [subsGridDataUpdate, subsGridRef, subsSearchApi]);

    useEffect(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch(key);
        }
    }, [subsHandleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '설비명', value: equiName, valueChange: equiNameChange },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [equiName, equiNameChange, mainHandleSearch]);

    return (
        <div className='fw-grid-page fw-flex-box col'>
            <FwAppLoader loading={loading} />
            <MainGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={mainGridRef}
                gridDataSource={mainGridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}                
                onRowClick={mainGridClick}           
                exp={{ disabled: authList.auth_dnlo === 0 }}   
                toolbar={{ default: true, items: toolbarItems }}
                selection={'single'}
            >
                {CommCol({ dataField: "equi_numb", caption: "설비번호" })}
                {CommCol({ dataField: "prem_seri", caption: "점검순번" })}
                {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                {DateCol({ dataField: "prem_date", caption: "점검일자" })}
                {CommCol({ dataField: "prem_damd", caption: "점검자" })}
                {CommCol({ dataField: "equi_gubu", caption: "설비구분" })}
                {CommCol({ dataField: "equi_name", caption: "설비명" })}
            </MainGrid>
            <SubsGrid
                title={subsTitle}
                role={subsRole}
                gridSize={gridSize}
                mainKey={subsKey}
                gridRef={subsGridRef}
                gridDataSource={subsGridData}
                exp={{ disabled: authList.auth_dnlo === 0 }}     
                toolbar={{ default: false }}
                selection={'single'}
            >
                    {CommCol({ dataField: "chck_part", caption: "점검PART" })}
                    {CommCol({ dataField: "chck_gubu", caption: "점검구분" })}
                    {CommCol({ dataField: "chck_term", caption: "점검주기" })}
                    {CommCol({ dataField: "chck_desc", caption: "점검항목" })}
                    {CommCol({ dataField: "prem_resu", caption: "점검결과" })}
                    {DateCol({ dataField: "plan_date", caption: "계획일자" })}
                    {DateCol({ dataField: "prem_date", caption: "점검일자" })}
                    {CommCol({ dataField: "prem_damd", caption: "점검자" })}
                    {CommCol({ dataField: "prem_memo", caption: "비고" })}
                    {CommCol({ dataField: "prem_remk", caption: "특기사항" })}
            </SubsGrid>                         
        </div>
    );
};