import React, { useState, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { useAuth } from '../../../../../contexts/auth';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';
import AddrStat from './AddrStat';
import UrisStat from './UrisStat';

export default function PathInfoStat(props) {
    const {
        acce, gubu, cata, catb, code,
        ipnoTitle, urisTitle, mainRole, 
    } = props;

    // 시스템
    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    return ( 
        <div className={(isXSmall || isSmall)? 'fw-horizon-grid-wrapper fw-flex-box col' : 'fw-horizon-grid-wrapper fw-flex-box row' }>
            <FwAppLoader loading={loading} />
            <AddrStat acce={acce} gubu={gubu} cata={cata} catb={catb} mainTitle={ipnoTitle} mainRole={mainRole} authList={authList} gridSize={(isXSmall || isSmall)? 'dual' : 'single' } />
            <UrisStat acce={acce} gubu={gubu} cata={cata} catb={catb} mainTitle={urisTitle} mainRole={mainRole} authList={authList} gridSize={(isXSmall || isSmall)? 'dual' : 'single' } />
        </div>        
    );
}

