import html2canvas from 'html2canvas';
import saveAs from 'file-saver';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { jsPDF as JsPdf } from 'jspdf';
import { fwUtil } from '../util';

// 보고서 다운로드
export const reportData = async (api, data) => {
    const axiosConfig = { method: "POST", responseType: "blob" };
    try {
        const axiosInstance = fwUtil.axiosInstanceCreate(axiosConfig);
        const res = await axiosInstance.post(`${api}`, data);
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob); 
        return url;
    } catch (error) {
        console.log('searchData.error === ', error);
        fwUtil.aler.toast.erro('출력에 실패하였습니다.');
        return null;
    }
};

// 데이터 다운로드
export const exportData = (fileName) => (e) => {
    if (e.format === 'pdf') {
        const doc = new JsPdf();
        exportDataGridToPdf({
            jsPDFDocument: doc,
            component: e.component,
        }).then(() => {
            doc.save(`${fileName}.pdf`);
        });
    } else {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet(`${fileName}`);

        exportDataGridToXLSX({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
            });
        });
        e.cancel = true;
    }
};

// 데이터 검증
export function valditData(validationGroup) {
    const validationResult = validationGroup?.validate();
    if (!validationResult.isValid) {
        validationResult.brokenRules[0].validator.focus();
        fwUtil.aler.toast.erro(`${validationResult.brokenRules[0].message}`);
        return false;
    }
    return true;
};

// 그리드 알림 데이터
export const alertsData = (selectedData) => {
    if (fwUtil.conv.check.obj(selectedData)) {
        if (Object.keys(selectedData).length >= 2) {
            return Object.values(selectedData);
        }
    } else {
        return  [selectedData];
    }   
};

// DOM 내보내기 데이터
export function screenData (element, direction, fileName) {

    if (element) {
        html2canvas(element, { 
            scale: 2,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new JsPdf(direction, 'mm', 'a4');

            const isPortrait = direction === 'p';
            const imgWidth = isPortrait ? 210 : 297; // mm
            const imgHeight = isPortrait ? canvas.height * imgWidth / canvas.width : canvas.height * imgWidth / canvas.width;
            const pageHeight = isPortrait ? 297 : 210; // mm
            
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft > 0) {
                position = heightLeft - imgHeight;
                if (position > 0) {
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                }
                heightLeft -= pageHeight;
            }

            pdf.save(`${fileName}.pdf`);
            fwUtil.aler.toast.info('파일 다운로드를 마쳤습니다.');
        }).catch(error => {
            console.error('Error generating PDF:', error);
            fwUtil.aler.toast.erro('파일 추출에 실패하였습니다');
        });
    } else {
        fwUtil.aler.toast.erro('파일 추출에 실패하였습니다');
    }
};