import React from "react";
import { DashToolbar } from "../../toolbar/toolbar";
import './dash-body.scss';

const CommDash = React.memo(function CommDash(props) {
    const {
        title, search, exp,
        dateValue, dateValueChange,
        duraYesn, duraYesnChange,
        toolbar, dashRef,
        children,
    } = props;

    return (
        <>
            <DashToolbar
                title={title}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}
                toolbar={toolbar}
                search={search}
                exp={{ element: dashRef, fileName: exp?.fileName, disabled: exp.disabled }}
            />
            <div className="fw-dash-body" ref={dashRef}>
                {children}
            </div>
        </>
    )
})

export {
    CommDash
}