import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormGrid, FormText, FormDate } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    work_numb: '',
    work_date: fwUtil.date.getsText.date(),
    star_date: null,
    clos_date: null,
    work_clos: false,
    work_stat: '',
    afte_conf: '',
    appr_numb: '',
    clie_code: '',
    clie_name: '',
}

const setWork = set.mes.mfg.work;   

export function WorkOrdeRegiMainUpdate(props) {
    const {
        refresh, 
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const clieGridRef = useRef(null);
    const [clieData, setClieData] = useState([]);
    const clieGridUpdate = useCallback((e) => setClieData(e), []);
    const clieKeyExpr = 'clie_code';
    // 그리드 팝업
    const [cliePopupVisible, setCliePopupVisible] = useState(false);
    const cliePopupHiding = useCallback(() => setCliePopupVisible(false), []);

    const clieHandleSearch = useCallback(async () => {
        const sendData = { clieCode: "", clieName: "" };
        const res = await setWork.clie(sendData);
        clieGridUpdate(res);
    }, [clieGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const resData = {
                work_numb: seleData.work_numb || defaultData.work_numb,
                work_date: seleData.work_date || defaultData.work_date,
                clos_date: seleData.clos_date || defaultData.clos_date,
                clie_code: seleData.clie_code || defaultData.clie_code,
                clie_name: seleData.clie_name || defaultData.clie_name,                
            };

            dataUpdate(resData);
        }
    }, [mainGrid, hiding, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true)
        const postData = {
            workNumb: data.work_numb || defaultData.work_numb,
            workDate: data.work_date || defaultData.work_date,
            closDate: data.clos_date ? fwUtil.date.convTime.fmDate(data.clos_date) : defaultData.clos_date,
            clieCode: data.clie_code || defaultData.clie_code,
            clieName: data.clie_name || defaultData.clie_name,            
        };
        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    // 팝업 열릴 때
    const cliePopupOnShowing = useCallback(() => {
        clieHandleSearch();
    }, [clieHandleSearch]);

    // 팝업 더블 클릭
    const clieOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, clie_code: e.data.clie_code, clie_name: e.data.clie_name });
        cliePopupHiding();
    }, [data, dataUpdate, cliePopupHiding]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'고객'}
                role={'목록'}
                gridDataSource={clieData}
                gridRef={clieGridRef}
                mainKey={clieKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={clieOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "clie_code", caption: "고객번호" })}
                {CommCol({ dataField: "clie_loca", caption: "국내/국외" })}
                {CommCol({ dataField: "clie_gubu", caption: "고객구분" })}
                {CommCol({ dataField: "clie_name", caption: "고객명[한글]" })}
                {CommCol({ dataField: "clie_enam", caption: "고객명[영문]" })}
                {CommCol({ dataField: "clie_memo", caption: "비고" })}
            </ScrollGrid>
        )
    }, [clieData, clieGridRef, clieKeyExpr, clieOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'고객 선택'}
                visible={cliePopupVisible}
                shown={cliePopupOnShowing}
                hiding={cliePopupHiding}
            >
                {itemGrid}
            </PopupGrid>
            <PopupForm
                title={'작업지시 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true })}
                    </GroupItem>
                    {FormGrid({ value: data.clie_code, onClick: () => setCliePopupVisible(true), label: '고객번호', required: true })}
                    {FormText({ value: data.clie_name, onValueChange: updateField('clie_name'), label: '고객명', disabled: true })}    
                    {FormDate({ value: data.work_date, onValueChange: updateField('work_date'), label: '작업지시일' })}
                    {FormDate({ value: data.clos_date, onValueChange: updateField('clos_date'), label: '생산완료일' })}
                </GroupItem> 
            </PopupForm>
        </>
    );
}