// PproWareCanc - 입고 취소
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { PopupAler as CancelAlert } from '../../../../script/components/popup/popup';
import { fwUtil } from '../../../../script/util';
import { FwAppLoader } from '../../../../script/components/load-panel/load-panel';

export default function PproWareCanc(props) {
    const {
        acce, gubu, cata, catb, code,
        mainTitle, mainRole, 
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/cancel/search`;  // 조회 api
    const cancelApi = `${acce}/${gubu}/${cata}/${catb}/cancel/delete`;  // 취소 api
    const [loading, setLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'ware_numb';        
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);    
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(true);
    const [wareNumb, setWareNumb] = useState('');
    const [prosNumb, setProsNumb] = useState('');
    const [matlName, setMatlName] = useState('');
    const [clieName, setClieName] = useState('');
    const [matlCode, setMatlCode] = useState('');
    const dateValueChange= useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const wareNumbChange = useCallback((e) => { setWareNumb(e) }, []);
    const prosNumbChange = useCallback((e) => { setProsNumb(e) }, []);
    const matlNameChange = useCallback((e) => { setMatlName(e) }, []);
    const clieNameChange = useCallback((e) => { setClieName(e) }, []);
    const matlCodeChange = useCallback((e) => { setMatlCode(e) }, []);
    // 취소 알림창
    const [cancelAlertVisible, setCancelAlertVisible] = useState(false);
    const cancelAlertHide = useCallback(() => { setCancelAlertVisible(false); }, []);  

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, wareNumb: wareNumb, prosNumb: prosNumb, matlName: matlName, clieName: clieName, matlCode: matlCode };        
        await fwUtil.apis.search(searchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, wareNumb, prosNumb, matlName, clieName, matlCode, searchApi, gridDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 취소 경고 확인 클릭
    const onCancelClick = useCallback(async () => {
        gridRef.current.instance.beginCustomLoading();
        const selectedData = gridRef.current.instance.getSelectedRowsData() || [];
        const convertdData = selectedData.map((item) => ({
            wareNumb: item.ware_numb,
            prosNumb: item.pros_numb.replace(/-/g, ''),
            matlCode: item.matl_code,
            lotsNumb: item.lots_numb,
            wareQtys: item.ware_qtys,
            warePric: item.ware_pric,
            wareDate: item.ware_date,          
        }));
        const postData = { setaMaps: convertdData }
        await fwUtil.apis.submit(cancelApi, postData, handleSearch);
        gridRef.current.instance.endCustomLoading();
        cancelAlertHide();
    }, [gridRef, handleSearch, cancelApi, cancelAlertHide]);

    // 취소 버튼 클릭
    const handleCancelClick = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);
        if (selectedRowKey) {
            setCancelAlertVisible(true);
            return;
        } else {
            fwUtil.aler.toast.nsWarn();
        };

    }, [gridRef]);   

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '입고번호', value: wareNumb, valueChange: wareNumbChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '발주번호', value: prosNumb, valueChange: prosNumbChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재번호', value: matlCode, valueChange: matlCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '자재명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '업체명', value: clieName, valueChange: clieNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
        { type: 'btns', locate: 'after', icon: 'trash', text: '취소', hidden: false, disabled: authList.auth_dele === 0, onClick: handleCancelClick }
    ], [wareNumb, wareNumbChange, prosNumb, prosNumbChange, matlCode, matlCodeChange, matlName, matlNameChange, clieName, clieNameChange, handleSearch, authList.auth_dele, handleCancelClick]);

    return (
        <div className='fw-grid-page fw-flex-box row'>
            <FwAppLoader loading={loading} />
            <PagingGrid
                title={mainTitle}
                role={mainRole}
                gridSize={gridSize}
                mainKey={mainKey}
                gridRef={gridRef}
                gridDataSource={gridData}
                dateValue={dateValue}
                dateValueChange={dateValueChange}
                duraYesn={duraYesn}
                duraYesnChange={duraYesnChange}             
                toolbar={{ default: true, items: toolbarItems } }
                selection={'multiple'}
            >
                {CommCol({ dataField: "pros_numb", caption: "발주번호" })}
                {DateCol({ dataField: "ware_date", caption: "입고일" })}
                {CommCol({ dataField: "clie_name", caption: "업체명" })}
                {CommCol({ dataField: "matl_code", caption: "자재코드" })}
                {CommCol({ dataField: "matl_name", caption: "자재명" })}
                {CommCol({ dataField: "matl_spec", caption: "규격" })}
                {CommCol({ dataField: "lots_numb", caption: "Lot No." })}
                {DeciCol({ dataField: "ppro_qtys", caption: "발주수량" })}
                {DeciCol({ dataField: "ware_qtys", caption: "입고수량" })}
                {DeciCol({ dataField: "ware_pric", caption: "입고단가" })}
                {DeciCol({ dataField: "stoc-qtys", caption: "현재고량" })}
                {CommCol({ dataField: "ware_memo", caption: "비고" })}
            </PagingGrid>        
            <CancelAlert 
                grid={gridRef}
                visible={cancelAlertVisible}
                hiding={cancelAlertHide}
                confirm={onCancelClick}
                message={'해당 입고를 취소하시겠습니까?'}
            />               
        </div>
    );
};