import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react';
import '../detail-panel.scss';

export function PanelToolbar(props) {
    const {isEditing, toggleEditHandler, onSaveClick, additionalWork, upd} = props;
    return (
        <div className='fw-detail-panel-data-part fw-detail-panel-data-part-toolbar border'>
            <Toolbar>
                { upd &&
                <ToolbarItem location='after' visible={!isEditing}>
                    <Button icon='edit' text='수정' stylingMode='contained' type='default' onClick={toggleEditHandler} disabled={upd.disabled} />
                </ToolbarItem>
                }
                <ToolbarItem location='after' visible={isEditing}>
                    <Button icon='save' text='저장' stylingMode='contained' type='default' onClick={onSaveClick} />
                </ToolbarItem>
                <ToolbarItem location='after' visible={isEditing}>
                    <Button icon='undo' text='취소' stylingMode='outlined' type='normal' onClick={toggleEditHandler} />
                </ToolbarItem>
                { additionalWork &&
                <ToolbarItem location='before'
                    widget='dxDropDownButton'
                    options={{
                        text: '추가작업',
                        stylingMode: 'text',
                        dropDownOptions: { width: 'auto' },
                        width: 100,
                        items: additionalWork.items
                    }} 
                />
                }
            </Toolbar>
        </div>
    )
}