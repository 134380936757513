import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    work_code: '', 
    work_name: '', 
    work_dept: '', 
    mach_kind: '', 
    rout_code: '', 
    rout_name: '', 
    work_pric: 0.00, 
    work_memo: '',    
};

export function CommWorkStatInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        dataUpdate(defaultData);
    }, [dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workCode: data.work_code || '', 
            workName: data.work_name || '', 
            workDept: data.work_dept || '', 
            machKind: data.mach_kind || '', 
            routCode: data.rout_code || '', 
            routName: data.rout_name || '', 
            workPric: data.work_pric || 0.00, 
            workMemo: data.work_memo || '',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <PopupForm
            title={'작업자 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600 }  
        >
            <GroupItem colCount={isXSmall ? 1 : 2 }>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 }>
                    {FormText({ value: data.work_code, onValueChange: updateField('work_code'), label: '작업자코드', required: true, maxLength: 10 })}
                </GroupItem>
                {FormText({ value: data.work_name, onValueChange: updateField('work_name'), label: '작업자명', required: true, maxLength: 20 })}
                {FormText({ value: data.work_dept, onValueChange: updateField('work_dept'), label: '작업부서', required: true, maxLength: 20 })}
                {FormText({ value: data.mach_kind, onValueChange: updateField('mach_kind'), label: '기종' })}
                {FormNumb({ value: data.work_pric, onValueChange: updateField('work_pric'), label: '작업단가', length: [12, 2] })}
                {FormText({ value: data.rout_code, onValueChange: updateField('rout_code'), label: '공정코드', maxLength: 10 })}
                {FormText({ value: data.rout_name, onValueChange: updateField('rout_name'), label: '공정명', maxLength: 20 })}                
            </GroupItem>
            {FormMemo({ value: data.work_memo, onValueChange: updateField('work_memo'), label: '비고' })}
        </PopupForm>
    );
}