import { fwUtil } from "../../../../script/util";

export const innerHeight = {
    window: window.innerHeight > 850 ? window.innerHeight : 850,
    header: 48 + 53,
    footer: 70,
};

const onDragChange = (e) => {
    if (!e) {
        return;
    }
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.menu_code);
    let targetNode = visibleRows[e.toIndex].node;
    while (targetNode && targetNode.data && sourceNode) {
        if (targetNode.data.menu_code === sourceNode.data.menu_code) {
            e.cancel = true;
            break;
        }
        targetNode = targetNode.parent;
    }
};

const onOrdeChange = (e) => {
    const visibleRows = e.component.getVisibleRows();
    const fromData = visibleRows[e.fromIndex].data;
    const toData = visibleRows[e.toIndex].data;
    if (fromData.menu_high !== toData.menu_high) {
        fwUtil.aler.toast.warn("상위 메뉴가 같은 경우에만 변경할 수 있습니다");
        return null;
    } else {
        return { from: fromData, to: toData }
    }
};

const onOrdeBtnsClick = (e, act) => {
    if (!e || !e.component || !e.component.getVisibleRows()) {
        fwUtil.aler.toast.erro('동작 중 이상이 발생했습니다');
        return;
    }
    const rowIndex = e.row.rowIndex;
    const visibleRows = e.component.getVisibleRows();
    const maxLength = visibleRows.length;
    const modNum = act === "up" ? -1 : 1;
    if (rowIndex + 1 + modNum > maxLength || rowIndex + modNum < 0) {
        fwUtil.aler.toast.warn('이동할 수 없는 위치입니다.');        
        return;
    }

    const fromData = visibleRows[e.row.rowIndex].data;
    const toData = visibleRows[e.row.rowIndex + modNum].data;
    if (fromData.menu_high !== toData.menu_high) {
        return fwUtil.aler.toast.info("상위 메뉴가 같은 경우에만 변경할 수 있습니다");
    } else {
        return { from: fromData, to: toData }
    }
};

const onMenuChange = (menuData, from, to, gubu) => {
    const fromList = menuData
        .filter(item => item[gubu] === from[gubu])
        .map(item => ({ ...item, [gubu]: to[gubu] }));

    const remainList = menuData
        .filter(item => item[gubu] !== from[gubu])
        .map(item => {
            if (from[gubu] < to[gubu] && item[gubu] > from[gubu] && item[gubu] <= to[gubu]) {
                return { ...item, [gubu]: item[gubu] - 1 };
            }
            if (from[gubu] > to[gubu] && item[gubu] >= to[gubu] && item[gubu] < from[gubu]) {
                return { ...item, [gubu]: item[gubu] + 1 };
            }
            return item;
        });
    
    return [...fromList, ...remainList].sort((a, b) => a[gubu] - b[gubu]);
}


export const menuFunc = {
    dragChan: onDragChange,
    ordeChan: onOrdeChange,
    menuChan: onMenuChange,    
    btnsClic: onOrdeBtnsClick,
}