import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormMemo, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    bizs_gubu: '', 
    main_code: '', 
    main_yesn: true, 
    main_name: '', 
    main_memo: '',
};

const setCode = set.mes.com.code;

export function CodeInfoStatMainInsert(props) {
    const {
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 선택 항목
    const [gubuList, setGubuList] = useState([]);   
    const gubuListChange = useCallback((e) => setGubuList(e), []);

    const fetchData = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([setCode.gubu()]);
            const updatedGubuResult = Array.isArray(gubuResult) && gubuResult.length > 1 ? gubuResult.slice(1): gubuResult;
            gubuListChange(updatedGubuResult);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [gubuListChange]);

    // 열릴 때
    const onShowing = useCallback(() => {
        fetchData();
        dataUpdate(defaultData);
    }, [fetchData, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            bizsGubu: data.bizs_gubu ? data.bizs_gubu[0] : '',
            mainCode: data.main_code || '',
            mainYesn: data.main_yesn || false,
            mainName: data.main_name || '',
            mainMemo: data.main_memo || '',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
            <PopupForm
                title={'관리코드 등록'}       
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}  
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormSelc({ value: data.bizs_gubu, onValueChange: updateField('bizs_gubu'), label: '업무분류', items: gubuList, required: true })}
                    </GroupItem>
                    {FormText({ value: data.main_code, onValueChange: updateField('main_code'), label: '관리코드[자동]', disabled: true })}  
                    {FormBool({ value: data.main_yesn, onValueChange: updateField('main_yesn'), label: '사용여부' })}  
                </GroupItem>
                {FormText({ value: data.main_name, onValueChange: updateField('main_name'), label: '관리코드명', required: true })}
                {FormMemo({ value: data.main_memo, onValueChange: updateField('main_memo'), label: '비고' })}
            </PopupForm>            
    );
}