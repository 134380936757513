import EquiInfoStat from "./equi/InfoStat";
import MainInfoRegi from './maint/InfoRegi';
import MainInfoHist from './maint/InfoHist';
import PremListRegi from './prem/ListRegi';
import PremResuStat from './prem/ResuStat';
import PremResuRegi from './prem/ResuRegi';
import PremResuChan from './prem/ResuChan';
import CaliInfoRegi from './calib/InfoRegi';
import CaliInfoHist from './calib/InfoHist';
import EquiMoniStat from "./moni/Dash";

const catbEqui = ({ acce, gubu, cata }) => {
    const catb = "equi";
    const comp = {
        infoStat: () => (<EquiInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="F1" mainTitle="설비" mainRole="관리" />),
    }

    return comp;
};

const catbMain = ({ acce, gubu, cata }) => {
    const catb = "maint";
    const comp = {
        infoRegi: () => (<MainInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="F21" mainTitle="정비" mainRole="관리" />),
        infoHist: () => (<MainInfoHist acce={acce} gubu={gubu} cata={cata} catb={catb} code="F22" mainTitle="정비" mainRole="이력" subsTitle="보수" subsRole="이력" />),
    }

    return comp;
};

const catbPrem = ({ acce, gubu, cata }) => {
    const catb = "prem";
    const comp = {
        listRegi: () => (<PremListRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="F31" mainTitle="항목" mainRole="등록" subsTitle="항목" subsRole="상세" />),
        resuStat: () => (<PremResuStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="F32" mainTitle="결과" mainRole="현황" subsTitle="항목" subsRole="현황" />),
        resuRegi: () => (<PremResuRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="F33" mainTitle="결과" mainRole="등록" subsTitle="항목" subsRole="현황" />),
        resuChan: () => (<PremResuChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="F34" mainTitle="결과" mainRole="변경" subsTitle="항목" subsRole="현황" />),
    }

    return comp;
};

const catbCali = ({ acce, gubu, cata }) => {
    const catb = "calib";
    const comp = {
        infoRegi: () => (<CaliInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="F41" mainTitle="검교정" mainRole="관리" />),
        infoHist: () => (<CaliInfoHist acce={acce} gubu={gubu} cata={cata} catb={catb} code="F42" mainTitle="검교정" mainRole="이력" subsTitle="검교정" subsRole="상세" />),
    }

    return comp;
};

const catbMoni = ({ acce, gubu, cata }) => {
    const catb = "moni";
    const comp = {
        infoStat: () => (<EquiMoniStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="F5" title="설비 모니터링" />),
    }

    return comp;
};

export const cataEqu = ({ acce, gubu }) => {
    const cata = "equ"
    const comp = {
        catbEqui: catbEqui({ acce: acce, gubu: gubu, cata: cata }),
        catbMain: catbMain({ acce: acce, gubu: gubu, cata: cata }),
        catbPrem: catbPrem({ acce: acce, gubu: gubu, cata: cata }),
        catbCali: catbCali({ acce: acce, gubu: gubu, cata: cata }),
        catbMoni: catbMoni({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}