import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid } from '../../../../script/components/popup/popup';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const keyExpr = 'matl_code';  // 데이터 키

const setPurs = set.mes.pro.purs;

export function PursInfoRegiSelect(props) {
    const {
        refresh,
        selectApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const matlGridRef = useRef(null);
    // 검색 조건
    const [matlName, setMatlName] = useState('');
    const [itemGubu, setItemGubu] = useState('');
    const [gubuList, setGubuList] = useState([]);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    const itemGubuChange = useCallback((e) => setItemGubu(e), []);
    const gubuListChange = useCallback((e) => setGubuList(e), []);
    // 조회
    const handleSearch = useCallback(async () => {
        if (itemGubu) {
            const sendData = { itemGubu: itemGubu,  matlName: matlName };
            await fwUtil.apis.search(selectApi, sendData , dataUpdate);
        }
    }, [itemGubu, matlName, dataUpdate, selectApi]);    

    useEffect(() => {
        handleSearch();
    }, [itemGubu, handleSearch]);

    const fetchData = useCallback(async () => {
        try {
            const [gubuResult] = await Promise.all([setPurs.selc()]);
            gubuListChange(gubuResult);
            itemGubuChange(gubuResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [gubuListChange, itemGubuChange]);

    // 열릴 때
    const onShowing = () => {
        setLoading(true);
    };

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        await fetchData();
        await handleSearch();
        setLoading(false);
    }, [fetchData, handleSearch, setLoading]);

    // 제출
    const onSubmit = useCallback(async () => {
        const selectedRowKey = fwUtil.grid.get.sKey(matlGridRef);
        if (selectedRowKey) {
            setLoading(true);
            const selectedData = matlGridRef.current.instance.getSelectedRowsData() || [];

            const convertdData = selectedData.map((item) => ({
                itemGubu: item.item_gubu || '',
                matlCode: item.matl_code || ''
            }));
            const postData = { setaMaps: convertdData }
            await fwUtil.apis.submit(insertApi, postData, refresh);
            matlGridRef.current.instance.deselectAll();
            setLoading(false);
            hiding();
        } else {
            fwUtil.aler.toast.s1Erro();
        }
                
    }, [insertApi, hiding, matlGridRef, refresh]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'selc', locate: 'before', caption: '제품구분', value: itemGubu, valueChange: itemGubuChange, search: handleSearch, items: gubuList },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch },
    ], [matlName, matlNameChange, itemGubu, itemGubuChange, handleSearch, gubuList]);
    
    const gridBody = useMemo(() => (
        <ScrollGrid
            title="자재"
            role="목록"
            gridDataSource={data}
            gridRef={matlGridRef}
            mainKey={keyExpr}
            width={isXSmall ? 300 : isSmall ? 550 : 860}
            height={600}
            selection={'multiple'}
            toolbar={{ default: false, items: toolbarItems }}
            hideColumnChooser
        >
            {CommCol({ dataField: "matl_code", caption: "구성품번" })}
            {CommCol({ dataField: "matl_name", caption: "구성품명" })}
            {CommCol({ dataField: "item_gubu", caption: "품목구분" })}
        </ScrollGrid>
    ), [data, matlGridRef, toolbarItems, isXSmall, isSmall]);

    return ( 
        <PopupGrid
            title={'자재 목록'}   
            type={'추가'}
            loading={loading}
            visible={visible}  
            showing={onShowing}
            shown={onShown}  
            hiding={hiding}
            onSubmit={onSubmit}
        >   
            {gridBody}
        </PopupGrid>
    );
}