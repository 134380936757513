import OrdeInfoUplo from "./order/InfoUplo";
import OrdeInfoStat from "./order/InfoStat";
import OrdeInfoRegi from "./order/InfoRegi";
import OrdeInfoHist from "./order/InfoHist";
import SaleInfoCanc from "./sale/InfoCanc";
import SaleInfoRegi from "./sale/InfoRegi";
import SaleInfoStat from "./sale/InfoStat";
import ShipInfoChan from "./ship/InfoChan";
import ShipInfoHist from "./ship/InfoHist";
import ShipInfoRegi from "./ship/InfoRegi";
import ShipInfoRepo from "./ship/InfoRepo";
import ShipInfoStat from "./ship/InfoStat";
import QuotInfoRegi from "./quot/InfoRegi";
import QuotInfoRepo from "./quot/InfoRepo";

const catbOrde = ({ acce, gubu, cata }) => {
    const catb = "order";
    const comp = {
        infoUplo: () => (<OrdeInfoUplo acce={acce} gubu={gubu} cata={cata} catb={catb} code="A11" mainTitle="수주" mainRole="업로드" subsTitle="수주" subsRole="상세" />),
        infoStat: () => (<OrdeInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="A12" mainTitle="수주" mainRole="현황" />),
        infoRegi: () => (<OrdeInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="A13" mainTitle="수주" mainRole="등록" />),        
        infoHist: () => (<OrdeInfoHist acce={acce} gubu={gubu} cata={cata} catb={catb} code="A14" mainTitle="수주" mainRole="변경내역" />),  
    }

    return comp;
};

const catbShip = ({ acce, gubu, cata }) => {
    const catb = "ship";
    const comp = {
        infoStat: () => (<ShipInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="A21" mainTitle="납품" mainRole="현황" subsTitle="납품" subsRole="상세" />),
        infoRegi: () => (<ShipInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="A22" mainTitle="납품" mainRole="등록" />),
        infoChan: () => (<ShipInfoChan acce={acce} gubu={gubu} cata={cata} catb={catb} code="A23" mainTitle="납품" mainRole="변경" subsTitle="납품" subsRole="상세" />),        
        infoRepo: () => (<ShipInfoRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="A24" mainTitle="명세서" mainRole="출력" subsTitle="납품" subsRole="상세" />),  
        infoHist: () => (<ShipInfoHist acce={acce} gubu={gubu} cata={cata} catb={catb} code="A25" mainTitle="납품이력" mainRole="현황" />),
    }

    return comp;
};

const catbSale = ({ acce, gubu, cata }) => {
    const catb = "sales";
    const comp = {
        infoStat: () => (<SaleInfoStat acce={acce} gubu={gubu} cata={cata} catb={catb} code="A31" mainTitle="매출" mainRole="현황" subsTitle="매출" subsRole="상세" />),
        infoRegi: () => (<SaleInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="A32" mainTitle="매출" mainRole="등록" subsTitle="납품" subsRole="상세" />),  
        infoCanc: () => (<SaleInfoCanc acce={acce} gubu={gubu} cata={cata} catb={catb} code="A33" mainTitle="매출" mainRole="취소" subsTitle="매출" subsRole="상세" />),        
    }

    return comp;
};

const catbQuot = ({ acce, gubu, cata }) => {
    const catb = "quot";
    const comp = {
        infoRegi: () => (<QuotInfoRegi acce={acce} gubu={gubu} cata={cata} catb={catb} code="A41" mainTitle="견적" mainRole="등록" subsTitle="견적" subsRole="상세" />),
        infoRepo: () => (<QuotInfoRepo acce={acce} gubu={gubu} cata={cata} catb={catb} code="A42" mainTitle="견적" mainRole="출력" subsTitle="견적" subsRole="상세" />),
    }

    return comp;
};

export const cataSal = ({ acce, gubu }) => {
    const cata = "sal"
    const comp = {
        catbOrde: catbOrde({ acce: acce, gubu: gubu, cata: cata }),
        catbShip: catbShip({ acce: acce, gubu: gubu, cata: cata }),
        catbSale: catbSale({ acce: acce, gubu: gubu, cata: cata }),
        catbQuot: catbQuot({ acce: acce, gubu: gubu, cata: cata }),
    }

    return comp;
}