import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid, FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    docu_numb: '',     //견적번호        
    docu_seri: '',     //견적순번        
    item_code: '',     //제품코드        
    item_name: '',     //제품명           
    desc_size: '',     //규격              
    item_qtys: 0.000,  //수량              
    item_quni: '',     //단위              
    item_cwon: 0.00,   //단가              
    item_remk: '',     //비고
};

const setOrde = set.mes.sal.orde;

export function QuotRegiDetailInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [mainData, setMainData] = useState([]);
    const mainDataUpdate = useCallback((e) => setMainData(e), []);
    const [subsData, setSubsData] = useState([]);
    const subsDataUpdate = useCallback((e) => setSubsData(e), [defaultData]);

    // 그리드
    const itemGridRef = useRef(null);
    const [itemData, setItemData] = useState([]);
    const itemGridUpdate = useCallback((e) => setItemData(e), []);
    const itemKeyExpr = 'item_code';
    // 그리드 팝업
    const [itemPopupVisible, setItemPopupVisible] = useState(false);
    const itemPopupHiding = useCallback(() => setItemPopupVisible(false), []);
    // 팝업 조회
    const itemHandleSearch = useCallback(async () => {
        const sendData = { itemCode: "" };
        const res = await setOrde.item(sendData);
        itemGridUpdate(res);
    }, [itemGridUpdate]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const mainRowData = fwUtil.grid.get.sData(mainGrid);
        if (!mainRowData) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const updatedData = {
                ...defaultData,
                docu_numb: (mainRowData.docu_numb + mainRowData.docu_revi),
            };
            mainDataUpdate(mainRowData);
            subsDataUpdate(updatedData);
        }
    }, [hiding, mainGrid, mainDataUpdate, subsDataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!subsData) return;
        subsDataUpdate({ ...subsData, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            docuNumb: subsData.docu_numb || '',      
            docuSeri: subsData.docu_seri || '',       
            itemCode: subsData.item_code || '',       
            itemName: subsData.item_name || '',        
            descSize: subsData.desc_size || '',         
            itemQtys: subsData.item_qtys || 0.000,      
            itemQuni: subsData.item_quni || '',         
            itemCwon: subsData.item_cwon || 0.00,       
            itemRemk: subsData.item_remk || '',   
        }

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [subsData, insertApi, refresh, hiding]);

    // 팝업 열릴 때
    const itemPopupOnShowing = useCallback(() => {
        itemHandleSearch();
    }, [itemHandleSearch]);

    // 팝업 더블 클릭
    const itemOnRowDblClick = useCallback((e) => {
        subsDataUpdate({ ...subsData, item_code: e.data.item_code, item_name: e.data.item_name, orde_qtys: e.data.mode_qtys });
        itemPopupHiding();
    }, [subsData, subsDataUpdate, itemPopupHiding]);

    const itemGrid = useMemo(() => {
        return (
            <ScrollGrid
                title={'제품'}
                role={'목록'}
                gridDataSource={itemData}
                gridRef={itemGridRef}
                mainKey={itemKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={itemOnRowDblClick}
                selection={'single'}
            >
                {CommCol({ dataField: "item_code", caption: "제품번호" })}
                {CommCol({ dataField: "item_name", caption: "제품명" })}
            </ScrollGrid>
        )
    }, [itemData, itemGridRef, itemKeyExpr, itemOnRowDblClick, isXSmall, isSmall]);

    return (
        <>
            <PopupGrid
                title={'제품 선택'}
                visible={itemPopupVisible}
                shown={itemPopupOnShowing}
                hiding={itemPopupHiding}
            >
                {itemGrid}
            </PopupGrid>
            <PopupForm
                title={'견적상세 - 등록'}
                type={'등록'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                    <GroupItem colCount={isXSmall ? 1 : 4} colSpan={isXSmall ? 1 : 2} cssClass='remk-form quot-docn'>
                        {FormText({ value: mainData.docu_numb, disabled: true, label: "견적번호" })}
                        {FormText({ value: mainData.docu_revi, disabled: true, label: "REV. NO" })}
                        {FormText({ value: mainData.cust_name, disabled: true, label: "고 객 명" })}
                        {FormText({ value: mainData.prjt_name, disabled: true, label: "공 사 명" })}
                    </GroupItem>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2} cssClass='remk-form quot-item'>
                        <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                            {FormText({ value: subsData.docu_seri, onValueChange: updateField('docu_seri'), label: "item순번", required: true, disabled: true })}
                        </GroupItem>
                        <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                            {FormText({ value: subsData.item_name, onValueChange: updateField('item_name'), label: "제 품 명", required: true, })}
                            {FormGrid({ value: subsData.item_code, onValueChange: updateField('item_code'), label: "제품코드", required: true, onClick: () => setItemPopupVisible(true), })}
                        </GroupItem>   
                        <GroupItem colCount={isXSmall ? 1 : 3} colSpan={isXSmall ? 1 : 2}>
                            {FormText({ value: subsData.desc_size, onValueChange: updateField('desc_size'), label: "규   격" })}
                            {FormNumb({ value: subsData.item_qtys, onValueChange: updateField('item_qtys'), label: "수   량", length: [12, 2] })}
                            {FormText({ value: subsData.item_quni, onValueChange: updateField('item_quni'), label: "단   위" })}
                            {FormNumb({ value: subsData.item_cwon, onValueChange: updateField('item_cwon'), label: "단   가", length: [15, 2] })}
                            {FormNumb({ value: subsData.subs_kwon, onValueChange: updateField('subs_kwon'), label: "금   액", length: [15, 2], disabled: true })}
                        </GroupItem> 
                        <GroupItem colCount={isXSmall ? 1 : 1} colSpan={isXSmall ? 1 : 2}>
                            {FormMemo({ value: subsData.item_remk, onValueChange: updateField('item_remk'), label: "비   고" })}
                        </GroupItem>
                    </GroupItem>
                </GroupItem>
            </PopupForm>
        </>
    );
}