import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupWarn } from '../../../../script/components/popup/popup';
import { FormBool, FormDate } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    reqe_date: fwUtil.date.getsText.date(),
    insp_okey: true,
};

const shipDataSource = fwUtil.grid.get.dataSource();

export function ShipReqeRegiInsert(props) {
    const {
        mainGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 수주 그리드
    const shipRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            shipDataSource.store().insert({ init: true }).then(() => shipDataSource.reload());
            const shipSelected = mainGrid.current.instance.getSelectedRowsData() || [];
            for (const ship of shipSelected) {
                const newData = {
                    stoc_loca: ship.stoc_loca || '',
                    orde_numb: ship.orde_numb || '',
                    clie_code: ship.clie_code || '',
                    clie_name: ship.clie_name || '',
                    item_code: ship.item_code || '',
                    item_name: ship.item_name || '',
                    deli_date: ship.deli_date || null,
                    orde_qtys: ship.orde_qtys || 0.000,
                    deli_qtys: ship.deli_qtys || 0.000,
                    stoc_qtys: ship.stoc_qtys || 0.000,
                    reqe_qtys: ship.reqe_qtys || 0.00,
                };
                shipDataSource.store().insert(newData);
            }
            
            shipDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, mainGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const shipData = shipRef?.current?.instance?.getDataSource()?.items();
        const postShipData = shipData.map((item) => ({
            stocLoca: item.stoc_loca || '',
            reqeDate: data.reqe_date || fwUtil.date.getsText.date(),
            inspOkey: data.insp_okey || false,
            ordeNumb: item.orde_numb || '',
            clieCode: item.clie_code || '',
            clieName: item.clie_name || '',
            itemCode: item.item_code || '',
            itemName: item.item_name || '',            
            reqeQtys: item.reqe_qtys || 0.00,
            reqeRemk: item.reqe_remk || '',
        }));        
        const postData = {
            reqeNumb: '',
            setaMaps: postShipData || [],
        };

        await fwUtil.apis.submit(insertApi, postData, refresh);
        setLoading(false);
        mainGrid?.current?.instance?.deselectAll();
        alertHide();
        hiding();
    }, [data, insertApi, refresh, hiding, alertHide, shipRef, mainGrid]);    

    return (
        <>           
        <PopupForm
            title={'출하 지시 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'수주목록'}
                        gridRef={shipRef} 
                        gridDataSource={shipDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "clie_name", caption: "고객명", allowEditing: false })}
                        {CommCol({ dataField: "item_name", caption: "제품명", allowEditing: false })}
                        {DateCol({ dataField: "deli_date", caption: "납기일자", allowEditing: false })}
                        {DeciCol({ dataField: "orde_qtys", caption: "수주수량", allowEditing: false })}
                        {DeciCol({ dataField: "deli_qtys", caption: "기출하량", allowEditing: false })}
                        {DeciCol({ dataField: "stoc_qtys", caption: "재고수량", allowEditing: false })}
                        {DeciCol({ dataField: "reqe_qtys", caption: "지시수량", check: true })}
                        {CommCol({ dataField: "reqe_remk", caption: "특기사항", check: true })}
                    </AddingGrid>
                </div>
            }
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.reqe_date, onValueChange: updateField('reqe_date'), label: '지시일자' })}
                {FormBool({ value: data.insp_okey, onValueChange: updateField('insp_okey'), label: '출하검사 확인' })}
            </GroupItem>
        </PopupForm>   
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 건들을 출하 지시 하시겠습니까?'}
        />  
        </>
    );
}