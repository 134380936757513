import React, { useState, useCallback, useRef, useMemo } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid, PopupWarn } from '../../../../script/components/popup/popup';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';

const keyExpr = ['purs_numb', 'matl_code'];  // 데이터 키

export function PproInfoRegiSubsSelect(props) {
    const {
        mainGrid,
        refresh,
        selectApi, insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const matlGridRef = useRef(null);
    // 검색 조건
    const [matlCode, setMatlCode] = useState('');
    const [matlName, setMatlName] = useState('');
    const matlCodeChange = useCallback((e) => setMatlCode(e), []);
    const matlNameChange = useCallback((e) => setMatlName(e), []);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);    

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { matlCode: matlCode, matlName: matlName };
        await fwUtil.apis.search(selectApi, sendData, dataUpdate);
    }, [matlCode, matlName, dataUpdate, selectApi]);

    // 열릴 때
    const onShowing = () => {
        setLoading(true);
    };

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        await handleSearch();
        setLoading(false);
    }, [handleSearch, setLoading]);

    // 제출
    const onSubmit = useCallback(async () => {
        const selectPproKey = fwUtil.grid.get.sKey(mainGrid);
        const selectedRowKey = fwUtil.grid.get.sKey(matlGridRef);
        if (selectedRowKey) {
            setLoading(true);
            const selectedData = matlGridRef.current.instance.getSelectedRowsData() || [];

            const convertdData = selectedData.map((item) => ({
                pproSeri: item.ppro_seri || '',
                matlCode: item.matl_code || '',
                pproQtys: item.requ_qtys || 0.000,
                pproWeig: item.requ_weig || 0.000,
                pproPric: item.pros_pric || item.ware_pric || 0.00,
                reqeDept: item.reqe_dept || '',
                pproMemo: item.ppro_memo || '',
                pursNumb: item.purs_numb || '',
            }));
            const postData = { pproNumb: selectPproKey, setaMaps: convertdData }
            await fwUtil.apis.submit(insertApi, postData, refresh);
            matlGridRef.current.instance.deselectAll();
            setLoading(false);
            hiding();
        } else {
            fwUtil.aler.toast.s1Erro();
        }

    }, [insertApi, hiding, matlGridRef, refresh, mainGrid]);

    const handleSubmit = useCallback(() => {
        const seleData = fwUtil.grid.get.mData(matlGridRef);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.nsWarn();
        } else {
            const hasZeroPrices = seleData.some(item => item.pros_pric == 0 && item.ware_pric == 0);
            console.log(seleData);
            if (hasZeroPrices) {
                setAlertVisible(true);
            } else {
                onSubmit();
            }
        }
    }, [setAlertVisible, onSubmit]);

    const onRowClick = useCallback(({ key, isSelected }) => {
        fwUtil.grid.sel.mRow(matlGridRef, key, isSelected);
    }, [matlGridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '제품번호', value: matlCode, valueChange: matlCodeChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '제품명', value: matlName, valueChange: matlNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [matlCode, matlCodeChange, matlName, matlNameChange, handleSearch]);

    const gridBody = useMemo(() => (
        <ScrollGrid
            title="자재"
            role="목록"
            gridDataSource={data}
            gridRef={matlGridRef}
            mainKey={keyExpr}
            width={isXSmall ? 300 : isSmall ? 550 : isMedium ? 860 : 1200}
            height={600}
            onRowClick={onRowClick}
            selection={'multiple'}
            toolbar={{ default: false, items: toolbarItems }}
            edit
        >
            {CommCol({ dataField: "purs_numb", caption: "요청번호", allowEditing: false })}
            {DateCol({ dataField: "purs_date", caption: "요청일자", allowEditing: false })}
            {CommCol({ dataField: "matl_code", caption: "자재번호", allowEditing: false })}
            {CommCol({ dataField: "matl_name", caption: "자재품명", allowEditing: false })}
            {DeciCol({ dataField: "requ_qtys", caption: "요청수량", allowEditing: false })}
            {CommCol({ dataField: "pros_unit", caption: "단위", allowEditing: false })}
            {DeciCol({ dataField: "pros_pric", caption: "단가", check: true })}
            {DeciCol({ dataField: "ware_pric", caption: "최종입고 단가", allowEditing: false })}
            {DeciCol({ dataField: "pros_cwon", caption: "금액", allowEditing: false })}
            {/*CommCol({ dataField: "reqe_dept", caption: "수요처" })*/}
            {DateCol({ dataField: "deli_date", caption: "납기요청일", allowEditing: false })}
            {CommCol({ dataField: "purs_memo", caption: "비고", allowEditing: false })}
        </ScrollGrid>
    ), [data, matlGridRef, onRowClick, toolbarItems, isXSmall, isSmall, isMedium]);

    return (
        <>
            <PopupWarn
                visible={alertVisible}
                hiding={alertHide}
                confirm={onSubmit}
                reverse
                warning={"발주 품목 등록 중 단가가 0인 항목이 있습니다."}
                message={'등록 후 확인해주세요.'}
            />        
            <PopupGrid
                title={'발주 상세품목'}
                type={'추가'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                shown={onShown}
                hiding={hiding}
                onSubmit={handleSubmit}
            >
                {gridBody}
            </PopupGrid>
        </>
    );
}