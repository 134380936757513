import { ScrollView } from "devextreme-react";
import { HomeCardItem } from "./item/Home-card";
import { fwUtil } from "../../../script/util";

function getTickTitle(data) {
  if (!data.user_name) {
    return '알 수 없음';
  }

  if (data.user_name === "개발자") {
    return '개발자';
  }

  let title = '';

  if (data.dept_name) {
    title += data.dept_name;
  }

  if (title) {
    title += ' - ';
  }
  title += data.user_name;

  if (data.posi_name) {
    title += ` ${data.posi_name}`;
  }

  return title;
}

export function HomeConnList(props) {
  const { title, data } = props; 

  return (
    <HomeCardItem title={title}>
      <ScrollView>
        <div className="fw-tick-body">
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <div
                className="fw-tick-card fw-flex-box row a-center"
                key={index}
              >
                <div className={`tick-icon-frame fw-flex-box a-center j-center ${item.user_name ? 'Identified' : 'undefined'}`}>
                  <i className={`dx-icon-user`}></i>
                </div>
                <div className="tick-body fw-flex-box col">
                  <div className="tick-title">
                    <span className={`title-status ${item.user_name ? 'Identified' : 'undefined'}`}>●</span>
                    <span className="title-content">{getTickTitle(item)}</span>
                  </div>
                  <div className="tick-detail">
                    <i className="dx-icon-clock"></i> 세션 만료까지 : {fwUtil.date.convTime.unTime(item.sess_expi)}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </ScrollView>
    </HomeCardItem>
  );
}
