import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormMemo, FormNumb, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    user_idno: "",
    user_name: "",
    logs_date: fwUtil.date.getsText.date(),
    scre_name: "",
    logs_gubu: "",
    logs_ipno: "",
    data_size: 0,
    send_remk: "",
    send_erro: ""
};

export function LogsInfoStatUpdate(props) {
    const {
        refresh,
        mainGrid,
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.lKey(mainGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const sendData = { userIdno: selectedRowKey.user_idno, logsDate: selectedRowKey.logs_date };
            const res = await fwUtil.apis.search(targetApi, sendData);
            const resData = {
                user_idno: res.user_idno || "",
                user_name: res.user_name || "",
                logs_date: res.logs_date || fwUtil.date.getsText.date(),
                scre_name: res.scre_name || "",
                logs_gubu: res.logs_gubu || "",
                logs_ipno: res.logs_ipno || "",
                data_size: res.data_size || 0,
                send_remk: res.send_remk || "",
                send_erro: res.send_erro || ""
            };

            dataUpdate(resData);
        }
    }, [hiding, mainGrid, targetApi, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            userIdno: data.user_idno || "",
            logsDate: fwUtil.date.convTime.toLoca(data.logs_date) || null,
            screName: data.scre_name || "",
            logsGubu: data.logs_gubu || "",
            logsIpno: data.logs_ipno || "",
            dataSize: data.data_size || 0,
            sendRemk: data.send_remk || "",
            sendErro: data.send_erro || ""
        };

        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <PopupForm
            title={'로그 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? "100%" : 600}  
        >
            <GroupItem colCount={isXSmall ? 1 : 2}>
                {FormText({ value: data.scre_name, onValueChange: updateField('scre_name'), label: '화면명' })}
                {FormText({ value: data.logs_gubu, onValueChange: updateField('logs_gubu'), label: '사용구분' })}
                {FormText({ value: data.logs_ipno, onValueChange: updateField('logs_ipno'), label: '접속 IP' })}
                {FormNumb({ value: data.data_size, onValueChange: updateField('data_size'), label: '데이터크기', length: [11, 0], suffix: 'Kbyte' })}
            </GroupItem>
            {FormMemo({ value: data.send_remk, onValueChange: updateField('send_remk'), label: '비고' })}
            {FormMemo({ value: data.send_erro, onValueChange: updateField('send_erro'), label: '오류내용' })}
        </PopupForm>
    );
}