import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormDate, FormNumb, FormText, FormMemo } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    plan_numb: '',
    plan_date: fwUtil.date.getsText.date(),
    plan_qtys: 0,
    item_code: '',
    item_name: '',
    orde_numb: '',
    work_numb: '',
    work_date: fwUtil.date.getsText.pDate(-5),
    work_year: false,
    plan_memo: '',
}

export function PlanInfoRegiMainUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        const seleData = fwUtil.grid.get.sData(mainGrid);
        if (fwUtil.conv.check.nua(seleData)) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
           
            const resData = {
                plan_numb: seleData.plan_numb || defaultData.plan_numb,
                orde_numb: seleData.orde_numb || defaultData.orde_numb,
                work_numb: seleData.work_numb || defaultData.work_numb,
                item_code: seleData.item_code || defaultData.item_code,
                item_name: seleData.item_name || defaultData.item_name,
                plan_date: seleData.plan_date || defaultData.plan_date,
                plan_qtys: seleData.plan_qtys || defaultData.plan_qtys,
                work_date: seleData.work_date || defaultData.work_date,
                work_year: seleData.work_year || defaultData.work_year,
                plan_memo: seleData.plan_memo || defaultData.plan_memo,
            };
            console.log(resData)
            dataUpdate(resData);
        }
    }, [mainGrid, hiding, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true)
        const postData = {
            planNumb: data.plan_numb || defaultData.plan_numb,
            ordeNumb: data.orde_numb || defaultData.orde_numb,
            workNumb: data.work_numb || defaultData.work_numb,
            itemCode: data.item_code || defaultData.item_code,
            itemName: data.item_name || defaultData.item_name,
            planDate: data.plan_date || defaultData.plan_date,
            planQtys: data.plan_qtys || defaultData.plan_qtys,
            workDate: data.work_date || defaultData.work_date,
            workYear: data.work_year || defaultData.work_year,
            planMemo: data.plan_memo || defaultData.plan_memo,
        };
        await fwUtil.apis.action(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'연간계획 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? "100%" : 600}
            >
                <GroupItem colCount={isXSmall ? 1 : 2}>
                    {FormText({ value: data.plan_numb, onValueChange: updateField('plan_numb'), label: '생산계획번호', disabled: true, required: true })}
                    {FormText({ value: data.orde_numb, onValueChange: updateField('orde_numb'), label: '수주번호', disabled: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', disabled: true, required: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_name'), label: '제 품 명', disabled: true, required: true })}
                    {FormDate({ value: data.plan_date, onValueChange: updateField('plan_date'), label: '생산계획일', required: true })}
                    {FormNumb({ value: data.plan_qtys, onValueChange: updateField('plan_qtys'), label: '계획수량', required: true, length: [5, 0] })}
                    {FormDate({ value: data.work_date, onValueChange: updateField('work_date'), label: '생산예정일', required: true })}
                </GroupItem>
                {FormMemo({ value: data.plan_memo, onValueChange: updateField('plan_memo'), label: '비고' })}   
            </PopupForm>
        </>
    );
}