import { fwUtil } from "../../focuswin/script/util";
import { mesGubu } from "../set";

const getOrdeClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/order/client/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getOrdeItem = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/order/item/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

const getShipClie = async (sendData) => {
    try {
        const api = `privat${mesGubu}/sys/set/order/client/list`;
        const data = await fwUtil.apis.search(api, sendData);
        return data;
    }
    catch (error) {
        console.log('getPosiList.error === ', error);
        return [];
    }
};

export const sal = {
    orde: {
        clie: getOrdeClie,
        item: getOrdeItem,
    },
    ship: {
        clie: getShipClie,
    }
}