import React, { useState, useCallback, useRef } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm, PopupGrid, PopupWarn } from '../../../../script/components/popup/popup';
import { FormDate, FormGrid } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { AddingGrid, ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const listDataSource = fwUtil.grid.get.dataSource();

const defaultData = {
    plan_date: fwUtil.date.getsText.date(),
    prem_date: fwUtil.date.getsText.date(),
    prem_damd: '',
};

const setPrem = set.mes.equ.prem;

export function PreMaintResultInsert(props) {
    const {
        mainGrid, subsGrid,
        refresh,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);    
    // 발주 그리드
    const listRef = useRef(null);
    // 확인 알림
    const [alertVisible, setAlertVisible] = useState(false);
    const alertHide = useCallback(() => setAlertVisible(false), []);
    // 업체 그리드
    const damdGridRef = useRef(null);
    const [damdData, setDamdData] = useState([]);
    const damdGridUpdate = useCallback((e) => setDamdData(e), []);
    const damdKeyExpr = 'user_idno';  
    // 그리드 팝업
    const [damdPopupVisible, setDamdPopupVisible] = useState(false);
    const damdPopupHiding = useCallback(() => setDamdPopupVisible(false), []);    

    // 업체 조회
    const damdHandleSearch = useCallback(async() => {
        const sendData = { userIdno: "" };
        const res = await setPrem.damd(sendData);
        damdGridUpdate(res);
    }, [damdGridUpdate]);

    // 업체 팝업 열릴 때
    const damdPopupOnShowing = useCallback(() => {
        damdHandleSearch();
    }, [damdHandleSearch]);

    // 업체 팝업 더블 클릭
    const damdOnRowDblClick = useCallback((e) => {
        dataUpdate({ ...data, prem_damd: e.data.user_name });
        damdPopupHiding();
    }, [data, dataUpdate, damdPopupHiding]);    

    // 열릴 때
    const onShowing = useCallback(async() => {
        const selectedRowKey = fwUtil.grid.get.sKey(subsGrid);
        if (!selectedRowKey) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            listDataSource.store().insert({ init: true }).then(() => listDataSource.reload());
            const selectedRowData = subsGrid.current.instance.getSelectedRowsData() || [];
            for (const item of selectedRowData) {
                const newData = {
                    chck_seri: item.chck_seri || '',
                    chck_part: item.chck_part || '',
                    chck_gubu: item.chck_gubu || '',
                    chck_term: item.chck_term || '',
                    chck_desc: item.chck_desc || '',
                    chck_resu: item.chck_resu || '',
                    chck_remk: item.chck_remk || '',
                    chck_memo: item.chck_memo || '',                    
                };
                listDataSource.store().insert(newData);
            }
            
            listDataSource.reload();
            dataUpdate(defaultData);
        }
    }, [hiding, subsGrid, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };		    

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const mainKey = fwUtil.grid.get.sKey(mainGrid);
        const listData = listRef?.current?.instance?.getDataSource()?.items();
        const postPremData = listData.map((item) => ({
            planDate: data.plan_date || null,
            premDate: data.prem_date || null,
            premDamd: data.prem_damd || '',
            premSeri: mainKey.prem_seri || '',
            chckSeri: item.chck_seri || '',
            chckPart: item.chck_part || '',
            chckGubu: item.chck_gubu || '',
            chckTerm: item.chck_term || '',
            chckDesc: item.chck_desc || '',
            premResu: item.chck_resu || '',
            premRemk: item.chck_remk || '',
            premMemo: item.chck_memo || '',
        }));        
        const postData = {
            mainNumb: mainKey || '',
            setaMaps: postPremData || [],
        };

        const allHiding = () => {
            alertHide();
            hiding();
        }
        await fwUtil.apis.action(insertApi, postData, refresh, allHiding);
        setLoading(false);
    }, [insertApi, refresh, hiding, alertHide, listRef, mainGrid, data]);

    return (
        <>
        <PopupGrid
            title={'점검자 선택'}   
            type={'선택'}
            visible={damdPopupVisible}  
            shown={damdPopupOnShowing}  
            hiding={damdPopupHiding}
        >   
            <ScrollGrid
                title={'점검자'}
                role={'목록'}
                gridDataSource={damdData}
                gridRef={damdGridRef}
                mainKey={damdKeyExpr}
                width={isXSmall ? 300 : isSmall ? 550 : 860}
                height={600}
                hideColumnChooser
                onRowDblClick={damdOnRowDblClick}
                toolbar={{ default: false }}
                selection={'single'}
            >
                {CommCol({ dataField: "dept_name", caption: "부서명" })}
                {CommCol({ dataField: "posi_name", caption: "직위명" })}
                {CommCol({ dataField: "user_name", caption: "성명" })}
            </ScrollGrid>
        </PopupGrid>          
        <PopupForm
            title={'예방정비 결과 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={() => setAlertVisible(true)}
            width={isXSmall ? 300 : isSmall ? 600 : isMedium ? 900 : 1200 } 
            additionalChild={
                <div className='fw-additional-child-box fw-flex-box col'>
                    <AddingGrid
                        title={'예방정비 결과 항목'}
                        gridRef={listRef} 
                        gridDataSource={listDataSource}
                        allowEdit
                        allowUpdate
                    >
                        {CommCol({ dataField: "chck_seri", caption: "순번", allowEditing: false })}
                        {CommCol({ dataField: "chck_part", caption: "점검PART", allowEditing: false })}
                        {CommCol({ dataField: "chck_gubu", caption: "점검구분", allowEditing: false })}
                        {CommCol({ dataField: "chck_term", caption: "점검주기", allowEditing: false })}
                        {CommCol({ dataField: "chck_desc", caption: "점검항목", allowEditing: false })}
                        {CommCol({ dataField: "chck_resu", caption: "점검결과", check: true })}
                        {CommCol({ dataField: "chck_remk", caption: "특이사항", check: true })}
                        {CommCol({ dataField: "chck_memo", caption: "비고", check: true })}                      
                    </AddingGrid>                    
                </div>
            }         
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4 } >
                {FormDate({ value: data.plan_date, onValueChange: updateField('plan_date'), label: '계획일자' })}
                {FormDate({ value: data.prem_date, onValueChange: updateField('prem_date'), label: '점검일자' })}
                {FormGrid({ value: data.prem_damd, onClick: () => setDamdPopupVisible(true), label: "점검자", required: true })}
            </GroupItem>
        </PopupForm>
        <PopupWarn
            visible={alertVisible}
            hiding={alertHide}
            confirm={onSubmit}
            message={'해당 항목들을 등록 하시겠습니까?'}
        />  
        </>
    );
}