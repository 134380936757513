import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { fwUtil } from "../../../../script/util";
import { useAuth } from "../../../../../contexts/auth";
import { CommDash } from "../../../../script/components/dash-board/dash-body/dash-body";
import { DashGridCard } from "./Dash-grid";
import { DashCharCard } from "./Dash-char";
import { FwAppLoader } from "../../../../script/components/load-panel/load-panel";
import { set } from "../../../../../api/set";
import { convertEquiSensDate } from "./Dash-util";
import "./dash.scss";

const setEqu = set.mes.equ;

export default function EquiMoniStat(props) {
    const {
        acce, gubu, cata, catb, code,
        title,
    } = props;

    // api
    const searchApi = `${acce}/${gubu}/${cata}/${catb}/search`;  // 조회 api
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 대쉬보드
    const dashRef = useRef(null);
    const [mainDashData, setMainDashData] = useState([]);
    const [subsDashData, setSUbsDashData] = useState([]);
    const mainDashDataUpdate = useCallback((e) => setMainDashData(e), []);
    const subsDashDataUpdate = useCallback((e) => setSUbsDashData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState([fwUtil.date.getsText.pDate(1),fwUtil.date.getsText.pDate(2)]);
    const [duraYesn, setDuraYesn] = useState(true);
    const [mainNumb, setMainNumb] = useState("전체");
    const [mainList, setMainList] = useState([]);
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);
    const mainNumbChange = useCallback((e) => { setMainNumb(e) }, []);
    const mainListChange = useCallback((e) => { setMainList(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, gubu, code);
    }, [authCheck, setLoading, setAuthList, gubu, code]);

    // 구분값 받아오기
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [mainResult] = await Promise.all([setEqu.moni.main()]);
                if (mainResult && mainResult.length > 0) {
                    mainListChange(mainResult);
                    mainNumbChange(mainResult[0].main_numb);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [mainListChange, mainNumbChange]);

    // 조회
    const handleSearch = useCallback(async () => {
        if (mainNumb) {
            setPageLoading(true);
            const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, mainNumb: mainNumb };
            const res = await fwUtil.apis.search(searchApi, sendData);
            const dateRes = convertEquiSensDate(res);
            mainDashDataUpdate(res);
            subsDashDataUpdate(dateRes);
            setPageLoading(false);            
        }
    }, [dateValue, duraYesn, mainNumb, searchApi, mainDashDataUpdate, subsDashDataUpdate]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '설비', value: mainNumb, valueChange: mainNumbChange, search: handleSearch, items: mainList, valueExpr: "main_numb", displayExpr: "equi_numb", width: 170 },        
    ], [mainNumb, mainNumbChange, mainList, handleSearch]);

    return (
        <div className="fw-dash-page fw-flex-box col prod">
            <FwAppLoader loading={loading} />                  
            <CommDash
                title={title}
                dashRef={dashRef}                
                duraYesn={duraYesn}                
                dateValue={dateValue}
                duraYesnChange={duraYesnChange}                
                dateValueChange={dateValueChange}
                search={handleSearch}             
                exp={{ fileName: "설비모니터링", disabled: authList.auth_dnlo === 0 }}
                toolbar={{ items: toolbarItems }}
            >
                <DashGridCard loading={pageLoading} data={mainDashData} />
                <DashCharCard loading={pageLoading} data={subsDashData} />
            </CommDash>
        </div>
    )
}