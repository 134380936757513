import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormBool, FormDate, FormMemo, FormNumb, FormSelc, FormText } from '../../../../script/components/form/form-item';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';
import { set } from '../../../../../api/set';

const defaultData = {
    // 작업정보
    work_numb: '', 
    item_code: '',
    item_name: '',
    rout_numb: '',
    rout_name: '',
    work_damd: '',
    insp_qtys: 0,
    // 불량정보
    insp_date: fwUtil.date.getsText.date(),
    insp_damd: '',
    judg_qtys: 0,
    judg_ca01: false,
    judg_ca02: false,
    judg_ca03: false,
    judg_ca04: false,
    judg_ca05: false,
    judg_ca06: false,
    judg_ca07: false,
    judg_ca08: false,
    judg_ca09: false,
    judg_ca10: false,
    judg_memo: '',
};

const setRout = set.mes.qci.rout;

export function RoutQcisRegiInsert(props) {
    const {
        refresh,
        mainGrid,
        insertApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 구분 리스트
    const [damdList, setDamdList] = useState([]);
    const damdListChange = useCallback((e) => setDamdList(e), []);

    const fetchSet = useCallback(async () => {
        try {
            const [damdResult] = await Promise.all([setRout.damd.list]);
            damdListChange(damdResult);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.s1Erro()
            hiding();
        }
    }, [damdListChange, hiding]);

    // 열릴 때
    const onShowing = useCallback(() => {
        const selectedMainRowData = fwUtil.grid.get.sData(mainGrid);
        if (selectedMainRowData) {
            fetchSet();
            const newData = {
                ...defaultData,
                work_numb: selectedMainRowData.work_numb || '',
                item_code: selectedMainRowData.item_code || '',
                item_name: selectedMainRowData.item_name || '',
                rout_numb: selectedMainRowData.rout_numb || '',
                rout_name: selectedMainRowData.rout_name || '',
                work_damd: selectedMainRowData.work_damd || '',
                insp_qtys: selectedMainRowData.insp_qtys || 0,
            }
            dataUpdate(newData);
        } else {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, hiding, mainGrid, fetchSet]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            workNumb: data.work_numb || '', 
            itemCode: data.item_code || '',
            itemName: data.item_name || '',
            routNumb: data.rout_numb || '',
            routName: data.rout_name || '',
            workDamd: data.work_damd || '',
            inspQtys: data.insp_qtys || 0,
            inspDate: data.insp_date ? fwUtil.date.convTime.fmDate(data.insp_date) : fwUtil.date.getsText.date(),
            inspDamd: data.insp_damd || '',
            judgQtys: data.judg_qtys || 0,
            judgCa01: data.judg_ca01 || false,
            judgCa02: data.judg_ca02 || false,
            judgCa03: data.judg_ca03 || false,
            judgCa04: data.judg_ca04 || false,
            judgCa05: data.judg_ca05 || false,
            judgCa06: data.judg_ca06 || false,
            judgCa07: data.judg_ca07 || false,
            judgCa08: data.judg_ca08 || false,
            judgCa09: data.judg_ca09 || false,
            judgCa10: data.judg_ca10 || false,
            judgMemo: data.judg_memo || '',
        };

        await fwUtil.apis.action(insertApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, insertApi, refresh, hiding]);

    return (
        <>
        <PopupForm
            title={'공정검사 등록'}       
            type={'등록'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? '100%' : isMedium ? 600 : 1200 }
        >
            <GroupItem colCount={isXSmall ? 1 : isSmall || isMedium ? 2 : 4}>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form insp-info'>
                    {FormText({ value: data.work_numb, onValueChange: updateField('work_numb'), label: '작업지시번호', disabled: true })}
                    {FormText({ value: data.item_code, onValueChange: updateField('item_code'), label: '제품번호', disabled: true })}
                    {FormText({ value: data.item_name, onValueChange: updateField('item_name'), label: '제품명', disabled: true })}
                    {FormText({ value: data.rout_numb, onValueChange: updateField('rout_numb'), label: '공정번호', disabled: true })}
                    {FormText({ value: data.rout_name, onValueChange: updateField('rout_name'), label: '공정명', disabled: true })}
                    {FormText({ value: data.work_damd, onValueChange: updateField('work_damd'), label: '작업자', disabled: true })}
                    {FormNumb({ value: data.insp_qtys, onValueChange: updateField('insp_qtys'), label: '불량수량', disabled: true })}
                </GroupItem>
                <GroupItem colCount={isXSmall ? 1 : 2 } colSpan={isXSmall ? 1 : 2 } cssClass='remk-form insp-judg'>
                    <GroupItem colCount={isXSmall ? 1 : 2} colSpan={isXSmall ? 1 : 2}>
                        {FormDate({ value: data.insp_date, onValueChange: updateField('insp_date'), label: '검사일자' })}
                        {FormSelc({ value: data.insp_damd, onValueChange: updateField('insp_damd'), label: '검사자', items: damdList })}
                        {FormNumb({ value: data.judg_qtys, onValueChange: updateField('judg_qtys'), label: '불합격수량' })}
                    </GroupItem>                    
                    <GroupItem colCount={4} colSpan={2}>
                        {FormBool({ value: data.judg_ca01, onValueChange: updateField('judg_ca01'), label: '소재', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca02, onValueChange: updateField('judg_ca02'), label: '셋팅', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca03, onValueChange: updateField('judg_ca03'), label: '외관', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca04, onValueChange: updateField('judg_ca04'), label: '치수', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca05, onValueChange: updateField('judg_ca05'), label: '탭/나사', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca06, onValueChange: updateField('judg_ca06'), label: '용접', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca07, onValueChange: updateField('judg_ca07'), label: '전/후처리', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca08, onValueChange: updateField('judg_ca08'), label: '조립/작동', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca09, onValueChange: updateField('judg_ca09'), label: '취급부주의', disabled: data.judg_qtys === 0 })}
                        {FormBool({ value: data.judg_ca10, onValueChange: updateField('judg_ca10'), label: '기타', disabled: data.judg_qtys === 0 })}
                    </GroupItem>
                    <GroupItem colSpan={isXSmall ? 1 : 2}>
                        {FormMemo({ value: data.judg_memo, onValueChange: updateField('judg_memo'), label: '불량내용', disabled: !data.judg_ca10 })}
                    </GroupItem>
                </GroupItem>      
            </GroupItem>
        </PopupForm> 
        </>
    );
}