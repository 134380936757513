import { DashCard } from "../../../../script/components/dash-board/dash-card/dash-card";
import { CommChart } from "../../../../script/components/chart/charts";
import { SBarSeri } from "../../../../script/components/chart/charts-item";
import { fwUtil } from "../../../../script/util";

export function DashOrdeCharCard(props) {
    const { loading , data } = props;

    const tooltipTamplate = (arg) => {
        return {
            text: `${arg.argumentText} ${arg.seriesName}: ${fwUtil.conv.to.krLocales(arg.value)}원`,
        };
    };

    return (
        <DashCard
            title={"총 수주 현황"}
            addClass={'orde-char'}
            loading={loading}
        >
            <CommChart
                dataSource={data}
                tooltip={{ location: "edge", template: tooltipTamplate }}
            >
                {SBarSeri({ valueField: "orde_kwon", caption: "금액", args: "orde_date" })}
            </CommChart>
        </DashCard>
    )
}